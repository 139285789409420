import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { NbCardModule, NbIconModule, NbSelectComponent, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
	startOfDay,
	endOfDay,
	subDays,
	addDays,
	endOfMonth,
	isSameDay,
	isSameMonth,
	addHours,
} from "date-fns";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
	DAYS_OF_WEEK,
	CalendarDateFormatter
} from 'angular-calendar';
import { EventColor } from "calendar-utils";
import { Subject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { id } from 'date-fns/locale';
import { CoursesService } from 'src/app/core/services/courses';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalListadoCursosComponent } from '../grupos/modal-listado-cursos/modal-listado-cursos.component';
import { ModalGruposEditarComponent } from '../modal-grupos-editar/modal-grupos-editar.component';
import { User } from 'src/app/core/models/users/user.models';

interface salwareEvent {
	idEvent?: Number;
	Name: string;
	Nivel: string;
	IdUser?: Number;
	IdCenter?: Number;
	IdGroup?: Number;
	IdCourse?: Number;
	IdTarget?: Number;
	Realizado?: Number;
	Fecha_Inicio?: Date;
	Fecha_Fin?: Date;
}

const colors: Record<string, EventColor> = {
	red: {
		primary: "#dc1431",
		secondary: "#d04545",
	},
	blue: {
		primary: "#233f7d",
		secondary: "#000080",
	},
	green: {
		primary: "#00a650",
		secondary: "#9cce04",
	},
};

@Component({
	selector: "app-modal-schedules",
	standalone: false,
	templateUrl: "./modal-schedules.component.html",
	styleUrls: ["./modal-schedules.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ModalSchedulesComponent {
	idCenter: any = 3;
	target: any;
	contentSetted: boolean = false;
	asignatura: any;
	user: User;
	refresh = new Subject<void>();
	events: CalendarEvent[] = [];
	activeDayIsOpen: boolean = true;
	levelsOfCountry: any;
	selectedGroupInfo: any;
	view: CalendarView = CalendarView.Month;
	viewDate = new Date();
	locale: string = "es";
	weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
	weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
	CalendarView = CalendarView;
	editingEvent: salwareEvent;
	modalData: {
		action: string;
		event: salwareEvent;
	};
	actions: CalendarEventAction[] = [
		{
			label: "Editar ",
			a11yLabel: "Edit",
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.handleEvent("Edited", event);
			},
		},
		{
			label: "  Borrar",
			a11yLabel: "Delete",
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.events = this.events.filter((iEvent) => iEvent !== event);
				this.handleEvent("Deleted", event);
			},
		},
	];
	selectedLevel: any;
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private activengb: NgbActiveModal,
		private loginService: LoginService,
		private GruposService: GruposService,
		private toaster: ToasterService,
		public coursesService: CoursesService
	) {
		//this.inactivityService.startInactivityTimer();
	}
	@ViewChild("modalContent", { static: true }) modalContent: TemplateRef<any>;
	@ViewChild("levelSelector", { static: true })
	levelSelector: NbSelectComponent;

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.pullCenterEvents(this.idCenter);
		this.user = this.loginService.getUser();

		this.coursesService
			.getCountryLevelsByCountry("ESP")
			.subscribe((countryLevels) => {
				if (countryLevels) {
					this.levelsOfCountry = countryLevels.data;

				}
			});
	}

	setLevel(level) {
		let levelName = this.levelsOfCountry.find((l) => l.idCountryLevel == level);
		this.editingEvent.Nivel = levelName.countryLevel;

		console.log("el nivel", this.selectedLevel);
	}

	selectContent() {
		const modalRef = this.modalService.open(ModalListadoCursosComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});

		modalRef.componentInstance.cameFromCompetitions = true;
		modalRef.componentInstance.targetSelected =
			this.target != null ? [this.target] : [];

		//idGroup: 0 for now TODO ask for this
		modalRef.componentInstance.group = { idGroup: 0 };

		modalRef.result.then((data) => {
			console.log("data", data);
			this.contentSetted = true;
			this.target = data.grafo;
			this.editingEvent.IdCourse = data.course.idCourse;
			this.editingEvent.IdTarget = data.grafo.idTarget;
			this.asignatura = data.grafo.tituloAsignatura;
		});
	}

	openGroupsSelector() {
		const modalGrupos = this.modalService.open(ModalGruposEditarComponent, {
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});

		modalGrupos.result.then((result) => {
			this.setSelectedGroupInfo();
		});
	}

	setSelectedGroupInfo() {
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		if (storedGroupInfo) {
			if (storedGroupInfo.idUser != this.user.idUser) {
				localStorage.removeItem("selectedGroupInfo");
			}
		}
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);

		this.editingEvent.IdGroup = this.selectedGroupInfo.idGroup;
	}

	dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
		if (isSameMonth(date, this.viewDate)) {
			if (
				(isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
				events.length === 0
			) {
				this.activeDayIsOpen = false;
			} else {
				this.activeDayIsOpen = true;
			}
			this.viewDate = date;
		}

		this.editingEvent = {
			Name: "",
			Nivel: "2 ESO",
			IdUser: this.loginService.getUser().idUser,
			IdCenter: 3,
			IdGroup: 432,
			IdCourse: 0,
			IdTarget: 0,
			Realizado: 0,
			Fecha_Inicio: date,
			Fecha_Fin: date,
		};

		this.handleEvent("Clicked", events[0]);
	}

	saveNewEvent(event: salwareEvent) {
		this.GruposService.createUpdateEvent(event).subscribe((res) => {
			if (res) {
				console.log("la respuesta de crear evento", res);

				if (
					res.error.msg == "Evento creado" ||
					res.error.msg == "Evento Actualizado"
				) {
					//notification success adn close modal
					this.toaster.success("Evento guardado correctamente");

					//i need to close only the modal with id #modalContent

					this.pullCenterEvents(this.idCenter);
				} else {
					this.toaster.error("Error al crear el evento");
				}
			}
		});
	}

	pullCenterEvents(idCenter) {
		this.GruposService.getCenterEvents(idCenter).subscribe((res) => {
			if (res) {
				console.log("los eventos de BD", res);
				this.fillEventsWithPulledEvents(res.data);
			}
		});
	}

	fillEventsWithPulledEvents(events) {
		this.events = events.map((event) => {
			return {
				id: event.idEvent,
				level: event.nivel,
				idGroup: event.idGroup,
				idCourse: event.idCourse,
				idTarget: event.idTarget,
				realizado: event.realizado ? 1 : 0,
				idUser: event.idUser,
				start: addDays(new Date(event.fecha_Inicio), 1),
				end: addDays(new Date(event.fecha_Fin), 1),
				title: event.name,
				color:
					event.colour == "rojo"
						? colors.red
						: event.colour == "azul"
						? colors.blue
						: colors.green,
				actions: this.actions,
				resizable: {
					beforeStart: true,
					afterEnd: true,
				},
				draggable: true,
			};
		});

		console.log("los eventos", this.events);
	}

	eventTimesChanged({
		event,
		newStart,
		newEnd,
	}: CalendarEventTimesChangedEvent): void {
		this.events = this.events.map((iEvent) => {
			if (iEvent === event) {
				return {
					...event,
					start: newStart,
					end: newEnd,
				};
			}
			return iEvent;
		});
		this.handleEvent("Dropped or resized", event);
	}

	handleEvent(action: string, event?: any): void {
		console.log("el evento", event, action);
		if (action == "Edited") {
			this.editingEvent = {
				idEvent: event.id,
				Name: event.title,
				Nivel: event.level,
				IdUser: event.idUser,
				IdCenter: 3,
				IdGroup: event.idGroup,
				IdCourse: event.idCourse,
				IdTarget: event.idTarget,
				Realizado: event.realizado,
				Fecha_Inicio: subDays(new Date(event.start), 1),
				Fecha_Fin: subDays(new Date(event.end), 1),
			};
			this.selectedLevel = event.level;
			this.modalService.open(this.modalContent, { size: "lg" });
		}else if(action == "Deleted"){
			console.log("el evento a borrar", event);
			this.deleteEvent(event);
		}else if(action == "Clicked"){
			this.modalService.open(this.modalContent, { size: "lg" });
		}

		// this.modalData = { this.editingEvent, action };


	}

	addEvent(): void {
		this.events = [
			...this.events,
			{
				title: "New event",
				start: startOfDay(new Date()),
				end: endOfDay(new Date()),
				color: colors.red,
				draggable: true,
				resizable: {
					beforeStart: true,
					afterEnd: true,
				},
			},
		];
	}

	deleteEvent(eventToDelete: CalendarEvent) {
		this.events = this.events.filter((event) => event !== eventToDelete);
		this.GruposService.deleteEventByIdEvent(eventToDelete.id).subscribe(
			(res) => {
				if (res) {
					if (res.error.msg == "El evento ha sido eliminado") {
						this.toaster.success("Evento Eliminado Correctamente");
					} else {
						this.toaster.error("Error al eliminar el evento");
					}
				}
			}
		);
	}

	setView(view: CalendarView) {
		this.view = view;
	}

	closeOpenMonthViewDay() {
		this.activeDayIsOpen = false;
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}
}
