import { Utils } from './../../../../../../core/utils/utils';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ModelQuizFile } from "src/app/core/models/quizzes";
import { QuizzesService } from "src/app/core/services/quizzes";
import { QuizzesstackService } from "src/app/core/services/quizzesstack/quizzesstack.service";
import { FILE_FORMATS } from "src/app/core/utils/file-formats";
import { SIGMA_CONSTANTS } from "src/app/core/utils/sigma-constants";
import { ModalTextEditService } from "src/app/shared/components/modal-text-edit/modal-text-edit.service";
import { ModalTextEditComponent } from "src/app/shared/components/modal-text-edit/node-detail-files-text-edit.component";
import { ViewFileModalComponent } from "src/app/shared/components/view-file-modal/view-file-modal.component";
import { environment } from "src/environments/environment";
import { AppZones } from 'src/app/core/models/masters/masters.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

/*********************** */
export const IMAGEN_URL = "imagen";
export const VIDEO_URL = "video";
export const AUDIO_URL = "audio";
export const DOC_URL = "doc";
export const TEXTO_URL = "text";

export const PICTURE_URL = "picture";
export const VID_URL = "video";
export const AUD_URL = "audio";
export const PDF_URL = "pdf";
export const TEXT_URL = "text";
/*****************************/

@Component({
    selector: "app-quiz-edit-multiple-body",
    templateUrl: "./quiz-edit-multiple-body.component.html",
    styleUrls: ["./quiz-edit-multiple-body.component.scss"],
    styles: [
        `
            .my-custom-class .tooltip-inner {
                background-color: green;
                font-size: 125%;
            }
            .my-custom-class .arrow::before {
                border-top-color: darkgreen;
            }
        `,
    ],
})
export class QuizEditMultipleBodyComponent implements OnInit {
    @ViewChild("myInput1", { static: true }) myInput1: ElementRef;
    @ViewChild("myInput2", { static: true }) myInput2: ElementRef;
    @ViewChild("myInput3", { static: true }) myInput3: ElementRef;
    @ViewChild("myInput4", { static: true }) myInput4: ElementRef;
    @Output() quizEdited: EventEmitter<any> = new EventEmitter<any>();
    @Output() onQuizSupEdited: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateFooter = new EventEmitter<string>();
    @Output() saveTitle = new EventEmitter<string>();
    @Output() activeCrearTabs = new EventEmitter<boolean>();
		@Output() publishQuiz: EventEmitter<boolean> = new EventEmitter<boolean>();
    public infoForm: UntypedFormGroup;

    @Input() quiz;
    @Input() quizzes;
    @Input() quizFiles: ModelQuizFile;
    @Input() elements: any[];
    @Input() courseId: number;
    @Input() graphId: number;
    public optionFormTextoIdioma1: UntypedFormGroup;
    public optionFormTextoIdioma2: UntypedFormGroup;
    public form: UntypedFormGroup;
    public viewFull: boolean = true;
    public panelOpenState: boolean = true;
    public completedQuiz: boolean = true; //Habilitar el boton crear quiz cuando los datos esten completos
    public idiomaSelected: number = 0;

    /************************ */
    public AUDIO_TYPE = FILE_FORMATS.AUDIO_TYPE;
    public IMAGE_TYPE = FILE_FORMATS.IMAGE_TYPE;
    public PDF_TYPE = FILE_FORMATS.PDF_TYPE;
    public TXT_TYPE = FILE_FORMATS.TXT_TYPE;
    public VIDEO_TYPE = FILE_FORMATS.VIDEO_TYPE;

    public DIR_VIDEO_URL = VID_URL;
    public DIR_AUDIO_URL = AUD_URL;
    public DIR_DOC_URL = PDF_URL;
    public DIR_PICTURE_URL = PICTURE_URL;
    public DIR_TEXT_URL = TEXT_URL;

    public typeMediaUrl: string;
    public mediaViewUrl: string;
    typeFileFormat: string;
    updateFile: boolean | any;
    public loading = false;
    public activeCrear: boolean = false;
    subQuizsMultipleEdit: any;
    modo: boolean;
    viewBotonModoAuto: boolean;
    textEditTemp: string;
		tabs: any;

    /********************************* */

    constructor(
        private fb: UntypedFormBuilder,
        private quizzesStackService: QuizzesstackService,
        private modalService: NgbModal,
        private translateService: TranslateService,
				private toaster: ToasterService,
        private quizzesService: QuizzesService,
        private modalTextEditSer: ModalTextEditService,
        private utils: Utils
    ) {
        this.form = this.fb.group({
            modo: [false],
        });
        this.mediaViewUrl = `${environment.quizzesContent}`;
        this.infoForm = this.fb.group({
            title: ["", Validators.required],
        });

        this.optionFormTextoIdioma1 = this.fb.group({
            answer: "",
        });
        this.optionFormTextoIdioma2 = this.fb.group({
            answer: "",
        });
    }

    ngOnInit() {
        this.infoForm.get("title").patchValue(this.quiz.quizTittle);
        this.textEditSuscribe();
    }

    ngAfterViewInit() {
        this.activarPestañasYBoton();
    }

    activarPestañasYBoton() {
        this.activeCrear = this.dataComplete(this.quizFiles);

        this.activeCrearTabs.emit(this.existeAlMenosUnFichero(this.quizFiles));
    }

    attachment(typeFileFormat: string, numberIdioma: number) {
        this.idiomaSelected = numberIdioma;
        this.typeFileFormat = typeFileFormat;
        this.updateFile = this.fileExist(
            this.idiomaSelected,
            this.typeFileFormat
        ); //Verificamos si tenemos que realizar un update un fichero que existe

        if (this.updateFile === null) {
						// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
						this.toaster.success(this.translateService.instant("QUIZZES.FICHEROENUSO"));
        }

        if (typeFileFormat === this.IMAGE_TYPE) {
            this.typeMediaUrl =
                IMAGEN_URL + this.idiomaSelected + "/" + PICTURE_URL;
            document.getElementById(typeFileFormat).click();
        } else if (typeFileFormat === this.PDF_TYPE) {
            this.typeMediaUrl = DOC_URL + this.idiomaSelected + "/" + PDF_URL;
            document.getElementById(typeFileFormat).click();
        } else if (typeFileFormat === this.AUDIO_TYPE) {
            this.typeMediaUrl = AUDIO_URL + this.idiomaSelected + "/" + AUD_URL;
            document.getElementById(typeFileFormat).click();
        } else if (typeFileFormat === this.VIDEO_TYPE) {
            this.typeMediaUrl = VIDEO_URL + this.idiomaSelected + "/" + VID_URL;
            document.getElementById(typeFileFormat).click();
        }
    }

    fileExist(idiomaSelected: number, typeFileFormat: string) {
        if (idiomaSelected === 1) {
            if (this.typeFileFormat === this.IMAGE_TYPE) {
                if (this.comprobarEliminacion(this.quizFiles.imagen1)) {
                    return null;
                }
                return this.quizFiles.imagen1 !== null ? true : false;
            } else if (this.typeFileFormat === this.PDF_TYPE) {
                if (this.comprobarEliminacion(this.quizFiles.doc1)) {
                    return null;
                }
                return this.quizFiles.doc1 !== null ? true : false;
            } else if (this.typeFileFormat === this.AUDIO_TYPE) {
                if (this.comprobarEliminacion(this.quizFiles.audio1)) {
                    return null;
                }
                return this.quizFiles.audio1 !== null ? true : false;
            } else if (this.typeFileFormat === this.VIDEO_TYPE) {
                if (this.comprobarEliminacion(this.quizFiles.video1)) {
                    return null;
                }
                return this.quizFiles.video1 !== null ? true : false;
            }
        } else if (idiomaSelected === 2) {
            if (this.typeFileFormat === this.IMAGE_TYPE) {
                if (this.comprobarEliminacion(this.quizFiles.imagen2)) {
                    return null;
                }
                return this.quizFiles.imagen2 !== null ? true : false;
            } else if (this.typeFileFormat === this.PDF_TYPE) {
                if (this.comprobarEliminacion(this.quizFiles.doc2)) {
                    return null;
                }
                return this.quizFiles.doc2 !== null ? true : false;
            } else if (this.typeFileFormat === this.AUDIO_TYPE) {
                if (this.comprobarEliminacion(this.quizFiles.audio2)) {
                    return null;
                }
                return this.quizFiles.audio2 !== null ? true : false;
            } else if (this.typeFileFormat === this.VIDEO_TYPE) {
                if (this.comprobarEliminacion(this.quizFiles.video2)) {
                    return null;
                }
                return this.quizFiles.video2 !== null ? true : false;
            }
        }
    }

    onFileSelected(event) {
        let file = event.target.files[0];
        this.saveFile(file);
        let input = document.getElementById(this.typeFileFormat);
        this.myInput1.nativeElement.value = "";
        this.myInput2.nativeElement.value = "";
        this.myInput3.nativeElement.value = "";
        this.myInput4.nativeElement.value = "";
    }

    saveFile(option: any) {
        const typeMediaUrl = this.typeMediaUrl;
        this.loading = true;
        if (this.updateFile) {
            this.quizzesStackService
                .updateFileQuizz(
                    this.quiz.idOriginal,
                    option,
                    typeMediaUrl,
                    this.currentNameFile(typeMediaUrl, this.idiomaSelected)
                )
                .subscribe(
                    (res) => {
                        this.updateArrayFileList(res, this.idiomaSelected);
												// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
                        this.toaster.success(this.translateService.instant("QUIZZES.FICHEROACTUALIZADO"));
                        this.activarPestañasYBoton();

                        this.loading = false;
                    },
                    (err) => console.error(err)
                );
        } else {
            this.quizzesStackService
                .addFileQuizz(this.quiz.idOriginal, option, typeMediaUrl)
                .subscribe(
                    (res) => {
                        this.updateArrayFileList(res, this.idiomaSelected);
												this.loading = false;
												// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
                        this.toaster.success(this.translateService.instant("QUIZZES.FICHEROAGREGADO"));
                        this.activarPestañasYBoton();

                    },
                    (err) => console.error(err)
                );
        }
    }

    /**Comprobamos los datos obligatorios para poder crear las pestañas de forma automatica
     **
     * !Oblicatorio son los elementos que se comprueban
     * *AUDIOS, IMAGEN Y TEXTO
     * @param quizFiles
     * @returns boolean
     */
    dataComplete(quizFiles: ModelQuizFile): any {
        if (
            quizFiles.audio1 !== "" &&
            quizFiles.audio2 !== "" &&
            quizFiles.imagen1 !== "" &&
            quizFiles.imagen2 !== "" &&
            quizFiles.text1 !== "" &&
            quizFiles.text2 !== "" &&
            quizFiles.audio1 !== null &&
            quizFiles.audio2 !== null &&
            quizFiles.imagen1 !== null &&
            quizFiles.imagen2 !== null &&
            quizFiles.text1 !== null &&
            quizFiles.text2 !== null
        ) {
            // DA ERROR DE APPENDCHILD
           this.toaster.success(
               this.translateService.instant("QUIZZES.PUEDECREARQUIZES")
            );
            return true;
        }

        return false;
    }

    existeAlMenosUnFichero(quizFiles: ModelQuizFile) {
        if (
            (quizFiles.doc1 !== "" && quizFiles.doc1 !== null) ||
            (quizFiles.doc2 !== "" && quizFiles.doc2 !== null) ||
            (quizFiles.audio1 !== "" && quizFiles.audio1 !== null) ||
            (quizFiles.audio2 !== "" && quizFiles.audio2 !== null) ||
            (quizFiles.imagen1 !== "" && quizFiles.imagen1 !== null) ||
            (quizFiles.imagen2 !== "" && quizFiles.imagen2 !== null) ||
            (quizFiles.text1 !== "" && quizFiles.text1 !== null) ||
            (quizFiles.text2 !== "" && quizFiles.text2 !== null) ||
            (quizFiles.video1 !== "" && quizFiles.video1 !== null) ||
            (quizFiles.video2 !== "" && quizFiles.video2 !== null)
        ) {
            return true;
        }
        return false;
    }

    currentNameFile(typeMediaUrl: string, idiomaSelected: number) {
        if (idiomaSelected === 1) {
            if (typeMediaUrl === "imagen1/" + this.IMAGE_TYPE) {
                return this.quizFiles.imagen1;
            } else if (typeMediaUrl === "doc1/" + this.PDF_TYPE) {
                return this.quizFiles.doc1;
            } else if (typeMediaUrl === "audio1/" + this.AUDIO_TYPE) {
                return this.quizFiles.audio1;
            } else if (typeMediaUrl === "video1/" + this.VIDEO_TYPE) {
                return this.quizFiles.video1;
            }
        } else if (idiomaSelected === 2) {
            if (typeMediaUrl === "imagen2/" + this.IMAGE_TYPE) {
                return this.quizFiles.imagen2;
            } else if (typeMediaUrl === "doc2/" + this.PDF_TYPE) {
                return this.quizFiles.doc2;
            } else if (typeMediaUrl === "audio2/" + this.AUDIO_TYPE) {
                return this.quizFiles.audio2;
            } else if (typeMediaUrl === "video2/" + this.VIDEO_TYPE) {
                return this.quizFiles.video2;
            }
        }
    }

    updateArrayFileList(res: any, idiomaSelected: number) {
        if (idiomaSelected === 1) {
            if (this.typeFileFormat === this.IMAGE_TYPE) {
                this.quizFiles.imagen1 = res.data;
            } else if (this.typeFileFormat === this.PDF_TYPE) {
                this.quizFiles.doc1 = res.data;
            } else if (this.typeFileFormat === this.AUDIO_TYPE) {
                this.quizFiles.audio1 = res.data;
            } else if (this.typeFileFormat === this.VIDEO_TYPE) {
                this.quizFiles.video1 = res.data;
            }
        } else if (idiomaSelected === 2) {
            if (this.typeFileFormat === this.IMAGE_TYPE) {
                this.quizFiles.imagen2 = res.data;
            } else if (this.typeFileFormat === this.PDF_TYPE) {
                this.quizFiles.doc2 = res.data;
            } else if (this.typeFileFormat === this.AUDIO_TYPE) {
                this.quizFiles.audio2 = res.data;
            } else if (this.typeFileFormat === this.VIDEO_TYPE) {
                this.quizFiles.video2 = res.data;
            }
        }
    }

    openQuillEditor(idiomaSelected: number) {
        if (idiomaSelected === 1) {
            let ficheroEnUso = this.comprobarEliminacion(this.quizFiles.text1)
                ? true
                : false;
            if (ficheroEnUso) {
								// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
								this.toaster.success(this.translateService.instant("QUIZZES.FICHEROENUSO"));
            }
        } else if (idiomaSelected === 2) {
            let ficheroEnUso = this.comprobarEliminacion(this.quizFiles.text2)
                ? true
                : false;
            if (ficheroEnUso) {
								// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
								this.toaster.success(this.translateService.instant("QUIZZES.FICHEROENUSO"));
            }
        }

        const modalRef = this.modalService.open(ModalTextEditComponent, {
            scrollable: true,
            windowClass: MODAL_DIALOG_TYPES.W70,
        });
        modalRef.componentInstance.idOriginal = this.quiz.idOriginal;
        modalRef.componentInstance.mediaUrl = idiomaSelected;
        this.idiomaSelected = idiomaSelected;

        if (idiomaSelected === 1) {
            modalRef.componentInstance.textSource = this.quizFiles.text1;
        } else {
            modalRef.componentInstance.textSource = this.quizFiles.text2;
        }

        modalRef.result.then(
            (result) => {
                const typeMediaUrl = this.DIR_TEXT_URL;
                // console.log(result);
                if (idiomaSelected === 1 && result !== undefined && result !== null) {
                    this.quizzesStackService
                        .updateTextEditQuizz(
                            this.quiz.idOriginal,
                            result,
                            typeMediaUrl + "1"
                        )
                        .subscribe(
                            (res) => {
                                this.quizFiles.text1 = res.data;
                                this.activarPestañasYBoton();
                            },
                            (err) => console.error(err)
                        );
                } else if (idiomaSelected === 2 && result !== undefined && result !== null) {
                    this.quizzesStackService
                        .updateTextEditQuizz(
                            this.quiz.idOriginal,
                            result,
                            typeMediaUrl + "2"
                        )
                        .subscribe(
                            (res) => {
                                this.quizFiles.text2 = res.data;
                                this.activarPestañasYBoton();
                            },
                            (err) => console.error(err)
                        );
                }
            },
            (reason) => {
                if ((reason == 1 || reason == 0 ) && this.textEditTemp.length > 0){
                        const typeMediaUrl = this.DIR_TEXT_URL;
                        this.quizzesStackService
                        .updateTextEditQuizz(
                            this.quiz.idOriginal,
                            this.textEditTemp,
                            typeMediaUrl + idiomaSelected
                        )
                        .subscribe(
                            (res) => {
                                idiomaSelected == 1? this.quizFiles.text1 = res.data : this.quizFiles.text2 = res.data ;
                                this.activarPestañasYBoton();
                            },
                            (err) => console.error(err)
                        );
                }

            }
        );
    }

    private textEditSuscribe() {
        this.modalTextEditSer.textoEdit.subscribe((textEdit: string) => {
            this.textEditTemp =  textEdit;
            if (this.idiomaSelected == 1) {
                this.quizFiles.text1 = textEdit;
            } else if (this.idiomaSelected == 2) {
                this.quizFiles.text2 = textEdit;
            }
        });
    }

    deleteFile(
        typeFileFormat: string,
        idiomaSelected: number,
        nameFile: string
    ) {
        let ficheroEnUso = this.comprobarEliminacion(nameFile) ? true : false;
        if (ficheroEnUso) {
					// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
					this.toaster.success( this.translateService.instant("QUIZZES.FICHEROENUSO") );
        }
        this.idiomaSelected = idiomaSelected;
        this.typeFileFormat = typeFileFormat;
        if (typeFileFormat === this.IMAGE_TYPE) {
            this.typeMediaUrl =
                IMAGEN_URL + this.idiomaSelected + "/" + PICTURE_URL;
        } else if (typeFileFormat === this.PDF_TYPE) {
            this.typeMediaUrl = DOC_URL + this.idiomaSelected + "/" + PDF_URL;
        } else if (typeFileFormat === this.AUDIO_TYPE) {
            this.typeMediaUrl = AUDIO_URL + this.idiomaSelected + "/" + AUD_URL;
        } else if (typeFileFormat === this.VIDEO_TYPE) {
            this.typeMediaUrl = VIDEO_URL + this.idiomaSelected + "/" + VID_URL;
        }

        this.loading = true;
        this.quizzesStackService
            .deleteFileQuizz(this.quiz.idOriginal, this.typeMediaUrl, nameFile)
            .subscribe(
                (res) => {
                    res.data = null;
                    this.updateArrayFileList(res, this.idiomaSelected);
										// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
                    this.toaster.success(this.translateService.instant("QUIZZES.FICHEROELIMINADO"));
                    this.activarPestañasYBoton();
                    this.loading = false;
                },
                (err) => console.error(err)
            );
    }

    comprobarEliminacion(nameFile: string) {
        for (let index = 0; index < this.quizzes.length; index++) {
            const array = this.quizzes[index];
            for (let index = 0; index < array.element.length; index++) {
                const element = array.element[index];
                if (nameFile === element.data) {
                    return true;
                }
            }
        }
        return false;
    }

    deleteTextQuillEditor(idiomaSelected) {
        if (idiomaSelected === 1) {
            let ficheroEnUso = this.comprobarEliminacion(this.quizFiles.text1)
                ? true
                : false;
            if (ficheroEnUso) {
								// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
								this.toaster.success(this.translateService.instant("QUIZZES.FICHEROENUSO"));
            }
            this.loading = true;
            this.typeMediaUrl = "text1";
            this.quizzesStackService
                .updateTextEditQuizz(
                    this.quiz.idOriginal,
                    "",
                    this.typeMediaUrl
                )
                .subscribe(
                    (res) => {
                        this.quizFiles.text1 = "";
                        this.activarPestañasYBoton();
                        this.loading = false;
                    },
                    (err) => console.error(err)
                );
        } else {
            let ficheroEnUso = this.comprobarEliminacion(this.quizFiles.text2)
                ? true
                : false;
            if (ficheroEnUso) {
								// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
								this.toaster.success(this.translateService.instant("QUIZZES.FICHEROENUSO"));
            }
            this.loading = true;
            this.typeMediaUrl = "text2";
            this.quizzesStackService
                .updateTextEditQuizz(
                    this.quiz.idOriginal,
                    "",
                    this.typeMediaUrl
                )
                .subscribe(
                    (res) => {
                        this.activarPestañasYBoton();
                        this.quizFiles.text2 = "";
                        this.loading = false;
                    },
                    (err) => console.error(err)
                );
        }
    }

    crearSubQuizzes() {
        if (this.dataComplete(this.quizFiles)) {
            this.quizzesService
                .getSubQuizMultiEdit(Number(this.quiz.idOriginal))
                .subscribe(
                    (res) => {
                        this.subQuizsMultipleEdit = res;
												// ENTRA EN CATCH POR CULPA DEL APPEND CHILD
                        this.toaster.success(this.translateService.instant("QUIZZES.SUBQUIZAGREGADO_CREADOS"));
                        this.updateFooter.emit(res.data.quizzes);
												this.publishQuiz.emit(true);
                        this.tabs = res.data.quizzes;
                    },
                    (err) => console.log(err)
                );
        }
    }

		deleteAllQuizes(){}

    public onTextBlur(event: any) {
        if (event.target.value && this.infoForm.valid) {
            const dataSave = this.infoForm.value;
            this.quiz.quizTittle = dataSave.title.substring(
                0,
                SIGMA_CONSTANTS.QUIZ_TEXT_MAXLENGTH
            );
            this.infoForm.get("title").setValue(this.quiz.quizTittle);
            this.quiz.isMultiplexed = 1;
            this.quizzesService
                .createQuiz(this.courseId, this.graphId, this.quiz)
                .subscribe(
                    (res) => {
                        this.onQuizSupEdited.emit(this.quiz);
                    },
                    (err) => console.error(err)
                );
        }
    }

    modoManualAuto() {
        this.modo = !this.modo;
        if (this.modo) {
            this.viewBotonModoAuto = true;
            this.activeCrearTabs.emit(false);
        } else {
            this.viewBotonModoAuto = false;
            this.activeCrearTabs.emit(
                this.existeAlMenosUnFichero(this.quizFiles)
            );
        }
    }

    keydown(e) {
        if (e.which < 0x20) {
            return;
        }
        if (e.target.value.length >= SIGMA_CONSTANTS.QUIZ_TEXT_MAXLENGTH) {
            e.preventDefault();
            e.stopPropagation();
            this.infoForm
                .get("title")
                .setValue(
                    e.target.value.substring(
                        0,
                        SIGMA_CONSTANTS.QUIZ_TEXT_MAXLENGTH
                    )
                );
        }
    }

    //Ver contenido en un modal
    /**
     * !audio, video, imagen
     * @param mediaPreview: string
     */
    previewContentMedia(url, type){
        let title = '';
        let message = '';
        if (this.DIR_VIDEO_URL === type) {
            title = this.translateService.instant(
                "QUIZZES.VIDEO"
            )
        }
        else if (this.DIR_AUDIO_URL === type) {
            title = this.translateService.instant(
                "QUIZZES.HYPERMEDIA.AUDIO"
            )
        } else if (this.DIR_PICTURE_URL === type) {
            title = this.translateService.instant(
                "QUIZZES.HYPERMEDIA.IMAGEN"
            )
        }
        else if (this.DIR_DOC_URL === type) {
            title = this.translateService.instant(
                "QUIZZES.HYPERMEDIA.PDF"
            )
        }

        const modalRef = this.modalService.open(ViewFileModalComponent, {
            scrollable: true,
            windowClass: MODAL_DIALOG_TYPES.W70,
        })

        modalRef.componentInstance.title = title;
        modalRef.componentInstance.url = url;
        modalRef.componentInstance.message = message;
        modalRef.result.then(res => {
        });
    }

    getButtonView(){
        return this.utils.getAccessZones(AppZones.QUIZMULTIPLEBUTTON)
    }
}
