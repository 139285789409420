import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { SIGMA_CONSTANTS } from '../../../../core/utils/sigma-constants';
import { ACTIONS } from 'src/app/core/utils/actions';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelIcono } from 'src/app/core/models/courses/icono.model';
import { gNode, ObjetoNodoAuto } from 'src/app/core/models/graph/gNode.model';
import { LoginService } from 'src/app/core/services/login';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { SnapshotService } from 'src/app/core/services/snapshot/snapshot.service';
import { MastersService } from 'src/app/core/services/masters';
import { IdiomaModel, MateriaModel } from 'src/app/core/models/masters';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FILE_FORMATS } from 'src/app/core/utils/file-formats';
import { finalize} from 'rxjs/operators';
import { NodeFilesFormat } from 'src/app/core/models/nodes/node-files-format.model';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { CourseModel } from 'src/app/core/models/courses';
import { User } from 'src/app/core/models/users/user.models';
import { PowerService } from 'src/app/core/services/power/power.service';
import { ModalRadarComponent } from 'src/app/shared/components/modal-radar/modal-radar.component';
import { LocalStorage } from 'src/app/core/utils';
import { DEFAULTCLOSEPADS } from 'src/app/core/models/masters/masters.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $: any
declare var document:any
interface Time {
    hours: number,
    minutes: number,
    seconds: number;
}

@Component({
    selector: 'app-node-detail',
    templateUrl: './node-detail.component.html',
    styleUrls: ['./node-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NodeDetailComponent implements OnInit, OnDestroy {

    languages$: Observable<IdiomaModel[]>;
    subjects$: Observable<MateriaModel[]>;
    mouseInterval:any = ''

    saveClicked: Subject<any> = new Subject<any>();
    saveClicked$: Observable<any> = this.saveClicked.asObservable();

    nomGrafo: string;
    nomNode: string
    selectedFile: File = null;
    cargando: boolean;
    inicial: boolean;
    iconos: any = [];
    idFormato: number;
    videoSource: string;
    imageSource: string;
    audioSource: string;
    pdfSource: string;
    textSource: string;
    mensaje: string;
    imagen: string;
    format: string;
    automatic: any = false;
    isShowPads:boolean = true
    isShowLabels:boolean = false

    positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
    position = new UntypedFormControl(this.positionOptions[0]);


    // A.G.
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Input() type: string;
    @Input() currentGraph: any;
    private send = false;
    @Input() node; // :gnode
    @Output() newNode = new EventEmitter<gNode>();
    intervalId;
    time: Time;
    countSeconds: number;
    course: CourseModel;
    timerId: number = null;
    isShowFiles:boolean = true
    modoAuto: ObjetoNodoAuto;

    files: File[] = [];

    showSaveButton: boolean = false;
    isLoading: boolean = false;
    canEdit: boolean = false;
    tutorialSW: any;
    user: User;

    constructor(
        public modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public loginService: LoginService,
        private nodeService: NodeService,
        private quizService: QuizzesService,
        public snapshotService: SnapshotService,
        private mastersService: MastersService,
				private toaster: ToasterService,
        private translateService: TranslateService
    ) {
        this.languages$ = this.mastersService.getAllLanguages();
        this.nodeService.currentNode.subscribe((n) => this.node = n);
        this.quizService.currentGraph.subscribe((g) => this.currentGraph = g);
        var subscribe = this.quizService.loading.subscribe((n: boolean) => {
            this.cargando = n
            if (this.cargando == false && loginService.esEstudiante()) {
                // this.executeAutomaticStudy();
                subscribe.unsubscribe();
            }
        });
    }
    // Fin A.G.

    ngOnInit() {

        if(this.loginService.esEstudiante())
            document.documentElement.requestFullscreen() //Enter fullscreen

        $('.popover3').hide();

        if(this.loginService.esAutor())
            this.isShowPads = false

        this.user = this.loginService.getUser()


        this.node = { ...this.node };
        this.nodeService.setCurrentNode(this.node);

        this.canEdit = this.loginService.esAutor() && this.node.user.idUser === this.loginService.getUser().idUser;


        // A.G.
        this.currentGraph.idTarget = this.idMapa;
        this.currentGraph.idCourse = this.idCurso;
        this.quizService.setCurrentGraph(this.currentGraph);
        this.quizService.setType(this.type);
        this.quizService.setExecuteAutomatic(false);
        if (this.type == SIGMA_CONSTANTS.NODE_TYPE.toLowerCase()) {
            this.quizService.setCurrentNode(this.node);
        }
        this.automatic = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.AUTOMATIC));

        this.inicial = true;
        if (this.loginService.esAutor()) {
            //this.mensaje = "Por favor suba los archivos de estudio";
            this.mensaje = "subir";
        }
        if (this.loginService.esEstudiante()) {
            //this.mensaje = "Aquí se mostrará sus archivos de estudio";
            this.mensaje = "mostrar";
        }

        //Check if the user deleted the node, to close this modal and refresh the graph
        this.nodeService.delNode.subscribe(value => {
            if(value){
                this.closeModal('')
            }
        })
        setTimeout(() => { this.isShowFiles = false }, DEFAULTCLOSEPADS)

        //CUENTA REGRESIVA PARA MOSTRAR LOS QUIZ
        //this.executeAutomaticStudy();
    }


    showFiles(value){

        let interval: number = 1
        clearInterval(this.mouseInterval)

        this.mouseInterval = setInterval(() => {
            interval++
            if(interval === 4)
            {
                this.isShowFiles = false
                clearInterval(this.mouseInterval)
            }
        }, DEFAULTCLOSEPADS / 3)

        this.isShowFiles = true//Change variable value
    }

    changeStatus($event) {
        this.send = $event;
    }

    receiveNewNodeData($event) {
        this.newNode.emit($event);
    }

    cancelar() {
        this.modalService.dismissAll();
    }

    registrar() {
        this.modalService.dismissAll();
    }

    reproducirFormato(formato: string) {
        this.format = formato;
    }

    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }

    // executeAutomaticStudy() {
    //     let date = new Date();
    //     date.setSeconds(date.getSeconds() + this.node.duration);
    //     this.timerId = countdown(date, (ts) => {
    //         this.time = ts;
    //         console.log("Time:", this.time);
    //         if (this.time.minutes == 0 && this.time.seconds == 0) {
    //             if (this.automatic && this.loginService.esEstudiante()) {
    //                 this.quizService.setExecuteAutomatic(true);
    //                 const modalRef = this.modalService.open(ModalReproducirQuizzesComponent,
    //                     {
    //                         scrollable: true,
    //                         windowClass: MODAL_DIALOG_TYPES.W95
    //                     })
    //                 modalRef.componentInstance.node = this.node;
    //                 modalRef.componentInstance.idCurso = this.idCurso;
    //                 modalRef.componentInstance.idMapa = this.idMapa;
    //                 modalRef.componentInstance.currentGraph = this.currentGraph;
    //                 modalRef.componentInstance.type = 'node';
    //                 modalRef.componentInstance.nomGrafo = '';
    //                 modalRef.result.then((result) => {
    //                     console.log(result);
    //                 }, (reason) => {
    //                 });
    //                 this.closeModal('close');
    //             }
    //             if (this.timerId) {
    //                 clearInterval(this.timerId);
    //             }
    //         }
    //     }, countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
    // }

    ngOnDestroy() {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        if(this.loginService.esEstudiante())
            document.exitFullscreen();
    }

    save() {
        this.saveClicked.next(true);
    }

    onNodeFilesTypeChanged(format: string) {
        this.showSaveButton = format === FILE_FORMATS.TXT_TYPE;
    }

    onNodeSave(data: any) {
        const node: gNode = { ...data.node };
        node.size = 20;
        node.type = SIGMA_CONSTANTS.NODE_DEFAULT_TYPE;
        this.isLoading = true;
        // Manda al backend el nodo a crear para crearlo en BD
        this.nodeService.createNode(this.idCurso, this.idMapa, node, data.image).pipe(finalize(() => this.isLoading = false)).subscribe(
            res => {

                switch(res.type){

                    case HttpEventType.Response:
                        let nodesFiles;
                        if (this.node.nodesFiles) {
                            nodesFiles = { ...this.node.nodesFiles };
                        } else {
                            nodesFiles = { idNodeFile: res.body.data.idNodesFile }
                        }

                        this.node = { ...res.body.data, nodesFiles };
                        this.nodeService.currentNode.next(this.node);

                        this.newNode.next(this.node);
                        this.toaster.success(this.translateService.instant('NODEFORMCOMPONENT.SAVED'));

                        break
                }


            },
            err => {
                this.toaster.error(this.translateService.instant('NODEFORMCOMPONENT.ERROR'));
            }
        );
    }

    //RETURN OUTPUT COMPONENT FUNCTION
    onNodeFileAdded({ type, content, operators, showResult }) {

        if (type === FILE_FORMATS.TXT_TYPE) {
            this.uploadText(type, content, operators);
        } else {
            this.uploadFile(type, content, operators, showResult);
        }
    }

    private uploadFile(type: string, file: File, operatorIcons: ModelIcono[], showResult: boolean = true) {
        this.isLoading = true;
        this.nodeService.postNodeFile(this.node.nodesFiles.idNodeFile, type + 'Add', operatorIcons, file).pipe(finalize(() => this.isLoading = false)).subscribe(
            (event:HttpEvent<any>) => {
                let progressVal:number = 0

                switch(event.type){
                    case HttpEventType.UploadProgress:
                        progressVal = Math.round(event.loaded / event.total * 100);
                        this.nodeService.currentUploadProgress.next({
                            value: progressVal,
                            type: type
                        })
                        break;
                    case HttpEventType.Response:
                        const node = { ...this.node };
                        if (!node.nodesFiles) {
                            node.nodesFiles = {};
                        }

                        switch (type) {
                            case FILE_FORMATS.AUDIO_TYPE:
                                node.nodesFiles.audioFile = event.body.data.fileName;
                                break;
                            case FILE_FORMATS.IMAGE_TYPE:
                                node.nodesFiles.pictureFile = event.body.data.fileName;
                                break;
                            case FILE_FORMATS.PDF_TYPE:
                                node.nodesFiles.pdfFile = event.body.data.fileName;
                                break;
                            case FILE_FORMATS.VIDEO_TYPE:
                                node.nodesFiles.videoFile = event.body.data.fileName;
                                break;
                            case FILE_FORMATS.VIDEO_PREVIEW_TYPE:
                                node.nodesFiles.videoImage = event.body.data.fileName;
                                break;
                        }

												this.node = { ...node };
												this.nodeService.currentNode.next(this.node);

												if (showResult)
														this.toaster.success(this.translateService.instant('NODEFORMCOMPONENT.FILESAVED'));

                        this.nodeService.currentUploadProgress.next({ value: 0, type: type })
                }

            },
            err => {
                if (showResult)
                    this.toaster.error(this.translateService.instant('NODEFORMCOMPONENT.FILEERROR'));
                this.nodeService.currentUploadProgress.next({ value: 0, type: type })
            }
        );
    }

    private uploadText(type: string, text: string, operatorIcons: ModelIcono[]) {
        this.isLoading = true;
        this.nodeService.postNodeFile(this.node.nodesFiles.idNodeFile, type + 'Add', operatorIcons, undefined, text).pipe(finalize(() => this.isLoading = false)).subscribe(
            event => {

                switch(event.type){

                    case HttpEventType.Response:
                        const node = { ...this.node }
                        if (!node.nodesFiles)
                            node.nodesFiles = {}

                        node.nodesFiles.txtFile = text
                        this.node = { ...node }
                        this.nodeService.currentNode.next(this.node)
                        this.toaster.success(this.translateService.instant('NODEFORMCOMPONENT.FILESAVED'))
                        break
                }


            },
            err => this.toaster.error(this.translateService.instant('NODEFORMCOMPONENT.FILEERROR'))
        );
    }

    showPads(value:boolean):void{
        this.isShowPads = value
    }
    showLabels(value:boolean):void{
        this.isShowLabels = value
    }
    getAction(value:string){
        switch(value){
            case ACTIONS.CLOSEMODAL:
                this.closeModal('')
                break
            case ACTIONS.STAIRS:
                this.modalService.open(ModalRadarComponent, { scrollable: false, windowClass: MODAL_DIALOG_TYPES.W50})
                break
            case ACTIONS.HELP:
                break
            case ACTIONS.ACTIVITIES:
                //Modo automático, sólo quizzes
                break
        }
    }
    getControllerAction(value:string):void{
        switch(value){
            case ACTIONS.UP:
                break
            case ACTIONS.DOWN:
                break
            case ACTIONS.DOWN:
                break
            case ACTIONS.NEXT:
                break
        }
    }
}
