<div class="header-options">
	<div class="px-4 py-3">
		<div>
			<button mat-icon-button (click)="showInfo()" hidden>
				<mat-icon>info_outline</mat-icon>
			</button>
		</div>

		<!-- <mat-button-toggle-group [disabled]="type > 0" [(ngModel)]="type" (change)="onQuizTypeSelected($event)"> -->

		<div class="col d-flex justify-content-around">

			<div class="px-2 d-flex justify-content-center align-items-center">
				<form [formGroup]="form">
					<select formControlName="pattern" class="form-control" (change)="updateRepetition()">
						<option *ngFor="let item of repetitionList" [value]="item.pattern">{{item.descripcion}}</option>
					</select>
				</form>
			</div>

			<div class="px-2 d-flex justify-content-center align-items-center">
				<div class="d-flex justify-content-center align-items-center flex-column px-3">
					<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.CERTIFICAR' | translate"
						placement="button" (click)="certifiedQuiz($event)">
						<mat-icon class="circle-button" svgIcon="quiz_certificado"></mat-icon>
					</button>
					<span class="mt-2">{{'SIGMACOMPONENT.CERTIFICAR' | translate}}</span>
				</div>

				<form [formGroup]="form" class="px-3">
					<mat-slide-toggle class="buttonPublicar" (click)="certifiedQuiz($event)"
						formControlName="certificadoQuiz"></mat-slide-toggle>
				</form>
			</div>

			<div class="px-2 d-flex justify-content-center align-items-center">
				<form [formGroup]="form">
					<mat-slide-toggle formControlName="published" (click)="publish($event)">{{'EDITARCURSO.PUBLISH' |
						translate}}</mat-slide-toggle>
				</form>
			</div>

			<div class="px-2 d-flex justify-content-center align-items-center">
				<button mat-icon-button ngbTooltip="{{'QUIZZES.DELETE' | translate }}"
					(click)="deleteMultipleQuiz()" *ngIf="loginService.esAutor()">
					<mat-icon class="icon-head pointer color-red-text" data-toggle="tooltip" data-placement="top">delete</mat-icon>
				</button>
			</div>

		</div>
	</div>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="onCloseModal()">
		</nb-icon>
	</div>
</div>
