import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize, map } from 'rxjs/operators';
import { LoginService } from 'src/app/core/services/login';
import { NodeService } from 'src/app/core/services/node/node.service';
import { UsersService } from 'src/app/core/services/users';
import { Utils } from 'src/app/core/utils/utils';
import { NodeModeAutoComponent } from 'src/app/shared/components/nodes/node-auto/node-mode-auto.component';
import { environment } from 'src/environments/environment';
import { ImagenPipe } from '../../pipes/imagen.pipe';
import { ModalCursosComponent } from '../cursos/modal-cursos';
import { ModalVerCursoComponent } from '../cursos/modal-ver-curso';
import { ModalAceptarCancelarComponent } from '../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component';
import { ModalListAsso } from './modal-associations.class';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
  selector: 'app-modal-associations-edit-nodos',
  templateUrl: './modal-associations-edit-nodos.component.html',
  styleUrls: ['./modal-associations-edit-nodos.component.scss']
})
export class ModalAssociationsEditNodosComponent implements OnInit {

    listAsst: ModalListAsso[] = [];
    curremtUserId: number;
    canEdit:boolean = false;
    url = `${environment.nodeContentPicture}`;

    constructor(
        private modalService: NgbModal,
        private loginService: LoginService,
        public userService: UsersService,  private activeModal: NgbActiveModal,
        private nodosService: NodeService,
        private imagePipe: ImagenPipe,
        private translateService: TranslateService,
        public utils: Utils,
        public router: Router,
        private nodeService: NodeService,
				private toaster: ToasterService
    ) { }

    ngOnInit() {
        this.curremtUserId = this.loginService.getUser().idUser;
        this.canEdit = this.loginService.esAutor()
        this.getListAss();
    }

    getListAss() {
        let nodo =  JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.N1)) ;
        this.nodosService.getListAss(nodo.idOriginal).subscribe((res: any) => {

            if (this.canEdit && res.data.length === 0) {
                this.busquedaNodo();
            }
            else {
                res.data.forEach((element:ModalListAsso) => {
                if (element.nodeDes.image === null) {
                    element.nodeDes.image = '';
                }
                if (element.nodeOrg.image === null) {
                    element.nodeOrg.image = '';
                }
                this.listAsst.push(element)
                });
            }
        })

    }

    busquedaNodo(){
        localStorage.setItem(LOCALSTORAGESTRINGS.SEARCHNODE, 'true');

        const modalRef = this.modalService.open( ModalCursosComponent,
        {
            scrollable: true,
            windowClass: MODAL_DIALOG_TYPES.W95,
        });

        this.activeModal.close();
        modalRef.componentInstance.buscarNodo = true;

        modalRef.result.then((result) => {
        if (result != 'Abrir mapa') {
            localStorage.setItem(LOCALSTORAGESTRINGS.SEARCHNODE, 'false');
        }
        }, (reason) => {
        });
    }


  buscarNodoOpenCursoEstudiante() {
    localStorage.setItem(LOCALSTORAGESTRINGS.SEARCHNODE, 'true');
    const modalRef = this.modalService.open(ModalVerCursoComponent,
        {
            scrollable: true,
            windowClass: MODAL_DIALOG_TYPES.W100
        });
    modalRef.componentInstance.buscarNodo = true;
    modalRef.componentInstance.id = localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID);
    modalRef.componentInstance.openAssociated = true;
    this.activeModal.close();
    modalRef.result.then((result) => {
      if (result != 'Abrir mapa') {
      localStorage.setItem(LOCALSTORAGESTRINGS.SEARCHNODE, 'false');
     }
    }, (reason) => {
    });
  }


  close(){
    this.activeModal.close();
  }


  updateUrl(event: any) {
    event.target.src = '../../assets/images/no-image.png'
  }

  getImagenIdeaOrigen(image: any):string{

    image ? image = this.imagePipe.transform(image, 'nodes') : image = '../../assets/images/no-image.png';
    return `url('${image}')`
  }

  deleteAss(element: ModalListAsso){
    const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
			{
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30
			});


		modalRef.componentInstance.mensaje = this.translateService.instant('ASOCIACIONES.MENSAGES.ELIMINARASOCIACION');
		modalRef.result.then((result: boolean) => {
			// Si devuelve true lo borro y si devuelve false no hago nada
			if (result) {
        this.nodosService.deleteAss(element).subscribe(res => {
          this.toaster.success(this.translateService.instant('ASOCIACIONES.MENSAGES.ELIMINADAASOCIACION'));
          console.log(res);
          if (this.listAsst.length === 1) {
            this.activeModal.close();
          } else {
            this.listAsst = [];
            this.getListAss();
          }

        });
			}
		});

  }

  openCursoEstudiante(idCurso: number) {
    const modalRef = this.modalService.open(ModalVerCursoComponent,
        {
            scrollable: true,
            windowClass: MODAL_DIALOG_TYPES.W100
        });
    modalRef.componentInstance.id = idCurso;
    modalRef.componentInstance.openAssociated = true;
  }

    closeModal(sendData?: any) {
      this.activeModal.close(sendData);
    }

    openGrafo(idgrafo: number, idcurso: number){
      this.close();
      this.router.navigateByUrl(`/course/${idcurso}/graph/${idgrafo}`);
    }

    isLoading: boolean = false;
    openNode(idnode: number, idgrafo: number, idcurso: number){

      this.nodeService.getNode(idnode, idcurso, idgrafo).pipe(map((res: any) => res.data[0]), finalize(() => this.isLoading = false)).subscribe(res => {
        const modalRef = this.modalService.open(NodeModeAutoComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W100,
                backdrop: 'static'
            }
        );
          modalRef.componentInstance.node = res;
          modalRef.componentInstance.hideBar = true;

          modalRef.componentInstance.idCurso =idcurso;
          modalRef.componentInstance.idMapa = idgrafo;
          modalRef.componentInstance.type = 'node';

          modalRef.componentInstance.adjustWindow = true

      });
    }

}
