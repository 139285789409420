import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-barras',
  templateUrl: './barras.component.html',
  styleUrls: ['./barras.component.scss']
})
export class BarrasComponent implements OnInit {
	@Input() consultaID: number | null = null;

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];
	labels_nivel: any[] = [
		"1º ESO",
		"2º ESO",
		"3º ESO",
		"4º ESO",
		"1º Bach",
		"2º Bach"
	];
	datasets_nivel: any[] = [
    {
      label: 'Pedro Martinez',
      data: [86, 0, 0, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Ana Diaz-Granados',
      data: [63, 32, 0, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Patricia Contreras',
      data: [88, 0, 0, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Luisa Smith',
      data: [0, 34, 26, 51, 60, 89],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Maria García',
      data: [0, 57, 96, 10, 51, 38],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Montse Abreu',
      data: [0, 83, 0, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Felipe Aristizabal',
      data: [0, 0, 70, 12, 79, 84],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Alicia Roa',
      data: [0, 0, 38, 43, 72, 84],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Carlos Perez',
      data: [0, 0, 0, 0, 44, 46],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
  ];
	labels_asignatura: any[] = [
		"Matemáticas",
		"Lenguaje",
		"Geografía",
		"Historia",
		"Geometría",
		"Filosofía",
		"Música",
		"Inglés",
		"Biología",
		"Física",
		"Química",
	];
	datasets_asignatura: any[] = [
    {
      label: 'Pedro Martinez',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 60, 44],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Ana Diaz-Granados',
      data: [0, 94, 0, 0, 0, 55, 0, 0, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Patricia Contreras',
      data: [30, 0, 0, 0, 0, 0, 0, 0, 0, 46, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Luisa Smith',
      data: [0, 0, 0, 0, 0, 0, 0, 75, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Maria García',
      data: [84, 0, 0, 0, 87, 0, 0, 0, 0, 0, 71],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Montse Abreu',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 74, 0, 88],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Felipe Aristizabal',
      data: [0, 43, 67, 80, 0, 0, 0, 0, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Alicia Roa',
      data: [0, 0, 0, 0, 0, 0, 84, 0, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
		{
      label: 'Carlos Perez',
      data: [0, 0, 80, 80, 0, 92, 0, 0, 0, 0, 0],
      //borderColor: Utils.CHART_COLORS.red,
      //backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    },
  ];

  constructor(
		private translateService: TranslateService,
	) { }

  ngOnInit() {
		if (this.consultaID !== null) {
			switch (this.consultaID){
				case 1:
					this.labels = this.labels_nivel;
					this.datasets = this.datasets_nivel;
					break;
				case 2:
					this.labels = this.labels_asignatura;
					this.datasets = this.datasets_asignatura;
					break;
			}
    }
		setTimeout(() =>	this.createChart(),	200);
  }

	createChart(){
		var canvas = document.getElementById('myChart') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.labels, // Etiquetas para las barras
				datasets: this.datasets
			},
			options: {
				//indexAxis: 'y',
				responsive : true,
				plugins: {
					legend: {
						position: 'right',
					},
					filler: {
						propagate: true,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: false,
								//modifierKey: 'ctrl', // Se activa con la tecla Control
							},
							pinch: {
								enabled: false,
							},
							mode: 'x',
						},
						pan:{
							enabled: true,
							mode: 'x',
							scaleMode: 'x',
						},
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								if (label) {
									label += ': ';
								}
								label += context.raw + '%'; // Formatea los valores del tooltip como porcentajes
								return label;
							}
						}
					}
				},
				scales: {
					x: {
						stacked: false,
						title: {
							display: false,
							text: this.translateService.instant('INFORMES.AVERAGETIMEPERMINUTE'),
						},
					},
					y: {
						stacked: false,
						suggestedMin: 0,
						position: 'top',
						ticks: {
							callback: function(value) {
								return value + '%'; // Formatea los valores como porcentajes
							}
						}
					}
				},
			}
		});
	}

}
