<ul style="list-style-type: none;">
	<li *ngFor="let label of labels">
		<nb-card class="no-edge-card mb-0">
			<nb-card-header>
				<h6>{{label.label}}</h6>
			</nb-card-header>
			<nb-card-body class="py-4 px-0">
				<div class="d-flex flex-wrap gap-5 justify-content">
					<div *ngFor="let label2 of label.data" class="card-Mygroup"
						[ngStyle]="{'display': label2.data[0] != 0 ? 'block' : 'none'}">
						<nb-card class="group-info border-body" [ngStyle]="{'background-color': colorKnowledge(label2.data[0])}">
							<nb-card-header class="border-header">
								<div class="img-container">
									<img [src]="'../../../../../assets/no-image.png'" class="img-fluid">
								</div>
							</nb-card-header>
							<nb-card-footer>
								<span>{{label2.label}}</span>
							</nb-card-footer>
						</nb-card>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
	</li>
</ul>
