<div class="popover" id="pActions" #pActions  *ngIf="!proyectoControl">
	<div class="text-right">
			<mat-icon class="icon-color-blue closebtn pointer text-right" ngbTooltip="{{ 'SIDEBARDERECHO.CERRAR' | translate }}" (click)="scUtils.closeCreateMenu()">close</mat-icon>
	</div>
	<div class="d-flex flex-row flex-wrap">
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.ANADIRIDEA' | translate" placement="top" style="z-index: 9999;" (click)="registrarNodo()">
						<mat-icon class="circle-button" svgIcon="AnadirIdea"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.ANADIRIDEA' | translate}}</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.ANADIRACTIVIDAD' | translate" placement="top" style="z-index: 9999;" (click)="createQuiz()">
						<mat-icon class="circle-button" svgIcon="AnadirActividad"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.ANADIRACTIVIDAD' | translate}}</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.ANADIRFAMILIAACTIVIDADES' | translate" placement="top" style="z-index: 9999;" (click)="createQuizMultiple()">
						<mat-icon class="circle-button" svgIcon="AnadirActividadesMultiples"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.ANADIRFAMILIAACTIVIDADESCORTA' | translate}}</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button color="primary" [ngbTooltip]="'SIGMACOMPONENT.PASTE' | translate" placement="top" style="z-index: 9999;">
						<mat-icon class="circle-button" svgIcon="content_paste" color="primary"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.PASTE' | translate}}</span>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div *ngIf="this.selectedGraph != null" class="d-flex flex-row flex-wrap">
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.TRAERIDEA' | translate"
								placement="bottom" style="z-index: 9999;" (click)="alquilarIdea()" >
						<mat-icon class="circle-button" svgIcon="TraerIdea"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.TRAERIDEA' | translate}}</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.TRAERACTIVIDAD' | translate"
						placement="bottom" style="z-index: 9999;" (click)="alquilarActividad()">
						<mat-icon class="circle-button" svgIcon="TraerActividad"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.TRAERACTIVIDAD' | translate}}</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.ANADIRPEGATINA' | translate" placement="bottom" style="z-index: 9999;" (click)="addSticker()">
						<mat-icon class="circle-button" svgIcon="Sticker"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.ANADIRPEGATINA' | translate}}</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.ANADIRFONDO' | translate" placement="bottom" (click)="bgUtils.setBackground()" style="z-index: 9999;">
						<mat-icon class="circle-button" svgIcon="insert_photo" color="primary"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.ANADIRFONDO' | translate}}</span>
		</div>

		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'" *ngIf="bgUtils.background">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.REMOVEBACKGROUND' | translate" placement="bottom" (click)="removeBackground()" style="z-index: 9999;">
						<mat-icon class="circle-button" svgIcon="delete" color="primary"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.REMOVEBACKGROUND' | translate}}</span>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div class="d-flex flex-row flex-wrap">
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.ADDTEXT' | translate"
								placement="bottom" style="z-index: 9999;" (click)="addText()" >
								<nb-icon class="circle-button icon-size" icon="text-outline" style="color: #3f51b5;"></nb-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.ADDTEXT' | translate}}</span>
		</div>
		<div *ngIf="this.selectedGraph" class="d-flex flex-column text-center align-items-center p-2"
			[ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.FIXZOOM' | translate" placement="bottom"
				style="z-index: 9999;" (click)="fixZoom('fix')">
				<nb-icon class="circle-button icon-size" icon="pin-outline" style="color: #3f51b5;"></nb-icon>
			</button>
			<span class="mt-2">{{'SIGMACOMPONENT.FIXZOOM' | translate}}</span>
		</div>
		<div *ngIf="this.selectedGraph && this.selectedGraph?.zoomX != 0 || this.selectedGraph?.zoomX == null"
			class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.UNFIXZOOM' | translate" placement="bottom"
				style="z-index: 9999;" (click)="fixZoom('unfix')">
				<nb-icon class="circle-button icon-size" icon="pin" style="color: #b53f3f;"></nb-icon>
			</button>
			<span class="mt-2">{{'SIGMACOMPONENT.UNFIXZOOM' | translate}}</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.ORDERIDEAS' | translate" placement="bottom"
				style="z-index: 9999;" (click)="changeIdeasOrder(this.currentGraph)">
				<nb-icon class="circle-button icon-size" icon="menu-arrow-outline" style="color: #3f51b5;"></nb-icon>
			</button>
			<span class="mt-2">{{'SIGMACOMPONENT.ORDERIDEAS' | translate}}</span>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div class="d-flex flex-row flex-wrap">
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'Salwizar'" placement="bottom"
				style="z-index: 9999;" (click)="openSalwizar()">
				<nb-icon class="circle-button icon-size" icon="sun-outline" style="color: #3f51b5;"></nb-icon>
			</button>
			<span class="mt-2">Salwizar</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'" >
			<button mat-raised-button mat-icon-button [ngClass]="generatedIdeasExists ? '' : 'disabled-button'"
				[ngbTooltip]="'SIGMACOMPONENT.DESIGNIDEAS' | translate" placement="bottom"
				style="z-index: 9999;" (click)="openDesignIdeas()">
				<nb-icon class="circle-button icon-size" icon="edit-2-outline"
					[ngStyle]="{'color': (generatedIdeasExists==true) ? '#3f51b5' : '#ffffff'}"></nb-icon>
			</button>
			<span class="mt-2">{{'SIGMACOMPONENT.DESIGNIDEAS' | translate}}</span>
		</div>
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.LINKIDEAS' | translate" placement="bottom"
				style="z-index: 9999;" (click)="autoLinkIdeas()">
				<nb-icon class="circle-button icon-size" icon="share-outline" style="color: #3f51b5;"></nb-icon>
			</button>
			<span class="mt-2">{{'SIGMACOMPONENT.LINKIDEAS' | translate}}</span>
		</div>
		<!-- <div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.UNFIXZOOM' | translate" placement="bottom"
				style="z-index: 9999;" (click)="generateQuizzes()">
				<nb-icon class="circle-button icon-size" icon="question-mark-circle-outline" style="color: #3f51b5;"></nb-icon>
			</button>
			<span class="mt-2">Generar Actividades</span>
		</div> -->
	</div>
</div>

<div class="popover" id="pActions" #pActions *ngIf="proyectoControl">
	<div class="text-right">
			<mat-icon class="icon-color-blue closebtn pointer text-right" ngbTooltip="{{ 'SIDEBARDERECHO.CERRAR' | translate }}" (click)="scUtils.closeCreateMenu()">close</mat-icon>
	</div>
	<mat-divider></mat-divider>
	<div class="d-flex flex-row flex-wrap">
		<div class="d-flex flex-column text-center align-items-center p-2" [ngClass]="bgUtils.background ? 'w-20' : 'w-25'">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.ANADIRACTIVIDAD' | translate" placement="top" style="z-index: 9999;" (click)="createQuiz()">
						<mat-icon class="circle-button" svgIcon="AnadirActividad"></mat-icon>
				</button>
				<span class="mt-2">{{'SIGMACOMPONENT.ANADIRACTIVIDAD' | translate}}</span>
		</div>
	</div>
	<mat-divider></mat-divider>
</div>

<div class="popover" id="pConnect">
    <mat-icon class="mat" color="primary">{{connectIcon}}</mat-icon>
</div>

<div class="popover" id="pNodeInfo">
    <h5>{{infoNodeTitle}}</h5>
    <p>{{infoNodeDesc}}</p>
</div>

<div class="popover3 card" id="pEditMenu3" #nodeQuizzPlaceHolderPopover>
	<!-- <div class="text-right  p-2">
        <mat-icon class="icon-color-blue closebtn pointer text-right" ngbTooltip="{{ 'SIDEBARDERECHO.CERRAR' | translate }}" (click)="scUtils.cerrarPlaceHolder()">close</mat-icon>
    </div> -->
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'pulse' } }"
			ngbTooltip="{{ 'SIDEBARDERECHO.CERRAR' | translate }}" (click)="scUtils.cerrarPlaceHolder()">
		</nb-icon>
	</div>

	<div class="sos-icon" *ngIf="loginService.esEstudiante()">
		<div class="pointer" ngbTooltip="{{ 'SOS.ASKFORHELP' | translate }}" (click)="askForHelp(nodeClickPopover)">
			<mat-icon [ngClass]="{'yellow-icon': (nodeClickPopover?.isSOS == 1 || isSOSClicked) && nodeClickPopover?.isHelped == 0,
														'green-icon': nodeClickPopover?.isSOS == 1 && nodeClickPopover?.isHelped == 1}"
														svgIcon="sos">
			</mat-icon>
		</div>
	</div>
	<div class="card-body">
		<div class="image-container" [style.background-image]="imagePlaceHolder"></div>
		<h5>{{infoNodeTitlePlaceHolder}}</h5>
		<p *ngIf="description !== undefined" class="card-text">{{description | truncate:[180, "..."]}}</p>
		<div *ngIf="!canEdit" class="d-flex align-center justify-content-center flex-column flex-md-row gap-3">
			<button nbButton class="btn-themecolor" size="small" (click)="asociarNodo(nodeClickPopover)" *ngIf="!canEdit && nodeClickPopover?.associated">
				{{'ASOCIACIONES.ASOCIACIONES' | translate}}
				<nb-icon icon="link-2"></nb-icon>
			</button>

			<button nbButton hero status="success" size="small" (click)="playNode(nodeClickPopover)">
				{{'SIGMACOMPONENT.PLAY' | translate }}
				<nb-icon icon="play-circle"></nb-icon>
			</button>
		</div>


		<div *ngIf="canEdit" class="d-flex align-center justify-content-center flex-column flex-md-row gap-3">
			<button nbButton class="btn-themecolor" size="small" (click)="asociarNodo(nodeClickPopover)">
				{{'ASOCIACIONES.ASOCIAR' | translate}}<nb-icon
				icon="link-2"></nb-icon>
			</button>

			<button nbButton hero status="success" size="small" (click)="editNode(nodeClickPopover)">
				{{'SIGMACOMPONENT.EDIT' | translate }}
				<nb-icon icon="edit-2"></nb-icon>
			</button>
		</div>
	</div>
</div>


<div class="popover2" id="pEditMenu" #nodeQuizzEditPopover>
	<div class="d-flex flex-row align-items-center justify-content-between">
		<span class="text-center" style="flex: 1;">{{infoNodeTitle}}</span>
		<mat-icon class="icon-color-blue closebtn pointer text-right" ngbTooltip="{{ 'SIDEBARDERECHO.CERRAR' | translate }}"
			(click)="scUtils.cerrarEditMenu()">close</mat-icon>
	</div>

	<div class="d-flex flex-row flex-wrap justify-content-center p-3 gap-3">

		<div class="d-flex flex-column text-center align-items-center gap-2">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.EDIT' | translate" placement="top"
				(click)="editElement()">
				<mat-icon class="circle-button" color="primary">edit</mat-icon>
			</button>
			<span>{{'SIGMACOMPONENT.EDIT' | translate}}</span>
		</div>

		<div class="d-flex flex-column text-center align-items-center gap-2">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.COPYBUFFER' | translate" placement="top"
				(click)="scUtils.copyElement()">
				<mat-icon class="circle-button" color="primary">content_copy</mat-icon>
			</button>
			<span>{{'SIGMACOMPONENT.COPYBUFFER' | translate}}</span>
		</div>

		<div class="d-flex flex-column text-center align-items-center gap-2" *ngIf="isNode">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.LINK' | translate" placement="top"
				(click)="scUtils.copyElement()">
				<mat-icon class="circle-button" svgIcon="link"></mat-icon>
			</button>
			<span>{{'SIGMACOMPONENT.LINK' | translate}}</span>
		</div>

		<div class="d-flex flex-column text-center align-items-center gap-2" *ngIf="loginService.esAutor() && !isRented">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.DELETE' | translate" placement="top"
				(click)="scUtils.deleteElement()">
				<mat-icon class="circle-button" color="primary">delete</mat-icon>
			</button>
			<span>{{'SIGMACOMPONENT.DELETE' | translate}}</span>
		</div>

		<div class="d-flex flex-column text-center align-items-center gap-2" *ngIf="loginService.esAutor() && isRented">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.DELETE' | translate" placement="top"
				(click)="scUtils.deleteElement()">
				<mat-icon class="circle-button" color="primary">delete</mat-icon>
			</button>
			<span>{{'GLOBALS.DESALQUILAR' | translate}}</span>
		</div>

		<div class="d-flex flex-row text-center align-items-center" *ngIf="loginService.esAutor() && infoNodeTitle !== 'Sticker'">
			<div class="d-flex flex-column align-items-center gap-2">
				<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.CERTIFICAR' | translate" placement="top"
					(click)="certifiedQuiz($event)">
					<mat-icon class="circle-button" svgIcon="quiz_certificado"></mat-icon>
				</button>
				<span>{{'SIGMACOMPONENT.CERTIFICAR' | translate}}</span>
			</div>
			<!-- <div class="pl-3">
				<form [formGroup]="formulario" #thisForm="ngForm">
					<mat-slide-toggle class="buttonPublicar" (click)="certifiedQuiz($event)"
						formControlName="certificadoQuiz"></mat-slide-toggle>
				</form>
			</div> -->
		</div>
	</div>
	<div class="d-flex flex-column text-center align-items-center gap-2">
		<button mat-raised-button mat-icon-button [ngbTooltip]="'SALWIZAR.GENERATEACTIVITY' | translate" placement="bottom"
			style="z-index: 9999;" (click)="generateQuizzes()">
			<nb-icon class="circle-button icon-size" icon="question-mark-circle-outline" style="color: #3f51b5;"></nb-icon>
		</button>
		<span>{{ 'SALWIZAR.GENERATEACTIVITY' | translate }}</span>
	</div>
</div>


<div style="position: relative;"
	[style.display]="((userIsEditor && canEdit == false) || (initialGraphs.includes(idMapa) && loadInitialGraph == false)) ? 'none' : 'block'">
    <div (window:resize)="onResize($event)" [ngbTooltip]="'SIGMACOMPONENT.ADDMULTIQUIZZ' | translate"  id="sigma-container"
         (contextmenu)="onRightClick()" #sigmaContainer>
    </div>
</div>
<mat-progress-bar mode="determinate" value="{{progress}}" *ngIf="progress" class="sigma-progress-bar"></mat-progress-bar>
<app-loading [isVisible]="isLoading"></app-loading>
<app-file-uploader *ngIf="bgUtils.settingBackground" (file)="newImage($event)" (closeBgEdit)="bgUtils.setBackground()" [activeColor]="'orangered'" [baseColor]="'lightgray'"></app-file-uploader>
<div class="app-version">{{version}}</div>
<!-- <div class="graph-title">
	<a (click)="emitshowModalCourse('gridList')">{{graphTitle}}</a>
</div> -->


<div *ngIf="isDemo" class="domain-demo col-4">
    <button type="button" class="btn btn-success btn-sm" (click)="login()" >{{'GLOBALS.ENTER' | translate}}</button>
    <button type="button" class="btn btn-info btn-sm ml-2"  (click)="registrarme()">{{'SIGMACOMPONENT.FREESIGNUP' | translate}}</button>
</div>

<div *ngIf="modoTraerActividad || modoTraerNodo" class="domain-demo-alquilar-cancelar col-4">
    <div class="alert alert-info" role="alert">
        <span>{{'SIGMACOMPONENT.CANCELARALQUILAR' | translate}}</span>
        <div class=" d-flex justify-content-end">
            <button type="button" class="btn btn-danger btn-sm" (click)="cancelarOperacionAlquilar()" >{{'ACEPTARCANCELAR.CANCELAR' | translate}}</button>
        </div>
    </div>
</div>
<!-- TOOLBAR FOR PADS -->

<div id="toolbar-bottom" [ngClass]="{'open': isFooterOpen}">
	<div class="hide-conten" (click)="toggleFooter()">
		<mat-icon class="pointer">
			{{ isFooterOpen ? 'arrow_downward' : 'arrow_upward' }}
		</mat-icon>
	</div>
	<div class="cont-btn-footer">
		<app-pad-controller class="glass" *ngIf="ModoAuto" [upAndDownOff]="false" [isModalOpen]="isModalOpen" [isShowLabels]="isShowLabels" [listNodeAuto]="ModoAuto" [isShow]="isShowPads" [canEdit]="changedToEditor" [NodeViewPads]="zoomNode" [idCurso]="idCurso" [idMapa]="idMapa" (action)="getActionFromPads($event)"></app-pad-controller>
		<app-pad-center class="glass" (modeAuto)="openModalModeAuto()" [courseName]="courseTitle" [graphTitle]="graphTitle" [isShow]="isShowPads" [isShowLabels]="isShowLabels" [canEdit]="changedToEditor"></app-pad-center>
		<app-pad-buttons class="glass" (modeAuto)="openModalModeAuto()" [courseName]="courseTitle" [isShow]="isShowPads" [isShowLabels]="isShowLabels" [canEdit]="changedToEditor"></app-pad-buttons>
	</div>

	<app-graph-buttons (action)="getActionFromPads($event)" [canEdit]="changedToEditor" [idCurso]="idCurso"></app-graph-buttons>
</div>




<!-- LATERAL MENU RIGHT  -->
<div id="lateral-menu" style="height: 10vh;">
    <app-graph-lateral-icons [isShowPads]="isShowPads" [isShowLabels]="isShowLabels" (action)="getActionFromRightMenu($event)"></app-graph-lateral-icons>

    <!-- MODAL -->
    <app-modal #myModal titulo="{{ 'SIGMACOMPONENT.REGISTERCOURSE' | translate }}">
        <div class="modal-body">
            <app-registrar-curso></app-registrar-curso>
        </div>
    </app-modal>

    <!-- MODAL -->
    <app-modal #modalAlert>
        <div class="modal-body">
            <div>{{ 'SIGMACOMPONENT.DELETENODESQUESTION' | translate }}</div>
            <div mat-dialog-actions>
                <button mat-button>{{ 'SIGMACOMPONENT.CANCEL' | translate }}</button>
                <button mat-button cdkFocusInitial>{{ 'SIGMACOMPONENT.OK' | translate }}</button>
            </div>
        </div>
    </app-modal>
</div>
