import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, ReplaySubject, of } from 'rxjs';
import { QuizzesService } from '../quizzes/quizzes.service';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../models/shared/api-response.model';
import { QuizStack } from '../../models/quizzes/quiz-stack.model';
import { QuizModel, TypleColorElement } from '../../models/quizzes';
import { User } from '../../models/users/user.models';
import { FormGroup } from '@angular/forms';
import { map, share } from 'rxjs/operators';

export interface ListQuizzesStackChallengesModel {
	idQuiz: number,
	reponses: number[],
	quiz: QuizModel,
	openTime: number,
	idTarget: number,
	idCourse: number,
	answered: number,
	result: boolean,
	text: string,
}
@Injectable({
	providedIn: "root",
})
export class QuizzesstackService {
	private _responseListQuizzesStackChallenges: ListQuizzesStackChallengesModel[] =
		[]; //Almacenamos las respuestas del quiz

	public get responseListQuizzesStackChallenges(): ListQuizzesStackChallengesModel[] {
		return this._responseListQuizzesStackChallenges;
	}
	public set responseListQuizzesStackChallenges(
		value: ListQuizzesStackChallengesModel[]
	) {
		this._responseListQuizzesStackChallenges = value;
	}

	private _forcedChecked: [] = []; //Almacenamos las respuestas del quiz

	public get forcedChecked(): [] {
		return this._forcedChecked;
	}
	public set forcedChecked(value: []) {
		this._forcedChecked = value;
	}

	private _lastRequestQuizz: QuizStack; //Almacenamos el ultimo quizz enviado al endpoint

	public get lastRequestQuizz(): QuizStack {
		return this._lastRequestQuizz;
	}
	public set lastRequestQuizz(value: QuizStack) {
		this._lastRequestQuizz = value;
	}

	constructor(
		public quizService: QuizzesService,
		private httpClient: HttpClient
	) {}

	public createQuizzesStack(
		quiz: any,
		user: User,
		courseId: number,
		graphId: number,
		openTime: number,
		result: boolean,
		files: File[],
		filesUploads?: any,
		modeSocket?: boolean,
		idGroup?: number
	) {
		let body: QuizStack;
		body = {
			idQuiz: quiz.idQuiz,
			idQuizOriginal:
				quiz.idMultiplexQuiz === 0 ? quiz.idQuiz : quiz.idQuizFather,
			idUser: user.idUser,
			asked: openTime,
			answered: Date.now(),
			result: result ? 1 : 0,
			timeCreation: Date.now(),
			idTarget: graphId,
			idCourse: courseId,
			answersImage: filesUploads.answersImagen,
			answersDocs: filesUploads.answersDocs,
			answersAudio: filesUploads.answersAudio,
			answersVideo: filesUploads.answersVideo,
			certifiedQuiz: quiz.certifiedQuiz,
			role: "o",
			answersText:
				quiz.answersText === undefined ? "" : quiz.answersText.answer,
			selectedOptions: quiz.selectedOptions,
		};

		let form: FormData = new FormData();

		form.append("quizzesstack", JSON.stringify(body));

		files.forEach((file) => {
			form.append("files", file);
		});
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		const idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		const url = modeSocket
			? `quizze/answerQuizzesSession/${idGrupo}/${true}/null`
			: `quizze/answerQuiz/${idGrupo}`;

		return this.httpClient.post<ApiResponse<any>>(url, form);
	}

	showQuizStack(idQuiz: any, userQuiz: any) {}

	getIndice(arrayQuiz, idQuiz) {
		var indice = -1;
		arrayQuiz.filter(function (quiz, i) {
			if (quiz.linkedIdQuiz === idQuiz) {
				indice = i;
			}
		});
		return indice;
	}

	/*UPDATE SCORE QUIZ OF STACK*/

	public anserwQuizElement(
		quizId: number,
		element,
		file?: File
	): Observable<any> {
		const formData: FormData = new FormData();
		if (file !== undefined) {
			file = file;
		} else if (element.data !== undefined) {
			file = element.data;
		}

		formData.append("elements", JSON.stringify(element));
		formData.append("files", file);

		return this.httpClient.post<any>(
			`quizze/quizzes/${quizId}/uploadfile/${element.type}/manualcorrect`,
			formData
		);
	}

	/********  QUIZZES MULTIPLES  ******** */

	public addFileQuizz(
		quizId: number,
		file: File,
		typeMediaUrl: string
	): Observable<any> {
		const formData: FormData = new FormData();
		formData.append("files", file);
		formData.append("nameFile", "");
		const url = `quizze/create/quizMultipleFile/${quizId}/${typeMediaUrl}`;
		formData.append("background", JSON.stringify(TypleColorElement.AZUL));

		return this.httpClient.post<any>(url, formData);
	}

	updateFileQuizz(
		quizId: number,
		file: File,
		typeMediaUrl: string,
		nameCurrentFile: string
	) {
		const formData: FormData = new FormData();
		formData.append("files", file);
		formData.append("nameFile", nameCurrentFile);
		formData.append("background", JSON.stringify(TypleColorElement.AZUL));
		const url = `quizze/update/quizMultipleFile/${quizId}/${typeMediaUrl}`;

		return this.httpClient.post<any>(url, formData);
	}

	public deleteFileQuizz(
		quizId: number,
		typeMediaUrl: string,
		nameFile: string
	): Observable<any> {
		const formData: FormData = new FormData();
		formData.append("files", "");
		formData.append("nameFile", nameFile);
		const url = `quizze/delete/quizMultipleFile/${quizId}/${typeMediaUrl}`;

		return this.httpClient.post<any>(url, formData);
	}

	/******************************** */
	createTextEditQuizz(quizId: any, result: any, typeMediaUrl: string) {
		const formData: FormData = new FormData();
		formData.append("files", "");
		formData.append("nameFile", result);
		const url = `quizze/update/quizMultipleFile/${quizId}/${typeMediaUrl}/text`;
		return this.httpClient.post<any>(url, formData);
	}

	updateTextEditQuizz(quizId: any, result: any, typeMediaUrl: string) {
		const formData: FormData = new FormData();
		formData.append("files", "");
		formData.append("nameFile", result);
		const url = `quizze/update/quizMultipleFile/${quizId}/${typeMediaUrl}/text`;
		return this.httpClient.post<any>(url, formData);
	}

	deleteTextEditQuizz(quizId: any, result: any, typeMediaUrl: string) {
		const formData: FormData = new FormData();
		formData.append("files", "");
		formData.append("nameFile", "");
		const url = `quizze/delete/quizMultipleFile/${quizId}/${typeMediaUrl}`;
		return this.httpClient.post<any>(url, formData);
	}

	public answerListQuizzesSession(
		idGroup: number,
		quizzesStack: QuizStack[]
	): Observable<any> {
		const url = `quizze/answerListQuizzesSession/${idGroup}`;
		const body: QuizStack[] = quizzesStack;

		return this.httpClient.post(url, body);
	}

	public answerQuizzesSession(
		idGroup: number,
		quizzesStack: QuizStack,
		challengeMode: boolean = true,
		idCompSession?: string
	): Observable<any> {
		const url = `quizze/answerQuizzesSession/${idGroup}/${challengeMode}/${idCompSession}`;
		let form: FormData = new FormData();
		form.append("quizzesstack", JSON.stringify(quizzesStack));

		return this.httpClient.post<ApiResponse<any>>(url, form);
	}

	/************************************************** */
}
