import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import { ModelQuizElement, ModelQuizFile } from "src/app/core/models/quizzes";
import { QuizElementTypes, QuizElementTypesAnswer } from "src/app/core/models/quizzes/quiz-element-types.enum";
import { QuizzesService } from "src/app/core/services/quizzes";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { QuizHypermediaComponent } from "src/app/shared/components/nodes/quiz-hypermedia/quiz-hypermedia.component";
import { environment } from "src/environments/environment";
import {
    AUD_URL,
    PDF_URL,
    PICTURE_URL,
    TEXT_URL,
    VID_URL,
} from "../../../quiz-edit-multiple-body/quiz-edit-multiple-body.component";
import { QuizModalHypermediaComponent } from "../../../quiz-modal-hypermedia/quiz-modal-hypermedia.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";

@Component({
    selector: "app-elements-sub-quizzes",
    templateUrl: "./elements-sub-quizzes.component.html",
    styleUrls: ["./elements-sub-quizzes.component.scss"],
})
export class ElementsSubQuizzesComponent implements OnInit {
    @Output() elementAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() elementUpdatedSize: EventEmitter<any> = new EventEmitter<any>();
    @Output() elementDelete: EventEmitter<any> = new EventEmitter<any>();


    /**
     * !Tipos de elementos en la parte de RESPUESTAS
     */
    private optionTypes: QuizElementTypes[] = [
        QuizElementTypes.O_TEXTS,
        QuizElementTypes.O_AUDIOS,
        QuizElementTypes.O_PDFS,
        QuizElementTypes.O_PICTURES,
        QuizElementTypes.O_VIDEOS
    ];

    public DIR_VIDEO_URL = VID_URL;
    public DIR_AUDIO_URL = AUD_URL;
    public DIR_DOC_URL = PDF_URL;
    public DIR_PICTURE_URL = PICTURE_URL;
    public DIR_TEXT_URL = TEXT_URL;
    public mediaViewUrl = `${environment.quizzesContent}`;
    public IMG = "IMG";
    public VID = "VID";
    public DOC = "DOC";
    public AUD = "AUD";
    public TEXT = "TEXT";

    @Input() quizFiles: ModelQuizFile;
    @Input() elementsData: any = [];
    ELEMENT_TYPE: QuizElementTypes;
    QuizElement: ModelQuizElement;
    Datos: ModelQuizElement[] = [];
    Respuestas: ModelQuizElement[] = [];
    Soluciones: ModelQuizElement[] = [];
    disabelNuevoEnDatos: boolean = false;
    disabelNuevoEnSol: boolean = false;
    disabelNuevoEnRes: boolean = false;
    idQuizElement: number;
    language: any = 0;
    constructor(
        private modalService: NgbModal,
        private quizzesServices: QuizzesService,
        private translateService: TranslateService,
				private toaster: ToasterService
    ) {}

    ngOnInit() {
        this.idQuizElement = this.elementsData.quizMultiple.idQuizMultiple;
        this.updateElement(this.elementsData.element);
        this.estadoBotones();
    }
    estadoBotones() {
        if (this.Datos.length >= 5) {
            this.disabelNuevoEnDatos = true;
        }
        if (this.Respuestas.length >= 5) {
            this.disabelNuevoEnRes = true;
        }
        if (this.Soluciones.length >= 5) {
            this.disabelNuevoEnSol = true;
        }
    }
    updateElementData(elementsData: ModelQuizElement, index){
        let notContinue = false
        for (let index = 0; index < this.Datos.length; index++) {
            let elementDataU = this.Datos[index];
            if (elementsData.idQuizzesDataElements === elementDataU.idQuizzesDataElements) {
                this.Datos[index].xsize = elementsData.xsize;
                this.Datos[index].ysize = elementsData.ysize;
                notContinue = true;
                break;
            }
        }
        if(!notContinue){
            for (let index = 0; index < this.Soluciones.length; index++) {
                let elementDataU = this.Soluciones[index];
                if (elementsData.idQuizzesDataElements === elementDataU.idQuizzesDataElements) {
                    this.Soluciones[index].xsize = elementsData.xsize;
                    this.Soluciones[index].ysize = elementsData.ysize;
                    notContinue = true;
                    break;
                }
            }
        }

    }
    updateElement(elementsData: any) {
        this.Datos = [];
        this.Respuestas = [];
        this.Soluciones = [];



        for (let index = 0; index < elementsData.length; index++) {
            this.QuizElement = new ModelQuizElement();
            const element = elementsData[index] as ModelQuizElement;
            this.QuizElement.data = element.data;
            this.QuizElement.elementType = element.elementType;
            this.QuizElement.idQuizMultiple = element.idQuizMultiple;
            this.QuizElement.idQuizzesDataElements =
                element.idQuizzesDataElements;
            this.QuizElement.responseCheck = element.responseCheck;
            this.QuizElement.xposition = element.xposition;
            this.QuizElement.xsize = element.xsize;
            this.QuizElement.yposition = element.yposition;
            this.QuizElement.ysize = element.ysize;

            if (
                this.QuizElement.elementType === this.elementTypes.Q_AUDIOS ||
                this.QuizElement.elementType === this.elementTypes.Q_VIDEOS ||
                this.QuizElement.elementType === this.elementTypes.Q_PDFS ||
                this.QuizElement.elementType === this.elementTypes.Q_PICTURES ||
                this.QuizElement.elementType === this.elementTypes.Q_TEXTS
            ) {
                this.Datos.push(this.QuizElement);
            } else if (

                this.QuizElement.elementType === this.elementTypes.O_AUDIOS ||
                this.QuizElement.elementType === this.elementTypes.O_VIDEOS ||
                this.QuizElement.elementType === this.elementTypes.O_PDFS ||
                this.QuizElement.elementType === this.elementTypes.O_PICTURES ||
                this.QuizElement.elementType === this.elementTypes.O_TEXTS ||
                this.QuizElement.elementType === this.elementTypes.O_VIDEOCAMARA ||
                this.QuizElement.elementType === this.elementTypes.O_CAMERA ||
                this.QuizElement.elementType === this.elementTypes.O_MICRO
            ) {

                    this.Respuestas.push(this.QuizElement);


            } else if (
                this.QuizElement.elementType === this.elementTypes.A_AUDIOS ||
                this.QuizElement.elementType === this.elementTypes.A_VIDEOS ||
                this.QuizElement.elementType === this.elementTypes.A_PDFS ||
                this.QuizElement.elementType === this.elementTypes.A_PICTURES ||
                this.QuizElement.elementType === this.elementTypes.A_TEXTS
            ) {
                this.Soluciones.push(this.QuizElement);
            }
        }
    }

    public get elementTypes() {
        return QuizElementTypes;
    }
    public get elementTypesAnswer() {
        return QuizElementTypesAnswer;
    }


    addElementTSoluciones() {
        const modalRef = this.modalService.open(QuizModalHypermediaComponent, {
            scrollable: true,
            windowClass: MODAL_DIALOG_TYPES.W95,
        });
        modalRef.componentInstance.quizFiles = this.quizFiles;
        modalRef.componentInstance.fileSelected.subscribe((data) => {
            modalRef.close();

            this.inserElement(data.fileName, data.fileType, false, false, true);
        });
    }

    addElementToRespuestas() {
        const modalRef = this.modalService.open(QuizHypermediaComponent, {
            scrollable: true,
            windowClass: MODAL_DIALOG_TYPES.W95,
        });
        modalRef.componentInstance.insert = false;
        modalRef.componentInstance.mostrarMic = false;
        modalRef.componentInstance.familiaIdeas = false;
        modalRef.componentInstance.quizFiles = this.quizFiles;
        modalRef.componentInstance.fileSelected.subscribe((data) => {
            modalRef.close();
            this.insertDataSelected(data.type);
            // this.inserElement(data.fileName, data.fileType, false, true, false);
        });
    }
    addElementToDatos() {
        const modalRef = this.modalService.open(QuizModalHypermediaComponent, {
        // const modalRef = this.modalService.open(QuizHypermediaComponent, {
            scrollable: true,
            windowClass: MODAL_DIALOG_TYPES.W95,
        });

        modalRef.componentInstance.quizFiles = this.quizFiles;

        modalRef.componentInstance.fileSelected.subscribe((data) => {
            modalRef.close();
            this.language = data.language;
            this.inserElement(data.fileName, data.fileType, true, false, false);
        });
    }

    inserElement(
        fileName: any,
        fileType: any,
        isDato: boolean,
        isResp: boolean,
        isSol: boolean
    ) {
        let elementType;
        let idQuizMultiple = this.idQuizElement;
        if (isDato) {
            elementType = this.buscarElementTypeOfDato(fileType);
        } else if (isResp) {
            elementType = this.buscarElementTypeOfResp(fileType);
        } else if (isSol) {
            elementType = this.buscarElementTypeOfSol(fileType);
        }
        if (elementType != undefined)
            this.inserDbNewElement(
                idQuizMultiple,
                elementType,
                fileName,
                isDato,
                isResp,
                isSol
            );
    }
    inserDbNewElement(
        idQuizMultiple: number,
        elementType: any,
        fileName: any,
        isDato: boolean,
        isResp: boolean,
        isSol: boolean
    ) {
        this.quizzesServices
            .quizzAddElement(idQuizMultiple, elementType, fileName, this.language)
            .subscribe(
                (res) => {
                    this.elementAdded.emit({
                        idQuizMultiple: idQuizMultiple,
                        element: res.data as ModelQuizElement,
                    });

                    this.toaster.success(
                        this.translateService.instant(
                            "QUIZZES.ELEMENTOAGREGADO"
                        )
                    );
                    this.estadoBotones();
                },
                (err) => {
                    console.error(err);
                }
            );
    }

    buscarElementTypeOfSol(fileType: any): any {
        if (fileType === this.IMG) {
            return this.elementTypes.A_PICTURES;
        } else if (fileType === this.AUD) {
            return this.elementTypes.A_AUDIOS;
        } else if (fileType === this.VID) {
            return this.elementTypes.A_VIDEOS;
        } else if (fileType === this.TEXT) {
            return this.elementTypes.A_TEXTS;
        } else if (fileType === this.DOC) {
            return this.elementTypes.A_PDFS;
        }
    }
    buscarElementTypeOfResp(fileType: any): any {
        if (fileType === this.IMG) {
            return this.elementTypes.O_PICTURES;
        } else if (fileType === this.AUD) {
            return this.elementTypes.O_AUDIOS;
        } else if (fileType === this.VID) {
            return this.elementTypes.O_VIDEOS;
        } else if (fileType === this.TEXT) {
            return this.elementTypes.O_TEXTS;
        } else if (fileType === this.DOC) {
            return this.elementTypes.O_PDFS;
        }
    }
    buscarElementTypeOfDato(fileType: any) {
        if (fileType === this.IMG) {
            return this.elementTypes.Q_PICTURES;
        } else if (fileType === this.AUD) {
            return this.elementTypes.Q_AUDIOS;
        } else if (fileType === this.VID) {
            return this.elementTypes.Q_VIDEOS;
        } else if (fileType === this.TEXT) {
            return this.elementTypes.Q_TEXTS;
        } else if (fileType === this.DOC) {
            return this.elementTypes.Q_PDFS;
        }
    }

    deleteElement(
        element: ModelQuizElement,
        isDato: boolean,
        isResp: boolean,
        isSol: boolean
    ) {
        // let idQuizMultiple = this.idQuizElement;

        this.quizzesServices
            .quizzDeleteElement(element.idQuizzesDataElements)
            .subscribe(
                (res) => {
                    this.elementDelete.emit(element);
                    if (isDato) {
                        this.disabelNuevoEnDatos = false;
                        this.Datos = this.Datos.filter(function (ELEMENT) {
                            return (
                                ELEMENT.idQuizzesDataElements !==
                                element.idQuizzesDataElements
                            );
                        });
                    } else if (isResp) {
                        this.disabelNuevoEnRes = false;
                        this.Respuestas = this.Respuestas.filter(function (
                            ELEMENT
                        ) {
                            return (
                                ELEMENT.idQuizzesDataElements !==
                                element.idQuizzesDataElements
                            );
                        });
                    } else {
                        this.disabelNuevoEnSol = false;
                        this.Soluciones = this.Soluciones.filter(function (
                            ELEMENT
                        ) {
                            return (
                                ELEMENT.idQuizzesDataElements !==
                                element.idQuizzesDataElements
                            );
                        });
                    }
                    this.toaster.success(
                        this.translateService.instant(
                            "QUIZZES.ELEMENTOELIMINADO"
                        )
                    );
                },
                (err) => {
                    console.error(err);
                }
            );
    }



    /**
     * !Buscamos el tipo de elemento seleccionado
     * @param type
     */
     insertDataSelected(type: number) {

        let typeElement: number = this.cambiarTypeEnRespuesta(type);
        let numberAsString = `${typeElement}`;
         let idQuizMultiple = this.idQuizElement;
         this.quizzesServices.quizzAddElement(idQuizMultiple,  numberAsString).subscribe(res => {
            // console.log(res)
            this.elementAdded.emit({
                idQuizMultiple: idQuizMultiple,
                element: res.data as ModelQuizElement,
            });

            this.toaster.success(
                this.translateService.instant(
                    "QUIZZES.ELEMENTOAGREGADO"
                )
            );
            this.estadoBotones();

         }, err => console.log(err))
    }

    cambiarTypeEnRespuesta(type: number): number {
        let elementType: number;
        if(type === 0){
            elementType = QuizElementTypes.O_TEXTS;
        }
        else if(type === 1){
            elementType = QuizElementTypes.O_VIDEOS;
        }
        else if(type === 2){
            elementType = QuizElementTypes.O_PICTURES;
        }
        else if(type === 3){
            elementType = QuizElementTypes.O_AUDIOS;
        }
        else if(type == 4){
            elementType = QuizElementTypes.O_PDFS;
        }

        else if(type == 6){
            elementType = QuizElementTypes.O_VIDEOCAMARA;
        }
        else if(type == 7){
            elementType = QuizElementTypes.O_MICRO;
        }

        return elementType;
    }

    public onResizeStop(event:IResizeEvent, index:number, datos: ModelQuizElement){

        //Set the width and the height of the image
        let width:number = event.size.width
        let height:number = event.size.height
        this.QuizElement = new ModelQuizElement();
        this.QuizElement.xsize = width;
        this.QuizElement.ysize = height;
        this.QuizElement.data = datos.data;
        this.QuizElement.elementType = datos.elementType;
        this.QuizElement.idQuizMultiple = datos.idQuizMultiple;
        this.QuizElement.idQuizzesDataElements = datos.idQuizzesDataElements;
        this.QuizElement.responseCheck = datos.responseCheck;
        this.QuizElement.xposition = datos.xposition;
        this.QuizElement.yposition = datos.yposition;

            this.updateAll(this.QuizElement);
    }
    updateAll(QuizElement: ModelQuizElement) {
          //Call to function to update the data in the backend
          this.quizzesServices.quizzUpdateElement(QuizElement).subscribe(res => {
            this.elementUpdatedSize.emit({
                idQuizMultiple: QuizElement.idQuizMultiple,
                element: res.data as ModelQuizElement,
            });
            this.toaster.success(
                this.translateService.instant(
                    "QUIZZES.DIALOGS.UPDATESIZE"
                )
            );
         }, err => console.log(err))

    }

    onResizeChangue(datos: ModelQuizElement){
        let width:any = document.getElementById("formControlRangeY");
        let height:any = document.getElementById("formControlRangeX");
        console.log(width.value)
        console.log(height.value)


        this.QuizElement = new ModelQuizElement();
        this.QuizElement.xsize = width.value;
        this.QuizElement.ysize = height.value;
        this.QuizElement.data = datos.data;
        this.QuizElement.elementType = datos.elementType;
        this.QuizElement.idQuizMultiple = datos.idQuizMultiple;
        this.QuizElement.idQuizzesDataElements = datos.idQuizzesDataElements;
        this.QuizElement.responseCheck = datos.responseCheck;
        this.QuizElement.xposition = datos.xposition;
        this.QuizElement.yposition = datos.yposition;

        this.updateAll(this.QuizElement);
    }

}
