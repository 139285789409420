<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10 modal-title">
			<h2>{{ "SETTINGS.CENTERTITLE" | translate }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="teacher-settings p-2" [hidden]="isLoading">
			<div class="col-12">
				<app-alert context="UserData"></app-alert>
			</div>

			<div class="row" *ngIf="userCenter == null">
				<div class="col-12 px-4 py-2">
					<ngb-alert type="info" [dismissible]="false">{{"TEACHERSETTINGS.NOTCENTERSASSOCIATED" | translate}}</ngb-alert>
				</div>
			</div>
			<div class="row">
				<div
					class="col-12 px-4 py-2"
				>
					<div class="mat-elevation-z8" *ngIf="userCenter != null">
						<table>
							<thead>
								<tr>
									<th>{{ "SETTINGS.CENTERNAME" | translate }}</th>
									<th>{{ "SETTINGS.CENTERADDRESS" | translate }}</th>
									<th>{{ "SETTINGS.CENTERPHONE" | translate }}</th>
									<th>{{ "SETTINGS.IDCENTER" | translate }}</th>
								</tr>
							</thead>
							<tbody>
								<tr >
									<td>{{ userCenter.centerName }}</td>
									<td>{{ userCenter.centerAddress }}</td>
									<td>{{ userCenter.centerPhone }}</td>
									<td>{{ userCenter.idCenter }}</td>
									<td>
										<button nbButton class="btn-themecolor" size="small" (click)="editCenter()">
											<span>{{ "TEACHERSETTINGS.BUTTONEDIT" | translate }}</span>
										</button>
									</td>
									<td>
										<button nbButton status="danger" size="small" (click)="deleteAssign(userCenter.idCenter)">
											<span>{{ "TEACHERSETTINGS.BUTTONDELETE" | translate }}</span>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12 py-2 px-4 d-flex justify-content-center align-center" *ngIf="userCenter == null">
					<button nbButton class="btn-themecolor" size="small" (click)="openModalCenters()">
						<span>{{ "TEACHERSETTINGS.BUTTONASSIGNMENT" | translate }}</span>
					</button>
				</div>
			</div>
		</div>
	</nb-card-body>

</nb-card>
