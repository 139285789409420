import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { TranslateService } from '@ngx-translate/core';

export interface EstudentReportingTable{
  name: string;
  image: string;
}

const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg"

@Component({
  selector: 'app-live-reporting-table',
  templateUrl: './live-reporting-table.component.html',
  styleUrls: ['./live-reporting-table.component.scss'],
})
export class LiveReportingTableComponent implements OnInit {
  displayedColumns: string[] = [];
  // dataSource : []=  [];
  dataSource: MatTableDataSource<any>;

  @Input() headTable: [];
  @Input() bodyTable: [];
  bodyTable2: any;
  // isLoading: boolean;
  // @Input() idGrafo: any;
  // @Input() idCourse: any;
  // @Input() idNode: any;
  // @Input() idGrupo: any;

  loading: boolean;
  quizType: number;
  challengeOn: boolean = JSON.parse(localStorage.getItem('challengeOn')) ? true : false;

  constructor( private imagePipe: ImagenPipe, private quizService: QuizzesService,
    private modalService: NgbModal, private translateService: TranslateService) { }

  ngOnInit() {
	this.challengeOn = JSON.parse(localStorage.getItem('challengeOn')) ? true : false;
  }

  // suscribeReportingService() {
  //   this.reportringService.reportingSusbcribe.subscribe( (res:any) => {
  //     this.headTable = res.headTable;
  //     this.bodyTable = res.bodyTable;
  //   })
  // }

  getUserAvatar(imagen):string{
    let image:string = ''
    imagen!== null && imagen !== ""? image = this.imagePipe.transform(imagen, 'userAvatar') : image = NOIMAGE
    return `url('${image}')`
  }


  // viewQuiz(node: any){
	// 	if (node.isMultiplex === 1) {
	// 		this.quizService.getQuizInformMultipleHijos(node.idQuiz, node.idUser).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {

	// 			const modalRef = this.modalService.open(ModalQuizesMultiplesComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W35, backdrop: 'static' })
	// 			modalRef.componentInstance.quizesMultiplesList = res;
	// 			modalRef.componentInstance.idUser = node.idUser;

	// 			modalRef.result.then(res => {
	// 					// Falta enviar la data para cambiar los valores y actualizar el backend
	// 					// console.log(res)
	// 					this.suscribeReportingService();
	// 					this.reportingService.getDataTableQuizes(this.idGrupo, this.idCourse, this.idGrafo, this.idNode, []).subscribe(  (res:any) => {
	// 						this.reportingService.reportingSusbcribe.next({ ...res.data})
	// 						this.loading = false;
	// 					})
	// 		})
	// 		});
	// 	} else {
	// 		this.quizService.getQuiz(node.idQuiz, this.idCourse, this.idGrafo).subscribe(res => {

	// 			if(res.quiz.quizType === 1){
	// 				this.quizService.getQuizInforme(node.idQuiz, node.idUser).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {
	// 					const modalRef = this.modalService.open(RecordarQuizPlayComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
	// 					modalRef.componentInstance.quiz = { ...res.quiz, user: res.quiz.user, idOriginal: res.quiz.idOriginal, id: res.quiz.idQuiz, originalX: res.quiz.originalX, originalY: res.quiz.originalY, size: res.quiz.size, sizeQuiz: res.quiz.sizeQuiz, x: res.quiz.x, y: res.quiz.y };;
	// 					modalRef.componentInstance.elements = res.elementsQuestion;
	// 					modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer;
	// 					modalRef.componentInstance.courseId = node.idCurso;
	// 					modalRef.componentInstance.graphId = node.target;

	// 					modalRef.result.then(res => {
	// 							// Falta enviar la data para cambiar los valores y actualizar el backend
	// 							console.log(res)
	// 							this.suscribeReportingService();
	// 							this.reportingService.getDataTableQuizes(this.idGrupo, this.idCourse, this.idGrafo, this.idNode, []).subscribe(  (res:any) => {
	// 								this.reportingService.reportingSusbcribe.next({ ...res.data})
	// 								this.loading = false;
	// 							})
	// 				})
	// 				});
	// 			} else{
	// 				this.toaster.error(this.translateService.instant("INFORMES.ONLYOPENANSWER")
	// 				)
	// 			}
	// 		})
	// 	}
  // }


}
