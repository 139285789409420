import { SosService } from './../../core/services/sos/sos.service';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NbWindowRef, NbWindowService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
declare var JitsiMeetExternalAPI: any;
@Component({
	selector: "app-meetings",
	templateUrl: "./meetings.component.html",
	styleUrls: ["./meetings.component.css"],
	encapsulation: ViewEncapsulation.None,
})
export class MeetingsComponent implements OnInit, AfterViewInit {
	domain: string = "8x8.vc"; // For self hosted use your domain
	room: any;
	options: any;
	api: any;
	user: any;

	// For Custom Controls
	isAudioMuted = false;
	isVideoMuted = false;
	helpPetitions: any;
	showCall: boolean = true;
	constructor(
		public activeModal: NgbActiveModal,
		private router: Router,
		private loginService: LoginService,
		private SosService: SosService,
		private windowService: NbWindowService,
		protected windowRef: NbWindowRef,
		private toaster: ToasterService,
		private translateService: TranslateService
	) {
		this.user = this.loginService.getUser();
	}

	ngOnInit() {
		//this.getHelpPetitions();
		this.windowRef.stateChange.subscribe((data) => {
			if (data.newState == "maximized") {
				this.minimize();
			}
			if (data.newState == "full-screen") {
				this.maximize();
			}
		});

		this.SosService.getHelpPetitionById(
			this.SosService.currentHelpPetition
		).subscribe((res: any) => {
			if (res.data.idUser == this.user.idUser) {
				let connectionDate = new Date()
				//console.log(connectionDate)
				let updateData = {
					...res.data,
					connected: true,
					connectionDate: connectionDate,
				};
				this.SosService.updateHelpPetition(updateData).subscribe(
					(result) => {
						this.toaster.success(
							this.translateService.instant("SOS.CONNECTIONOK")
						);
					},
					(err) => {
						console.log(err);
					}
				);
			}
		});
	}

	ngOnDestroy(): void {
		//Si el tutor cierra la llamada sin que el estudiante que pidio ayuda se conectara
		//Vuelve a setear la propiedad helped a false
		this.SosService.getHelpPetitionById(
			this.SosService.currentHelpPetition
		).subscribe((res: any) => {
			if (res.data.connected == false) {
				let updateData = {
					...res.data,
					helped: false,
					idTutor: null
				};
				this.SosService.updateHelpPetition(updateData).subscribe(
					(result) => {
						console.log(result.data);
					},
					(err) => {
						console.log(err);
					}
				);
			}
		});
	}

	minimize() {
		this.windowRef.maximize();
		this.showCall = false;
	}

	maximize() {
		this.windowRef.fullScreen();
		this.showCall = true;
	}

	ngAfterViewInit(): void {
		let roomName = this.SosService.getRoomName();

		this.options = {
			width: 1200,
			height: 800,
			userInfo: { displayName: this.user.firstName },
			configOverwrite: { prejoinPageEnabled: false },
			roomName: `vpaas-magic-cookie-49fdc41eb2de4bfbbc672d8499ac6b13/${roomName}`,
			parentNode: document.querySelector("#jaas-container"),
			// Make sure to include a JWT if you intend to record,
			// make outbound calls or use any other premium features!
			// jwt: "eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtNDlmZGM0MWViMmRlNGJmYmJjNjcyZDg0OTlhYzZiMTMvZjcxZDAzLVNBTVBMRV9BUFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE3MDExNzQ1NjIsImV4cCI6MTcwMTE4MTc2MiwibmJmIjoxNzAxMTc0NTU3LCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtNDlmZGM0MWViMmRlNGJmYmJjNjcyZDg0OTlhYzZiMTMiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOmZhbHNlLCJvdXRib3VuZC1jYWxsIjpmYWxzZSwic2lwLW91dGJvdW5kLWNhbGwiOmZhbHNlLCJ0cmFuc2NyaXB0aW9uIjpmYWxzZSwicmVjb3JkaW5nIjpmYWxzZX0sInVzZXIiOnsiaGlkZGVuLWZyb20tcmVjb3JkZXIiOmZhbHNlLCJtb2RlcmF0b3IiOnRydWUsIm5hbWUiOiJUZXN0IFVzZXIiLCJpZCI6Imdvb2dsZS1vYXV0aDJ8MTExMDg3MjI0Mzc2NDczOTMwNjg1IiwiYXZhdGFyIjoiIiwiZW1haWwiOiJ0ZXN0LnVzZXJAY29tcGFueS5jb20ifX0sInJvb20iOiIqIn0.UCGJpdhSXSXaAZhvswEvIasnU1f3u0I9jFuoDFfkJncPLSMV9mYGozhfFRLotcjPXi_scmskXXZhT1ja_X3HWM3Z61UQMz1hh5kZmLb6iiXiviKBoadnrgKWE2doYgv-9dSUq8rbTtpTiImRHjjxNSAs5O_4dXUrNTfbzuzDLnPwXsDyiTNtldeXr-A6SZXgOGpA0Ou8nlBmwYakHgEf4Gj5DmIfie_p1RJjbkgR1oPbpwBo2FGKUEDki_hc4M041cs5gHbdRFpBe8YZqtD0uUyREZpxQEuxC-9C4ao83LR0_jX-EKFmF-b487m59QRK9ETae1XghaeiDQhBabe8kQ"
		};

		this.api = new JitsiMeetExternalAPI(this.domain, this.options);

		// Event handlers
		this.api.addEventListeners({
			readyToClose: this.handleClose,
			participantLeft: this.handleParticipantLeft,
			participantJoined: this.handleParticipantJoined,
			videoConferenceJoined: this.handleVideoConferenceJoined,
			videoConferenceLeft: this.handleVideoConferenceLeft,
			audioMuteStatusChanged: this.handleMuteStatus,
			videoMuteStatusChanged: this.handleVideoStatus,
		});
	}

	closeModal(sendData?: any) {
		this.activeModal.close();
		this.windowRef.close();
	}

	handleClose = () => {
		this.closeModal();
		console.log("handleClose");
	};

	handleParticipantLeft = async (participant) => {
		console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
		const data = await this.getParticipants();
	};

	handleParticipantJoined = async (participant) => {
		console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
		const data = await this.getParticipants();
	};

	handleVideoConferenceJoined = async (participant) => {
		console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
		const data = await this.getParticipants();
	};

	handleVideoConferenceLeft = () => {
		console.log("handleVideoConferenceLeft");
		this.activeModal.close();
		//this.router.navigate(["/thank-you"]);
	};

	handleMuteStatus = (audio) => {
		console.log("handleMuteStatus", audio); // { muted: true }
	};

	handleVideoStatus = (video) => {
		console.log("handleVideoStatus", video); // { muted: true }
	};

	getParticipants() {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(this.api.getParticipantsInfo()); // get all participants
			}, 500);
		});
	}

	executeCommand(command: string) {
		this.api.executeCommand(command);
		if (command == "hangup") {
			this.closeModal();
			//this.router.navigate(["/thank-you"]);
			return;
		}

		if (command == "toggleAudio") {
			this.isAudioMuted = !this.isAudioMuted;
		}

		if (command == "toggleVideo") {
			this.isVideoMuted = !this.isVideoMuted;
		}
	}

	getHelpPetitions() {
		this.SosService.getHelpPetitions().subscribe(
			(data) => {
				this.helpPetitions = data.data;
			},
			(error) => {
				console.log(error);
			}
		);
	}
}

