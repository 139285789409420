<nb-card class="no-edge-card mb-0 col-12 h-100">
	<div class="closeModal">
		<nb-icon class="m-1 close-global" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<h5 class="bright-green-color pb-4">
			{{ "USERDATA.CONTACTTITLE" | translate }}
		</h5>

		<form [formGroup]="sendCommentsForm" (ngSubmit)="sendComments()" #thisForm="ngForm">
			<div class="row mb-3">
				<div class="col-12">
					<input nbInput shape="round" status="basic" fullWidth fieldSize="medium" class="form-control-login" type="text"
						placeholder="{{ 'USERDATA.NOMBRE' | translate }}" name="name" formControlName="name" />
					<div *ngFor="let validation of validationMessages.name">
						<mat-error *ngIf="
								sendCommentsForm.get('name')?.hasError(validation.type) &&
								(sendCommentsForm.get('name')?.dirty ||
									sendCommentsForm.get('name')?.touched ||
									thisForm.submitted)
							">
							<div class="error-message">{{ validation.message }}</div>
						</mat-error>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-12">
					<input type="email" nbInput shape="round" status="basic" fullWidth fieldSize="medium" class="form-control-login"
						placeholder="{{ 'USERDATA.EMAIL' | translate }}" name="email" formControlName="email" />
					<div *ngFor="let validation of validationMessages.email">
						<mat-error *ngIf="
								sendCommentsForm.get('email')?.hasError(validation.type) &&
								(sendCommentsForm.get('email')?.dirty ||
									sendCommentsForm.get('email')?.touched ||
									thisForm.submitted)
							">
							<div class="error-message">{{ validation.message }}</div>
						</mat-error>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-12 col-md-6">
					<nb-select placeholder="{{ 'LOGIN.PREFIX' | translate }}" fullWidth fieldSize="medium" class="SelectGlobal" hero shape="round" name="extension" formControlName="extension">
						<nb-option class="SelectGlobalOption" *ngFor="let prefix of $phonePrefixes | async" value="{{ prefix.dial_code }}">
							{{ prefix.dial_code }}
							{{ prefix.name_es }}
						</nb-option>
					</nb-select>
					<div *ngFor="let validation of validationMessages.prefix" class="mt-2">
						<div class="error-message"
							*ngIf="prefixControl?.hasError(validation.type) && (prefixControl?.dirty || prefixControl?.touched || thisForm.submitted)">
							{{validation.message}}</div>
					</div>
				</div>

				<div class="col-12 col-md-6 mt-2 mt-md-0">
					<input nbInput shape="round" status="basic" fullWidth fieldSize="medium" class="form-control-login"
						placeholder="{{ 'USERDATA.TELEFONOMOVIL' | translate }}" name="phone" formControlName="phone" />
					<div *ngFor="let validation of validationMessages.phone">
						<mat-error
							*ngIf="sendCommentsForm.get('phone')?.hasError(validation.type) && (sendCommentsForm.get('phone')?.dirty || sendCommentsForm.get('phone')?.touched || thisForm.submitted)">
							<div class="error-message">{{validation.message}}</div>
						</mat-error>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-12">
					<div class="form-group">
						<textarea nbInput shape="round" status="basic" fullWidth fieldSize="medium" class="form-control-login" fullWidth
							formControlName="comentary" id="comentary" rows="3"></textarea>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-12 col-md-6">
					<label for="name" class="text-color-secondary">
						{{ "USERDATA.ORCONTACTWHITUS" | translate }} <br />
						{{ "USERDATA.PHONE" | translate }}:
						<a class="bright-green-color" href="tel:+34623569819">+34623569819</a>
					</label>
				</div>
				<div class="col-12 col-md-6 pt-4 pt-md-0">
					<button nbButton class="d-block w-100 button-primary" type="submit">
						<span>{{ "USERDATA.SEND" | translate }}</span>
					</button>
				</div>
			</div>
		</form>
	</nb-card-body>
</nb-card>
