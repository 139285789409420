import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { PrefixCountry } from 'src/app/core/models/shared/prefixCountry.model';
import { LoginService } from 'src/app/core/services/login';
import { HttpClient } from '@angular/common/http';
import { NbDialogRef } from '@nebular/theme';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
	selector: 'app-modal-contact.component',
	templateUrl: './modal-contact.component.html',
	styleUrls: ['./modal-contact.component.scss']
})
export class ModalContactComponent implements OnInit {
	sendCommentsForm: UntypedFormGroup;
	private _maxLength: number = 15
	$phonePrefixes: Observable<PrefixCountry[]>
	validationMessages = {
		name: [],
    phone: [],
    extension: [],
    email: [],
    comentary: []
  };


	constructor(public activeModal: NgbActiveModal,
		public loginService: LoginService,
		private formBuild: UntypedFormBuilder,
		public translateService: TranslateService,
		private http: HttpClient,
		private dialogRef: NbDialogRef<any>,
		private toaster: ToasterService
		) {
		this.sendCommentsForm = this.formBuild.group({
			name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
			email: ['', [Validators.email]],
			comentary: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
			phone: new UntypedFormControl(''),
			extension: ['']
		});
	}


	ngOnInit() {
		this.$phonePrefixes = this.loginService.getPhonePrefix()
		this.cargarValidacionesFormulario();
		this.cargarDatosFormulario();
	}

	closeModal(sendData?: any) {
		this.dialogRef.close(sendData);
	}

	sendFormData(data):Observable<any> {
		return this.http.post<any>("user/supportEmail/inside",data)
	}

	showStatus() {
		console.log('??',this.sendCommentsForm)
	}

	sendComments() {
		if (this.sendCommentsForm.valid) {
			let data = this.sendCommentsForm.value
			data.extension = Number(data.extension.replace('+',''))
			data.phone = Number(data.phone)
			this.sendFormData(data).subscribe(res => {
				this.toaster.success(this.translateService.instant(res.error.msg));
				this.closeModal(true)
			})
		}
	}

	private cargarValidacionesFormulario() {

    this.translateService.get('VALIDACIONES.ELEMAILESOBLIGATORIO').subscribe((res: string) => {
      this.validationMessages.email.push({type: 'required', message: res});
  });

    this.translateService.get('VALIDACIONES.ELTELEFONOMOVILESOBLIGATORIO').subscribe((res: string) => {
      this.validationMessages.phone.push({type: 'required', message: res});
    });

    this.translateService.get('VALIDACIONES.MAXLENGTH').subscribe((res: string) => {
        this.validationMessages.phone.push({ type: 'maxlength', message: res.replace('{1}', `${this._maxLength}`) });
    });

		this.translateService.get('VALIDACIONES.MAXLENGTH').subscribe((res: string) => {
			this.validationMessages.name.push({ type: 'maxlength', message: res.replace('{1}', `${this._maxLength}`) });
	});


  }

	private cargarDatosFormulario() {
		let userData = this.loginService.getUser();
    this.sendCommentsForm.controls.name.setValue(userData.firstName);
    this.sendCommentsForm.controls.email.setValue(userData.mail);
    this.sendCommentsForm.controls.phone.setValue(userData.mobile);
    this.sendCommentsForm.controls.extension.setValue(userData.extension);
  }


}
