import { UsersService } from './../../../../core/services/users/users.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GroupModel } from 'src/app/core/models/groups/groups-model';
import { User } from 'src/app/core/models/users/user.models';
import { CoursesService } from 'src/app/core/services/courses';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { DynamicDatabase } from 'src/app/core/utils/treeView.utils';
import { environment } from 'src/environments/environment';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
  selector: 'app-search-group',
  templateUrl: './search-groups.component.html',
  styleUrls: ['./search-groups.component.scss']
})
export class SearchGroupComponent implements OnInit {

  public gruposList: GroupModel[] = [];
  public gruposListBuscar: GroupModel[] = [];
  user: User;
  profile: string;
  studentProfile: Profiles  = Profiles.Student;
  group: GroupModel;
  public formulario: UntypedFormGroup;
  numberOfInvited: number = 0;
  invitacionesList: any;
	public mediaViewUrl = `${environment.groupImagen}`;
	cargando: boolean;

  constructor(
    public translateService: TranslateService,
        public coursesService: CoursesService,
        public loginService: LoginService,
        public mastersService: GetDataService,
        public activeModal: NgbActiveModal,
        public database: DynamicDatabase,
        public router: Router,
        private groupService: GruposService,
        public userService:UsersService,
        private formBuild: UntypedFormBuilder,
				private toaster: ToasterService
  ) {
    this.formulario = this.formBuild.group({
      filtrado: [''],
  });
   }

  ngOnInit() {
  }

  addGrupos(data: any) {
    for (let index = 0; index < data.length; index++) {
      const element = data[index] as GroupModel;
      this.gruposList.push(element);
    }
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  filtrarEstudiantes(){
    const filtradoValue = this.formulario.value
		if(filtradoValue.filtrado.length == 7){
			this.cargando = true;
			this.groupService.getGruposFiltradoEstudiante(filtradoValue).subscribe((res) => {
				if(res.error.code == 0){
					this.gruposListBuscar = res.data
					this.cargando = false;
				} else{
					this.toaster.error(this.translateService.instant(res.error.msg));
					this.cargando = false;
				}

			},
			err => console.log(err));
		}
  }

  updateUrl(event: any) {
    event.target.src = '../../assets/images/no-image.png'
  }

  solicitarUnirGrupo(grupoUniver: any){
		this.cargando = true;
    console.log(grupoUniver)
    this.groupService.solicitarUnirGrupo(grupoUniver.idGroup).subscribe((res:any) => {
      console.log(res)
			this.formulario.reset();
			this.gruposListBuscar = [];
      this.toaster.success(this.translateService.instant('MODALGROUPSEDIT.REQUESTSENT'));
    },
    err => console.log(err));
		this.cargando = false;
  }

}
