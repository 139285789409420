<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10">
			<h4>{{'EDITORS.ASSIGNEDITORSTO' | translate}} {{curso.courseTittle}}</h4>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>

		<nb-card class="no-edge-card mb-0" >
			<div class="row m-1 justify-content-center">
				<div class="col-md-5 col-sm-12 col-12 p-0">
					<form [formGroup]="formulario" class="searchBar w-100 form-group" #thisForm="ngForm">
						<div class="input-container">
							<input type="search" nbInput fullWidth status="basic"
								placeholder="{{ 'EDITORS.SEARCHSEDITORSTOASSIGN' | translate }}" name="fiilter" formControlName="filtrado"
								autocomplete="off" (keyup)="filtrarNoEditores($event.target.value)">
						</div>
					</form>

					<ul class="list-group" *ngIf="users.length > 0">
						<li *ngFor="let noEditor of users | sortBy:'asc':'surname'"  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center mt-2">
							<div class="text-center avatar d-flex align-content-center" ngbTooltip="{{noEditor.idUser}}" placement="right">
									<img [src]="noEditor.picture | imagen:'userAvatar'" class="text-center avatar d-flex align-content-center" [alt]="noEditor.firstName" (error)="updateUrl($event)">
							</div>
							{{noEditor.firstName}}&nbsp;{{noEditor.surname}}
							<span (click)="agregarEditor(noEditor)"  ngbTooltip="{{'EDITORS.ADDEDITOR' | translate}}" placement="left" class="badge badge-pill"><mat-icon>add</mat-icon></span>
						</li>
					</ul>
					<!--<div *ngIf="users.length === 0" class="alert alert-info" role="alert">{{'EDITORS.TYPEUSERREGISTER' |
						translate}}</div>-->
				</div>

				<div class="col-md-1 col-sm-12 col-12 flex justify-content-center align-items-center my-md-0 my-4">
					<nb-icon class="arrow" status="basic" icon="arrow-circle-right"> </nb-icon>
				</div>

				<div class="col-md-5 col-sm-12 col-12 p-0">
					<div class="w-100 d-flex justify-content-center">
						<h3>{{ 'EDITORS.EDITORSASSIGNED' | translate }}</h3>
					</div>

					<ul class="list-group" *ngIf="editores.length > 0">
						<li *ngFor="let editor of editores| sortBy:'asc':'surname'"  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center mt-2">
							<div class="text-center avatar d-flex align-content-center" ngbTooltip="{{editor.idUser}}" placement="right">
									<img [src]="editor.picture | imagen:'userAvatar'" class="text-center p-2 d-flex align-content-center" [alt]="editor.firstName" (error)="updateUrl($event)">
							</div>
							{{editor.firstName}}&nbsp;{{editor.surname}}
							<span *ngIf="editores.length > 1" (click)="eliminarEditor(editor)"  ngbTooltip="{{'EDITORS.DELETEEDITOR' | translate}}" placement="left"  class="badge badge-pill"><mat-icon>delete</mat-icon></span>
							<span *ngIf="editores.length === 1" (click)="eliminarUltimo(editor)"  ngbTooltip="{{'EDITORS.DELETEEDITOR' | translate}}" placement="left"  class="badge badge-pill"><mat-icon style="color: gray">delete</mat-icon></span>
						</li>
					</ul>
				<!--<div *ngIf="editores.length === 0" class="alert alert-info" role="alert">
						{{'MODALLISTSTUDENTS.EMPTYLIST' | translate}}</div>-->
				</div>
			</div>
		</nb-card>
	</nb-card-body>

</nb-card>
