import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UserModel } from "src/app/core/models/courses";
import { AlertService } from "src/app/core/services/shared";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { UsersService } from "src/app/core/services/users";

@Component({
	selector: "app-nuevo-padre",
	templateUrl: "./nuevo-padre.component.html",
	styleUrls: ["./nuevo-padre.component.scss"],
})
export class NuevoPadreComponent implements OnInit {
	@Input() profile: string;
	@Output() action: EventEmitter<number> = new EventEmitter<number>();

	public formulario: UntypedFormGroup;
	users: any;
	errores: boolean=false;

	constructor(
		private formBuild: UntypedFormBuilder,
		public alertService: AlertService,
		public userService: UsersService,
		private toaster: ToasterService,
		private translateService: TranslateService
	) {
		this.formulario = this.formBuild.group({
			filter: [""],
		});
	}

	ngOnInit() {}

	filtrarPadres() {
		const filtradoValue = this.formulario.value;
		if (filtradoValue.filter.length === 10) {
			if (this.profile === "ESTUDIANTE") {
				this.userService.getFilterParents(filtradoValue.filter).subscribe(
					(res) => {
						this.users = res.data;
					},
					(err) => console.log(err)
				);
			} else if (this.profile === "PADRE") {
				this.userService.getFilterChildren(filtradoValue.filter).subscribe(
					(res) => {
						if (!res.data) {
							console.log(res.data);
							this.errores=true
							return
						}
						this.errores=false
						this.users = res.data;
					},
					(err) => console.log(err)
				);
			}
		}
	}

	enviarInvitacion(user: UserModel) {
		const idUser = user.idUser;
		if (this.profile === "ESTUDIANTE") {
			this.userService.childrequesttofather(idUser).subscribe((res) => {
				if (res.data) {
					this.filtrarPadres();
					this.toaster.success(
						this.translateService.instant("MODALLISTSTUDENTS.INVITATIONOK")
					);
				}
			});
		} else if (this.profile === "PADRE") {
			this.userService.parentRequestToFather(idUser).subscribe((res) => {
				if (res.data) {
					this.filtrarPadres();
					this.toaster.success(
						this.translateService.instant("MODALLISTSTUDENTS.INVITATIONOK")
					);
				}
			});
		}
	}

	cancelarInvitacion(user: UserModel) {
		const idUser = user.idUser;
		if (this.profile === "ESTUDIANTE") {
			this.userService.deletechildfather(idUser, "child").subscribe((res) => {
				this.filtrarPadres();
				this.toaster.success(
					this.translateService.instant("MODALLISTSTUDENTS.INVITATIONKO")
				);
			});
		} else if (this.profile === "PADRE") {
			this.userService.deletechildfather(idUser, "father").subscribe((res) => {
				this.filtrarPadres();
				this.toaster.success(
					this.translateService.instant("MODALLISTSTUDENTS.INVITATIONKO")
				);
			});
		}
	}

	back() {
		// mandamos el numero de la pantalla de nuevo padre
		this.formulario = this.formBuild.group({
			filter: [""],
		});
		this.action.emit(1);
	}
}
