<nb-card class="no-edge-card mb-0 modal-height-100-62 d-flex justify-content-center align-items-center">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body style="margin-top: 30%;">
		<!-- Sección con datos no sociales -->
		<div class="container" *ngIf="this.tipoDatos != 'social'">
			<div *ngIf="cargarCharts" class="card-container row justify-content-center">
				<div *ngFor="let consulta of consultas"
					class="col-md-4 col-sm-6 col-12 d-flex justify-content-center mb-4">
					<!-- Ajusta el tamaño mínimo y máximo de las tarjetas -->
					<div class="card-item card-equal-size" (click)="openChart(consulta.id)">
						<!-- Contenido de la tarjeta -->
						<h6>{{ consulta.title }}</h6>
						<div class="chart-container">
							<!-- Aquí van las gráficas -->
							<ng-container [ngSwitch]="consulta.id">
								<!-- GRAFICA RADIAL -->
								<app-radial-mini *ngSwitchCase="1" [parametroID]="1" [consultaID]="1"
									[datasetsImported]="datasetsImported_1" [labelsImported]="labelsImported_1">
								</app-radial-mini>
								<!-- GRAFICA SOLAR -->
								<app-solar-mini *ngSwitchCase="2" [parametroID]="1" [consultaID]="2"
									[datasetsImported]="datasetsImported_2" [labelsImported]="labelsImported_2">
								</app-solar-mini>
								<!-- Otras gráficas -->
								<!-- Más gráficos según tu código -->
							</ng-container>
						</div>
						<div class="layer"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- Sección con datos sociales -->
		<div class="container" *ngIf="this.tipoDatos == 'social'">
			<div *ngIf="surveysList.length !== 0">
				<div class="row justify-content-center">
					<div *ngFor="let survey of surveysList; let i = index"
						class="col-md-4 col-sm-6 col-12 d-flex justify-content-center mb-4">
						<nb-card class="pointer surveys-info card-equal-size" (click)="openSurvey(survey)">
							<nb-card-header class="pb-2 pl-0 pr-0 pt-0 border-bottom-0">
								<div class="img-container text-center" nbTooltip="{{survey.description}}"
									nbTooltipPlacement="top">
									<img [src]="'../../assets/images/demo_images/image' + i + '.png'"
										class="img-fluid survey-img">
								</div>
							</nb-card-header>
							<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
								<p class="text-center">{{ survey.title }}</p>
							</nb-card-body>
							<nb-card-footer class="p-2 d-flex justify-content-center align-items-center">
								<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;"
									[text]="survey.questions + ' Preguntas'"></nb-tag>
							</nb-card-footer>
						</nb-card>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>