<div *ngIf="alertMessage" class="w-100 text-center" style="position: fixed; top: 0; left: 0;"
	[ngClass]="{ 'alert': alertMessage, 'alert-success': alertMessage.type === 'success', 'alert-danger': alertMessage.type === 'error', 'alert-info': alertMessage.type === 'info', 'alert-warning': alertMessage.type === 'warning' }">
	<i *ngIf="alertMessage.type === 'success'" class="material-icons">done</i>
	<i *ngIf="alertMessage.type === 'danger'" class="material-icons">error</i>
	<i *ngIf="alertMessage.type === 'warning'" class="material-icons">warning</i>
	<i *ngIf="alertMessage.type === 'info'" class="material-icons">info</i>
	<span class="textoFontSize09 pl-2">{{alertMessage.text}}</span>
	<div *ngIf="alertMessage.type != 'success'">
		<span style="text-decoration: underline; display: block; margin-top: 5px; cursor: pointer;" (click)="showGuiaModal()">
			{{ "GUIALOGIN.LINK" | translate }}
		</span>
	</div>
</div>
