
	<form [formGroup]="formularioDatos" (ngSubmit)="enviarInvitacion()" #thisForm="ngForm">
		<nb-card>
			<div class="container">
				<div class="row d-flex justify-content-between mt-6 mb-6">
					<nb-card-header  class="mb-4 row d-flex">
						<div class="col-7 modal-title">
							<h2>{{'PARENTS.INVITE' | translate}}</h2>
						</div>
						<div class="col-2 not-show-mobile">
							<div class="dropdown">
								<button nbButton class="btn-themecolor" (click)="back()">
									{{'PARENTS.BACKTOPARENTS' | translate}}
								</button>
							</div>
						</div>
					</nb-card-header>
				</div>
				<nb-card-body>
					<div class="col-12">
						<div class="row" >
							<div class="col-sm-6 form-group mb-0" *ngIf="soloMail">
								<label for="mail" class="label">{{ 'USERDATA.EMAIL' | translate }}</label>
								<input type="email" nbInput fullWidth status="basic"
									placeholder="{{ 'REGISTRO.EMAILEJEMPLO' | translate }}" name="mail" formControlName="mail" required>
								<div *ngFor="let validation of validationMessages.mail">
									<mat-error
										*ngIf="formularioDatos.get('mail')?.hasError(validation.type) && (formularioDatos.get('mail')?.dirty || formularioDatos.get('mail')?.touched || thisForm.submitted)">
										<div class="error-message">{{validation.message}}</div>
									</mat-error>
								</div>
							</div>
						</div>
						<div class="row justify-content-end">
							<div class="col-sm-4 mb-2 mt-4 text-right">
								<button nbButton class="btn-themecolor" type="submit"><span>{{ 'PARENTS.SENDINVITATION' | translate	}}</span></button>
							</div>
						</div>
					</div>
				</nb-card-body>
			</div>
		</nb-card>
	</form>
