import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { MastersService } from "src/app/core/services/masters";
import { LoginService } from "src/app/core/services/login";
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { SecondStepStatisticsComponent } from './second-step-statistics/second-step-statistics.component';
import { ModalSchedulesComponent } from "../modal-schedules/modal-schedules.component";
import { ModalStructureComponent } from "../modal-structure/modal-structure.component";
import { finalize, forkJoin } from 'rxjs';
import { ReportService } from 'src/app/core/services/report/report.service';
import { ChatAssistantComponent } from '../chat-assistant/chat-assistant.component';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { Utils } from 'src/app/core/utils/utils';
import { CenterService } from 'src/app/core/services/center/center.service';
import { ModalAceptarCancelarComponent } from '../modal';

@Component({
	selector: "app-control-panel",
	templateUrl: "./control-panel.component.html",
	styleUrls: ["./control-panel.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ControlPanelComponent implements OnInit {
	profile: any;
	datasetsImported: any[] = [];
	labelsImported: any[] = [];
	cargarCharts: boolean = false;
	selectedLabel = null;

	texto1Centro: any = {
		title: `${this.translateService.instant("CENTRO.ACIERTOS")}<br>${"0"}`,
		backgroundColor: "#B3E5FC",
		color: "#003B64",
	};
	texto2Centro: any = {
		title: `${this.translateService.instant("CENTRO.CONOCIMIENTO")}<br>${"0"}`,
		backgroundColor: "#E1BEE7",
		color: "#4A0072",
	};

	consultas: any[] = [
		{ id: 14, title: "SOLO NIVELES" },
		{ id: 15, title: "SOLO ASIGNATURAS" },
		{ id: 16, title: "SOLO PROFESORES" },
		{ id: 17, title: "SOLO GRUPOS" },
		{ id: 18, title: "SOLO ALUMNOS" },
	];

	consultas_social: any[] = [
		{ id: 1, title: "Indicadores sociales" },
	];
	consultas_profe: any[] = [
		{ id: 1, title: this.translateService.instant("CENTRO.CONSULTA_1") },
		{ id: 2, title: this.translateService.instant("CENTRO.CONSULTA_2") },
	];
	consultas_estudiante: any[] = [
		{ id: 8, title: this.translateService.instant("CENTRO.CONSULTA_8") },
		{ id: 9, title: this.translateService.instant("CENTRO.CONSULTA_9") },
	];
	consultas_grupos: any[] = [
		{ id: 4, title: this.translateService.instant("CENTRO.CONSULTA_4") },
		{ id: 5, title: this.translateService.instant("CENTRO.CONSULTA_5") },
	];
	consultas_niveles: any[] = [
		{ id: 10, title: this.translateService.instant("CENTRO.CONSULTA_10") },
		{ id: 11, title: this.translateService.instant("CENTRO.CONSULTA_11") },
	];
	consultas_padres: any[] = [
		{ id: 12, title: this.translateService.instant("CENTRO.CONSULTA_12") },
		{ id: 13, title: this.translateService.instant("CENTRO.CONSULTA_13") },
	];
	consultas_asignaturas: any[] = [
		{ id: 19, title: this.translateService.instant("CENTRO.CONSULTA_19") },
		{ id: 20, title: this.translateService.instant("CENTRO.CONSULTA_20") },
	];
	consultas_abandono: any[] = [
		{ id: 21, title: this.translateService.instant("CENTRO.CONSULTA_21") },
		{ id: 22, title: this.translateService.instant("CENTRO.CONSULTA_22") },
	];
	isLoading: boolean;
	assignedCenter: any;

	constructor(
		public activeModal: NgbActiveModal,
		private translateService: TranslateService,
		private modalService: NgbModal,
		public loginService: LoginService,
		private mastersService: MastersService,
		private reportService: ReportService,
		private utils: Utils,
		private centerService: CenterService
	) {}

	ngOnInit() {
		this.profile = this.loginService.getProfile();
		//elimino el registro del grupo seleccionado como profe o estudiante
		localStorage.removeItem("selectedGroupInfo");
		// Crear un array de observables
		const requests = [];
		for (let index = 0; index < this.consultas.length; index++) {
			const element = this.consultas[index];
			// Pushear cada petición en el array de requests
			requests.push(this.reportService.getInformeCenter2Mini(1, element.id));
		}
		// Usar forkJoin para esperar a que todas las peticiones terminen
		forkJoin(requests).subscribe((responses: any[]) => {
			// Procesar las respuestas
			responses.forEach((res, index) => {
				this.datasetsImported.push(res.data[0].data);
				if (res.data[0].subject) {
					this.selectedLabel = res.data[0].subject;
				} else if (res.data[0].group) {
					this.selectedLabel = res.data[0].group;
				} else if (res.data[0].level) {
					this.selectedLabel = res.data[0].level;
				}
				this.labelsImported = this.selectedLabel
					? [this.selectedLabel.title]
					: [""];
			});
			// Una vez que todas las peticiones hayan terminado
			this.calcularPromedioCentral();
			this.cargarCharts = true;
		});

		this.getAssignmentCenters();
	}

	ngOnDestroy() {}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	calcularPromedioCentral() {
		//PARA EL PORCENTAJE DE ACIERTOS
		let aciertosPromedio = 0;
		let total1 = 0;
		for (let index = 0; index < this.datasetsImported[0].length; index++) {
			const element = this.datasetsImported[0][index];
			total1 = total1 + this.datasetsImported[0][index].data[0];
		}
		aciertosPromedio = total1 / this.datasetsImported[0].length;
		this.texto1Centro.title = `${this.translateService.instant(
			"CENTRO.ACIERTOS"
		)}<br>${aciertosPromedio.toFixed(1)}`;

		//PARA EL CONOCIMIENTO
		let conocimientoPromedio = 0;
		let total2 = 0;
		for (let index = 0; index < this.datasetsImported[0].length; index++) {
			const element = this.datasetsImported[0][index];
			total2 = total2 + this.datasetsImported[0][index].data[1];
		}
		conocimientoPromedio = total2 / this.datasetsImported[0].length;
		this.texto2Centro.title = `${this.translateService.instant(
			"CENTRO.CONOCIMIENTO"
		)}<br>${conocimientoPromedio.toFixed(1)}`;
	}

	openStep2(data: string) {
		const modalRef = this.modalService.open(SecondStepStatisticsComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
		switch (data) {
			case "teacher":
				modalRef.componentInstance.consultas = this.consultas_profe;
				modalRef.componentInstance.tipoDatos = data;
				break;
			case "student":
				modalRef.componentInstance.consultas = this.consultas_estudiante;
				modalRef.componentInstance.tipoDatos = data;
				break;
			case "groups":
				modalRef.componentInstance.consultas = this.consultas_grupos;
				modalRef.componentInstance.tipoDatos = data;
				break;
			case "levels":
				modalRef.componentInstance.consultas = this.consultas_niveles;
				modalRef.componentInstance.tipoDatos = data;
				break;
			case "parents":
				modalRef.componentInstance.consultas = this.consultas_padres;
				modalRef.componentInstance.tipoDatos = data;
				break;
			case "subjects":
				modalRef.componentInstance.consultas = this.consultas_asignaturas;
				modalRef.componentInstance.tipoDatos = data;
				break;
			case "failure":
				modalRef.componentInstance.consultas = this.consultas_abandono;
				modalRef.componentInstance.tipoDatos = data;
				break;
			case "social":
				modalRef.componentInstance.consultas = this.consultas_social;
				modalRef.componentInstance.tipoDatos = data;
				break;
		}
		modalRef.result.then((result: string) => {});
	}

	private getAssignmentCenters() {
		this.centerService
			.getListCenterByIdUserForUser()
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe((result) => {
				this.assignedCenter = result;
			});
	}

	openSchedules() {

		if(this.assignedCenter == null){
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modalRef.componentInstance.disableCancel = true;

			modalRef.componentInstance.optionalTitle = this.translateService.instant(
				"ALERTCHALLENGENODE.ASSIGNORCREATECENTERTITLE"
			);

			modalRef.componentInstance.mensaje = this.translateService.instant(
				"ALERTCHALLENGENODE.ASSIGNORCREATECENTER"
			);

			modalRef.result.then((result: any) => {
				if (result) {
					this.openConfiguration();
				}
			});
		} else {
			this.modalService.open(ModalSchedulesComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			// Creamos el log
			const bodyLog = {
				action: "Programaciones",
				actionData: "Ha entrado en el menú de Programaciones.",
				startDate: new Date(),
				endingDate: null,
				role: this.loginService.getProfile(),
				idGroup: 0,
			};
			this.mastersService
				.createActivityHistory(
					bodyLog.action,
					bodyLog.actionData,
					bodyLog.startDate,
					bodyLog.endingDate,
					bodyLog.role,
					bodyLog.idGroup
				)
				.subscribe();
		}
	}

	openConfiguration() {
		this.mastersService.openSettings(this.profile);
		//Creamos el log
		const bodyLog = {
			action: "Configuracion",
			actionData: "Ha entrado en el menú de Configuracion.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	openExplore() {
		localStorage.removeItem("challengeOn"); // eliminamos la variable que indica si estamos en un desafio
		localStorage.removeItem("fromEditor");
		this.activeModal.close();

		this.mastersService.verListadoCursos(
			null,
			Profiles.Center,
			0, //se pone en cero para que siempre muestre todos los proyectos
			"explorar"
		);

		//Creamos el log
		const bodyLog3 = {
			action: "proyectos",
			actionData: "Ha entrado en el menú de Proyectos.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog3.action,
				bodyLog3.actionData,
				bodyLog3.startDate,
				bodyLog3.endingDate,
				bodyLog3.role,
				bodyLog3.idGroup
			)
			.subscribe();
	}

	openProjects() {
		localStorage.removeItem("challengeOn"); // eliminamos la variable que indica si estamos en un desafio
		localStorage.removeItem("fromEditor");
		this.activeModal.close();

		this.mastersService.verListadoCursos(
			null,
			Profiles.Center,
			0, //se pone en cero para que siempre muestre todos los proyectos
			"proyectosCentro"
		);

		//Creamos el log
		const bodyLog3 = {
			action: "proyectos",
			actionData: "Ha entrado en el menú de Proyectos.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog3.action,
				bodyLog3.actionData,
				bodyLog3.startDate,
				bodyLog3.endingDate,
				bodyLog3.role,
				bodyLog3.idGroup
			)
			.subscribe();
	}

	openModalStructure() {
		this.modalService.open(ModalStructureComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		//Creamos el log
		const bodyLog = {
			action: "estructura",
			actionData: "Ha entrado en el menú de Estructura.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}

	showChatAssistant() {
		this.modalService.open(ChatAssistantComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W80,
		});

		const bodyLog = {
			action: "IA Chat",
			actionData: "Ha entrado en el asistente IA.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}
}
