import { DEFAULTFONTFAMILY } from '../../../../../core/models/masters/masters.enum';
import { Component, OnInit, Input } from '@angular/core';
import * as countdown from 'countdown';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login/login.service';
import { ModalReproducirNodoComponent } from '../../modal-reproducir-nodo/modal-reproducir-nodo.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetsService } from 'src/app/core/services/targets';
import { QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { VariablesPublicUtils } from 'src/app/core/utils/variablesPublic.utils';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

interface Time {
    hours: number,
    minutes: number,
    seconds: number;
}

@Component({
    selector: 'app-body-reproducir-quizzes',
    templateUrl: './body-reproducir-quizzes.component.html',
    styleUrls: ['./body-reproducir-quizzes.component.scss']
})

export class BodyReproducirQuizzesComponent implements OnInit {
    //@Input() node;
    @Input() mostrar: boolean;

    progress = 0;
    progressBar = document.querySelector('.progress-bar');
    intervalId;
    time: Time;
    iniBlock: boolean;
    countSeconds: number;
    timerId: number = null;
    @Input() date: Date | string;
    quiz: any;
    templates: any[];
    cargando: boolean;
    inicio: boolean;
    elementsQP: any[] = [];
    elementsCorrect: any[] = [];
    arrayQuiz: any[] = [];
    answer: boolean;
    isRevealed: boolean;
    isResponse: boolean;
    userQuiz: any;
    automatic: boolean;
    node: any;
    currentGraph: any;
    type: any;
    nextNodo: any;
    variablesPublicUtils: any;

    private templateTypes = ['VideoQuestions',
        'ImageQuestions',
        'AudioQuestions',
        'PdfQuestions',
        'TextQuestions',
        'VideoOptions',
        'ImageOptions',
        'AudioOptions',
        'PdfOptions',
        'TextOptions',
        'VideoCorrect',
        'ImageCorrect',
        'AudioCorrect',
        'PdfCorrect',
        'TextCorrect'];

    private elementIcons = ['videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject'];

    constructor(public quizService: QuizzesService,
        public loginService: LoginService,
        public modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public targetsService: TargetsService,
        public quizzesStackService: QuizzesstackService) {
        this.quizService.selectQuiz.subscribe((n) => {
            this.quiz = n;
            this.showQuiz(this.quiz);
        });
        this.quizService.loading.subscribe((n: boolean) => {
            this.cargando = n;
        });
        this.quizService.userQuiz.subscribe((uq) => {
            this.userQuiz = uq;
        });
        this.quizService.automatic.subscribe((a: boolean) => {
            this.automatic = a;
        });
        this.quizService.currentGraph.subscribe((g) => {
            this.currentGraph = g;
        });
        this.quizService.currentNode.subscribe((n) => {
            this.node = n;
        });
        this.quizService.type.subscribe((t) => {
            this.type = t;
        });
    }

    ngOnInit() {
        this.isRevealed = true;
        this.isResponse = false;
        this.inicio = true;
        this.templates = [];
        this.variablesPublicUtils = new VariablesPublicUtils();
        this.quizService.arrayQuiz.subscribe((a: any) => {
            this.arrayQuiz = a;
        });
    }

    discoverAnswer() {
        console.log("Descubir respuesta");
        this.answer = (this.answer == true) ? false : true;
        this.isRevealed = true;
        this.isResponse = false;
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.evaluateResponse();
    }

    evaluationNegative() {
        console.log("Autoevaluacion Negativa");
        this.isRevealed = true;
        this.isResponse = false;
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.evaluateResponse();
    }

    evaluationPositive() {
        console.log("Autoevaluacion Positiva");
        this.isRevealed = true;
        this.isResponse = false;
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.evaluateResponse();
    }

    nextQuiz(quiz) {
        /*this.userQuiz.idQuizOriginal=quiz.idQuiz;
        var index = this.arrayQuiz.findIndex(function(el){
              return el.idQuiz == quiz.idQuiz
        });*/

        //Verificar si es el ultimo quiz
        //Invocar al array de nodos ordenado
        //Verificar si el nodo es el ultimo
        //Si no es ultimo sigues al siguiente
        //Si es el ultimo cierra el modal.

        if ((this.automatic && !this.isLastNode(this.node) && (index == (this.arrayQuiz.length - 1)))) {
            this.nextNode(this.nextNodo);
        } else {

            this.userQuiz.idQuizOriginal = quiz.idQuiz;
            var index = this.arrayQuiz.findIndex(function (el) {
                return el.idQuiz == quiz.idQuiz
            });

            if (index != -1 && (index < this.arrayQuiz.length - 1)) {
                var nexQuiz = this.arrayQuiz[index + 1];
            } else {
                this.closeModal()
            }
        }
    }

    showQuiz(quiz: any) {
        console.log("QUIZZ BODY REPRODUCIR: showQuiz : ", quiz);
        //this.cargando=false;
        this.answer = false;
        this.isRevealed = false;
        //this.isDisabled=(this.isDisabled==true)?false:true;
        this.quiz = quiz;
        this.templates = [];
        var elementsQP = [];
        var elementsCorrect = [];
        if (this.templates.length == 0) {
            if (this.timerId) {
                clearInterval(this.timerId);
            }
            var canvas = document.getElementById("canvas");
            var lienzo = this.retornarLienzo(canvas);
            lienzo.clearRect(0, 0, 300, 150);
        }
    }

    mostrarClock(quiz: any) {
        if (quiz && this.templates.length > 0) {
            if (this.timerId) {
                clearInterval(this.timerId);
            }
            this.iniBlock = false;
            let date = new Date();
            date.setSeconds(date.getSeconds() + quiz.duration);
            this.timerId = countdown(date, (ts) => {
                this.time = ts;
                this.showClock(this.time, this.countSeconds);
                if (!this.iniBlock) {
                    this.iniBlock = true;
                    this.countSeconds = this.time.seconds + this.time.minutes * 60;
                };
                if (this.time.minutes == 0 && this.time.seconds == 0) {
                    clearInterval(this.timerId);
                    this.answer = true;
                    this.evaluateResponse();
                    this.isRevealed = true;
                }
            }, countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
        }
    }

    showClock(time, countSeconds) {
        var canvas = document.getElementById('canvas');
        var lienzo = this.retornarLienzo(canvas);
        if (lienzo) {
            var segundo = time.minutes * 60 + time.seconds;
            var radio = 25;
            // Defino límites en pí­xeles
            var minx = 10;
            var maxx = 45;
            var miny = 10;
            var maxy = 45;
            // Centro del recuadro
            var cx = minx + (maxx - minx) / 2;
            var cy = miny + (maxy - miny) / 2;
            // Borrar lienzo
            lienzo.clearRect(0, 0, 300, 300);
            lienzo.strokeStyle = "rgb(0,0,0)";
            lienzo.lineWidth = 1;
            // Dibujar el círculo del reloj
            lienzo.beginPath();
            lienzo.arc(cx, cy, radio, 0, Math.PI * 2, false);
            lienzo.stroke();
            // Dibujar los segundos restantes
            lienzo.beginPath();
            lienzo.fillStyle = "rgb(0,0,0)";
            lienzo.moveTo(cx, cy); // posiciono el lápiz en el centro del recuadro
            var angulo = 2 * Math.PI * (segundo / countSeconds); // calculo el ángulo del segundero
            lienzo.arc(cx, cy, radio, -Math.PI / 2, -Math.PI / 2 - angulo, false);
            lienzo.lineTo(cx, cy);
            lienzo.fill();
            //Mostrar la hora
            lienzo.beginPath();
            lienzo.font = "12pt " + DEFAULTFONTFAMILY
            //lienzo.fillStyle = "rgb(255,255,255)";
            lienzo.fillStyle = "rgb(117, 108, 108)";
            var minute = (time.minutes <= 9) ? "0" + time.minutes : time.minutes;
            var second = (time.seconds <= 9) ? "0" + time.seconds : time.seconds
            lienzo.fillText(minute + ":" + second, (cx / 2) - 10, cx + 5);
            //color de fonde de lienzo
            //lienzo.beginPath();
            //lienzo.fillStyle = "rgb(255,255,255)";
        }
    }

    retornarLienzo(canvas) {
        if (canvas.getContext) {
            var lienzo = canvas.getContext('2d');
            return lienzo;
        } else {
            return false;
        }
    }

    evaluateResponse() {
        this.quiz.idUser = this.loginService.getUser().idUser;
        this.quiz.knowledge = 0;
        this.quiz.idTarget = this.currentGraph.idTarget;
        this.quiz.idCourse = this.currentGraph.idCourse;
        this.quiz.result = 0;
        this.quiz.nextQuestion = 0;
    };

    nextNode(nextNode) {
        const modalRef = this.modalService.open(ModalReproducirNodoComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W65
            })
        modalRef.componentInstance.node = nextNode;
        modalRef.componentInstance.currentGraph = this.currentGraph;
        modalRef.componentInstance.type = 'node';
        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }

    isLastNode(node) {
        var nodes = this.currentGraph.nodes.filter((node) => node.nodeType == "Node");
        var array = nodes.sort(function (a, b) {
            return a["idOriginal"] - b["idOriginal"];
        });

        var n = array[array.length - 1];
        if (n.idOriginal == node.idOriginal) {
            return true;
        } else {
            var pos = 0;
            for (n of array) {
                pos = pos + 1;
                if (n.idOriginal == node.idOriginal) {
                    this.nextNodo = array[pos];
                    return false;
                }
            }
        }
    }

    calculateKnowledge(idQuiz) {
        /*Calcula y devuelve el conocimiento de un quiz (tenemos en cuenta IdQuiz)*/
        var knowledge = 0;
    }

    calculateAchieveKnowledge(quiz: any) {
    }

    closeModal(sendData?: any) {
        this.activeModal.close();
    }

    ngOnDestroy() {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }
}

