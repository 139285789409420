import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { map, take } from "rxjs/operators";
import { gNode, ObjetoNodoAuto } from "src/app/core/models/graph/gNode.model";
import { LoginService } from "src/app/core/services/login";
import { NodeService } from "src/app/core/services/node/node.service";
import { TutorialModalService } from "src/app/core/services/shared/tutorial.modal.service";
import { SIGMA_CONSTANTS } from "src/app/core/utils/sigma-constants";
import { NodeModeAutoComponent } from "../../../../../shared/components/nodes/node-auto/node-mode-auto.component";
import { SigmaCanvasService } from "../sigma-canvas.service";
import { QuizzesService } from 'src/app/core/services/quizzes';
import { ModalCursoDetailComponent } from "src/app/shared/components/cursos/modal-curso-detail/modal-curso-detail.component";
import { ModalCursoEditDetailComponent } from "src/app/shared/components/cursos/modal-curso-edit-detail/modal-curso-edit-detail.component";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { CourseEditorsModel } from "src/app/core/models/courses/course-editors.model";
import { ToasterService } from "src/app/core/services/shared/toaster.service";

declare var $
@Injectable({
    providedIn: 'root'
  })
export class SigmaCanvasUtils {

    MAXPAGE: number = 0;

	constructor(public loginService: LoginService,
		private sigmaCanvasService: SigmaCanvasService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private modalService: NgbModal,
		private tutorialModalService: TutorialModalService,
		private nodeService: NodeService,
		private quizService: QuizzesService
	) { }

    public cerrarEditMenu() {
        $('.popover2').hide();
    }
    public cerrarPlaceHolder() {
        $('.popover3').hide();
    }

    public copyElement() {
        $('.popover2').hide();
        var e:any = JSON.parse(localStorage.getItem('nodeData'));
        localStorage.setItem('portapapelesNodeData', JSON.stringify(e));
        if (e.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
            this.OpenDialog("Curso copiado correctamente");
        } else {
            this.OpenDialog("Quiz copiado correctamente");
        }
    }

    public deleteElement() {
        $('.popover2').hide();
        var e:any = JSON.parse(localStorage.getItem('nodeData'));

        this.sigmaCanvasService.deleteSigmaNode(e).subscribe(
            (res: any) => {
                this.toaster.success(this.translateService.instant('NODOS.DELETEOK'));
            },
            err => {
                this.toaster.error(this.translateService.instant('NODOS.DELETENOK'));
            }
        )
    }

    public pasteElement() {
        $('.popover').hide();
        var e = JSON.parse(localStorage.getItem('portapapelesNodeData'));
        var canvas = document.getElementById("sigma-container");
        //var pos = this.sigmaCanvasService.sigmaUtils.getMousePos(canvas, e.data.captor);

        var click = JSON.parse(localStorage.getItem('mouseClick'));
        if (e.data.node.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
            e.data.node.x = click.x + 170;
            e.data.node.y = click.y;
            e.data.node.paste = true;
            //this.sigmaCanvasService.sigmaUtils.refresh();

        } else {
            var quizOriginal:any = null
            quizOriginal['x'] = click.x + 170;
            quizOriginal['y'] = click.y;
            quizOriginal.idOriginal = e.data.node.idOriginal;
            //this.quizService.createTempQuizz(quizOriginal);
        }

        //this.sigmaCanvasService.sigmaUtils.refresh();
    }

    private OpenDialog(message: string): void {
        // const timeout = 1500;
        // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        //     data: message
        // });
        // dialogRef.afterOpened().subscribe(res => setTimeout(() => {
        //     dialogRef.close();
        // }, timeout));
    }

    obtenerQuizAsociado(arrayQuizzes: any) {
        var min = 0
        var max = arrayQuizzes.length - 1
        var i = Math.floor(Math.random() * (max - min) + min);
        return arrayQuizzes[i];
    }

    hidePopWindows():void{
        setTimeout(() => { $('.popover, .popover2, .popover3, #pEditMenu3').hide() }, 0);
    }
    closeCreateMenu():void{
        setTimeout(() => { $('.popover').hide(); }, 0);
    }

    changeColorFilters($event) {
        // this.hidePopWindows()
        // if ($event.greyChk != undefined) {
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.color === "#808080")).forEach((n) => {
        //         n.hidden = $event.greyChk ? false : true;
        //     });
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.color === "#ff0000")).forEach((n) => {
        //         n.hidden = $event.redChk ? false : true;
        //     });
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.color === "#ffff00")).forEach((n) => {
        //         n.hidden = $event.yellowChk ? false : true;
        //     });
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.color === "#90ee90")).forEach((n) => {
        //         n.hidden = $event.lgreenChk ? false : true;
        //     });
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.color === "#006400")).forEach((n) => {
        //         n.hidden = $event.dgreenChk ? false : true;
        //     });
        // } else if ($event.senmotemo != undefined) {
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.operator === "ABSTRACT")).forEach((n) => {
        //         n.hidden = $event.abstract ? false : true;
        //     });
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.operator === "TEMPORARY")).forEach((n) => {
        //         n.hidden = $event.temporary ? false : true;
        //     });
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.operator === "ASSOSTATIC")).forEach((n) => {
        //         n.hidden = $event.assostatic ? false : true;
        //     });
        //     this.sigmaCanvasService.sigmaUtils.allNodes().filter((n) => (n.operator === "SENMOTEMO")).forEach((n) => {
        //         n.hidden = $event.senmotemo ? false : true;
        //     });
        // }

        // this.sigmaCanvasService.sigmaUtils.refresh();
    }

    // buscarPaginaMax(nodes: gNode[]) {
    //     let maxPage = 0;
    //     for (let index = 0; index < nodes.length; index++) {
    //         let element = nodes[index] as gNode;
    //         if ( element.pages !== 'null' && element.pages !==  undefined && element.pages !== null) {
    //             let pagesCurrentNode =  element.pages.split(',');
    //             for (let i = 0; i < pagesCurrentNode.length; i++) {
    //                 const currentPage = pagesCurrentNode[i];
    //                 if (maxPage < parseInt(currentPage)) {
    //                     maxPage = parseInt(currentPage);
    //                 }
    //             }
    //         }
    //     }
    //     this.MAXPAGE = maxPage;
    // }

    public checkAlternativeQuizzes(sourceQuiz:any, destQuiz:any, allNodes:any, allEdges:any):boolean{

        let currentNodeSource = sourceQuiz
        let currentNodeDest = destQuiz

        //Tenemos que comprobar si el origin y destino son multiples o simples; los simples sólo pueden ir con los simples y los multiples, con los multiples
        if(((currentNodeSource.isMultiplexed && !currentNodeDest.isMultiplexed) || (!currentNodeSource.isMultiplexed && currentNodeDest.isMultiplexed)) && (currentNodeSource.nodeType == 'Quiz' && currentNodeDest.nodeType == 'Quiz')){
            this.toaster.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR1'))
            console.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR1'))
            return true
        }



        if(currentNodeSource.edges.length && currentNodeDest.edges.length){
            let status1:boolean = false
            let status2:boolean = false

            if(currentNodeSource.edges.length){
                currentNodeSource.edges.forEach(edge => {
                    if(edge.source.indexOf('q') >=0 && edge.target.indexOf('q') >=0 && currentNodeSource.nodeType != SIGMA_CONSTANTS.NODE_TYPE){
                        this.toaster.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR2'))
                        console.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR2'))
                        status1 = true
                    }
                    else if(edge.source.indexOf('q') >=0 && edge.target.indexOf('n') >=0){
                        //Salta si se unen un quiz (que ya esta en otro nodo) y un nodo
                        if(currentNodeDest.nodeType == 'Quiz' && currentNodeSource.nodeType == 'Quiz'){
                            this.toaster.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR3'))
                            console.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR3'))
                            status1 = true
                        }
                    }
                })
            }

            else if(currentNodeDest.edges.length){
                currentNodeDest.edges.forEach(edge => {
                    if(edge.source.indexOf('q') >=0 && edge.target.indexOf('q') >=0 && currentNodeDest.nodeType != SIGMA_CONSTANTS.NODE_TYPE){
                        this.toaster.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR2'))
                        console.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR2'))
                        status2 = true
                    }
                    else if(edge.source.indexOf('q') >=0 && edge.target.indexOf('n') >=0){
                        let tmp = false

                        currentNodeSource.edges.forEach(e => {
                            if(e.source.indexOf('q') >=0 && e.target.indexOf('q') >=0 && currentNodeDest.nodeType != SIGMA_CONSTANTS.NODE_TYPE){
                                this.toaster.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR6'))
                                console.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR6'))
                                tmp = true
                            }
                        });

                        //Revisar si se intenta un alternativo a un original
                        if(!tmp && currentNodeSource.nodeType != SIGMA_CONSTANTS.NODE_TYPE){
                            this.toaster.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR3')) //Salta si se unen un quiz (que ya esta en otro nodo) y un nodo
                            console.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR3'))
                            status2 = true
                        }

                    }
                })
            }

            if(status1 || status2)
                return true
            else
                return false
        }
        else if(currentNodeSource.edges.length){
            let status:boolean = false
            currentNodeSource.edges.forEach(edge => {
                if(edge.source.indexOf('q') >=0 && edge.target.indexOf('q') >=0){
                    this.toaster.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR4'))
                    console.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR4'))
                    status = true
                }
            });
            return status
        }
        else if(currentNodeDest.edges.length){
            let status:boolean = false

            currentNodeDest.edges.forEach(edge => {
                if(edge.source.indexOf('q') >=0 && edge.target.indexOf('q') >=0){
                    this.toaster.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR2'))
                    console.error(this.translateService.instant('CONTROLQUIZZESASSOCIATIONS.ERROR2'))
                    status = true
                }
            })
            return status
        }
        else
            return false

    }

    showListCourses(idCourse:number,idSelectedCourse?:number){
        this.hidePopWindows();
        const isViewList = true
        const modalRef = this.modalService.open(ModalCursoDetailComponent,
					{scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });

        modalRef.componentInstance.id = idCourse;

				// not the best way to do it, but it works, look how to access ngviewafterinit from the modal ref
				setTimeout(() => {
					modalRef.componentInstance.getTreeData();
				}, 400);

				modalRef.componentInstance.graphView = 'inlineList'

        modalRef.result.then(() => {}, () => {});
    }

		showCourseGraphs(idCourse){
			this.hidePopWindows();
			let modal:NgbModalRef = null
			modal = this.modalService.open(this.loginService.esAutor() ? ModalCursoEditDetailComponent : ModalCursoDetailComponent,
				{scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` }
			)
			modal.componentInstance.id = idCourse
			modal.componentInstance.graphView = 'gridList'

			modal.result.then(() => {}, () => {});
		}

		public userCanEditGraph(allowedUsers: CourseEditorsModel[]): boolean{
			const currentUser = this.loginService.getUser().idUser
			let returnValue = false

			if(!this.loginService.esAutor())
				return false

			allowedUsers.forEach(user => {
				if(user.idUSer === currentUser)
					returnValue = true
			});
			return returnValue
		}

    public showQuizzes(idNode:number): void{

        let arrayIds: string[] = []

        //Muestro los originales y guardo los ids de los originales mostrados, para ver si tienen quizzes alternativos
        this.sigmaCanvasService.sigmaUtils.allNodes().filter(n => n.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE).map(q => {
            if(q.edges.length){
                q.edges.map(e => {
                    if(e.source === idNode || e.target === idNode){
                        q.hidden = false
                        arrayIds.push(q.id)
                    }
                })
            }
            else
                q.hidden = false
        })

        //Recorremos los quizzes que se han mostrado para ver si tienen quizzes alternativos y mostrarlos
        arrayIds.map(id => {
            this.sigmaCanvasService.sigmaUtils.allNodes().filter(n => n.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE).map(q => {
                if(q.edges.length){
                    q.edges.map(e => {
                        if(e.connectionType === SIGMA_CONSTANTS.QUIZZES_QUIZZES && (e.source === id || e.target === id))
                            q.hidden = false
                    })
                }
            })
        })
    }

    openModalOptions():void{
        let image = 'automatic'
        const dialog = this.tutorialModalService.openManualAutoDialog(
            true,
            'Manual',
            this.translateService.instant('TUTORIALINICIO.TUTORIAL_TEXTO_MODAL_IDEOGRAFO'),
            [],
            '',
            this.translateService.instant('TUTORIALINICIO.BOTONES.AUTOMATIC'),
            this.translateService.instant('TUTORIALINICIO.BOTONES.MANUAL'),
            image

        )

        dialog.afterClosed().pipe(take(1)).subscribe(res =>{
            if (!res) {
            }
            else if(res){
                //this.openModalModeAuto(''); //Funcion a implementar: está en el sigma-canvas.component.ts
            }
        });
    }


    public changeView(type:string, show:boolean, sigmaUtils:any):void{
        sigmaUtils.allNodes().filter(n => (n.nodeType === type)).forEach(n => {
            n.hidden = !show;
        });
        sigmaUtils.refresh();
    }


        // ----------------------------------------------------------------------
    //   S T A I R C A S E    C H A R T
    // ----------------------------------------------------------------------
    public viewStaircaseChart(show:boolean, sigmaUtils:any, viewType:any) {

        var minX, minY, maxX, maxY, w, h, unitX, unitY;
        var maxLevel = 0, maxDuration = 0;
        var minLevel = 0, minDuration = 0;

        if (show) {

            // (1) Hide all Quizzes.
            sigmaUtils.allNodes().filter((n) => (n.type === SIGMA_CONSTANTS.QUIZ_NODE_TYPE)).forEach((n) => {
                n.hidden = true;
            });

            // (2) Get the boundaries.
            var rendererWidth = sigmaUtils.rendererWidth();
            var rendererHeight = sigmaUtils.rendererHeight();


            var bounds = sigmaUtils.getRectangle(rendererWidth, rendererHeight);

            maxX = bounds.x2;
            maxY = bounds.y2; //+ bounds.height;
            minX = bounds.x1;
            minY = bounds.y1;

            // (3) center coords
            //var cx = bounds.minX + (bounds.maxX - bounds.minX)/2;
            //var cy = bounds.minY + (bounds.maxY - bounds.minY)/2;

            // (4) Get min/max. nodeSwlevel and min/max. duration
            maxLevel = Math.max.apply(Math, sigmaUtils.allNodes().filter((n) => (n.nodeType !== SIGMA_CONSTANTS.QUIZ_TYPE)).map(function (n) { return n.nodeSwlevel; }));
            maxDuration = Math.max.apply(Math, sigmaUtils.allNodes().filter((n) => (n.nodeType !== SIGMA_CONSTANTS.QUIZ_TYPE)).map(function (n) { return n.duration; }));
            minLevel = Math.min.apply(Math, sigmaUtils.allNodes().filter((n) => (n.nodeType !== SIGMA_CONSTANTS.QUIZ_TYPE)).map(function (n) { return n.nodeSwlevel; }));
            minDuration = Math.min.apply(Math, sigmaUtils.allNodes().filter((n) => (n.nodeType !== SIGMA_CONSTANTS.QUIZ_TYPE)).map(function (n) { return n.duration; }));

            // (5) Width Height
            w = maxX - minX;
            h = maxY - minY;

            // (6)
            unitY = h / (maxLevel - minLevel);
            unitX = w / (maxDuration - minDuration);

            // (7) Relocate nodes
            var l, d;
            sigmaUtils.allNodes().filter((n) => (n.nodeType !== SIGMA_CONSTANTS.QUIZ_TYPE)).forEach((n) => {

                l = n.nodeSwlevel ? n.nodeSwlevel : 0;
                d = n.duration ? n.duration : 0;
                n.x = minX + ((d - minDuration) * unitX);
                n.y = maxY - ((l - minLevel) * unitY);
            });


            sigmaUtils.allEdges().forEach((e) => {
                if (!show) {
                    e.type = e.originalType;
                } else {
                    e.type = 'line';
                }
            });

            sigmaUtils.overrideClear(show, 20);

        } else {


            sigmaUtils.overrideClear(show);

            sigmaUtils.allNodes().filter((n) => (n.nodeType !== SIGMA_CONSTANTS.QUIZ_TYPE)).forEach((n) => {
                n.x = n.originalX;
                n.y = n.originalY;
            });


            this.changeView(SIGMA_CONSTANTS.NODE_TYPE, viewType.nodes, sigmaUtils)
            this.changeView(SIGMA_CONSTANTS.QUIZ_TYPE, viewType.quizzes, sigmaUtils)

            sigmaUtils.centerGraph();

        }
        sigmaUtils.refresh();
    }
    existsElement(index: number, idElements) {
        return idElements[index] === undefined ? false : true;
    }

    public openModalModeAuto(autoMode: ObjetoNodoAuto, ordenSeleccionado: number[], viewType:any){
        this.hidePopWindows()
        let listQuiz = [];

        if (viewType.nodes && viewType.quizzes) {
            listQuiz = autoMode.obtenerListado(ordenSeleccionado);
        }
        else if (viewType.nodes) {
            listQuiz = autoMode.listSoloExplicaciones();
        }
        else if (viewType.quizzes) {
            listQuiz = autoMode.listadoSoloQuiz();
        }

        if (listQuiz.length > 0) {

            this.nodeService.getNode(listQuiz[0].idOriginal, this.sigmaCanvasService.courseId, this.sigmaCanvasService.graphId).pipe(map((res: any) => res.data[0])).subscribe(res => {
                const modalRef = this.modalService.open(NodeModeAutoComponent, { windowClass: MODAL_DIALOG_TYPES.W100 });

                modalRef.componentInstance.listQuiz = [];
                modalRef.componentInstance.listQuiz = listQuiz;
                modalRef.componentInstance.node = res;
                modalRef.componentInstance.idCurso = this.sigmaCanvasService.courseId;
                modalRef.componentInstance.idMapa = this.sigmaCanvasService.graphId;
                modalRef.componentInstance.viewQuiz = viewType.quizzes && !viewType.nodes ? true : false;;
                modalRef.componentInstance.soloQuiz = viewType.quizzes && !viewType.nodes ? true : false;
                modalRef.componentInstance.soloNodos = !viewType.quizzes && viewType.nodes ? true : false;
                modalRef.componentInstance.autoTodo = viewType.quizzes && viewType.nodes ? true : false;
                modalRef.componentInstance.currentGraph = listQuiz;
                modalRef.componentInstance.type = SIGMA_CONSTANTS.NODE_TYPE.toLowerCase();
                modalRef.componentInstance.course = this.sigmaCanvasService.course;
                modalRef.componentInstance.allItems = autoMode
            });
        }
        else
            this.toaster.success(this.translateService.instant('SIGMACOMPONENT.ERRORCONFIG'));
    }



    public customModeAuto(autoMode: ObjetoNodoAuto, type:string, value:gNode|any, viewType:any, ordenSeleccionado: number[]){

        let listQuiz = [];

        if (viewType.nodes && viewType.quizzes) {
            //Obtener listado de un power especifico.
            listQuiz = autoMode.obtenerListado(ordenSeleccionado);
        }
        else if (viewType.nodes) {
            listQuiz = autoMode.listSoloExplicaciones();
        }
        else if (viewType.quizzes) {
            listQuiz = autoMode.listadoSoloQuiz();
        }

        if (listQuiz.length > 0) {

            if(type == SIGMA_CONSTANTS.NODE_TYPE.toLowerCase()){

                this.nodeService.getNode(value.idOriginal, this.sigmaCanvasService.courseId, this.sigmaCanvasService.graphId).pipe(map((res: any) => res.data[0])).subscribe(res => {
                    const modalRef = this.modalService.open(NodeModeAutoComponent,
                        {
                            scrollable: true,
                            windowClass: MODAL_DIALOG_TYPES.W100,
                            backdrop: 'static'
                        }
                    );
                    modalRef.componentInstance.listQuiz = [];
                    modalRef.componentInstance.listQuiz = listQuiz;
                    modalRef.componentInstance.node = res;
                    modalRef.componentInstance.hideBar = true;
                    modalRef.componentInstance.idCurso = this.sigmaCanvasService.courseId;
                    modalRef.componentInstance.idMapa = this.sigmaCanvasService.graphId;
                    modalRef.componentInstance.viewQuiz = viewType.quizzes && !viewType.nodes ? true : false;;
                    modalRef.componentInstance.soloQuiz = viewType.quizzes && !viewType.nodes ? true : false;
                    modalRef.componentInstance.soloNodos = !viewType.quizzes && viewType.nodes ? true : false;
                    modalRef.componentInstance.autoTodo = viewType.quizzes && viewType.nodes ? true : false;
                    modalRef.componentInstance.currentGraph = listQuiz;
                    modalRef.componentInstance.type = SIGMA_CONSTANTS.NODE_TYPE.toLowerCase();
                    modalRef.componentInstance.course = this.sigmaCanvasService.course;
                    modalRef.componentInstance.adjustWindow = true
                    modalRef.componentInstance.allItems = autoMode

                    modalRef.result.then(() => {}).catch(res => {})
                });

            }
            else if(type == SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase()){
                if(value.isMultiplexed === 1){
                    this.quizService.getQuizMultipleCanvasQuizMultiple(value.idOriginal).subscribe(res => {
                        const modalRef = this.modalService.open(NodeModeAutoComponent,
                            {
                                scrollable: true,
                                windowClass: MODAL_DIALOG_TYPES.W100,
                                backdrop: 'static'
                            }
                        );

                        modalRef.componentInstance.quiz = { ...res.quiz, user: value.user, idOriginal: value.idOriginal, id: value.id, originalX: value.originalX, originalY: value.originalY, size: value.size, sizeQuiz: value.sizeQuiz, x: value.x, y: value.y };;
                        modalRef.componentInstance.elements = res.elements;
                        modalRef.componentInstance.courseId = this.sigmaCanvasService.courseId;
                        modalRef.componentInstance.graphId = this.sigmaCanvasService.graphId;
                        modalRef.componentInstance.hideBar = true;
                        modalRef.componentInstance.listQuiz = [];
                        modalRef.componentInstance.listQuiz = listQuiz;
                        modalRef.componentInstance.firstQuiz = 1
                        modalRef.componentInstance.idCurso = this.sigmaCanvasService.courseId;
                        modalRef.componentInstance.idMapa = this.sigmaCanvasService.graphId;
                        modalRef.componentInstance.viewQuiz = true
                        modalRef.componentInstance.soloQuiz = viewType.quizzes && !viewType.nodes ? true : false;
                        modalRef.componentInstance.soloNodos = !viewType.quizzes && viewType.nodes ? true : false;
                        modalRef.componentInstance.autoTodo = viewType.quizzes && viewType.nodes ? true : false;
                        modalRef.componentInstance.currentGraph = listQuiz;
                        modalRef.componentInstance.type = SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
                        modalRef.componentInstance.adjustWindow = true
                        modalRef.componentInstance.course = this.sigmaCanvasService.course;
                        modalRef.componentInstance.allItems = autoMode

                        modalRef.result.then(color => {});
                    });
                }
                else {
                    this.quizService.getQuizSimpleCanvasQuizSimple(value.idOriginal, this.sigmaCanvasService.courseId, this.sigmaCanvasService.graphId).subscribe(res => {
                        const modalRef = this.modalService.open(NodeModeAutoComponent,
                            {
                                scrollable: true,
                                windowClass: MODAL_DIALOG_TYPES.W100,
                                backdrop: 'static'
                            }
                        );

                        modalRef.componentInstance.quiz = { ...res.quiz, user: value.user, idOriginal: value.idOriginal, id: value.id, originalX: value.originalX, originalY: value.originalY, size: value.size, sizeQuiz: value.sizeQuiz, x: value.x, y: value.y };;
                        modalRef.componentInstance.elements = res.elements;
                        modalRef.componentInstance.courseId = this.sigmaCanvasService.courseId;
                        modalRef.componentInstance.graphId = this.sigmaCanvasService.graphId;
                        modalRef.componentInstance.hideBar = true;
                        modalRef.componentInstance.listQuiz = [];
                        modalRef.componentInstance.listQuiz = listQuiz;
                        modalRef.componentInstance.firstQuiz = 1
                        modalRef.componentInstance.idCurso = this.sigmaCanvasService.courseId;
                        modalRef.componentInstance.idMapa = this.sigmaCanvasService.graphId;
                        modalRef.componentInstance.viewQuiz = true
                        modalRef.componentInstance.soloQuiz = viewType.quizzes && !viewType.nodes ? true : false;
                        modalRef.componentInstance.soloNodos = !viewType.quizzes && viewType.nodes ? true : false;
                        modalRef.componentInstance.autoTodo = viewType.quizzes && viewType.nodes ? true : false;
                        modalRef.componentInstance.currentGraph = listQuiz;
                        modalRef.componentInstance.type = SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
                        modalRef.componentInstance.adjustWindow = true
                        modalRef.componentInstance.course = this.sigmaCanvasService.course;
                        modalRef.componentInstance.allItems = autoMode

                        modalRef.result.then(color => {});
                    });
                }
            }
        }
        else
            this.toaster.success(this.translateService.instant('SIGMACOMPONENT.ERRORCONFIG'))
    }
}
