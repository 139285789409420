import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalSelectRecipientComponent } from '../sigma-canvas/modal-select-recipient/modal-select-recipient.component';

interface SocialButton {
    id: number;
    icon: string;
    color: string;
}

@Component({
    selector: 'app-share-button',
    templateUrl: './share-button.component.html',
    styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {
    buttons: SocialButton[] = [
        {
            id: 1, icon: 'fab fa-instagram', color: '#517fa4'
        },
        {
            id: 2, icon: 'fab fa-whatsapp', color: '#4dc247'
        },
        {
            id: 3, icon: 'fab fa-twitter', color: '#55acee'
        },
        {
            id: 4, icon: 'fab fa-facebook-f', color: '#3b5998'
        },
        {
            id: 5, icon: 'salware', color: 'rgb(33, 76, 152)'
        },
        {
            id: 6, icon: 'fab fa-linkedin-in', color: '#007bb5'
        },
        {
            id: 7, icon: 'fab fa-youtube', color: '#bb0000'
        },
        {
            id: 8, icon: 'fab fa-pinterest', color: '#cb2027'
        }
    ];

    constructor(private modalService: NgbModal) { }

    ngOnInit() {
    }


    public shareOnSalware() {
        const modalRef = this.modalService.open(ModalSelectRecipientComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W55
            })
        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }
}
