import { take, takeUntil } from 'rxjs/operators';
import { ACTIONS } from '../../../core/utils/actions';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActionModel } from 'src/app/core/models/shared/actions.model';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { Subject } from 'rxjs';
import { Utils } from 'src/app/core/utils/utils';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { SigmaCanvasService } from '../nodes/sigma-canvas/sigma-canvas.service';
import { CoursesService } from 'src/app/core/services/courses';
import { SigmaUtils } from 'src/app/core/utils/sigma-utils';
import { Store } from "@ngrx/store";
import { State } from "src/app/store/models/store.model";
import { availableProfiles } from 'src/app/store/models/profiles.model';

@Component({
	selector: "app-graph-buttons",
	templateUrl: "./graph-buttons.component.html",
	styleUrls: ["./graph-buttons.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class GraphButtonsComponent implements OnInit {
	@Output() action: EventEmitter<ActionModel> = new EventEmitter<ActionModel>();
	@Input() canEdit: boolean;
	@Input() idCurso: any;

	profilesOfUser: availableProfiles;
	isFullscreen: boolean = false;
	profileBeforeChanges: string;
	private destroy$ = new Subject();
	comeFromProfile: string;
	profiles = Profiles;
	actualProfile: string;
	constructor(
		private loginService: LoginService,
		private usersService: UsersService,
		private utils: Utils,
		private toolsService: SigmaToolbarsService,
		private sigmaCanvasService: SigmaCanvasService,
		private coursesService: CoursesService,
		private store: Store<State>
	) {}

	ngOnInit() {
		this.profileBeforeChanges = this.loginService.getProfile();
		//para saber si el usuario es editor del proyecto
		this.coursesService.editorList(this.idCurso).subscribe((res) => {
			for (let index = 0; index < res.data.length; index++) {
				const element = res.data[index];
				if (element.idUser == this.loginService.getUser().idUser) {
					this.canEdit = true;
					break;
				}
			}
		});
		this.actualProfile = this.loginService.getProfile();
	}
	ngOnDestroy(): void {
		this.destroy$.next(true);
	}

	public esAutor() {
		return this.loginService.esAutor();
	}
	public esEstudiante() {
		return this.loginService.esEstudiante();
	}

	/** LATERAL BUTTONS */

	public zoomIn() {
		let action = new ActionModel(ACTIONS.ZOOMIN, true);
		this.action.emit(action);
	}
	zoomOut() {
		let action = new ActionModel(ACTIONS.ZOOMOUT, true);
		this.action.emit(action);
	}
	centerGraph() {
		//Si hay un zoom fijado el centrado del zoom se comporta diferente y hace la animacion hacia a donde
		//se hay fijado el zoom
		//Si no hay zoom fijado se centra el grafo
		if (this.sigmaCanvasService.zoomX != 0) {
			let action = new ActionModel(ACTIONS.CENTERGRAPH, {
				x: this.sigmaCanvasService.zoomX,
				y: this.sigmaCanvasService.zoomY,
				ratio: this.sigmaCanvasService.zoomRatio,
			});
			this.action.emit(action);
		} else {
			let action = new ActionModel(ACTIONS.CENTERGRAPH, true);
			this.action.emit(action);
		}
	}

	cambiarEstudiante() {
		this.store
			.select((store) => store.profiles)
			.subscribe((profiles) => {
				this.profilesOfUser = profiles;
			});
		let lastSelectedProfile = localStorage.getItem("lastSelectedProfile");
		this.sigmaCanvasService.setIsLoading(true);

		if (lastSelectedProfile != null) {
			if (
				lastSelectedProfile == this.profiles.Author &&
				this.profilesOfUser.profileEditor == true
			) {
				this.setProfile(this.profiles.Author);
			} else if (
				lastSelectedProfile == this.profiles.Teacher &&
				this.profilesOfUser.profileTeacher == true
			) {
				this.setProfile(this.profiles.Teacher);
			} else if (
				lastSelectedProfile == this.profiles.Student &&
				this.profilesOfUser.profileStudent == true
			) {
				this.setProfile(this.profiles.Student);
			}else if (
				lastSelectedProfile == this.profiles.Father &&
				this.profilesOfUser.profileParent == true
			) {
				this.setProfile(this.profiles.Father);
			}else if (
				lastSelectedProfile == this.profiles.Admin &&
				this.profilesOfUser.profileAdmin == true
			) {
				this.setProfile(this.profiles.Admin);
			}
		}
	}

	setProfile(profile) {
		if (profile == this.profiles.Author) {
			this.comeFromProfile = this.actualProfile;
		}
		if (
			profile == this.profiles.Teacher &&
			this.actualProfile == "ESTUDIANTE"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}
		if (profile == this.profiles.Student && this.actualProfile == "PROFESOR") {
			localStorage.removeItem("selectedGroupInfo");
		}
		if (
			profile == this.profiles.Teacher &&
			this.comeFromProfile == "ESTUDIANTE"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}
		if (
			profile == this.profiles.Student &&
			this.comeFromProfile == "PROFESOR"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}
		localStorage.setItem("lastSelectedProfile", profile);
		this.loginService.setProfile(profile, true);
	}

	cambiarEditor() {
		this.sigmaCanvasService.setIsLoading(true);
		localStorage.setItem("dontLoadMenu", "true");
		this.utils.loadMenu = false;
		this.toolsService.changeUpdateGraph(true);
		setTimeout(() => {
			this.sigmaCanvasService.setIsLoading(false);
		}, 100);
		this.loginService.setProfile(Profiles.Author);
	}

	showFullscreen() {
		this.isFullscreen = !this.isFullscreen;
		if (this.isFullscreen) document.documentElement.requestFullscreen();
		else document.exitFullscreen();
	}
}
