<nb-card class="no-edge-card modal-max-height-100-65 mb-0">

		<div class="closeModal">
			<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
				 (click)="closeModal('')">
			</nb-icon>
		</div>
		<nb-card-body>
			<div class="row d-flex justify-content-center cards-container">
				<div class="col-12">


							<nb-card class="background_themecolor">
								<nb-card-header>
									<div class="row">
										<div class="col-12">
											<h2 class="text-center">
												¿Que datos quieres ver?
											</h2>
										</div>
									</div>
								</nb-card-header>
								<nb-card-body class="container">
									<div class="cards">
										<div class="card-item" (click)="centersList()">
											<div class="card-image">
												<div
													class="icon-option icon-center pointer"
												></div>
											</div>
											<div class="card-info">
												<h6 class="card-title">
													<!-- {{ "MENUACTIONS.TEACHERTITLE1" | translate }} -->
													Ver centros
												</h6>
											</div>
										</div>
									</div>

									<div class="cards">
										<div class="card-item" (click)="teachersList()">
											<div class="card-image">
												<div
													class="menu-icon-teacher pointer"
												></div>
											</div>
											<div class="card-info">
												<h6 class="card-title">
													<!-- {{ "MENUACTIONS.TEACHERTITLE1" | translate }} -->
													Ver profesores
												</h6>
											</div>
										</div>
									</div>

									<div class="cards">
										<div class="card-item" (click)="studentsList()">
											<div class="card-image">
												<div
													class="menu-icon-student pointer"
												></div>
											</div>
											<div class="card-info">
												<h6 class="card-title">
													<!-- {{ "MENUACTIONS.TEACHERTITLE1" | translate }} -->
													Ver Estudiantes
												</h6>
												<p class="paragraph">
													<!-- {{ "MENUACTIONS.TEACHERSUBTITLE1" | translate }} -->

												</p>
											</div>
										</div>
									</div>
								</nb-card-body>
							</nb-card>

					</div>
				</div>

		</nb-card-body>
	</nb-card>
