import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "app-informes-centro",
	templateUrl: "./informes-centro.component.html",
	styleUrls: ["./informes-centro.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class InformesCentroComponent implements OnInit {
	parametroID: number | null = null;
	consultaID: number | null = null;

	parametros: any[] = [
		{ id: 1, title: "Aciertos (%) (predeterminado)"},
		{ id: 2, title: "Conocimiento (K)"},
		{ id: 3, title: "Trabajo (min)"},
		{ id: 4, title: "Constancia (σ min)"},
		{ id: 5, title: "Carga (min)"},
		{ id: 6, title: "Socialización (percentil)"},
	];
	consultas: any[] = [
		{ id: 1, title: "¿Cómo soy de bueno en cada nivel?"},
		{ id: 2, title: "¿En qué asignatura está mejor el centro?"},
		{ id: 3, title: "¿Cómo puedo evaluar a los profesores?"},
		{ id: 4, title: "¿Cómo sabes qué falla en tu centro?"},
		{ id: 5, title: "Quiero anticipar qué problemas voy a tener: chicos que no estudian, fallan mucho."},
		{ id: 6, title: "¿Cómo mides la calidad de tu centro?"},
		{ id: 7, title: "¿Cómo sabes que tu centro es mejor que otro?"},
		{ id: 8, title: "¿Qué grupos funcionan mejor?"},
		{ id: 9, title: "¿Tengo problemas de convivencia o respeto?"},
	];

	verBotonesGraficas: number = 0;

	constructor(
		public activeModal: NgbActiveModal,
	) { }

	ngOnInit() {}

	ngOnDestroy() {
	}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	onParametroChange() {
		this.consultaID = null; // Resetear el siguiente select
		this.verBotonesGraficas = 0;
	}

	onConsultaChange() {
		this.verBotonesGraficas = 0;
	}

	verLista(){
		this.verBotonesGraficas = 1;
		//mostramos la lista
		const div = document.getElementById("lista");
		if(div){
			div.style.display = "block";
		}
		//ocultamos la grafica de barras
		const div2 = document.getElementById("barras");
		if(div2){
			div2.style.display = "none";
		}
	}

	verBarras(){
		this.verBotonesGraficas = 2;
		//mostramos la grafica de barras
		const div = document.getElementById("barras");
		if(div){
			div.style.display = "block";
		}
		//ocultamos la lista
		const div2 = document.getElementById("lista");
		if(div2){
			div2.style.display = "none";
		}
	}

	verSolar(){
		this.verBotonesGraficas = 3;
	}

	verRadial(){
		this.verBotonesGraficas = 4;
	}

	verDispersion(){
		this.verBotonesGraficas = 5;
	}

}
