import { UsersService } from "./../../../core/services/users/users.service";
import { FlatTreeControl } from "@angular/cdk/tree";
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation, HostListener, Input } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IReporting, TypeChlidrenReporting } from "./interfaces/reporting";
import { ReportingService } from "./service/reporting.service";
import { debounceTime, finalize, switchMap, take, takeUntil } from "rxjs/operators";
import { GruposService } from "src/app/core/services/groups/grupos.service";
import { ChallengeListModel, GroupModel } from "src/app/core/models/groups/groups-model";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import moment from "moment-timezone";
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/models/store.model';
import { User } from "src/app/core/models/users/user.models";
import { LoginService } from "src/app/core/services/login";
import { UserModel } from "src/app/core/models/courses";
import { GraficosComponent } from "./graficos/graficos.component";
import { EstudianteQuizesDatosComponent } from "./estudiante-quizes-datos/estudiante-quizes-datos.component";
import { CoursesService } from "src/app/core/services/courses";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { LocalStorage } from "src/app/core/utils";
import { Utils } from "src/app/core/utils/utils";
import { GraphService } from "src/app/core/services/graph/graph.service";
import { TargetsService } from "src/app/core/services/targets";
import { Router } from "@angular/router";
import { TablaComponent } from "./tabla/tabla.component";
import { left } from "@popperjs/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MastersService } from "src/app/core/services/masters";

interface ExampleFlatNode {
	expandable: boolean;
	name: string;
	id: string;
	icon: string;
	level: number;
}

enum FILTER_MODE {
	LIST_MODE = "list_mode",
	CHALLENGE_MODE = "challenge_mode",
}

enum SELECTED_TYPE {
	GRAPH = "graph",
	CHALLENGE = "challenge",
}

declare var google: any;

@Component({
	selector: "app-reporting",
	templateUrl: "./reporting.component.html",
	styleUrls: ["./reporting.component.scss"],
	animations: [
    trigger('accordion', [
      state('open', style({
        width: 'auto',
				transform: 'translateY(0)',
  			overflow: 'initial'
      })),
      state('closed', style({
        width: '0',
				overflow: 'hidden',
  			transform: 'translateY(-500px)',
      })),
      transition('open <=> closed', [
        animate('0.3s ease-out')
      ]),
    ]),
    trigger('accordion2', [
      state('in', style({
        width: 'auto',
				right: 'auto',
				padding: '15px',
				transform: 'translateX(0px)',
  			overflow: 'initial',
      })),
      state('out', style({
        width: '0',
				right: '0',
				overflow: 'hidden',
				padding: '0',
  			transform: 'translateX(500%)',
      })),
      transition('in <=> out', [
        animate('0.3s ease-in-out')
      ]),
    ]),
		trigger('accordion3', [
      state('see', style({
        width: 'auto',
				left: 'auto',
				transform: 'translateX(0)',
  			overflow: 'initial',
      })),
      state('hide', style({
        width: '0',
				left: '0',
				overflow: 'hidden',
  			transform: 'translateX(-200px)',
      })),
      transition('see <=> hide', [
        animate('0.3s ease-out')
      ]),
    ])
  ],
	encapsulation: ViewEncapsulation.None,
})
export class ReportingComponent implements OnInit {
	@Input() hotView: boolean = false;
	@Input() hotViewIdGroup: number;
	@Input() hotViewIdSession: number[];
	public idCourse: any;
	public idGrafo: any;
	public idNode: any;
	public idGrupo: any = 0;
	public group: any;
	isLoading: boolean = true;
	challenges: ChallengeListModel[] = [];
	selectedFilterMode: string = FILTER_MODE.LIST_MODE;
	listChallengeSelected: number[] = [];
	listGraphSelected: number[] = [];
	filterModeList = FILTER_MODE;
	selectedType = SELECTED_TYPE;
	$toogle = new Subject<void>();
	//showReportButton = false;
	usersInscritos: any[] = [];
	user: User;
	editMode = false;
	editValue: number = null;
	nameSession: string;
	titulo: string = "";
	childrenList: any[];
	idHijo: any;
	public gruposList: GroupModel[] = [];
	idCentro: any;
	primergrupo: any = {
		idGroup: 0,
		title: "Sin grupo",
	};
	isFullscreen = false;
	showFullscreen = false;
	informeChallenge = false;
	informeType: number = 2;
	dataCharts: any;
	showElement: boolean = false;
	showElement2: boolean = false;
	buttonText: string = "Filtros";
	claseInvertida: boolean = false;
	filtroAz: string = "A-Z";
	filtro90: string = "9-0";
	filtroAzTabla: string = "A-Z";
	filtroAciertos: string = "";
	filtroTiempo: string = "";
	isMobile: boolean = false;
	accordionOpen: boolean = false;
	accordionOpen2: boolean = false;
	accordionOpen3: boolean = false;
	verBotonG1: number = 0;
	verBotonG2: number = 0;
	verBotonG3: number = 0;
	verBotonG4: number = 0;
	verBotonG5: number = 0;
	isMenuOpen: boolean = true;
	showMessage: boolean = true;
	public dataTable: any [] = [];
	public formulario: UntypedFormGroup;
	selectedChildId: number;
  selectedChildGroups = [];
  selectedGroupId: number;

	private _transformer = (node: IReporting, level: number) => {
		return {
			expandable: !!node.children && node.children.length > 0,
			name: node.name,
			id: node.id,
			icon: node.icon,
			level: level,
			type: node.type,
			idCourse: node.idCourse,
			idTarget: node.idTarget,
			pendingQuizzes: node.pendingQuizzes,
			progressPercentage: node.progressPercentage,
			colorBar: node.colorBar,
		};
	};

	treeControl = new FlatTreeControl<ExampleFlatNode>(
		(node) => node.level,
		(node) => node.expandable
	);

	treeFlattener = new MatTreeFlattener(
		this._transformer,
		(node) => node.level,
		(node) => node.expandable,
		(node) => node.children
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
	loading: boolean = false;
	downloadIcon: boolean = false;
	selectedNode: any = null;
	reportGenerated: boolean = false;
	reportType: string = "";
	reportTextButtonRefresh: string =
		this.translateService.instant("INFORMES.REFRESH");
	reportTextButton: string = this.translateService.instant(
		"INFORMES.SHOWREPORT"
	);
	private destroy$ = new Subject();
	profileTitles = {
		ESTUDIANTE: "Estudiante",
		AUTOR: "Editor",
		PROFESOR: "Profesor",
		PADRE: "Padre",
		ADMIN: "Administrador",
	};
	profile: any;
	profileTitle: string;
	challengeOn: boolean = JSON.parse(this.localStorage.getItem("challengeOn"))
		? true
		: false;
	reportingChallenge: boolean = false; // si el informe es de los desafios entonces es true
	primerHijo: any;
	buscarNodo: any;

	private _showReportButton: boolean = false;
	showCompetitionsResults: boolean = false;
	get showReportButton(): boolean {
		return this._showReportButton;
	}
	set showReportButton(value: boolean) {
		if (value === true) {
			setTimeout(() => {
				//this.verInforme1(7); //si la variable se pone en true, 1seg despues se ejecuta la llamada del informe

			}, 1000);
		}
		this._showReportButton = value;
	}
	@ViewChild(GraficosComponent)
	graficosComponent: GraficosComponent;
	rangeStr: string = '';
	@ViewChild(TablaComponent)
	tablaComponent: TablaComponent;

	verActividades: boolean = false;
	verAciertos: boolean = false;
	verFotos: boolean = false;
	verK: boolean = false;
	mostrarFlechita: boolean = false;
	stepGuide: number = 1;

	constructor(
		private reportingService: ReportingService,
		private activeModal: NgbActiveModal,
		public userService: UsersService,
		private groupService: GruposService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private store: Store<State>,
		public loginService: LoginService,
		public coursesService: CoursesService,
		private localStorage: LocalStorage,
		private utils: Utils,
		private graphServ: GraphService,
		private modalService: NgbModal,
		public targetsService: TargetsService,
		public router: Router,
		private formBuild: UntypedFormBuilder,
		private mastersService: MastersService,
	) {}

	ngOnInit(): void {
		 //con esto evitamos el error de jquery en la tabla
		if(!JSON.parse(localStorage.getItem("noConflict"))){
			localStorage.setItem("noConflict", "false");
		}
		// obtenemos el centro
		let config = JSON.parse(localStorage.getItem("config-site"));
		this.idCentro = config.idCenter;
		console.log(this.idCentro);
		//obtenemos el perfil que esta activo
		this.store
			.select((store) => store.selectedProfile)
			.pipe(takeUntil(this.destroy$))
			.subscribe((selectedProfile) => {
				//this.profile = selectedProfile['selectedProfile']
				this.profile = this.loginService.getProfile();
				this.profileTitle = this.profileTitles[this.profile];
			});
		this.user = this.loginService.getUser();
		this.loading = true;
		if (this.profile === "PADRE") {
			this.formulario = this.formBuild.group({
				name: ["",	Validators.required],
				idGroup: ["",	Validators.required],
			});
			this.loading = false;
			//obtenemos el listado de hijos
			/**
			this.primerHijo = this.childrenList[0];
			this.idHijo = this.childrenList[0].idUser.toString();
			console.log(this.childrenList);
			this.actualizarComboGrupos();
			//this.getMasterDataParent();
			//this.getListadoEstudiantes();
			*/
		} else {
			//this.getMasterDataCourses();
			this.getListadoEstudiantes();
			this.$toogle
				.pipe(
					debounceTime(1000),
					switchMap(() =>
						this.reportingService.informeDataTableChallenges(
							this.idGrupo,
							this.listChallengeSelected,
							true
						)
					)
				)
				.subscribe(
					(result) => {
						this.dataTable = Object.values(result.data);
						//this.reportingService.reportingSusbcribe.next({ ...result.data });
						this.showFullscreen = true;
						this.informeChallenge = true;
						this.reportType = "challenge";
					},
					(err) =>
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						)
				);
		}
		//definimos el titulo
		if (this.idGrupo !== 0) {
			this.titulo = "- " + this.group.title;
		}
		//esto es para los graficos de google
		google.charts.load("current", { packages: ["corechart"] });

		this.isMobile = window.innerWidth < 1200;
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		if(this.hotView == true){
			this.idGrupo = this.hotViewIdGroup;
			this.listChallengeSelected = this.hotViewIdSession;
			this.loadHotView(this.hotViewIdGroup, this.hotViewIdSession);
		}
	}

	getMasterDataCourses() {
		this.accordionOpen3 = false;
		this.verBotonG1 = 1;
		this.verBotonG2 = 0;
		this.showMessage = true;
		this.dataTable = []; // con esto ocultamos la tabla
		this.listChallengeSelected = [];
		this.showCompetitionsResults = false;
		localStorage.removeItem("challengeOn");
		localStorage.removeItem("challengeType");
		this.reportingChallenge = false;
		this.selectedFilterMode = FILTER_MODE.LIST_MODE;
		this.resetData();
		if(this.profileTitle !== "Padre"){
			let rol = this.profileTitle === "Estudiante" ? '2' : '3';
			this.reportingService
			.getReportingByGroupId(this.idGrupo, rol)
			.pipe(finalize(() => (this.loading = false)))
			.subscribe(
				(res: any) => {
					this.dataSource.data = res.data.courses;
					this.stepGuide = 2;
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORCOURSES")
					);
					this.dataSource.data = [];
				}
			);
		} else{
			this.reportingService
			.getReportingParent(this.selectedGroupId, this.selectedChildId.toString())
			.pipe(finalize(() => (this.loading = false)))
			.subscribe(
				(res: any) => {
					this.dataSource.data = res.data.courses;
					this.stepGuide = 2;
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORCOURSES")
					);
					this.dataSource.data = [];
				}
			);
		}

		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha seleccionado el informe de Ideografos",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	getMasterDataParent() {
		localStorage.removeItem("challengeOn");
		localStorage.removeItem("challengeType");
		this.reportingChallenge = false;
		this.selectedFilterMode = FILTER_MODE.LIST_MODE;
		this.resetData();
		this.reportingService
			.getReportingParent(this.idGrupo, this.idHijo)
			.pipe(finalize(() => (this.loading = false)))
			.subscribe(
				(res: any) => {
					this.dataSource.data = res.data.courses;
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORCOURSES")
					);
					this.dataSource.data = [];
				}
			);
	}

	getListadoEstudiantes() {
		if (this.idGrupo !== 0) {
			this.groupService
				.getListEstudiantes(this.idGrupo)
				.pipe(finalize(() => (this.loading = false)))
				.subscribe((res: any) => {
					this.usersInscritos = res.data;
				});
		} else if (this.idGrupo === 0) {
			this.usersInscritos.push(this.user);
			this.loading = false;
		}
	}

	resourse = (node: IReporting) => {
		this.localStorage.removeItem("challengeOn");
		this.localStorage.removeItem("challengeType");
		if (
			node.type === TypeChlidrenReporting.NODE &&
			this.profile == "PROFESOR"
		) {
			this.selectedNode = node;
			this.loading = true;
			this.idNode = node.id;
			this.idCourse = node.idCourse;
			this.idGrafo = node.idTarget;
			this.downloadIcon = true;
			//this.verInforme1_nodo(0);
			this.reportingService
				.getDataTableQuizes(
					this.idGrupo,
					this.idCourse,
					this.idGrafo,
					this.idNode,
					[]
				)
				.subscribe(
					(res: any) => {
						this.reportingService.reportingSusbcribe.next({ ...res.data });
						this.loading = false;
						this.downloadIcon = false;
						this.reportGenerated = true;
						this.reportType = "node";
					},
					(err) => {
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						);
					}
				);
		} else if (
			node.type === TypeChlidrenReporting.NODE &&
			this.profile == "ESTUDIANTE"
		) {
			this.selectedNode = node;
			this.loading = true;
			this.idNode = node.id;
			this.idCourse = node.idCourse;
			this.idGrafo = node.idTarget;
			this.downloadIcon = true;
			//this.verInforme1_nodo(7);
			this.reportingService
				.getDataTableQuizesStudent(
					this.idGrupo,
					this.idCourse,
					this.idGrafo,
					this.idNode,
					[]
				)
				.subscribe(
					(res: any) => {
						this.reportingService.reportingSusbcribe.next({ ...res.data });
						this.loading = false;
					},
					(err) => {
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						);
					}
				);
		} else if (
			node.type === TypeChlidrenReporting.NODE &&
			this.profile == "PADRE"
		) {
			this.selectedNode = node;
			this.loading = true;
			this.idNode = node.id;
			this.idCourse = node.idCourse;
			this.idGrafo = node.idTarget;
			this.downloadIcon = true;
			this.reportingService
				.getDataTableQuizesParent(
					this.idGrupo,
					this.idCourse,
					this.idGrafo,
					this.idNode,
					this.idHijo
				)
				.subscribe(
					(res: any) => {
						this.reportingService.reportingSusbcribe.next({ ...res.data });
						this.loading = false;
					},
					(err) => {
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						);
					}
				);
		}
	};

	hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

	closeModal(sendData?: any) {
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha salido de la pantalla Informes",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
		this.verActividades = false;
		this.verAciertos = false;
		this.verFotos = false;
		localStorage.removeItem("challengeOn");
		localStorage.removeItem("challengeType");
		this.activeModal.close();
	}

	getChallenges() {
		this.accordionOpen3 = false;
		this.verBotonG1 = 2;
		this.showMessage = true;
		this.showCompetitionsResults = false;
		//ocultamos todos los informes
		this.dataTable = [];
		this.listGraphSelected = [];
		this.showFullscreen = false;
		this.verBotonG2 = 0;
		const div = document.getElementById("infoTable");
		div.style.display = "block";
		//ocultamos los otros informes
		const div2 = document.getElementById("myBarChart");
		div2.style.display = "none";
		//this.cambiarInforme(2); // para que se muestre la tabla del informe
		localStorage.setItem("challengeOn", "true");
		this.challengeOn = JSON.parse(localStorage.getItem("challengeOn"));
		this.reportingChallenge = true;
		console.log(this.challengeOn);
		this.selectedFilterMode = FILTER_MODE.CHALLENGE_MODE;
		this.resetData();
		this.groupService
			.getChallenges(this.idGrupo)
			.pipe(finalize(() => (this.loading = false)))
			.subscribe(
				(result) => {
					result.data.forEach((element) => {
						//Arevalo - se deja comentado ya que el contenido del titulo del desafio puede ser cualquier texto
						/*moment.locale('es');
						let serverDate = moment.tz(element.nameSession, "UTC");
						let localDate = serverDate.local()
						element.nameSession = localDate.format('LLLL')*/
					});
					this.challenges = result.data as ChallengeListModel[];
					this.stepGuide = 2;
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORCHALLENGES")
					);
					this.challenges = [];
				}
			);

		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha seleccionado el informe de Desafios",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	downloadExcel() {
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha exportado el Informe",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();

		this.reportingService
			.downloadExcel(this.dataTable)
			.subscribe(
				(blob) => {
					const url = URL.createObjectURL(blob);
					const a = document.createElement("a");
					a.href = url;
					const fecha = new Date();
					const nombre = `infome_${this.idNode}_${fecha}.xlsx`;
					a.download = nombre;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.ERROREXCEL")
					);
				}
			);
	}

	toggleChallenge(ev: boolean, data: any, type: string) {
		this.stepGuide = 3;
		//ocultamos todos los informes
		this.dataTable = [];
		this.showFullscreen = false;
		this.verBotonG2 = 0;
		const div = document.getElementById("infoTable");
		div.style.display = "block";
		const div2 = document.getElementById("myBarChart");
		div2.style.display = "none";
		localStorage.setItem("challengeOn", "true");
		this.showMessage = true;
		this.challengeOn = JSON.parse(localStorage.getItem("challengeOn"));
		if (type === SELECTED_TYPE.CHALLENGE) {
			this.reportType = "challenge";
			const obj = data as ChallengeListModel;
			if (ev) this.listChallengeSelected.push(obj.idSession);
			else
				this.listChallengeSelected = this.listChallengeSelected.filter(
					(element) => element !== obj.idSession
				);
			//this.$toogle.next(); //con esto se abre la tabla de forma automatica
		} else {
			this.reportType = "target";
			const obj = data as IReporting;
			const id = Number(obj.id);

			if (ev) this.listGraphSelected.push(id);
			else
				this.listGraphSelected = this.listGraphSelected.filter(
					(element) => element !== id
				);
			if (this.listGraphSelected.length) this.showReportButton = true;
			else {
				this.showReportButton = false;
				this.resetData();
			}
		}

		//Creamos el log
		if(type === SELECTED_TYPE.CHALLENGE){
			const bodyLog1 = {
				action: "informes",
				actionData: "Ha seleccionado el desafio con idSession " + data.idSession + ".",
				startDate: new Date(),
				endingDate: null,
				role: this.loginService.getProfile(),
				idGroup: this.idGrupo,
			};
			this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
		} else{
			const bodyLog1 = {
				action: "informes",
				actionData: "Ha seleccionado el ideografo con id " + data.id + ".",
				startDate: new Date(),
				endingDate: null,
				role: this.loginService.getProfile(),
				idGroup: this.idGrupo,
			};
			this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
		}
	}

	cambiarInforme(type) {
		//Creamos el log
		let info = type == 1 ? "Tiempo de Trabajo" : "Tabla de Resultados";
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha visualizado el informe de " + info + ".",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
		this.stepGuide = 0;
		this.showElement = false;
		this.showElement2 = false;
		this.showMessage = false;
		this.isMenuOpen = false;
		this.informeType = type;
		switch (type) {
			case 1: {
				this.verInforme1(7);
				this.verBotonG2 = 1;
				this.verBotonG3 = 0;
				this.accordionOpen = false;
				this.accordionOpen2 = false;
				this.accordionOpen3 = false;
				const div = document.getElementById("myBarChart");
				div.style.display = "block";
				//ocultamos los otros informes
				const div2 = document.getElementById("infoTable");
				div2.style.display = "none";
				break;
			}
			case 2: {
				this.verInforme2();
				this.verBotonG2 = 2;
				this.verBotonG4 = 0;
				this.verBotonG5 = 0;
				this.accordionOpen = false;
				this.accordionOpen2 = false;
				this.accordionOpen3 = false;
				const div = document.getElementById("infoTable");
				div.style.display = "block";
				//ocultamos los otros informes
				const div2 = document.getElementById("myBarChart");
				div2.style.display = "none";
				break;
			}
		}
	}

	verInforme1(rangeTime) {
		this.loading = true;
		//this.cambiarInforme(this.informeType);
		//this.verInforme2();
		this.showFullscreen = true;
		this.informeChallenge = false;
		this.challengeOn = false;
		let rol =
			this.profileTitle === "Estudiante"
				? "2"
				: this.profileTitle === "Profesor"
				? "3"
				: "4";
		this.reportingService.dataChartSusbcribe.unsubscribe;
		this.reportingService
			.getInformeWorkingTimeForTarget(
				this.idGrupo,
				this.listGraphSelected,
				rol,
				rangeTime
			)
			.subscribe(
				(result) => {
					this.dataCharts = result.data;
					this.reportingService.dataChartSusbcribe.next({ ...result });
					this.downloadIcon = false;
					this.reportGenerated = true;
					this.reportType = "target";
					this.loading = false; //ocultamos el spinner y mostramos los informes
					//this.graficosComponent.changeRangeTime("rangeTime");
				},
				(err) =>
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORQUIZZES")
					)
			);
	}

	verInforme2() {
		this.dataTable = []; // con esto ocultamos la tabla
		this.showFullscreen = true;
		//this.informeChallenge = false;
		if (this.profile === "PADRE") {
			this.idGrupo = this.selectedGroupId;
			this.idHijo = this.selectedChildId;
			this.challengeOn = false;
			this.reportingService
				.informeDataTableTargets(this.idGrupo, this.listGraphSelected, 4, [this.idHijo])
				.subscribe(
					(result) => {
						this.dataTable = Object.values(result.data);
						//this.reportingService.reportingSusbcribe.next({ ...result.data });
						this.downloadIcon = false;
						this.reportGenerated = true;
						this.reportType = "target";
						//this.tablaComponent.precargarFiltros();
					},
					(err) =>
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						)
				);
		} else {
			//this.challengeOn = false;
			let rol = this.profileTitle === "Estudiante" ? 2 : 3;
			if(this.reportType == 'target'){
				this.reportingService
				.informeDataTableTargets(this.idGrupo, this.listGraphSelected, rol, [])
				.subscribe(
					(result) => {
						this.dataTable = Object.values(result.data);
						//this.reportingService.reportingSusbcribe.next({ ...result.data });
						this.downloadIcon = false;
						this.reportGenerated = true;
					},
					(err) =>
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						)
				);
			} else if(this.reportType == 'challenge'){
				this.reportingService
				.informeDataTableChallenges(this.idGrupo, this.listChallengeSelected, true)
				.subscribe(
					(result) => {
						this.dataTable = Object.values(result.data);
						//this.reportingService.reportingSusbcribe.next({ ...result.data });
						this.downloadIcon = false;
						this.reportGenerated = true;
					},
					(err) =>
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						)
				);
			}
		}
	}

	loadHotView(hotViewIdGroup: number, hotViewIdSession: number[]) {
		this.dataTable = []; // con esto ocultamos la tabla
		this.reportingService
			.informeDataTableChallenges(hotViewIdGroup, hotViewIdSession, true)
			.subscribe(
				(result) => {
					console.log("result.dataaa", result.data);
					this.dataTable = Object.values(result.data);
					this.downloadIcon = false;
					this.loading = false;
					this.reportGenerated = true;
					this.showMessage = false;
					this.reportType = "challenge";
					this.informeType = 2;
					const div = document.getElementById("infoTable");
					div.style.display = "block";
					this.showFullscreen = true;
					this.isMenuOpen = false;
				},
				(err) =>
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORQUIZZES")
					)
			);
	}

	/*
	verInforme1_nodo(rangeTime) {
		this.loading = true;
		this.cambiarInforme(this.informeType);
		this.showFullscreen = true;
		this.informeChallenge = false;
		this.challengeOn = false;
		let rol =
			this.profileTitle === "Estudiante"
				? "2"
				: this.profileTitle === "Profesor"
				? "3"
				: "4";
		this.reportingService.dataChartSusbcribe.unsubscribe;
		this.reportingService
			.getInformeWorkingTimeForNode(
				this.idGrupo,
				[this.idGrafo],
				rol,
				rangeTime,
				this.idNode
			)
			.subscribe(
				(resu) => {
					this.dataCharts = resu.data;
					this.reportingService.dataChartSusbcribe.next({ ...resu });
					this.downloadIcon = false;
					this.reportGenerated = true;
					this.loading = false; //ocultamos el spinner y mostramos los informes
					this.graficosComponent.changeRangeTime("Siempre");
				},
				(err) =>
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORQUIZZES")
					)
			);
	}
	*/

	ordenarChart() {
		this.verBotonG4 = 1;
		let order = this.filtroAz;

		if (order === '') {
			order = 'A-Z';
		} else if (order === 'A-Z') {
			order = 'Z-A';
		} else if (order === 'Z-A') {
			order = 'A-Z';
		}
		this.filtroAz = order;
		this.graficosComponent.ordenarChart(order);
		this.showElement = false;
		this.accordionOpen2 = false;
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha ordenado el informe de Tiempo de Trabajo por Nombre",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	ordenarChart2() {
		this.verBotonG4 = 2;
		let order = this.filtro90;

		if (order === '') {
			order = '9-0';
		} else if (order === '9-0') {
			order = '0-9';
		} else if (order === '0-9') {
			order = '9-0';
		}
		this.filtro90 = order;
		this.graficosComponent.ordenarChart(order);
		this.showElement = false;
		this.accordionOpen2 = false;
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha ordenado el informe de Tiempo de Trabajo por Tiempo Promedio",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	changeRangeTime(rangeStr) {
		//Creamos el log
		let info = rangeStr == "7days" ? "Ver últimos 7 dias" : rangeStr == "month" ? "Ver último mes" : "Ver último año";
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha usado el filtro de " + info + ", en el informe de Tiempo de Trabajo.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
		this.rangeStr = rangeStr;
		this.reportingService.dataChartSusbcribe.unsubscribe;
		let range = 0;
		switch (rangeStr) {
			case "Siempre":
				range = 0;
				break;
			case "7days":
				this.verBotonG5 = 1;
				this.accordionOpen2 = false;
				range = 7;
				break;
			case "month":
				this.verBotonG5 = 2;
				this.accordionOpen2 = false;
				range = 30;
				break;
			case "year":
				this.verBotonG5 = 3;
				this.accordionOpen2 = false;
				range = 365;
				break;
		}
		if (this.reportType == "target") {
			this.verInforme1(range);
		} else if (this.reportType == "node") {
			//this.verInforme1_nodo(range);
		}
		this.graficosComponent.changeRangeTime(rangeStr);
	}

	ordenarTabla_AZ() {
		this.verBotonG3 = 1;
		this.verAciertos = false;
		let order = this.filtroAzTabla;
		if (order === '') {
			order = 'A-Z';
		} else if (order === 'A-Z') {
			order = 'Z-A';
		} else if (order === 'Z-A') {
			order = 'A-Z';
		}
		this.filtroAzTabla = order;
		//this.tablaComponent._ordenarTabla(order);
		this.tablaComponent.aplicarFiltro(order);
		this.showElement = false;
		this.accordionOpen2 = false;
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha ordenado el informe de Tabla de Resultados por Nombre",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	ordenarTabla_Aciertos() {
		this.verBotonG3 = 2;
		this.verAciertos = false;
		let order = this.filtroAciertos;
		if (order === '') {
			order = 'aciertosDEC';
		} else if (order === 'aciertosASC') {
			order = 'aciertosDEC';
		} else if (order === 'aciertosDEC') {
			order = 'aciertosASC';
		}
		this.filtroAciertos = order;
		//this.tablaComponent._ordenarTabla(order);
		this.tablaComponent.aplicarFiltro(order);
		this.showElement = false;
		this.accordionOpen2 = false;
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha ordenado el informe de Tabla de Resultados por Acierto",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	ordenarTabla_Tiempo() {
		this.verBotonG3 = 3;
		this.verAciertos = false;
		let order = this.filtroTiempo;
		if (order === '') {
			order = 'tiempoTotalDEC';
		} else if (order === 'tiempoTotalASC') {
			order = 'tiempoTotalDEC';
		} else if (order === 'tiempoTotalDEC') {
			order = 'tiempoTotalASC';
		}
		this.filtroTiempo = order;
		//this.tablaComponent._ordenarTabla(order);
		this.tablaComponent.aplicarFiltro(order);
		this.showElement = false;
		this.accordionOpen2 = false;
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha ordenado el informe de Tabla de Resultados por Tiempo Total",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	private resetData() {
		this.reportingService.reportingSusbcribe.next({ data: [] });
		this.showReportButton = false;
		this.showFullscreen = false;
		this.listChallengeSelected = this.listGraphSelected = [];
	}

	editSaveSession(challenge: ChallengeListModel) {
		this.editMode = !this.editMode;
		this.editMode
			? (this.editValue = challenge.idSession)
			: (this.editValue = null);

		if (!this.editMode && !this.editValue && this.nameSession) {
			this.groupService
				.updateNameSession(
					challenge.idSession,
					this.nameSession,
					challenge.idGroup
				)
				.subscribe(
					(result) => {
						this.toaster.success(
							this.translateService.instant("INFORMES.NAMESESSIONOK")
						);
						this.nameSession = "";
					},
					(err) => {
						this.toaster.error(
							this.translateService.instant("INFORMES.NAMESESSIONKO")
						);
					}
				);
		}
	}

	deleteChallenge(challenge: ChallengeListModel) {
		//Eliminar el reto
		this.groupService
			.deleteChallenge(challenge.idGroup, challenge.idSession)
			.subscribe(
				(result) => {
					this.toaster.success(
						this.translateService.instant("INFORMES.DELETECHALLENGEOK")
					);
					this.getChallenges();
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.DELETECHALLENGEKO")
					);
				}
			);
	}

	nameSessionChanged(name: string) {
		this.nameSession = name;
	}

	reload(reload: boolean) {
		if (reload) {
			this.verInforme2();
		}
	}

	onHijoSelect(event: any) {
		this.dataTable = []; // con esto ocultamos la tabla
		this.idHijo = event;
		this.idGrupo = 0;
		this.resetData();
		this.actualizarComboGrupos();
	}

	actualizarComboGrupos() {
		this.gruposList = [];
		this.gruposList.push(this.primergrupo);
		// Llamada al método que obtiene los grupos del hijo seleccionado
		this.groupService.getGruposByUser(this.idHijo, this.idCentro).subscribe(
			(res) => {
				this.gruposList.push(...res.data);
				this.idGrupo = this.gruposList[0]?.idGroup;
				this.getMasterDataParent();
				this.getListadoEstudiantes();
			},
			(error) => {
				console.error("Error al obtener los grupos del hijo", error);
			}
		);
	}

	onGrupoSelect(event: any) {
		this.dataTable = []; // con esto ocultamos la tabla
		this.idGrupo = event;
		this.resetData();
		this.getMasterDataParent();
		this.getListadoEstudiantes();
	}

	toggleFullscreen() {
		this.isFullscreen = !this.isFullscreen;
	}

	verGrafo(idCourse, idTarget) {
		this.coursesService
			.getCourseById(idCourse)
			.pipe(take(1))
			.subscribe((res) => {
				let courseTargetList = res.data.coursesTarget;
				let courseTarget = null;
				for (let index = 0; index < courseTargetList.length; index++) {
					const element = courseTargetList[index];
					if (element.target.idTarget == idTarget) {
						courseTarget = element;
						break;
					}
				}
				if (courseTarget) {
					this.verDetalleMapa(courseTarget);
				}
			});
	}

	verDetalleMapa(mapa: any) {
		//this.localStorage.removeItem('lastNodeElement');
		this.listaGrafosVisitados(mapa);
		const idMapa = mapa.target.idTarget;
		const idCurso = mapa.cours.idCourse;
		this.localStorage.setItem("lastTarget", idMapa);
		this.localStorage.removeItem("fromEditor");
		const idCourse = mapa.idCourse_Target;
		localStorage.setItem("dontLoadMenu", "true"); //Este provoca que no se muestre el menú al cambiar de perfil
		this.utils.loadMenu = false;
		this.graphServ.getGraphInfo(idMapa).subscribe((res) => {
			const viewType = res.data.viewType;
			this.graphServ.changedefaultTypeView(res.data.viewType);
			this.buscarNodo = JSON.parse(localStorage.getItem("buscarActividad"));
			this.router
				.navigateByUrl(`/course/${idCurso}/graph/${idMapa}`)
				.then((res) => {
					setTimeout(() => {
						localStorage.setItem("dontLoadMenu", "true");
						this.utils.loadMenu = false;
					}, 100);
				});

			if (this.loginService.esAutor()) {
				let historialGrafos_editor = [];
				let localStoredHistory_editor = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR)
				);
				if (localStoredHistory_editor == null) {
					historialGrafos_editor.push(idCurso);
				} else {
					if (localStoredHistory_editor.length > 15) {
						localStoredHistory_editor.shift(); // Elimina el primer elemento
					}
					localStoredHistory_editor.push(idCurso);
				}
				historialGrafos_editor.push(idCurso);
				this.localStorage.setItem(
					LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR,
					JSON.stringify(
						localStoredHistory_editor == null
							? historialGrafos_editor
							: localStoredHistory_editor
					)
				);
			} else {
				let historialGrafos = [];
				let localStoredHistory = JSON.parse(
					localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY)
				);
				if (localStoredHistory == null) {
					historialGrafos.push(idCurso);
				} else {
					if (localStoredHistory.length > 15) {
						localStoredHistory.shift(); // Elimina el primer elemento
					}
					localStoredHistory.push(idCurso);
				}
				historialGrafos.push(idCurso);
				this.localStorage.setItem(
					LOCALSTORAGESTRINGS.GRAPHSHISTORY,
					JSON.stringify(
						localStoredHistory == null ? historialGrafos : localStoredHistory
					)
				);
			}
			this.closeModal("Abrir mapa");
			this.modalService.dismissAll();
		});
		this.targetsService.registercountopengraph().subscribe((res) => {});
	}
	listaGrafosVisitados(grafo: any) {
		// Obtenemos el array de los últimos grafos visitados.
		let graphVisited_Student =
			JSON.parse(this.localStorage.getItem("graphVisited_Student")) || [];
		const newElement = {
			title: grafo.target.tittle,
			idGrafo: grafo.target.idTarget,
			idCurso: grafo.cours.idCourse,
		};
		// Validar si el nuevo elemento ya está en el array
		const isDuplicate = graphVisited_Student.some(
			(element) =>
				element.idGrafo === newElement.idGrafo &&
				element.idCurso === newElement.idCurso
		);
		// Si no es un duplicado, lo agregamos
		if (!isDuplicate) {
			// Si ya hay 10 elementos, eliminamos el ultimo.
			if (graphVisited_Student.length >= 10) {
				// con esto determinamos el tamaño del array
				graphVisited_Student.pop();
			}
			// Agregamos el nuevo elemento.
			graphVisited_Student.unshift(newElement);
			// Actualizamos el local storage.
			this.localStorage.setItem(
				"graphVisited_Student",
				JSON.stringify(graphVisited_Student)
			);
		}
	}

	switchCompetitionsReport(){
		this.accordionOpen3 = false;
		this.verBotonG1 = 3;
		this.stepGuide = 2;
		//ocultamos todos los informes
		this.dataTable = [];
		this.listChallengeSelected = [];
		this.listGraphSelected = [];
		this.showFullscreen = false;
		this.verBotonG2 = 0;
		const div = document.getElementById("infoTable");
		div.style.display = "block";
		//ocultamos los otros informes
		const div2 = document.getElementById("myBarChart");
		div2.style.display = "none";
		this.showMessage = true;
		this.resetData();
		this.showCompetitionsResults = !this.showCompetitionsResults;

		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha seleccionado el informe de Competicion",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	@HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (window.innerWidth <= 1200) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

	changeVerActividades(){
		this.verActividades = !this.verActividades;
		this.accordionOpen2 = false;
		//Creamos el log
		let info = this.verActividades ? "Ver Actividades" : "Ocultar Actividades";
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha usado el filtro de " + info + ", en el informe de Tabla de Resultados.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	changeVerAcierto(){
		this.tablaComponent.changeVerAcierto();
		this.verAciertos = !this.verAciertos;
		this.accordionOpen2 = false;
		//Creamos el log
		let info = this.verAciertos ? "Ver % Acierto" : "Ocultar % Acierto";
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha usado el filtro de " + info + ", en el informe de Tabla de Resultados.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	changeVerFotos(){
		this.verFotos = !this.verFotos;
		this.accordionOpen2 = false;
		//Creamos el log
		let info = this.verFotos ? "Ver Nombres" : "Ver Fotos";
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha usado el filtro de " + info + ", en el informe de Tabla de Resultados.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	changeVerK(){
		this.verK = !this.verK;
		this.accordionOpen2 = false;
		//Creamos el log
		let info = this.verK ? "Ver K" : "Ocultar K";
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha usado el filtro de " + info + ", en el informe de Tabla de Resultados.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	getVerActividadesLabel(){
    return this.translateService.instant(this.verActividades ? 'INFORMES.HIDEACTIVITIES' : 'INFORMES.SEEACTIVITIES');
  }

  getVerAciertoLabel(){
    return this.translateService.instant(this.verAciertos ? 'INFORMES.HIDE%SUCCESS' : 'INFORMES.SEE%SUCCESS');
  }

  getVerFotosLabel(){
    return this.translateService.instant(this.verFotos ? 'INFORMES.TOSEEPHOTOS' : 'INFORMES.HIDEPHOTOS');
  }

  getVerKLabel(){
    return this.translateService.instant(this.verK ? 'INFORMES.HIDEK' : 'INFORMES.SEEK');
  }

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
		this.accordionOpen = false;
		this.accordionOpen2 = false;
	}

	toggleAccordion() {
    this.accordionOpen = !this.accordionOpen;
		this.accordionOpen2 = false;
		this.accordionOpen3 = false;
  }

	toggleAccordion2() {
    this.accordionOpen2 = !this.accordionOpen2;
		this.accordionOpen = false;
		this.accordionOpen3 = false;
  }

	toggleAccordion3() {
    this.accordionOpen3 = !this.accordionOpen3;
		this.accordionOpen2 = false;
		this.accordionOpen = false;
  }

	onChildSelected(childId: number) {
    const selectedChild = this.childrenList.find(child => child.idUser === childId);
    if (selectedChild) {
      this.selectedChildGroups = selectedChild.groupList;
    } else {
      this.selectedChildGroups = [];
    }
    this.selectedGroupId = null; // Reset the selected group
		//ocultamos todos los informes
		this.stepGuide = 1;
		this.dataSource.data = []
		this.dataTable = [];
		this.showFullscreen = false;
		this.verBotonG2 = 0;
		const div = document.getElementById("infoTable");
		div.style.display = "block";
		const div2 = document.getElementById("myBarChart");
		div2.style.display = "none";
		localStorage.setItem("challengeOn", "true");
		this.showMessage = true;
		this.challengeOn = JSON.parse(localStorage.getItem("challengeOn"));
		//-----
  }
	onGroupSelected(groupId: number) {
		//cada vez que seleccionemos un grupo nuevo, recargamos el listado de proyectos
		this.selectedGroupId = groupId;
  	this.getMasterDataCourses();
  }

}
