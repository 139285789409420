<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="row gap-2 align-items-center">
			<!-- TITULO DEL GRUPO -->
			<div class="col-12 col-md-5 modal-title">
				<h2>{{ "GRUPOS.MISGRUPOS" | translate }}</h2>
			</div>
			<!-- BOTONES -->
			<div class="col-12 col-md-6">
				<div class="row justify-content-end gap-3">
					<!-- SALIR DEL GRUPO -->
					<div *ngIf="storedGroupInfo" class="d-flex align-items-center justify-content-center">
						<button nbButton class="btn-red" size="small" (click)="noGroup()">
							<ng-container *ngIf="profile === 'ESTUDIANTE'">{{ 'MODALGROUPSEDIT.SELFSTUDY' | translate }}</ng-container>
							<ng-container *ngIf="profile === 'PROFESOR'">{{ 'MODALGROUPSEDIT.SELFEDUCATE' | translate }}</ng-container>
						</button>
					</div>
					<!-- BUSCAR GRUPO -->
					<div *ngIf="profile === studentProfile" class="d-flex align-items-center justify-content-center">
						<div class="dropdown">
							<button nbButton class="btn-themecolor" size="small" (click)="openSearchGroups()">
								{{ "MODALGROUPSEDIT.SEARCHGROUPS" | translate }}
							</button>
						</div>
					</div>
					<!-- NOTIFICACIONES -->
					<div *ngIf="profile === studentProfile" class="d-flex align-items-center justify-content-center">
						<div class="dropdown">
							<div *ngIf="numberOfInvited >= 1; else disabledInviteButton">
								<button nbButton class="btn-themecolor" size="small" [nbPopover]="notificationsTemplate" nbPopoverPlacement="bottom">
									<span class="font-weight-bold">{{ "MODALGROUPSEDIT.INVITATIONS" | translate }} </span>
									<span class="badge background-icon-action-red ml-2">{{ numberOfInvited }}</span>
								</button>
							</div>

							<ng-template #disabledInviteButton>
								<button nbButton class="btn-themecolor" size="small" [nbPopover]="notificationsTemplate" nbPopoverPlacement="bottom"
									disabled>
									<span class="font-weight-bold">{{ "MODALGROUPSEDIT.INVITATIONS" | translate }}</span>
									<span class="badge background-icon-action-red ml-2">{{numberOfInvited}}</span>
								</button>
							</ng-template>

							<ng-template #notificationsTemplate>
								<nb-card class="no-edge-card mb-0">
									<nb-list *ngIf="numberOfInvited >= 1; else noInvitations">
										<nb-list-item class="border-bottom-0 border-top-0" *ngFor="let item of invitacionesList">
											<span class="font-weight-bold mr-1">
												{{ item.groupTitle }}
											</span>
											<div class="ml-1">
												<nb-icon class="mr-1 icon-action-blue" icon="checkmark-circle-2" style="color: green;"
													[options]="{ animation: { type: 'pulse' } }" (click)="aceptar(item)"></nb-icon>
												<nb-icon class="mr-1 icon-action-red" icon="close-circle"
													[options]="{ animation: { type: 'pulse' } }" (click)="rechazar(item)"></nb-icon>
											</div>
										</nb-list-item>
									</nb-list>
									<ng-template #noInvitations>
										<div class="alert alert-info" role="alert">
											{{ "MODALGROUPSEDIT.NOINVITATIONSYET" | translate }}
										</div>
									</ng-template>

								</nb-card>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"></nb-icon>
	</div>
	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>

		<nb-actions class="mt-3 not-show-desktop" size="medium" fullWidth>
			<nb-action icon="search" (click)="openSearchGroups()"></nb-action>
			<nb-action>
				<div *ngIf="profile === studentProfile" class="dropdown">
					<div *ngIf="numberOfInvited >= 1; else disabledInviteButton">
						<button nbButton class="btn-themecolor" [nbPopover]="notificationsTemplate" nbPopoverPlacement="bottom">
							<span class="font-weight-bold">{{ "MODALGROUPSEDIT.INVITATIONS" | translate }}</span>
							<span class="badge background-icon-action-red ml-2">{{ numberOfInvited }}</span>
						</button>
					</div>
					<ng-template #disabledInviteButton>
						<button nbButton class="btn-themecolor" [nbPopover]="notificationsTemplate" nbPopoverPlacement="bottom" disabled>
							<span class="font-weight-bold">{{ "MODALGROUPSEDIT.INVITATIONS" | translate }}</span>
							<span class="badge background-icon-action-red ml-2">{{ numberOfInvited }}</span>
						</button>
					</ng-template>

					<ng-template #notificationsTemplate>
						<nb-card class="no-edge-card mb-0">
							<nb-card-header>{{ "MODALGROUPSEDIT.INVITATIONS" | translate }}</nb-card-header>
							<nb-list *ngIf="numberOfInvited >= 1; else noInvitations">
								<nb-list-item *ngFor="let item of invitacionesList">
									<span class="font-weight-bold mr-1">
										{{ item.groupTitle }}
									</span>
									<div class="ml-1">
										<nb-icon class="mr-1 icon-action-blue" icon="checkmark-circle-2"
											[options]="{ animation: { type: 'pulse' } }" (click)="aceptar(item)"></nb-icon>
										<nb-icon class="mr-1 icon-action-red" icon="close-circle"
											[options]="{ animation: { type: 'pulse' } }" (click)="rechazar(item)"></nb-icon>
									</div>
								</nb-list-item>
							</nb-list>
							<ng-template #noInvitations>
								<div class="alert alert-info" role="alert">
									{{ "MODALGROUPSEDIT.NOINVITATIONSYET" | translate }}
								</div>
							</ng-template>
						</nb-card>
					</ng-template>
				</div>
			</nb-action>
		</nb-actions>
		<div class="d-flex flex-wrap gap-5 justify-content-center">
			<div *ngIf="gruposList.length === 0 && profile === studentProfile" class="d-flex justify-content-center">
				<div class="alert alert-info" role="alert">
					{{ "MODALGROUPSEDIT.NOTBELONGGROUP" | translate }}
				</div>
			</div>

			<div *ngFor="let grupo of gruposList | sortBy : 'asc' : 'tittle'" class="card-Mygroup">
				<nb-card class="pointer mb-0"
				[ngClass]="{
					'group-info': isProfesor,
					'group-info_student': !isProfesor,
					'border-body-select': idGrupoSelect === grupo.idGroup,
					'border-body': idGrupoSelect !== grupo.idGroup
				}">
					<nb-card-header class="p-0" [ngClass]="{'border-header-select2': idGrupoSelect === grupo.idGroup, 'border-header2': idGrupoSelect !== grupo.idGroup}">
						<div style="position: relative;" class="d-flex justify-content-center flex-wrap p-1">
							<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;" [text]="grupo.nivel + ' ' + grupo.letras"
							[style.background]="getNivelesColor(grupo.nivel)"></nb-tag>
						</div>
					</nb-card-header>
					<nb-card-header class="p-2 image-header" [ngClass]="{'border-header-select': idGrupoSelect === grupo.idGroup, 'border-header': idGrupoSelect !== grupo.idGroup}"
					 (click)="verDetalleGrupo(grupo)" [style.background-image]="'url(' + (grupo.imagen | imagen : 'group') + ')'" *ngIf="networkSpeed >= 1"
					 style="border-radius: 0%;" ></nb-card-header>
					 <nb-card-header class="p-2 image-header" [ngClass]="{'border-header-select': idGrupoSelect === grupo.idGroup, 'border-header': idGrupoSelect !== grupo.idGroup}"
					 (click)="verDetalleGrupo(grupo)" [style.background-image]="'url(../../../../assets/images/no-image.png)'" *ngIf="networkSpeed < 1"
					 style="border-radius: 0%;" ></nb-card-header>
					<nb-card-body class="p-2" [ngClass]="{'background-body-select': idGrupoSelect === grupo.idGroup, 'background-body': idGrupoSelect !== grupo.idGroup}"
						(click)="verDetalleGrupo(grupo)">
						<div>
							<p class="courseTittle" [ngClass]="isProfesor ? 'courseTittle_teacher' : 'courseTittle_student'">
								{{ grupo.title | titlecase | truncate : [50, "..."] }}
							</p>
							<p *ngIf="profile != studentProfile" class="m-0 pt-2 courseTittle">
								Código: {{ grupo.identification | truncate : [50, "..."] }}
							</p>
						</div>
					</nb-card-body>
					<nb-card-footer class="flex flex-row justify-content-end pt-0 pl-1 pr-1 pb-1" [ngClass]="{'background-footer-select': idGrupoSelect === grupo.idGroup, 'background-footer': idGrupoSelect !== grupo.idGroup}" *ngIf="profile === studentProfile">
						<mat-icon class="pointer color-red-text" nbTooltip="{{ 'MODALGROUPSEDIT.SALIRGRUPO' | translate }}" (click)="salirGrupo(grupo)">delete</mat-icon>
					</nb-card-footer>
					<nb-card-footer class="flex flex-column p-1" *ngIf="profile != studentProfile" [ngClass]="{'background-footer-select': idGrupoSelect === grupo.idGroup, 'background-footer': idGrupoSelect !== grupo.idGroup}">
						<div class="flex flex-wrap gap-2 w-100 align-items-center justify-content-center">
							<div class="cont-img" nbTooltip="{{ 'MODALGROUPSEDIT.ESTUDIANTESASIGNADOS' | translate }}">
								<span>{{ grupo.assignedStudents }}</span>
								<img src="../../../../assets/images/icons/EstudianteLineaBlanco.svg">
							</div>
							<div class="cont-img" nbTooltip="{{ 'MODALGROUPSEDIT.PROYECTOSASIGNADOS' | translate }}">
								<span>{{ grupo.assignedProjects }} </span>
								<img src="../../../../assets/images/icons/ProyectoComoMuchosGrafos.svg">
							</div>
							<div class="cont-img" nbTooltip="{{ 'MODALGROUPSEDIT.DESAFIOSREALIZADOS' | translate }}">
								<span>{{ grupo.challengesRealized }}</span>
								<img src="../../../../assets/images/icons/desafios_icon.svg">
							</div>
							<div class="cont-img" style="cursor: pointer !important;" nbTooltip="{{ 'GRUPOS.AJUSTES' | translate }}" (click)="modificarGrupo(grupo)">
								<img src="../../../../assets/images/icons/AjustesLineaBlanco.svg">
							</div>
						</div>

						<div *ngIf="!isStudent" class="dropdownActions d-flex justify-content-center align-items-center">
							<div *ngIf="grupo.numberOfInvited >= 1; else disabledInviteButton">
								<div class="content-alert" [nbPopover]="notificationsActionsTemplate" nbPopoverPlacement="bottom">
									<nb-icon icon="bell"></nb-icon>
									<span class="badge background-icon-action-red">{{grupo.numberOfInvited}}</span>
								</div>
							</div>
							<ng-template #disabledInviteButton>
								<!-- <button nbButton class="btn-themecolor" [nbPopover]="notificationsActionsTemplate" nbPopoverPlacement="bottom" disabled>
																					<span class="font-weight-bold">{{'MODALGROUPSEDIT.INVITATIONS' | translate}} </span>  <span class="badge badge-danger ml-2">{{grupo.numberOfInvited}}</span>
																				</button> -->
							</ng-template>

							<ng-template #notificationsActionsTemplate>
								<nb-card class="no-edge-card mb-0">
									<nb-card-header class="p-2">{{ "MODALGROUPSEDIT.ESTUDIANTESASIGNADOS2" | translate }}</nb-card-header>
									<nb-list *ngIf="grupo.numberOfInvited >= 1; else noInvitationsActions">
										<nb-list-item class="margin-bottom-0" *ngFor="let item of grupo.invitacionesList">
											<span class="font-weight-bold mr-1">
												{{ item.firstName }}
											</span>
											<div class="ml-1">
												<nb-icon class="mr-1 icon-action-blue" icon="checkmark-circle-2"
													[options]="{ animation: { type: 'pulse' } }" (click)="aceptarTeacher(grupo.idGroup, item)"></nb-icon>
												<nb-icon class="mr-1 icon-action-red" icon="close-circle" [options]="{ animation: { type: 'pulse' } }"
													(click)="rechazarTeacher(grupo.idGroup, item)"></nb-icon>
											</div>
										</nb-list-item>
										<ng-template #noInvitationsActions>
											<div class="alert alert-info" role="alert">
												No tienes ninguna invitación
											</div>
										</ng-template>
									</nb-list>
								</nb-card>
							</ng-template>
						</div>
					</nb-card-footer>
				</nb-card>
			</div>
			<!-- ADD NEW GROUP -->
			<div *ngIf="profile !== studentProfile" class="d-flex card-Mygroup pointer"
				title="{{ 'GRUPOS.NUEVOGRUPO' | translate }}" (click)="nuevoGrupo()">
				<div class="card d-flex justify-content-center">
					<div class="img-container">
						<img src="../../../../../assets/images/new-item.png" class="img-fluid" />
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>

