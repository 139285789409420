import {
	devenvironment,
	localenvironmentdev,
	localenvironmentpro,
	prodenvironment,
	psycast,
} from "./environment-list";

export const environment = devenvironment;



