import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, forkJoin } from 'rxjs';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { QuizElement } from 'src/app/core/models/quizzes/quiz-element.model';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { UtilsService } from 'src/app/core/services/shared/utils.service';
import { QuizPromptEditComponent } from '../quiz-prompt-edit/quiz-prompt-edit.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
    selector: 'app-quiz-edit-body',
    templateUrl: './quiz-edit-body.component.html',
    styleUrls: ['./quiz-edit-body.component.scss']
})
export class QuizEditBodyComponent implements OnInit, OnChanges {
    @Input() quiz;
    @Input() elements: any[];
    @Input() courseId: number;
    @Input() graphId: number;

    @Output() quizEdited: EventEmitter<any> = new EventEmitter<any>();
    @Output() questionsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
		@Output() quizTypeChanged: EventEmitter<any> = new EventEmitter<any>();
		@Output() fromIA: EventEmitter<any> = new EventEmitter<any>();

		private destroy$ = new Subject();

    public questions: any[] = [];
    public options: any[] = [];
    public answers: any[] = [];

    private questionTypes: QuizElementTypes[] = [
        QuizElementTypes.Q_TEXTS,
        QuizElementTypes.Q_AUDIOS,
        QuizElementTypes.Q_PDFS,
        QuizElementTypes.Q_PICTURES,
        QuizElementTypes.Q_VIDEOS
    ];

    private optionTypes: QuizElementTypes[] = [
        QuizElementTypes.O_TEXTS,
        QuizElementTypes.O_AUDIOS,
        QuizElementTypes.O_PDFS,
        QuizElementTypes.O_PICTURES,
        QuizElementTypes.O_VIDEOS
    ];

    private answerTypes: QuizElementTypes[] = [
        QuizElementTypes.A_TEXTS,
        QuizElementTypes.A_AUDIOS,
        QuizElementTypes.A_PDFS,
        QuizElementTypes.A_PICTURES,
        QuizElementTypes.A_VIDEOS
    ];
    cont: number;
		currentQuiz: any;
		showButtons: boolean = false;
	  iaCorrectedSelected: boolean = false;
		teacherCorrectedSelected: boolean = false;
		showForm: boolean = false;
		activeIA: boolean = false;

	constructor(private quizzesService: QuizzesService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private utilsService: UtilsService,
		private modalService: NgbModal
	) { }

    ngOnInit() {
			//this.getQuizCurrent();
		}

		// getQuizCurrent(){
		// 	this.quizzesService.getCurrentQuiz().subscribe(	quiz => {
		// 		this.currentQuiz = quiz;
		// 		console.log('cambie el tipo ',this.currentQuiz);
		// 		if(this.currentQuiz.quizType != 0){
		// 			this.showForm = true;
		// 		}
		// 		if(this.currentQuiz.quizType == 4){
		// 			this.iaCorrectedSelected = true;
		// 			this.showButtons = true
		// 		}
		// 		if(this.currentQuiz.quizType == 1){
		// 			this.teacherCorrectedSelected = true;
		// 			this.showButtons = true
		// 		}
		// 	 });
		// }

		// ngAfterViewInit(): void {
		// 	//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		// 	//Add 'implements AfterViewInit' to the class.
		// 	this.quizzesService.setCurrentQuiz(this.quiz);
		// }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.elements && changes.elements.currentValue && changes.elements.currentValue.length > 0) {

            this.questions = changes.elements.currentValue.filter(
                e => this.questionTypes.includes(e.elementType)
            ).sort((a, b) => a.yPosition - b.yPosition);

            this.options = changes.elements.currentValue.filter(
                e => this.optionTypes.includes(e.elementType)
            ).sort((a, b) => a.yPosition - b.yPosition);

            this.answers = changes.elements.currentValue.filter(
                e => this.answerTypes.includes(e.elementType)
            ).sort((a, b) => a.yPosition - b.yPosition);
        }
    }

    public onSaveInfo(info: any) {
        if (info.title !== this.quiz.quizTittle || this.utilsService.timeToMinutes(info.duration) !== this.quiz.duration || info.instructions !== this.quiz.quizInstructions) {
            this.quiz.quizTittle = info.title;
            this.quiz.duration = this.utilsService.timeToMinutes(info.duration);
            this.quiz.quizInstructions = info.instructions;

            this.quizzesService.createQuiz(this.courseId, this.graphId, this.quiz).subscribe(
							res => {
                this.quizEdited.emit(this.quiz)

            },
            err => console.error(err)
            );
        }
    }

    public onSaveData(data: any) {
        const element: QuizElement = this.elementToQuizElement(data.element);
				let file: File;
				if(data.file){
					if (data.file.files != undefined) {
						file = data.file.files;
					} else if (data.file.file != undefined) {
						file = data.file.file;
					} else {
						file = data.file;
					}
				}

        this.quizzesService
					.createOrUpdateQuizElement(this.quiz.idQuiz, element, file)
					.subscribe(
						(res) => {
							const questionIndex = this.questions
								.map((q) => q.idQuizzesDataElements)
								.findIndex((q) => q === res.data.idQuizzesDataElements);
							if (questionIndex >= 0) {
								this.questions = [
									...this.questions.slice(0, questionIndex),
									res.data,
									...this.questions.slice(questionIndex + 1),
								].sort((a, b) => a.yPosition - b.yPosition);
							} else {
								this.questions = [...this.questions, res.data].sort(
									(a, b) => a.yPosition - b.yPosition
								);
							}
							this.questionsChange.emit(this.questions);
						},
						(err) => console.error(err)
					);
    }

    public onDeleteData(idElement: number) {
        const questionIndex = this.questions.findIndex(q => +q.idQuizzesDataElements === +idElement);

        this.quizzesService.deleteQuizElement(idElement).subscribe(
            res => {
                if (questionIndex >= 0) {
                    this.questions = [...this.questions.slice(0, questionIndex), ...this.questions.slice(questionIndex + 1)].sort((a, b) => a.yPosition - b.yPosition);
                }
                this.questionsChange.emit(this.questions)
            },
            err => {
                console.error(err);
            }
        );
    }
  /**
     * Verifica si el editor ha marcado una respuesta correcta para poder guardar las opciones en this.options[]
     * Si no se selecciona ninguna respuesta se marcara la primera opcion como correcta por defecto
     *
     */
   verResponseCheck() {
       this.cont = 0;
        for (let index = 0; index < this.options.length; index++) {
            const option = this.options[index] as QuizElement;
            if (option.responseCheck === 1) {
                this.cont++;
            }
        }
        if (this.cont > 0) {
            return true;
        } else {
            return false;
        }
    }

saveFirstCorrect(){
    if (this.options.length > 0) {
    this.options[0].responseCheck = 1;
    this.quizzesService.createOrUpdateQuizElement(this.quiz.idQuiz,  this.options[0], undefined).subscribe(
        res => {
            const optionIndex = this.options.findIndex(a => a.idQuizzesDataElements === res.data.idQuizzesDataElements);
            if (optionIndex >= 0) {
                this.options = [...this.options.slice(0, optionIndex), res.data, ...this.options.slice(optionIndex + 1)].sort((a, b) => a.yPosition - b.yPosition);
            } else {
                this.options = [...this.options, res.data].sort((a, b) => a.yPosition - b.yPosition);
            }
        },
        err => console.error(err)
    );
    this.toaster.success(this.translateService.instant('QUIZZES.MANDATORYOPTION'));
    }
}

    public onSaveOption(option: any) {

        let element: QuizElement = this.elementToQuizElement(option.element);
        if (this.quiz.quizType === 1) {
            element.idQuizzesDataElements = null;
        }
        if (element.responseCheck !== 1) {
            if (!this.verResponseCheck()) {
                this.saveFirstCorrect();
            }
            else if(this.cont === 1){
                if (element.yPosition === 1) {
                    element.responseCheck = 1;
                    this.toaster.success(this.translateService.instant('QUIZZES.MANDATORYOPTION'));
                }
                else {
                    this.options.filter((value:QuizElement) => {
                        if (value.idQuizzesDataElements ===  element.idQuizzesDataElements){
                            if (value.responseCheck !== element.responseCheck ) {
                                this.saveFirstCorrect()
                            }
                        }
                    })
                }

            }
        }


        this.quizzesService.createOrUpdateQuizElement(this.quiz.idQuiz, element, option.file).subscribe(
            res => {
                const optionIndex = this.options.findIndex(a => a.idQuizzesDataElements === res.data.idQuizzesDataElements);
                if (optionIndex >= 0) {
                    this.options = [...this.options.slice(0, optionIndex), res.data, ...this.options.slice(optionIndex + 1)].sort((a, b) => a.yPosition - b.yPosition);
                } else {
                    this.options = [...this.options, res.data].sort((a, b) => a.yPosition - b.yPosition);
                }
            },
            err => console.error(err)
        );
    }



        public onSaveOptions(options: []) {

            const elements: QuizElement[] = options.map(a => this.elementToQuizElement(a));

            let auxOptions = [...this.options];

                const httpCalls = [];

                for (let index = 0; index < elements.length; index++) {
                    const element = elements[index];
                    httpCalls.push(this.quizzesService.createOrUpdateQuizElement(this.quiz.idQuiz, element));
                }

            forkJoin(httpCalls).subscribe(
                (response: any[]) => {
                    response.forEach(res => {
                        const optionIndex = auxOptions.findIndex(a => +a.idQuizzesDataElements === +res.data.idQuizzesDataElements);
                        if (optionIndex >= 0) {
                            auxOptions = [...auxOptions.slice(0, optionIndex), res.data, ...auxOptions.slice(optionIndex + 1)].sort((a, b) => a.yPosition-b.yPosition);
                        } else {
                            auxOptions = [...auxOptions, res.data].sort((a, b) => a.yPosition-b.yPosition);
                        }
                    });
                    this.options = [...auxOptions];
                }
            );


        }




    public onDeleteOption(idOption: number) {
        const optionIndex = this.options.findIndex(q => +q.idQuizzesDataElements === +idOption);

        this.quizzesService.deleteQuizElement(idOption).subscribe(
            res => {
                if (optionIndex >= 0) {
                    this.options = [...this.options.slice(0, optionIndex), ...this.options.slice(optionIndex + 1)].sort((a, b) => a.yPosition - b.yPosition);
                }
            },
            err => {
                console.error(err);
            }
        );
    }

    //Si cambia el tipo de quiz unico, multile o manual, eliminar las opciones
    public onDeleteOptions() {

			this.quizzesService.quizOptions = [...this.options];
			this.quizzesService.onDeleteOptions();
			setTimeout(() => {
				this.options = [];
			}, 500);

    }

    public onSaveAnswer(answer: any) {
        const element: QuizElement = this.elementToQuizElement(answer.element);
        this.quizzesService.createOrUpdateQuizElement(this.quiz.idQuiz, element, answer.file).subscribe(
            res => {
                const answerIndex = this.answers.map(q => q.idQuizzesDataElements).findIndex(q => q === res.data.idQuizzesDataElements);
                if (answerIndex >= 0) {
                    this.answers = [...this.answers.slice(0, answerIndex), res.data, ...this.answers.slice(answerIndex + 1)].sort((a, b) => a.yPosition - b.yPosition);
                } else {
                    this.answers = [...this.answers, res.data].sort((a, b) => a.yPosition - b.yPosition);
                }
            },
            err => console.error(err)
        );
    }

    public onDeleteAnswer(idElement: number) {
        const answerIndex = this.answers.findIndex(q => q.idQuizzesDataElements === idElement);

        this.quizzesService.deleteQuizElement(idElement).subscribe(
            res => {
                if (answerIndex >= 0) {
                    this.answers = [...this.answers.slice(0, answerIndex), ...this.answers.slice(answerIndex + 1)].sort((a, b) => a.yPosition - b.yPosition);
                }
            },
            err => {
                console.error(err);
            }
        );
    }

    private elementToQuizElement(element: any): QuizElement {
        const quizElement: QuizElement = {
            idQuizzesDataElements: element.id,
            data: element.data,
            idQuiz: this.quiz.idQuiz,
            elementType: element.type,
            responseCheck: +element.isCorrect,
            xPosition: 0,
            xSize: element.width,
            yPosition: element.order,
            ySize: element.height
        };

        return quizElement;
    }

		public onQuizTypeSelected(value) {

			if (value === 1) {
				this.teacherCorrectedSelected = true
				this.iaCorrectedSelected = false
			}
			if (value === 4 && this.activeIA) {
				this.iaCorrectedSelected = true
				this.teacherCorrectedSelected = false
				this.quizTypeChanged.emit(value);
				const modalRef = this.modalService.open(QuizPromptEditComponent,
					{
						scrollable: true,
						windowClass: MODAL_DIALOG_TYPES.W60
					});

				modalRef.componentInstance.idCourse = this.courseId;
				modalRef.componentInstance.idTarget = this.graphId;
				modalRef.componentInstance.idQuiz = this.quiz.idQuiz;

				modalRef.result.then((result: boolean) => {
					if (result) {
						console.log('result', result);
					}
				});
			}
			// si no se ha escrito nada en la explicacion no se puede activar la correccion con IA
			if(value === 4 && !this.activeIA){
				this.toaster.error(this.translateService.instant('QUIZZES.EXPLICACIONREQUERIDA'));
			}
			else{
				this.quizTypeChanged.emit(value);
				this.fromIA.emit(this.iaCorrectedSelected);
			}
	}

	public activarIA(activarIA: boolean){
		this.activeIA = activarIA;
	}

}
