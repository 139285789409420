import { map, reduce } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import {
	AuthorModel,
	AuthorResponseModel,
	CenterModel,
	IdiomaModel,
	MateriaModel,
	NivelModel,
	PaisModel,
	RegionModel,
} from "../../models/masters";
import { PublishResponseModel } from "../../models/masters/publish.model";
import { TitleService } from "../shared";
import {
	CommunityModel,
	ResponseCommunityModel,
} from "../../models/masters/community.model";
import { ProjectTypeModel } from "../../models/masters/project-type.model";

const GETALLSUBJECTS = "commons/getDatosMaestrosSubjects";
const GETALLCOUNTRIES = "commons/getDatosMaestrosCountries";
const GETALLLEVELS = "commons/getDatosMaestrosSwlevels";
const GETCENTERS = "group/getDatosMaestrosCenters";
const GETLANGUAGES = "commons/getDatosMaestrosLanguage";
const GETCENTERBYID = "group/getCentersByIdCenter";
const GETACTIVECENTERS = "group/getCenterOnTeachersCenters";
const GETALLCOMMUNITIES = "group/comboprovinces";
const GETPROJECTSTYPES = "commons/getProjectType";

declare var $: any;

@Injectable({
	providedIn: "root",
})
export class GetDataService {
	private _appProjectType: ProjectTypeModel[] = [];
	private _appLanguages: IdiomaModel[] = [];
	private _appSubjects: MateriaModel[] = [];
	private _appCountries: PaisModel[] = [];
	private _appCenters: CenterModel[] = [];
	private _appLevels: NivelModel[] = [];
	private _appCommunities: CommunityModel[] = [];

	public get appLevels(): NivelModel[] {
		return this._appLevels;
	}
	public set appLevels(value: NivelModel[]) {
		this._appLevels = value;
	}

	public get appCenters(): CenterModel[] {
		return this._appCenters;
	}
	public set appCenters(value: CenterModel[]) {
		this._appCenters = value;
	}

	public get appCountries(): PaisModel[] {
		return this._appCountries;
	}
	public set appCountries(value: PaisModel[]) {
		this._appCountries = value;
	}

	public get appSubjects(): MateriaModel[] {
		return this._appSubjects;
	}
	public set appSubjects(value: MateriaModel[]) {
		this._appSubjects = value;
	}

	public get appProjectType(): ProjectTypeModel[] {
		return this._appProjectType;
	}
	public set appProjectType(value: ProjectTypeModel[]) {
		this._appProjectType = value;
	}

	public get appLanguages(): IdiomaModel[] {
		return this._appLanguages;
	}
	public set appLanguages(value: IdiomaModel[]) {
		this._appLanguages = value;
	}

	public get appCommunities(): CommunityModel[] {
		return this._appCommunities;
	}
	public set appCommunities(value: CommunityModel[]) {
		this._appCommunities = value;
	}

	constructor(
		private http: HttpClient,
		public translateService: TranslateService,
		public titleService: TitleService
	) {}

	/**
	 * Function that publishes or unpublishes an item type
	 * @param type Type of element: “course” – “target” – “node” – “quiz”
	 * @param id ID of the element
	 * @param value Value of the switch component: true/false
	 * @returns Boolean: True or false if successful
	 */
	setPublishType(
		type: string,
		id: number,
		value: boolean
	): Observable<PublishResponseModel> {
		let endpoint = `commons/published/${type}/${id}/${value}`;
		return this.http.get<PublishResponseModel>(endpoint);
	}

	getAllSubjects(): void {
		this.http
			.get<MateriaModel[]>(GETALLSUBJECTS)
			.subscribe((res) => (this.appSubjects = res));
	}

	getAllProjectsTypes(): void {
		this.http
			.get<ProjectTypeModel[]>(GETPROJECTSTYPES)
			.subscribe((res) => (this.appProjectType = res));
	}

	/**
	 * Function that return all countries in the app
	 * @returns
	 */
	getAllCountries(): void {
		this.http
			.get<PaisModel[]>(GETALLCOUNTRIES)
			.subscribe((res) => (this.appCountries = res));
	}

	/**
	 * Function that return all the centers in the app
	 * @returns
	 */
	getAllCenters(): Observable<CenterModel[]> {
		return this.http.get<CenterModel[]>(GETCENTERS);
	}

	/**
	 * Function that return the info of the center
	 * @param idCenter ID of the center (ex: 1)
	 * @returns Center detail
	 */

	getCenterById(idCenter: string): Observable<CenterModel> {
		let httpParams = new HttpParams().set("idCenter", idCenter);
		return this.http.get<CenterModel>(GETCENTERBYID, { params: httpParams });
	}

	/**
	 * Function that return all languages avilables in the app
	 * @returns
	 */
	getAllLanguages(): Observable<IdiomaModel[]> {
		return this.http.get<IdiomaModel[]>(GETLANGUAGES);
	}

	/**
	 * Function that return all levels in the app
	 * @returns
	 */
	getAllLevels(): void {
		this.http
			.get<NivelModel[]>(GETALLLEVELS)
			.subscribe((res) => (this.appLevels = res));
	}

	/**
	 * Función que devuelve el listado de comunidades de la app
	 */
	getAllCommunities(): void {
		this.http
			.get<ResponseCommunityModel>(GETALLCOMMUNITIES)
			.pipe(map((res) => res.data))
			.subscribe((res) => (this.appCommunities = res));
	}

	/**
	 * Function that return all the centers actives con teachers_centers table
	 * @returns array of centers
	 */

	getActiveCenters() {
		return this.http.get<any>(GETACTIVECENTERS);
	}

}
