import { Pipe, PipeTransform } from '@angular/core';
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'nodeCover'
})
export class NodeCoverPipe implements PipeTransform {
	transform(img: string): any {
		if (!img || img === 'null')
				return SIGMA_CONSTANTS.IMAGE_DEFAULT;

		let url = '';
		url += environment.nodeImages;

		return url += `${img}`
	}
}
