<nb-card class="mb-0">

	<nb-card-header>
		<h6 class="pl-4">{{ question.text }}</h6>
	</nb-card-header>

	<!--========== PREGUNTA TIPO 1 - ESCALA LIKERT ==========-->
	<nb-card-body class="p-2 d-flex justify-content-center align-items-center" *ngIf="question.type == 1">
		<div class="d-flex w-50 justify-content-between">
			<nb-radio-group [(ngModel)]="selectedOptionLikert" (ngModelChange)="emitSelectedResponse()"
				class="d-flex flex-row justify-content-between w-100">
				<nb-radio
					*ngFor="let option of question.options"
					[value]="option.idSurveyQuestionOption">
					{{ option.text }}
				</nb-radio>
			</nb-radio-group>
		</div>
	</nb-card-body>
	<!--========== PREGUNTA TIPO 2 - SELECCION UNICA ==========-->
	<nb-card-body class="p-2 d-flex justify-content-center align-items-center" *ngIf="question.type == 2">
		<div class="d-flex w-50 justify-content-between">
			<nb-radio-group [(ngModel)]="selectedOptionSingle" (ngModelChange)="emitSelectedResponse()"
				class="d-flex flex-column justify-content-between w-100">
				<nb-radio
					*ngFor="let option of question.options"
					[value]="option.idSurveyQuestionOption">
					{{ option.text }}
				</nb-radio>
			</nb-radio-group>
		</div>
	</nb-card-body>
	<!--========== PREGUNTA TIPO 3 - SELECCION MULTIPLE ==========-->
	<nb-card-body class="p-2 d-flex justify-content-center align-items-center" *ngIf="question.type == 3">
		<div class="d-flex flex-column w-50 justify-content-between">
			<div *ngFor="let option of question.options" class="d-flex align-items-center mb-2">
				<div>
					<nb-checkbox status="basic" [(ngModel)]="option.selected"
						(change)="emitSelectedResponse()">
						<label>{{ option.text }}</label>
					</nb-checkbox>
				</div>
			</div>
		</div>
	</nb-card-body>
	<!--========== PREGUNTA TIPO 4 - RESPUESTA ABIERTA ==========-->
	<nb-card-body class="p-2 d-flex justify-content-center align-items-center" *ngIf="question.type == 4">
		<div class="d-flex flex-column w-50 justify-content-between">
			<textarea rows="4" cols="50" [(ngModel)]="openResponse" (ngModelChange)="onOpenResponseChange($event)"></textarea>
		</div>
	</nb-card-body>

	<nb-card-footer *ngIf="question.required == 1" style="color: red;">
		*{{'ENCUESTAS.QUESTIONMANDATORY' | translate}}
	</nb-card-footer>
</nb-card>
