import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from 'src/app/core/models/courses';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UsersService } from 'src/app/core/services/users';

@Component({
	selector: 'app-invitar-padre',
	templateUrl: './invitar-padre.component.html',
	styleUrls: ['./invitar-padre.component.scss']
})

export class InvitarPadreComponent implements OnInit {
	@Output() action: EventEmitter<number> = new EventEmitter<number>();

  public formularioDatos: UntypedFormGroup;
	users: UserModel[] = [];
	soloMail: boolean = true;
	validationMessages = {
    mail: [],
  };

	constructor(
    private formBuild: UntypedFormBuilder,
		public userService: UsersService,
    private translateService: TranslateService,
		private toaster: ToasterService
	) {
		this.formularioDatos = this.formBuild.group({
			mail: [''],
		});
	}

	ngOnInit() {
		// Cargo las validaciones del formulario
    this.cargarValidacionesFormulario();
	}

	enviarInvitacion(){
		if (this.formularioDatos.invalid) {
      return;
    } else {
			const campos = this.formularioDatos.value;
			this.userService.invitationoutsidefather(campos.mail).subscribe(res => {
				if(res.error.msg === "PARENTS.INVITATIONSEND"){
					this.back();
					this.toaster.success(this.translateService.instant("MODALLISTSTUDENTS.INVITATIONSENT"));
				} else {
					this.toaster.error(this.translateService.instant("ERROR.HAOCURRIDOUNERROR"));
				}
			});
		}
	}

	back(){
		// mandamos el numero de la pantalla de nuevo padre
		this.formularioDatos = this.formBuild.group({
			mail: [''],
		});
		this.action.emit(1);
	}

	private cargarValidacionesFormulario() {
		this.translateService.get('VALIDACIONES.ELEMAILESOBLIGATORIO').subscribe((res: string) => {
			this.validationMessages.mail.push({type: 'required', message: res});
		});
  }

}
