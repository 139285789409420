import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-head-select-recipient',
  templateUrl: './head-select-recipient.component.html',
  styleUrls: ['./head-select-recipient.component.scss']
})
export class HeadSelectRecipientComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }
  @Input() idCurso:any;
  ngOnInit() {
  }

  closeModal(sendData?: any) {
    console.log("Cerrar modal HeadSelectRecipientComponent");
    console.log("idCurso : " +  this.idCurso);
    this.activeModal.close(sendData);
  }

}
