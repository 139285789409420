<nb-card class="mb-0 modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-11 modal-title">
			<h2>{{'MENUACTIONS.TEACHERTITLE10' | translate}}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div>
			<app-loading [isVisible]="cargando"></app-loading>
			<app-lista-padres *ngIf="screenView === 1" [profile]="profile" (action)="changeScreen($event)"></app-lista-padres>
			<app-nuevo-padre *ngIf="screenView === 2" [profile]="profile"
				(action)="changeScreen($event)"></app-nuevo-padre>
			<app-invitar-padre *ngIf="screenView === 3" (action)="changeScreen($event)"></app-invitar-padre>
		</div>
	</nb-card-body>
</nb-card>
