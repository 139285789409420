import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RegisterModel, RegisterNewUser, RegisterNickModel } from '../../models/users/register.model';
import { RequestSMSCode, VerifySMSCode } from '../../models/users/request-sms-code.model';
const REQUESTSMSCODE = 'user/mobileRequest'
const REQUESTMAILCODE = 'user/validationMessageByEmail'
const MOBILEVERIFIED = 'user/mobileVerify/{1}'
const MOBILEVERIFIEDBYMAIL = 'user/whatsAppVerify/{1}'
const NICKREGISTERUSER = 'user/nickregisteruser'
const SMSVERIFIED = 'user/resetpassword/mobileVerify'
const MOBILEREQUEST = 'user/mobileRequest'

@Injectable()
export class RegisterService {

    constructor(private http: HttpClient) { }

    checkEmailExists(email: string): Observable<boolean> {
        const url = `user/getExistMail?mail=${email}`;
        return this.http.get<boolean>(url).pipe(catchError(err => { console.error(err); return of(false) }));
    }

    registerUser(user: RegisterModel) {
        const url = `user/register-user`;
        return this.http.post<any>(url, user);
    }

    registerNewUser(user: RegisterNewUser) {
        const url = `user/register-user`;
        return this.http.post<any>(url, user);
    }

    mobileValidation(mobile: string, extension: string) {
        let body = {
            mobile: mobile,
            extension: extension
        }
        const url = `user/mobileValidation`;
        return this.http.post<any>(url, body);
    }

    mailValidation(mail: string) {
        let body = {
            mail: mail,
        }
        const url = `user/mailValidation`;
        return this.http.post<any>(url, body);
    }

		nickValidation(nick: string){
			let body = {
				nick: nick,
			}
			const url = `user/nickvalidation`;
			return this.http.post<any>(url, body)
		}

		nickSecurityQuestionsAnswerValidation(nick: string, idSecurityQuestions: number, securityAnswer: string, idSecurityQuestions2: number, securityAnswer2: string){
			let body = {
				nick: nick,
				idSecurityQuestions: idSecurityQuestions,
				securityAnswer: securityAnswer,
				idSecurityQuestions2: idSecurityQuestions2,
				securityAnswer2: securityAnswer2,
			}
			const url = `user/nickSecurityQuestionsAnswerValidation`;
			return this.http.post<any>(url, body)
		}

		getUserByNick(nick: string){
			let body = {
				nick: nick,
				idSecurityQuestions: 0,
				securityAnswer: 0,
				idSecurityQuestions2: 0,
				securityAnswer2: 0,
			}
			const url = `user/getUserByNick`;
			return this.http.post<any>(url, body)
		}


    /**
 * Función para llamar a la API para obtener un código por SMS para validar el registro del usuario
 * @param prefixPhone Prefijo del número telefónico para identificar al país
 * @param phoneNumber Número de teléfono del usuario
 * @returns Devuelve un objeto con la información si ha salido bien o mal
 */
    public requestSmsCode(prefixPhone: string, phoneNumber: number): Observable<RequestSMSCode> {
        let body = {
            mobile: phoneNumber,
            extension: prefixPhone
        }
        return this.http.post<RequestSMSCode>(REQUESTSMSCODE, body)
    }

    public requestMailCode(prefixPhone: string, phoneNumber: number, email: string): Observable<any> {
        let body = {
            mobile: phoneNumber,
            extension: prefixPhone,
            mail: email
        }
        return this.http.post<any>(REQUESTMAILCODE, body)
    }

    /**
     * Función para validar el código introducido por el usuario y darlo de alta
     * @param code Código de 6 dígitos (número) que se le ha enviado mediante un sms al móvil
     * @param user Objeto usuario con los campos para darlo de alta en la plataforma
     * @returns Devuelve un objeto con un code y un mensaje, dependiendo de si ha tenido o no éxito el registro
     */
    public mobileVerified(code: string, user: RegisterModel): Observable<VerifySMSCode> {
        return this.http.post<VerifySMSCode>(MOBILEVERIFIED.replace('{1}', code), user)
    }

    public mobileVerifiedByMail(code: string, user: RegisterModel): Observable<any> {
        return this.http.post<any>(MOBILEVERIFIEDBYMAIL.replace('{1}', code), user)
    }

		public nickRegisterUser(user: RegisterNickModel): Observable<any> {
			let body = {
				nick: user.nick,
				fatherEmail: user.fatherEmail,
				idLanguageIso: user.idLanguageIso,
				keyWord: user.keyWord,
				idSecurityQuestions: user.idSecurityQuestions,
				securityAnswer: user.securityAnswer,
				idSecurityQuestions2: user.idSecurityQuestions2,
				securityAnswer2: user.securityAnswer2,
				profileEditor: user.profileEditor,
				profileStudent: user.profileStudent,
				profileTeacher: user.profileTeacher,
				profileParent: user.profileParent
		}
		return this.http.post<any>(NICKREGISTERUSER, body)
	}

    public smsPinVerified(code: string, prefix: string, mobile: string): Observable<VerifySMSCode> {
        let body = {
            firstName: '',
            surname: '',
            birthdate: '',
            mail: '',
            keyWord: '',
            idGenre: '',
            idLanguageIso: '',
            mobile: mobile,
            extension: prefix
        }
        let url = SMSVERIFIED + '/' + code;
        return this.http.post<VerifySMSCode>(url, body)
    }

    mobileRequestSms(prefix: any, mobile: any) {
        let body = {
            firstName: '',
            surname: '',
            birthdate: '',
            mail: '',
            keyWord: '',
            idGenre: '',
            idLanguageIso: '',
            mobile: mobile,
            extension: prefix
        }
        return this.http.post<RequestSMSCode>(MOBILEREQUEST, body)
    }

		registrationErrorNotification(logRegistro: String[], success: boolean){
			let html = '<html><body><ul>';
			for (const log of logRegistro) {
					html += `<li>${log}</li>`;
			}
			html += '</ul></body></html>';
			const url = `user/registrationErrorNotification/${success}`;
			return this.http.post<any>(url, html)
		}
}
