<nb-card [nbSpinner]="loading == true" nbSpinnerStatus="danger" class="mb-0">
	<ng-container *ngIf="loading == true; else form">
		<nb-card-body>Generando el contenido con IA</nb-card-body>
	</ng-container>
	<ng-template #form>
		<nb-card-header class="border-bottom-0">
			<div class="col-11">
				<div>
					<h5>Ingresa un PDF con el tema para salwizar la informacion</h5>
				</div>
			</div>
		</nb-card-header>
		<div class="closeModal">
			<nb-icon class="m-1" icon="checkmark-circle-2"
				placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
			</nb-icon>
		</div>
		<nb-card-body>
			<div class="m-5 row d-flex">

				<div class="modal-body">
					<div *ngIf="salwizarForm">
						<form [formGroup]="salwizarForm" autocomplete="off">

							<div class="row justify-content-center">
								<div class="col-12">

									<div class="flex justify-content-between">
										<div class="col-6">
											<label for="textarea">indica un tema a explicar:</label>
											<input type="text" formControlName="topic" class="form-control" [disabled]="fileSelected == true">
										</div>
										<div class="col-6">
											<label for="textarea">o carga un archivo:</label>
											<input type="file" (change)="onFileSelected($event)" accept="application/pdf" [disabled]="salwizarForm.get('topic').value != ''" #inputFile>
											<button nbButton status="danger" (click)="clearFile()">Clear File</button>
										</div>
									</div>

								</div>
							</div>
							<div class="row justify-content-center">
								<div class="col-12">
									<label for="select1">Nivel Educativo:</label>
									<select formControlName="level" id="select1" class="form-control">
										<option value="Preescolar">Preescolar</option>
										<option value="Primaria">Primaria</option>
										<option value="Secundaria">Secundaria</option>
										<option value="Preparatoria">Preparatoria</option>
										<option value="Universidad">Universidad</option>
									</select>
								</div>
							</div>

							<div class="row justify-content-center">
								<div class="col-12">
									<label for="select2">Idioma:</label>
									<select formControlName="lang" id="select2" class="form-control">
										<option value="Español">Spanish</option>
										<option value="Ingles">English</option>
										<option value="Frances">French</option>

									</select>
								</div>
							</div>

							<!-- <div class="row justify-content-center">
								<div class="col-12">
									<label >Numero de palabras por idea:</label>
									<input type="number" formControlName="words" class="form-control">
								</div>
							</div> -->

							<!-- <div class="row justify-content-center">
								<div class="col-12">
									<label for="select2">Cantidad de ideas a generar:</label>
									<select formControlName="cant" id="select3" class="form-control">
										<option value="5">5</option>
										<option value="10">10</option>
										<option value="15">15</option>

									</select>
								</div>
							</div> -->

							<div class="row justify-content-center">
								<div class="col-12">
									<label for="textarea">Instrucciones adicionales:</label>
									<textarea formControlName="extra" id="textarea" class="form-control"></textarea>
								</div>
							</div>

						</form>

					</div>
				</div>
			</div>
		</nb-card-body>
		<nb-card-footer class="border-top-0">
			<div class="mb-2 row justify-content-center">
				<div class="col-6 d-flex justify-content-center">
					<button nbButton status="primary" (click)="generateProposals()">Mostrar Propuestas</button>
				</div>
			</div>
		</nb-card-footer>
	</ng-template>


</nb-card>
