<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
			placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-header>
	</nb-card-header>
	<nb-card-body class="p-0">
		<div *ngIf="survey">
			<app-loading [isVisible]="cargando"></app-loading>

			<nb-card class="no-edge-card background_themecolor mb-0">
				<nb-card-body>
					<div class="col-12 col-lg-7 my-4 my-lg-0">
						<nb-card class="mb-0 background_themecolor pointer no-edge-card ">
							<nb-card-header style="padding: 0;">
								<div class="form-group">
									<h6>{{ survey.title }}</h6>
								</div>
							</nb-card-header>
							<nb-card-body style="padding: 0;">
								<div class="form-group">
									<p>{{ survey.description }}</p>
								</div>
							</nb-card-body>
						</nb-card>
					</div>
				</nb-card-body>
			</nb-card>

			<nb-card class="no-edge-card">
				<nb-card-body>
					<div class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
						<div class="col-12">
							<app-loading [isVisible]="cargando"></app-loading>
							<!-- TARJETAS PREGUNTAS -->
							<div *ngIf="survey.questionsList.length !== 0">
								<div class="d-flex flex-column gap-5 justify-content-center">
									<div *ngFor="let question of survey.questionsList">
										<app-surveys-question-answer
											[question]="question"
											(responseSelected)="onResponseSelected($event)">
										</app-surveys-question-answer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</nb-card-body>
				<nb-card-footer class="d-flex justify-content-center align-items-center">
					<button nbButton status="primary">{{ 'ENCUESTAS.SENDANSWER' | translate }}</button>
				</nb-card-footer>
			</nb-card>
		</div>
	</nb-card-body>
</nb-card>
