
<div class="sticky-top sticky-top-header">
    <div class="modal-header mr-4 ml-4 mt-4" align="center">
        <div>
            <span class="textoFontSize1 colorTextoActivadoCursoGrafo font-weight-bold"></span>
        </div>
        <div>
            <span class="textoFontSize1  font-weight-bold">{{'MODALQUIZMULTIPLE.LIST' | translate}}</span>
        </div>
        <div>
            <button type="button" class="close" data-dismiss="modal" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="container">
    <div class="row p-0">
        <div class="col-12">

            <div class="list-group m-2">
                <a *ngFor="let quiz of quizesMultiplesList"  class="list-group-item list-group-item-action pointer"
                [ngStyle]="{'color':'black','background-color':quiz.isChecked === 1? quiz.color:'#61a1e9' ,'text-align':'center', 'font-weight':'500'}">
                    <div *ngIf="quiz.isChecked === 0"  class="isNotChecked d-flex justify-content-center align-items-center">
                        <mat-icon svgIcon="inte"  (click)="openQuizMultipleHijo(quiz)"></mat-icon>
                    </div>
                    <div *ngIf="quiz.isChecked === 1"  class="isChecked">
                      <a class="text-dark font-weight-bold pointer-event"  (click)="openQuizMultipleHijo(quiz)" >
                        <span>{{quiz.texto}}</span>
                      </a>
                    </div>
                </a>
              </div>
        </div>
    </div>
</div>
