import { Injectable } from "@angular/core";
import { QuizzesService } from "../quizzes/quizzes.service";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SnapshotService {
    private previewBlob = new Subject();
    public previewBlob$ = this.previewBlob.asObservable();

    constructor(
        public quizService: QuizzesService,
    ) { }

    public snapshotName: string = "sample_snapshot";
    public userImageType: string = "JPG";
    public imageTypes = [
        "JPG",
        "PNG",
        "BMP",
        "TIFF",
        "GIF",
        "PPM",
        "PGM",
        "PBM",
        "PNM",
        "WebP",
        "HEIF",
        "BPG",
        "ECW",
        "FITS",
        "FLIP",
        "PAM",
        "CD5",
        "CPT",
        "PSD",
        "PSP",
        "XCF",
        "PDN",
    ];

    public takeSnapshot(element: any):void {

        try {

            const canvasElement = <HTMLCanvasElement>(
                document.createElement("CANVAS")
            );
            const imagen = element.nativeElement;
            const context = canvasElement.getContext("2d");
            let w: number, h: number, ratio: number;
            imagen.width = 650;
            imagen.height = 500;
            ratio = imagen.width / imagen.height;
            w = imagen.width - 100;
            h = w / ratio;
            canvasElement.width = w;
            canvasElement.height = h;
            context.fillRect(0, 0, w, h);
            context.drawImage(imagen, 0, 0, w, h);
            const link = document.createElement('a');
            this.snapshotName = this.snapshotName !== '' ? this.snapshotName : 'snapshot';
            this.userImageType = this.imageTypes.indexOf(this.userImageType.toUpperCase()) >= 0 ? this.userImageType.toUpperCase() : 'PNG';
            link.setAttribute('download', this.snapshotName + '.' + this.userImageType);
            const dataURL = canvasElement.toDataURL();
            this.quizService.setCurrenImagen(dataURL);
            const base64 = dataURL.split(";base64,")[1];
            const imageName = "archivo" + Date.now() + ".png";
            const imageBlob = this.dataURItoBlob(base64);
            // this.download(imageBlob);
            const imageFile = new File([imageBlob], imageName, {
                type: "image/png",
            });


            console.log("Call Service")
            let formData = new FormData();
            formData.append("file", imageFile, imageFile.name);

        } catch (error) {
            console.error("takeSnapshot", error)
        }


    }

    private dataURItoBlob(dataURI):Blob|null {

        try {
            const byteString = window.atob(dataURI);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const int8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                int8Array[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([int8Array], { type: "image/png" });
            return blob;

        } catch (error) {
            console.error("dataURItoBlob", error)
            return null
        }
    }

    public processSnapshotVideo(result, type):void {

        try {

            var blob = new Blob([result], { type: type });
            var url = URL.createObjectURL(blob);
            var video: any = document.createElement("video");
            var timeupdate = () => {
                if (this.takeSnapshotVideo(video)) {
                    video.removeEventListener("timeupdate", timeupdate);
                    video.pause();
                }
            };
            video.addEventListener("loadeddata", () => {
                if (this.takeSnapshotVideo(video)) {
                    video.removeEventListener("timeupdate", timeupdate);
                }
            });

            video.addEventListener("timeupdate", timeupdate);
            video.preload = "metadata";
            video.src = url;
            // Load video in Safari / IE11
            video.muted = true;
            video.playsInline = true;
            video.crossOrigin = "anonymous";
            video.play();

        } catch (error) {
            console.error("processSnapshotVideo", error)
        }


    }

    private takeSnapshotVideo(video:any):boolean {
        try {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
            setTimeout(() => {
                canvas.toBlob((blob) => {
                    this.previewBlob.next(blob);
                });
                return true;
            }, 500);

        } catch (error) {
            console.error("takeSnapshotVideo", error)
            return false
        }
    }
}
