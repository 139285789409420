<nb-card [nbSpinner]="loading == true" nbSpinnerStatus="danger" class="mb-0">
	<ng-container *ngIf="loading == true; else form">
		<nb-card-body>Generando los quizzes con IA</nb-card-body>
	</ng-container>
	<ng-template #form>
		<nb-card-header class="border-bottom-0">
			<div class="col-11">
				<div>
					<h5>Indica las instrucciones para el quizz</h5>
				</div>
			</div>
		</nb-card-header>
		<div class="closeModal">
			<nb-icon class="m-1" icon="checkmark-circle-2"
				placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
			</nb-icon>
		</div>
		<nb-card-body>
			<div class="m-5 row d-flex">

				<div class="modal-body">
					<div *ngIf="salwizarForm">
						<form [formGroup]="salwizarForm" autocomplete="off">

							<div class="row justify-content-center">
								<div class="col-12">
								</div>
							</div>
							<!-- <div class="row justify-content-center">
								<div class="col-12">
									<label for="select1">Nivel Educativo:</label>
									<select formControlName="level" id="select1" class="form-control">
										<option value="Preescolar">Preescolar</option>
										<option value="Primaria">Primaria</option>
										<option value="Secundaria">Secundaria</option>
										<option value="Preparatoria">Preparatoria</option>
										<option value="Universidad">Universidad</option>
									</select>
								</div>
							</div> -->

							<div class="row justify-content-center">
								<div class="col-12">
									<label for="select2">Idioma:</label>
									<select formControlName="lang" id="select2" class="form-control">
										<option value="Español">Spanish</option>
										<option value="Ingles">English</option>
										<option value="Frances">French</option>

									</select>
								</div>
							</div>

							<div class="row justify-content-center">
								<div class="col-12">
									<label >Numero actividades por idea:</label>
									<input type="number" formControlName="cant" min="1" max="3" class="form-control">
								</div>
							</div>

							<!-- <div class="row justify-content-center">
								<div class="col-12">
									<label >Cantidad opciones por actividad:</label>
									<input type="number" formControlName="options" min="2" max="4" class="form-control">
								</div>
							</div> -->

						</form>

					</div>
				</div>
			</div>
		</nb-card-body>
		<nb-card-footer class="border-top-0">
			<div class="mb-2 row justify-content-center">
				<div class="col-6 d-flex justify-content-center">
					<button nbButton status="primary" (click)="generateQuizzes()">Generar Quizzes</button>
				</div>
			</div>
		</nb-card-footer>
	</ng-template>


</nb-card>
