export const SIGMA_CONSTANTS = {
	NODE_SIZE: 30,
	QUIZ_SIZE: 28,
	STICKER_SIZE: 50,
	QUIZ_SMALL_SIZE: 5,
	NODE_TYPE: 'Node',
	QUIZ_TYPE: 'Quiz',
    QUIZ_NODE_TYPE: 'text',
	STICKER_TYPE: 'Sticker',
	TEXT_TYPE: 'Text',
	STICKER_NODETYPE: 'image',
	IMAGE_CLIP: 1,
	IMAGE_SCALE: 1.4,
	IMAGE_DEFAULT : 'assets/images/nodes/planificacion.svg',
	IMAGE_AUDIO_DEFAULT: 'assets/images/icons/volume_up.svg',
	IMAGE_PDF_DEFAULT: 'assets/images/icons/upload_file.svg',
	IMAGE_TEXT_DEFAULT: 'assets/images/icons/text_snippet.svg',
	QUIZ_TEXT_MAXLENGTH:80,
    STICKER_DEFAULT_SIZE: 80,
    WIDTH: 500,
    HEIGHT: 500,
	QUIZZES_QUIZZES: 'QuizzesQuizzes',
    QUIZZES_NODES: 'QuizzesNodes',
    NODES_NODES: 'NodesNodes',
	QUIZ_SMALL: 'small',
	QUIZ_LARGE: 'large',
    NODE_DEFAULT_SIZE: 8,
    NODE_DEFAULT_TYPE: 'image',
    NODE_TYPE_CIRCLE: 'circle',
    NODE_TEMP : 'temp',
    MAXRATIO : 10
}
export const enum MULTIPLEXEDQUIZZES{ ISMULTIPLEXED = 1, ISNOTMULTIPLEXED = 0}
