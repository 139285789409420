import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ModelQuizFile, ModelSubQuizFile, QuizModel } from 'src/app/core/models/quizzes';
import { MastersService } from 'src/app/core/services/masters';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { DialogService } from 'src/app/core/services/shared/dialog.service';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { QuizEditMultipleFooterComponent } from './components/quiz-edit-multiple-footer/quiz-edit-multiple-footer.component';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

class RespServer {
    data: boolean;
    error: ErrorRespuesta;
    status: number;
}
class ErrorRespuesta {
    code: number
    msg: string;
}

@Component({
    selector: 'app-quiz-edit',
    templateUrl: './quiz-edit-multiple.component.html',
    styleUrls: ['./quiz-edit-multiple.component.scss']
})
export class QuizEditMultipleComponent implements OnInit, OnDestroy {
    @ViewChild(QuizEditMultipleFooterComponent)
    private quizEditMultipleFooterComponent: QuizEditMultipleFooterComponent;

    @Input() courseId: number;
    @Input() graphId: number;
    @Input() currentGraph: any;
    @Input() quiz;
    @Input() quizzes;
    @Input() quizFiles: ModelQuizFile;
    @Input() elements;

    @Output() newQuiz = new EventEmitter<QuizModel>();

    template: any;
    isLoading: boolean = false;

    saveClicked: Subject<any> = new Subject<any>();
    saveClicked$: Observable<any> = this.saveClicked.asObservable();

	constructor(private activeModal: NgbActiveModal,
		private quizService: QuizzesService,
		private translateService: TranslateService,
		private dialog: MatDialog,
		private dialogService: DialogService,
		private mastersService: MastersService,
		private cdr: ChangeDetectorRef,
		private st: SigmaToolbarsService,
		private toaster: ToasterService) {
	}
	ngOnDestroy(): void {
		this.st.changeUpdateGraph(true)
	}

    /**
     * Function to detect changes in models
     */
    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    ngOnInit() {}

    updateFooter($event){
        this.quizzes = { ...$event };
        this.quizEditMultipleFooterComponent.updateTabs($event)
    }

    activeCrearTabs($event: boolean){
        this.quizEditMultipleFooterComponent.activeCrearTabs($event)
    }

    onQuizTypeChanged(type) {}

    changueQuizType(type) {
			this.quiz.quizType = type;
			this.quizService.createQuiz(this.courseId, this.graphId, this.quiz).pipe(finalize(() => this.isLoading = false)).subscribe(
					res => {
							// this.quiz = { ...res.data };
							this.quizService.currentQuiz.next(this.quiz);
							this.toaster.success(this.translateService.instant('QUIZFORMCOMPONENT.SAVED'));
					},
					err => {
							console.error(err);
							this.toaster.error(this.translateService.instant('QUIZFORMCOMPONENT.ERROR'));
					}
			);
    }

    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }

    save() {
        this.saveClicked.next(true);
    }

    onQuizEdited(quizzes) {
        this.quizzes = { ...quizzes };
        this.quizService.currentQuiz.next(this.quizzes);
    }

    onQuizSupEdited(quiz) {
        this.quiz = { ...quiz };
        this.quizService.currentQuiz.next(this.quiz);
    }

    updateArrayElements(element){
			for (let index = 0; index < this.quizzes.length; index++) {
					const quizMultiple = this.quizzes[index].quizMultiple as ModelSubQuizFile;
					if (quizMultiple.idQuizMultiple === element.idQuizMultiple) {
							this.quizzes[index].element.push(element.element);
							this.quizEditMultipleFooterComponent.updateElements(this.quizzes, index)
							break;
					}
			}
    }

    updateElementSize(elementDataUpdated){
        let salir = false;
        for (let index = 0; index < this.quizzes.length; index++) {
            let element:any = this.quizzes[index].element;
           for (let x = 0; x < element.length; x++) {
               let element_2 = element[x];
               if(element_2.idQuizMultiple == elementDataUpdated.idQuizMultiple
                &&
                element_2.elementType == elementDataUpdated.element.elementType
                )
               {
                element_2.xsize = elementDataUpdated.element.xsize;
                element_2.ysize = elementDataUpdated.element.ysize;
                this.quizEditMultipleFooterComponent.updateElementsData(element_2, index)
                salir = true;
                break;
               }
           }
           if(salir)
           {
               break
           };
        }
    }

    quizzAdded(quiz){
        quiz.element = new Array();
        this.quizzes.push(quiz);
        this.quizEditMultipleFooterComponent.updateTabs( this.quizzes)
    }

    async updateArrayDeleteElement(elementDelete){
        for (let index = 0; index < this.quizzes.length; index++) {
            let quizMultiple = this.quizzes[index].quizMultiple as ModelSubQuizFile;
            if (quizMultiple.idQuizMultiple === elementDelete.idQuizMultiple) {
                this.quizzes[index].element = await this.quizzes[index].element.filter( filterEelement =>{
                    if(filterEelement.idQuizzesDataElements !== elementDelete.idQuizzesDataElements)
                        return filterEelement;
                });
                 break;
            }
        }
    }

    saveTitle(title:string){}

    onPublishQuiz(currentValue:boolean):void{
		//Check the quiz instructions and the quiz data, to validate the publish or unpublish switch
			let okMessage:string = currentValue ? this.translateService.instant('GENERAL.OKPUBLISH') : this.translateService.instant('GENERAL.OKUNPUBLISH')
			let errorMessage:string = currentValue ? this.translateService.instant('GENERAL.KOPUBLISH') : this.translateService.instant('GENERAL.KOUNPUBLISH')
			this.quiz = {...this.quiz, published: currentValue ? Date.now(): null}
			this.quizService.currentQuiz.next(this.quiz);
			this.mastersService.setPublishType('quiz',Number(this.quiz.idOriginal),currentValue).subscribe(result => {
					if(result.data)
							this.toaster.success(okMessage)
					else
							this.toaster.error(this.translateService.instant('QUIZFORMCOMPONENT.KOPUBLISHCONTENT'))
			}, err => {
					this.toaster.error(errorMessage)
			})
    }

    deleteAllQuizes(){
			this.quizService.deleteAllSubQuizesMultiples(this.quiz.idQuiz).subscribe(res => {
				let respuestaServer: RespServer = new RespServer();
				respuestaServer = JSON.parse(JSON.stringify(res));
				// console.log(respuestaServer)
				if (respuestaServer.data) {
						this.quizzes = [];
						this.quizEditMultipleFooterComponent.deleteAllQuizes();
						this.toaster.success(respuestaServer.error.msg);
				}
				else if(!respuestaServer.data) {
						respuestaServer.error.msg = this.translateService.instant('QUIZFORMCOMPONENT.KOPUBLISHCONTENT');
						this.toaster.success(respuestaServer.error.msg);
				}
			})
    }

}
