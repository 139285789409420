import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SurveyModel } from 'src/app/core/models/surveys/survey.model';

@Component({
  selector: 'app-surveys-answer',
  templateUrl: './surveys-answer.component.html',
  styleUrls: ['./surveys-answer.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SurveysAnswerComponent implements OnInit {
	survey: SurveyModel = null;

	constructor(
		public activeModal: NgbActiveModal,
	){}

	ngOnInit(): void{}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	onResponseSelected(response: any) {
		console.log('Respuesta seleccionada:', response);
	}

}
