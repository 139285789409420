<div class="pad-left-container" [ngClass]="isShow ? 'show-pad' : 'hide-pad'">

    <div class="pad-buttons-left d-flex justify-content-center flex-column">
        <!-- TOP CENTER BUTTON -->
        <!-- <div class="d-flex flex-column align-items-center justify-content-center position-relative">
            <label class="label top" *ngIf="isShowLabels">{{courseName}}</label>
            <div class="circle-button" (click)="emitshowModalCourse('gridList')" [ngbTooltip]="!isShowLabels ? courseName : ''">
                <mat-icon svgIcon="cursolibro" class="icon-color-blue"></mat-icon>
            </div>
        </div> -->

        <!-- LEFT AND RIGHT BUTTONS -->

        <div class="d-flex align-items-center justify-content-between">

                <div class="position-relative d-flex align-items-center mr-2">
                    <!-- <label class="label left" *ngIf="isShowLabels">{{'PADSLABELS.LIST' | translate}}</label>
                    <div class="" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.LIST' | translate) : ''" (click)="emitshowModalCourse('inlineList')">

												<nb-icon icon="list"></nb-icon>
                    </div> -->
                </div>
                <div class="position-relative d-flex align-items-center">
									<!-- <div class="flex" *ngIf="showDiscoverButton == true">
										<ng-container *ngIf="discoverModeOn == true; else discoverMode">
											<div>
												<nb-icon icon="radio-button-off-outline" (click)="discoverModeSwitch()"></nb-icon>
											</div>

										</ng-container>
										<ng-template #discoverMode>
											<nb-icon icon="radio-button-on-outline" (click)="discoverModeSwitch()"></nb-icon>
										</ng-template>



									</div> -->
                    <div class="flex" (click)="emitshowModalCourse('inlineList')">
                        <!-- <mat-icon svgIcon="lista" class="icon-color-blue"></mat-icon> -->
												<nb-icon icon="list"></nb-icon>
                    </div>
                </div>

        </div>

        <!-- BOTTOM CENTER BUTTON -->
        <!-- <div class="d-flex justify-content-center flex-column align-items-center position-relative">
            <button class="circle-button" [ngbTooltip]="!isShowLabels ? ('PADSLABELS.AUTOMATIC' | translate) : ''" (click)="selectAtomatic()" disabled>
                <mat-icon class="icon-color-blue" color="primary"  svgIcon="automatic"></mat-icon>
            </button>
            <label class="label bottom" *ngIf="isShowLabels">{{'PADSLABELS.AUTOMATIC' | translate}}</label>
        </div> -->
    </div>
</div>

