import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { QuizModel } from 'src/app/core/models/quizzes';
import { MenuHamburger } from 'src/app/core/models/shared/menu-hamburger.model';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { DialogService } from 'src/app/core/services/shared/dialog.service';
import { ACTIONS } from 'src/app/core/utils/actions';

@Component({
		selector: 'app-recordar-quiz-play-multiple',
		templateUrl: './quiz-play-multiple.component.html',
		styleUrls: ['./quiz-play-multiple.component.scss'],
		encapsulation: ViewEncapsulation.None

})
export class RecordarQuizPlayMultipleComponent implements OnInit {
		@Input() quiz: any;
		@Input() elements: any[];
		@Input() courseId: number;
		@Input() graphId: number;
		@Input() respuestaEstudiante: any;

		private automatic: boolean = false;

		answered: boolean = false;

		private _newColor: string;

		menuItems: MenuHamburger [] = [
				{
						id: 1,
						icon : 'sports_esports_outlined',
						text: 'SIDEBARDERECHO.ACTIVARPADS',
						clickFunction : 'showHidePads'
				},
				{
						id: 2,
						icon : 'bocadillo',
						text: 'SIDEBARDERECHO.LABELS',
						clickFunction : 'showHideLabels'
				}
		]

		isShowPads:boolean = true;
		isShowLabels:boolean = false;
		menuPosition:any = {top: '12%', right:'3%'};

		discoverAnswer: Subject<boolean> = new Subject<boolean>();
		discoverAnswer$: Observable<boolean> = this.discoverAnswer.asObservable();

		evaluate: Subject<boolean> = new Subject<boolean>();
		evaluate$: Observable<boolean> = this.evaluate.asObservable();

		playNext: Subject<boolean> = new Subject<boolean>();
		playNext$: Observable<boolean> = this.playNext.asObservable();

		tryClose: Subject<boolean> = new Subject<boolean>();
		tryClose$: Observable<boolean> = this.tryClose.asObservable();
	listQuiz: any;

		constructor(private quizService: QuizzesService, private dialog: MatDialog, public activeModal: NgbActiveModal, private translateService: TranslateService, private dialogService: DialogService) { }

		ngOnInit() {
		}

		onDiscoverAnswer() {
				this.discoverAnswer.next(true);
		}

		onEvaluate(result: boolean) {
				this.evaluate.next(result);
		}

		onPlayNext() {
				this.playNext.next(true);
		}

		onAnswered() {
				this.answered = true;
		}

		onColorChanged(newColor: string) {
				this._newColor = newColor;
		}

		onClose(corregido) {
			this.activeModal.close(corregido);
		}

				/**
		 * Get action from hamburger menu
		 * @param value
		 */
		getActionFromMenu(value:string):void{
			switch(value){
				case ACTIONS.SHOWHIDEPADS:
					this.isShowPads = !this.isShowPads
					break
				case ACTIONS.SHOWHIDELABELS:
					this.isShowLabels = !this.isShowLabels
			}
		}

		getAction(value:string):void{
			switch(value){
				case ACTIONS.CLOSE:
					this.activeModal.close()
					break
				case ACTIONS.HELP:
					break
				case ACTIONS.CHECK:
					break
				case ACTIONS.IDEA:
					break
			}
		}

		getControllerAction(value:string):void{
			switch(value){
				case ACTIONS.UP:
					break
				case ACTIONS.DOWN:
					break
				case ACTIONS.BACK:
					break
				case ACTIONS.NEXT:
					this.openSiguienteQuiz();
					break
			}
		}

		openSiguienteQuiz() {
			console.log(this.listQuiz)

			// let index = this.listQuiz.indexOf(this.quiz.idQuiz);

			let indice = this.listQuiz.findIndex(quiz => quiz.idQuiz === this.quiz.idQuiz);
			// indice = indice+1;
			if (indice !== -1 && indice < this.listQuiz.length-1) {
				this.activeModal.close(indice+1);
			}
			else{
				this.activeModal.close(-1);

			}

			// console.log(indice)
		}
}
