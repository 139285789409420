import { NodeService } from "./../../../../core/services/node/node.service";
import { GruposModalClose } from "./../../../../core/models/groups/groups-model";
import { UsersService } from "../../../../core/services/users/users.service";
import { AuthorModel } from "../../../../core/models/masters/author-response.model";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FilterCourseModel, CourseModel } from "src/app/core/models/courses";
import { GroupModel } from "src/app/core/models/groups/groups-model";
import { TranslateService } from "@ngx-translate/core";
import { CoursesService } from "src/app/core/services/courses";
import { LoginService } from "src/app/core/services/login";
import { AlertService } from "src/app/core/services/shared";
import { GruposService } from "src/app/core/services/groups/grupos.service";
import { ModalEditarCursoComponent } from "src/app/shared/components/cursos/modal-editar-curso";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { startWith, map, finalize, debounceTime } from "rxjs/operators";
import { Observable } from "rxjs";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
	DynamicDatabase,
	DynamicDataSource,
	DynamicFlatNode,
} from "src/app/core/utils/treeView.utils";
import { SliceStringPipe } from "src/app/shared/pipes/slice-string.pipe";
import { SortByPipe } from "src/app/shared/pipes/sort-by.pipe";
import { Router } from "@angular/router";
import { ModalCursoDetailComponent } from "../../cursos/modal-curso-detail/modal-curso-detail.component";
import { ModalCursoEditDetailComponent } from "../../cursos/modal-curso-edit-detail/modal-curso-edit-detail.component";
import { QuizzesService } from "src/app/core/services/quizzes";
import { RecordarQuizPlayComponent } from "../../quiz-open/quiz-play/quiz-play.component";
import { RecordarQuizPlayMultipleComponent } from "../../quiz-open/quiz-play-multiple/quiz-play-multiple.component";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { MatomoAnalyticsUtils } from "src/app/core/utils/matomo-analytics.utils";
import { ToasterService } from "src/app/core/services/shared/toaster.service";

import { ModalCelebrationComponent } from "../../modal-celebration/modal-celebration.component";
import { Utils } from "src/app/core/utils/utils";
import { DEFAULTCLOSEPADS } from "src/app/core/models/masters/masters.enum";
import { environment } from "src/environments/environment";
import { CourseListModel } from "src/app/shared/components/cursos/modal-cursos-listado/interface/modal-cursos-listado";

const USERTYPE = {
	STUDENT: "estudiante",
	EDITOR: "editor",
};

const ELEMENTTYPE = {
	COURSE: "COURSE",
	GRAPH: "GRAPH",
	NODE: "NODE",
	QUIZ: "QUIZ",
};

const URLCELEBRATIONS: string = environment.celebrations;

@Component({
	selector: "app-modal-cursos-grupo",
	templateUrl: "./modal-cursos-grupo.component.html",
	styleUrls: ["./modal-cursos-grupo.component.scss"],
	providers: [SliceStringPipe],
	encapsulation: ViewEncapsulation.None,
})
export class ModalCursosGrupoComponent implements OnInit {
	public CERRARLISTADOCURSOS: number = GruposModalClose.CERRARLISTADOCURSOS;

	group: GroupModel;
	filtro: FilterCourseModel = new FilterCourseModel();
	cargando = false;
	authorControl: UntypedFormControl = new UntypedFormControl("");
	cursos: CourseModel[] = [];
	viewList: boolean;
	isMyCourses: UntypedFormControl = new UntypedFormControl(true);
	filteredOptions: Observable<AuthorModel[]>;
	authors: AuthorModel[] = [];
	public treeControl: FlatTreeControl<DynamicFlatNode>;
	public dataSource: DynamicDataSource;
	getLevel = (node: DynamicFlatNode) => node.level;
	hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;
	SEARCH_STRING: string = "|-|";
	isExpandable = (node: DynamicFlatNode) => node.expandable;
	asc = new SortByPipe();
	LIST_COURSE: string[] = [];
	public graphView: string = "gridList";
	options: any;
	public formulario: UntypedFormGroup;
	opcion: string;
	modoTraerNodo: any;
	modoTraerActividad: any;
	recordar = false;
	ModoAuto: any;
	ordenSeleccionado: number[] = [0];
	buscarNodo = false;
	public viewType = {
		nodes: false,
		quizzes: true,
	};
	isLoading: boolean;
	isGroup: boolean = true;

	viewQuiz: boolean = false;
	isShowFiles: boolean = true;
	isShowPads: boolean = false;
	isShowLabels: boolean = false;
	mouseInterval
	elements: any[] = [];
	idGraph: number = null;
	idSelectedCourse: number = null;
	quiz: any = null;
	node: any = null;
	answered: boolean = false;
	treeCourses: CourseListModel[] = [];

	constructor(
		public translateService: TranslateService,
		public coursesService: CoursesService,
		private groupService: GruposService,
		public loginService: LoginService,
		private alertService: AlertService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public database: DynamicDatabase,
		public router: Router,
		public userService: UsersService,
		private formBuild: UntypedFormBuilder,
		private quizService: QuizzesService,
		private ma: MatomoAnalyticsUtils,
		private utils: Utils,
		private nodeService: NodeService,
		private toaster: ToasterService
	) {
		this.formulario = this.formBuild.group({ filtrado: [""] });
		this.setupSearchListener();
	}
	ngOnInit(): void {
		// throw new Error('Method not implemented.');
		this.isMyCourses.patchValue(this.loginService.esAutor());
		this.getListadoCursos();
	}

	getListadoCursos() {
		this.groupService
			.getListCursos(this.group.idGroup)
			.subscribe((res: any) => {
				// console.log(res)
				// if (condition) {

				// }
				this.cursos = res.data;
			});
	}

	obtenerDatosMaestros() {
		this.filteredOptions = this.authorControl.valueChanges.pipe(
			startWith(""),
			map((value) => (typeof value === "string" ? value : value.name)),
			map((name) => this._filter(name))
		);
	}
	private _filter(name: string): AuthorModel[] {
		if (name) {
			this.isMyCourses.setValue(false);
			this.isMyCourses.disable();
			return this.authors.filter(
				(author) => author.name.toLowerCase().indexOf(name.toLowerCase()) === 0
			);
		} else {
			this.isMyCourses.enable();
			return this.authors.slice();
		}
	}

	filtrarCursos() {
		this.cargando = true;
		const filtradoValue = this.formulario.value;
		this.groupService
			.filtrarCursosBusqueda(
				this.group.idGroup,
				filtradoValue,
				this.group.idProfessor
			)
			.subscribe(
				(res: any) => {
					// console.log(res)
					this.cursos = res.data;
					this.cargando = false;
				},
				(err) => {
					console.log(err);
					this.cargando = false;
				}
			);
	}

	verDetalleCurso(idCurso) {
		if (this.recordar) {
			this.abrirListadoQuizes(idCurso);
		} else {
			this.opcion === "editar"
				? this.openCursoEditor(idCurso)
				: this.openCursoEstudiante(idCurso);
		}
	}

	abrirListadoQuizes(idCurso) {
		this.coursesService.recordarQuizesListado(idCurso).subscribe((res: any) => {
			// console.log(res)

			if (res.data.length > 0) {
				if (res.data.length < 20) {
					this.toaster.success(
						this.translateService.instant("CURSOS.ERROR1")
					);
				} else {
					this.ModoAuto = res.data;
					let indice = 0;
					this.openModalModeAuto(indice);
				}
			} else {
				this.toaster.success(
					this.translateService.instant("CURSOS.ERROR1")
				);
			}
		});
	}

	openModalModeAuto(indice) {
		// console.log(indice);

		if (indice < this.ModoAuto.length - 1) {
			if (
				this.ModoAuto[indice].idQuiz !== this.ModoAuto[indice].idQuizOriginal
			) {
				this.quizService
					.getQuizMultipleCanvasQuizMultiple(
						this.ModoAuto[indice].idQuiz,
						this.ModoAuto[indice].idCourse,
						this.ModoAuto[indice].idTarget
					)
					.pipe(finalize(() => (this.isLoading = false)))
					.subscribe((res: any) => {
						const modalRef = this.modalService.open(
							RecordarQuizPlayMultipleComponent,
							{
								scrollable: true,
								windowClass: MODAL_DIALOG_TYPES.W100,
								backdrop: "static",
							}
						);
						modalRef.componentInstance.quiz = {
							...res.quiz,
							user: res.quiz.user,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							originalX: res.quiz.originalX,
							originalY: res.quiz.originalY,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							x: res.quiz.x,
							y: res.quiz.y,
						};
						modalRef.componentInstance.elements = res.elements;
						modalRef.componentInstance.listQuiz = this.ModoAuto;

						modalRef.result.then(
							(res) => {
								if (res < this.ModoAuto.length - 1) {
									this.openModalModeAuto(res);
								} else {
									this.toaster.success(
										this.translateService.instant("CURSOS.OKALLQUIZZES")
									);
								}
							},
							(err) => {
								this.toaster.success(
									this.translateService.instant("CURSOS.OKALLQUIZZES")
								);
							}
						);
					});
			} else {
				this.quizService
					.getQuiz(
						this.ModoAuto[indice].idQuiz,
						this.ModoAuto[indice].idCourse,
						this.ModoAuto[indice].idTarget
					)
					.pipe(finalize(() => (this.isLoading = false)))
					.subscribe((res: any) => {
						const modalRef = this.modalService.open(RecordarQuizPlayComponent, {
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W100,
							backdrop: "static",
						});
						modalRef.componentInstance.quiz = {
							...res.quiz,
							user: res.quiz.user,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							originalX: res.quiz.originalX,
							originalY: res.quiz.originalY,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							x: res.quiz.x,
							y: res.quiz.y,
						};
						modalRef.componentInstance.elements = res.elements;
						modalRef.componentInstance.listQuiz = this.ModoAuto;

						modalRef.result.then(
							(res) => {
								if (res < this.ModoAuto.length - 1) {
									this.openModalModeAuto(res);
								} else {
									this.toaster.success(
										this.translateService.instant("CURSOS.OKALLQUIZZES")
									);
								}
							},
							(err) => {
								this.toaster.success(
									this.translateService.instant("CURSOS.OKALLQUIZZES")
								);
							}
						);
					});
			}
		} else {
			this.toaster.success(
				this.translateService.instant("CURSOS.OKALLQUIZZES")
			);
		}

		// this.scUtils.openModalModeAuto(this.ModoAuto, this.ordenSeleccionado, this.viewType);
	}

	openCursoEstudiante(idCurso: any) {
		this.ma.event("click", "view_item", "Curso");

		const modalRef = this.modalService.open(ModalCursoDetailComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`
		});

		modalRef.componentInstance.id = idCurso;
		modalRef.componentInstance.modoTraerNodo = this.modoTraerNodo;
		modalRef.componentInstance.modoTraerActividad = this.modoTraerActividad;
		modalRef.componentInstance.idGroup = this.group.idGroup;

		modalRef.result.then(
			(result) => {
				switch (result) {
					case "Curso borrado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso modificado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Abrir mapa":
						// Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
						this.closeModal(result);
						break;
					case "Editar nodos mapa":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.closeModal(result);
						break;
				}

				// Refresco el listado
				this.getListadoCursos();
			},
			(reason) => { }
		);
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}
	openCursoEditor(idCurso: number) {
		const modalRef = this.modalService.open(ModalCursoEditDetailComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		modalRef.componentInstance.id = idCurso;
		modalRef.result.then(
			(result) => {
				switch (result) {
					case "Curso borrado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso modificado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Abrir mapa":
						// Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
						this.closeModal(result);
						break;
					case "Editar nodos mapa":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.closeModal(result);
						break;
				}

				// Refresco el listado
				this.filtrarCursos();
			},
			(reason) => { }
		);
	}

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	verGrafo() { }

	nuevoCurso() {
		const modalRef = this.modalService.open(ModalEditarCursoComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W100} ${MODAL_DIALOG_TYPES.w100NEW}`,
		});
		modalRef.componentInstance.clickSidebar = true;
		modalRef.componentInstance.id = "nuevo";
		modalRef.result.then(
			(result) => {
				this.filtrarCursos();
			},
			(reason) => { }
		);
	}

	setupSearchListener() {
    this.formulario.get('filtrado').valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.filtrarCursosLupa();
      });
  }

	checkValue() {
		const filtradoValue = this.formulario.value;
		this.coursesService
			.filtradoCursosNew(
				filtradoValue.filtrado,
				this.isMyCourses.value,
				USERTYPE.STUDENT
			)
			.subscribe(
				(res) => {
					// console.log(res);
					this.cursos = res;
					this.cargando = false;
				},
				(err) => {
					console.log(err);
					this.cargando = false;
				}
			);
		this.authorControl.setValue("");
		this.authorControl.disable();
	}

	////////////////////////////////////////////////////
	////////////

	changeView(list) {
		this.viewList = list;
		if (this.viewList) {
			this.isLoading = false;
			this.getDataToTree();
			this.cargando = this.treeCourses.length ? false : true;
		} else {
			this.getListadoCursos();
			this.cargando = this.cursos.length ? false : true;
		}
	}

	getDataToTree() {
		const filter: string = this.formulario.value.filtrado;
		const rol: string = this.loginService.esAutor()
			? USERTYPE.EDITOR
			: USERTYPE.STUDENT;
		if (!this.treeCourses.length)
			this.coursesService
				.coursesListMode(filter, rol, this.isMyCourses.value)
				.subscribe((result) => {
					this.treeCourses = result;
					this.cargando = false;
				});
	}

	obtenerDatosCurso(id) {
		this.cargando = true;
		const TREE = [];
		this.coursesService.getGraphsByIdGroupByIdCourse(this.group.idGroup, id).subscribe((response: any) => {
			// Ya que solo se puede poner arrays de strings
			//En los elementos de los hijos concatenamos el id del curso y el id del grafo,
			//Necesitaremos para movernos al grafo seleccionado.
			if (response.data.coursesTarget.length >= 1) {
				for (
					let index = 0;
					index < response.data.coursesTarget.length;
					index++
				) {
					TREE.push(
						response.data.coursesTarget[index].target.tittle +
						this.SEARCH_STRING +
						id +
						this.SEARCH_STRING +
						response.data.coursesTarget[index].target.idTarget
					);
				}
			}
		});
		this.cargando = false;
		return TREE;
	}

	filtrarCursosLupa() {
		this.cargando = true;
		const filtradoValue = this.formulario.value;

		if(filtradoValue.filtrado.length >= 3) {
			if (this.loginService.esAutor()) {
				this.coursesService
					.filtradoCursosGrupo(this.group.idGroup, filtradoValue)
					.subscribe(
						(res) => {
							// console.log(res);
							this.cursos = res;
							this.cargando = false;
						},
						(err) => {
							console.log(err);
							this.cargando = false;
						}
					);
			} else {
				//revisar el form y el check y enviarlo
				const myCourses = this.isMyCourses.value;
				this.coursesService
					.filtradoCursosGrupo(this.group.idGroup, filtradoValue)
					.subscribe(
						(res) => {
							// console.log(res);
							this.cursos = res;
							this.cargando = false;
						},
						(err) => {
							console.log(err);
							this.cargando = false;
						}
					);
			}
		} else if (filtradoValue.filtrado.length === 0) {
				const myCourses = this.isMyCourses.value;
				this.coursesService
					.filtradoCursosGrupo(this.group.idGroup, filtradoValue)
					.subscribe(
						(res) => {
							// console.log(res);
							this.cursos = res;
							this.cargando = false;
						},
						(err) => {
							console.log(err);
							this.cargando = false;
						}
					);
		}
	}

		//Función que recibe los valores del elemento seleccionado en el listado de los cursos
		clickElement(element: CourseListModel) {
			const isAuthor: boolean = this.loginService.esAutor();

			//De forma provisional, sólo las acciones se contemplan desde el rol de estudiante para poder visualizar los nodos y actividades
			if (!isAuthor) {
				this.node = this.quiz = this.idSelectedCourse = null;
				this.answered = false;

				switch (element.type) {
					case ELEMENTTYPE.COURSE:
						break;
					case ELEMENTTYPE.GRAPH:
						break;
					case ELEMENTTYPE.NODE:
						this.isLoading = true;
						this.idSelectedCourse = element.idCourse;
						this.nodeService
							.getNode(element.idNode, element.idCourse, element.idTarget)
							.pipe(finalize(() => (this.isLoading = false)))
							.subscribe((result) => {
								this.node = result.data[0];
							});
						break;
					case ELEMENTTYPE.QUIZ:
						this.isLoading = true;
						this.idSelectedCourse = element.idCourse;
						this.getQuiz(
							element.idQuiz,
							element.idCourse,
							element.idTarget,
							element.multi
						);
						break;
				}
			}
		}

		private getQuiz(
			idQuiz: number,
			idCourse: number,
			idTarget: number,
			isMultiplexed: boolean
		) {
			this.idGraph = idTarget;

			if (isMultiplexed) {
				this.quizService
					.getQuizMultipleCanvasQuizMultiple(idQuiz, idCourse, idTarget)
					.pipe(finalize(() => (this.isLoading = false)))
					.subscribe((res) => {
						this.quiz = res.quiz;
						this.elements = res.elements;
					});
			} else {
				this.quizService
					.getQuiz(idQuiz, idCourse, idTarget)
					.pipe(finalize(() => (this.isLoading = false)))
					.subscribe((res: any) => {
						this.quiz = {
							...res.quiz,
							user: res.quiz.user,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							originalX: res.quiz.originalX,
							originalY: res.quiz.originalY,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							x: res.quiz.x,
							y: res.quiz.y,
						};
						this.elements = res.elements;
					});
			}
		}

		//Función que recibe valores para crear un nuevo elemento y añadirlo a la colección
		// createNewElement(newElement: NEWELEMENTTYPES){}
		// createUpdateGraph(idCurso: number) {}
		// createUpdateNode(){}
		// createUpdateQuiz(){}
		// viewGraph(){}

		// FUNCIONES DE LOS COMPONENTES DE QUIZ Y NODO

		showFiles(value) {
			let interval: number = 1;
			if (value) clearInterval(this.mouseInterval);

			this.mouseInterval = setInterval(() => {
				interval++;
				if (interval === 4) {
					this.isShowFiles = false;
					this.isShowPads = false;
					this.isShowLabels = false;
					clearInterval(this.mouseInterval);
				}
			}, DEFAULTCLOSEPADS / 3);

			this.isShowFiles = true; //Change variable value

			//QUENTAL
			if (this.viewQuiz) {
				if (this.utils.padsStatus.showPadsQuiz) this.isShowPads = true;
				if (this.utils.labelsStatus.showLabelsQuiz) this.isShowLabels = true;
			} else {
				if (this.utils.padsStatus.showPadsNode) this.isShowPads = true;
				if (this.utils.labelsStatus.showLabelsNode) this.isShowLabels = true;
			}
		}

		onViewGif(result: boolean) {
			let url: string = "";

			//Si result es true, gif ok; si es false, gif KO
			const numRandon = this.getRandomInt(1, 40);
			const numRandonNeg = this.getRandomInt(1, 19);

			if (result) url = URLCELEBRATIONS + "/positive/image-" + numRandon + ".gif";
			else url = URLCELEBRATIONS + "/negative/image-" + numRandonNeg + ".gif";

			//Mostrar un modal con el gif y que se cierre en 3 segundos o lo haga el usuario
			const modalRef = this.modalService.open(ModalCelebrationComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W80 + " celebration-modal-window",
				backdropClass: "celebration-modal-backdrop",
			});

			modalRef.componentInstance.url = url;

			modalRef.result.then(
				(res) => {
					modalRef.close();
				},
				(err) => {}
			);

			setTimeout(() => {
				modalRef.close();
			}, 3000);
		}

		hidenGif(event) {}

		private getRandomInt(min, max) {
			return Math.floor(Math.random() * (max - min)) + min;
		}

		onAnswered() {
			this.answered = true;
		}
}
