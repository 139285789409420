<nb-card class="no-edge-card mb-0 modal-height-100-62">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-header class="flex justify-content-between px-5 align-items-center">
		<div class="d-flex gap-4">
			<!-- Primer Select (parametros) -->
			<nb-select class="SelectForm mr-3" hero size="small" placeholder="Selecciona un parámetro" [(ngModel)]="parametroID" (ngModelChange)="onParametroChange()">
				<nb-option class="SelectFormOpt" *ngFor="let parametro of parametros" [value]="parametro.id" [disabled]="parametro.id !== 1" [ngStyle]="{color: parametro.id !== 1 ? '#DDDDDD' : 'black'}">
					{{parametro.title}}
				</nb-option>
			</nb-select>
			<!-- Segundo Select (consultas), visible solo si se ha seleccionado un parametro -->
			<nb-select *ngIf="parametroID" class="SelectForm mr-3" hero size="small" placeholder="Selecciona tu consulta" [(ngModel)]="consultaID" (ngModelChange)="onConsultaChange()">
				<nb-option class="SelectFormOpt" *ngFor="let consulta of consultas" [value]="consulta.id" [disabled]="consulta.id > 2" [ngStyle]="{color: consulta.id > 2 ? '#DDDDDD' : 'black'}">
					{{consulta.title}}
				</nb-option>
			</nb-select>
		</div>
		<!-- Botones, visibles solo si se ha seleccionado una consulta -->
		<div class="d-flex gap-4" *ngIf="consultaID">
			<!-- BOTON LISTA -->
			<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;" nbTooltipPlacement="top">
				<div>
					<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
						[ngClass]="{ 'active-button': verBotonesGraficas == 1 }" (click)="verLista()">
						<nb-icon class="small-icon" icon="grid-outline"></nb-icon>
					</button>
				</div>
				<label class="label-header m-0">
					{{ 'CENTRO.LISTA' | translate }}
				</label>
			</div>
			<!-- BOTON BARRAS -->
			<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;" nbTooltipPlacement="top">
				<div>
					<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
						[ngClass]="{ 'active-button': verBotonesGraficas == 2 }" (click)="verBarras()">
						<nb-icon class="small-icon" icon="bar-chart-outline"></nb-icon>
					</button>
				</div>
				<label class="label-header m-0">
					{{ 'CENTRO.BARRAS' | translate }}
				</label>
			</div>
			<!-- BOTON SOLAR -->
			<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;" nbTooltipPlacement="top">
				<div>
					<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center disabled-button"
						[ngClass]="{ 'active-button': verBotonesGraficas == 3 }" (click)="verSolar()" disabled>
						<nb-icon class="small-icon" icon="radio-button-on-outline"></nb-icon>
					</button>
				</div>
				<label class="label-header m-0" style="color: gray;">
					{{ 'CENTRO.SOLAR' | translate }}
				</label>
			</div>
			<!-- BOTON RADIAL -->
			<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;" nbTooltipPlacement="top">
				<div>
					<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center disabled-button"
						[ngClass]="{ 'active-button': verBotonesGraficas == 4 }" (click)="verRadial()" disabled>
						<nb-icon class="small-icon" icon="sun-outline"></nb-icon>
					</button>
				</div>
				<label class="label-header m-0" style="color: gray;">
					{{ 'CENTRO.RADIAL' | translate }}
				</label>
			</div>
			<!-- BOTON DISPERSION -->
			<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;" nbTooltipPlacement="top">
				<div>
					<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center disabled-button"
						[ngClass]="{ 'active-button': verBotonesGraficas == 5 }" (click)="verDispersion()" disabled>
						<nb-icon class="small-icon" icon="activity-outline"></nb-icon>
					</button>
				</div>
				<label class="label-header m-0" style="color: gray;">
					{{ 'CENTRO.DISPERSION' | translate }}
				</label>
			</div>
		</div>
	</nb-card-header>
	<nb-card-body>
		<!-- LISTA DE TARJETAS -->
		<div id="lista" class="w-100 h-100 px-5" *ngIf="verBotonesGraficas == 1">
			<app-lista [consultaID]="consultaID"></app-lista>
		</div>
		<!-- GRAFICA DE BARRAS -->
		<div id="barras" class="w-100 h-100 px-5" *ngIf="verBotonesGraficas == 2">
			<app-barras [consultaID]="consultaID"></app-barras>
		</div>
	</nb-card-body>
</nb-card>
