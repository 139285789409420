
	<nb-card class="no-edge-card mb-0">
		<nb-card-header class="mb-4">
			<div class="row justify-content-between">
				<div class="col-12 col-md-6 modal-title">
					<h2 *ngIf="profile === 'ESTUDIANTE'">{{'PARENTS.MYPARENTS' | translate}}</h2>
					<h2 *ngIf="profile === 'PADRE'">{{'PARENTS.MYCHILDREN' | translate}}</h2>
				</div>

				<div class="col-12 col-md-3 d-flex justify-content-center align-items-center pt-md-0 pt-3" *ngIf="profile === 'ESTUDIANTE'">
					<div class="dropdown">
						<button nbButton class="btn-themecolor" (click)="invitarPadre()">
							{{'PARENTS.INVITE' | translate}}
						</button>
					</div>
				</div>

				<div class="col-12 col-md-3 d-flex justify-content-center align-items-center pt-md-0 pt-3">
					<div class="dropdown">
						<div *ngIf="numberOfInvited >= 1; else disabledInviteButton">
							<button nbButton class="btn-themecolor" [nbPopover]="notificationsTemplate" nbPopoverPlacement="bottom">
								<span class="font-weight-bold">{{'MODALGROUPSEDIT.INVITATIONS' | translate}}</span>
								<span class="badge badge-danger ml-2">{{numberOfInvited}}</span>
							</button>
						</div>
						<ng-template #disabledInviteButton>
							<button nbButton class="btn-themecolor" [nbPopover]="notificationsTemplate" nbPopoverPlacement="bottom"
								disabled>
								<span class="font-weight-bold">{{'MODALGROUPSEDIT.INVITATIONS' | translate}}</span>
								<span class="badge badge-danger ml-2">{{numberOfInvited}}</span>
							</button>
						</ng-template>
						<ng-template #notificationsTemplate>
							<nb-card class="margin-bottom-0 mb-0">
								<nb-list *ngIf="numberOfInvited >= 1; else noInvitations">
									<nb-list-item *ngFor="let item of invitacionesList">
										<span class="font-weight-bold mr-1"> {{item.firstName}}&nbsp;{{item.surname}}&nbsp; </span>
										<div class="ml-1">
											<nb-icon class="mr-1 icon-action-blue" icon="checkmark-circle-2"
												[options]="{ animation: { type: 'pulse' } }" (click)="aceptarInvitacion(item)"></nb-icon>
											<nb-icon class="mr-1 icon-action-red" icon="close-circle"
												[options]="{ animation: { type: 'pulse' } }" (click)="rechazarInvitacion(item)" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
											</nb-icon>
										</div>
									</nb-list-item>
								</nb-list>
								<ng-template #noInvitations>
									<div class="alert alert-info" role="alert">
										{{'MODALGROUPSEDIT.NOINVITATIONSYET' | translate}}
									</div>
								</ng-template>
							</nb-card>
						</ng-template>
					</div>
				</div>
			</div>
		</nb-card-header>
		<app-loading [isVisible]="cargando"></app-loading>
		<nb-card fullWidth class="no-edge-card group-main-card mb-0">
				<nb-card-body>
					<div>
						<p class="text-alert"
							*ngIf="!profilesOfUser.profileEditor && profile === 'ESTUDIANTE' && parentsList.length === 0">
							{{'PARENTS.ENABLEEDITOR' | translate}}
						</p>
						<div class="mt-4">
							<div class="row">
								<div *ngFor="let parent of parentsList | sortBy:'asc':'tittle'" class="col-lg-3 col-md-6 col-sm-6 pointer d-flex justify-content-center align-items-center">
									<nb-card size="small" class="pointer project-info w-100">
										<nb-card-header>
											<div class="img-container d-flex justify-content-center align-items-center">
												<img [src]="parent.pictureUser | imagen:'userAvatar'" class="card-img-top">
											</div>
										</nb-card-header>
										<nb-card-body>
											<div>
												<h6 class="paragraph m-0">{{ parent.firstName + " " + parent.surname | titlecase | truncate:[50, '...'] }}</h6>
											</div>
										</nb-card-body>
										<nb-card-footer class="flex flex-row justify-content-end pt-1 pl-1 pr-1 pb-0">
											<span title="{{'GLOBALS.DELETE' | translate}}" (click)="deleteParent(parent)" *ngIf="profile !== 'ESTUDIANTE'">
												<mat-icon class="pointer color-red-text">delete</mat-icon>
											</span>
										</nb-card-footer>
									</nb-card>
								</div>
								<!-- ADD NEW PARENT -->
								<div class="col-lg-3 col-md-6 col-sm-6 pointer d-flex justify-content-center align-items-center"
									title="{{'PARENTS.ADDPARENT' | translate}}" (click)="nuevoPadre()" *ngIf="profile === 'ESTUDIANTE' && parentsList.length <= 4">
									<div class="card form-group w-100">
										<div class="img-container d-flex justify-content-center align-items-center">
											<img src="../../../../../assets/images/new-item.png" class="card-img-top">
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6 col-sm-6 pointer d-flex justify-content-center align-items-center"
									title="{{'PARENTS.ADDCHILD' | translate}}" (click)="nuevoPadre()" *ngIf="profile === 'PADRE'">
									<div class="card form-group w-100">
										<div class="img-container d-flex justify-content-center align-items-center">
											<img src="../../../../../assets/images/new-item.png" class="card-img-top">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</nb-card-body>
		</nb-card>
	</nb-card>
