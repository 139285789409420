<div>
	<ng-template class="w-100" #step7>
		<div class="copilot-custom copilop-info" style="position: sticky;">
			<div class="row flex justify-content-center align-items-center">

				<div class="col-12 ">
					<h3 class="title-info">{{'QUIZEDITHEADER.PUBLISHQUIZ' | translate}}</h3>
				</div>
			</div>
			<div class="footer-btn">
				<div class="row  flex justify-content-center align-items-center">
					<div class="col-2">
						<button class="btn btn-block btn-themecolor btn-sm button-ok" (click)="done()"
							type="button">{{'QUIZEDITHEADER.OK' | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</div>

<div class="header-options">
	<div class="d-flex w-100 justify-content-center align-items-center p-3">
		<div class="col-1 d-flex justify-content-center">
			<button mat-icon-button (click)="showInfo()">
				<mat-icon>info_outline</mat-icon>
			</button>
		</div>
		<div class="col-3 d-flex justify-content-center">
			<mat-button-toggle-group [(ngModel)]="type" (change)="onQuizTypeSelected($event)">
				<mat-button-toggle [value]="quizType.TEXT" matTooltip="{{'QUIZZES.HEADER.RESPUESTAABIERTA' | translate}}"
					matTooltipClass="black-tooltip">
					<mat-icon>
						subject
					</mat-icon>
				</mat-button-toggle>

				<mat-button-toggle [value]="quizType.SINGLE" matTooltip="{{'QUIZZES.HEADER.OPCIONCORRECTA' | translate}}"
					matTooltipClass="black-tooltip">
					<mat-icon>
						radio_button_checked
					</mat-icon>
				</mat-button-toggle>

				<mat-button-toggle [value]="quizType.MULTIPLE" matTooltip="{{'QUIZZES.HEADER.VARIASOPCIONES' | translate}}"
					matTooltipClass="black-tooltip">
					<mat-icon>
						check_box
					</mat-icon>
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div class="col-8 d-flex justify-content-center align-items-center" copilot [copilot-template]="step7" [copilot-step]="7">
			<div class="d-flex align-items-center px-2">
				<form [formGroup]="form">
					<select formControlName="pattern" class="form-control" (change)="updateRepetition()">
						<option *ngFor="let item of repetitionList" [value]="item.pattern">{{item.descripcion}}</option>
					</select>
				</form>
			</div>

			<div class="px-2">
				<form [formGroup]="form">
					<mat-slide-toggle class="buttonPublicar" (click)="certifiedQuiz($event)"
						formControlName="certificadoQuiz">{{'SIGMACOMPONENT.CERTIFICAR' | translate}}</mat-slide-toggle>
				</form>
			</div>

			<div class="px-2">
				<form [formGroup]="form">
					<mat-slide-toggle formControlName="published" (click)="publish($event)">{{ form.get('published').value ?
						('EDITARCURSO.VISIBLE' | translate) : ('EDITARCURSO.NOVISIBLE' | translate) }}</mat-slide-toggle>
				</form>
			</div>

			<div class="px-2">
				<button class="" mat-icon-button ngbTooltip="{{'QUIZZES.DELETE' | translate }}" (click)="deleteQuiz()"
					*ngIf="loginService.esAutor()">
						<mat-icon class="pointer color-red-text icon-head" data-toggle="tooltip" data-placement="top">delete</mat-icon>
				</button>
			</div>

		</div>
	</div>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="onCloseModal()">
		</nb-icon>
	</div>
</div>
