import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NbAccordionModule, NbActionsModule, NbCardModule, NbFormFieldModule, NbIconModule, NbSelectModule, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActionsOfCenterComponent } from './actions-of-center/actions-of-center.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
	selector: "app-modal-centros",
	standalone: true,
	imports: [
		CommonModule,
		NbCardModule,
		NbIconModule,
		NbTooltipModule,
		NbSelectModule,
		NbActionsModule,
		NbFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		NbAccordionModule,
	],
	templateUrl: "./modal-centros.component.html",
	styleUrls: ["./modal-centros.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCentrosComponent {
	formulario: UntypedFormGroup;
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private formBuild: FormBuilder,
		public translateService: TranslateService
	) {
		//this.inactivityService.startInactivityTimer();
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.formulario = this.formBuild.group({ filtrado: [""] });
	}

	selectedOrder = 1;
	iconSelected = false;
	historyFilterActive: boolean;
	orderList = [
		{
			nombre: "A - Z",
			idOrder: 1,
		},
		{
			nombre: "Z - A",
			idOrder: 2,
		},
		{
			nombre: "Más recientes",
			idOrder: 3,
		},
		{
			nombre: "Más antiguos",
			idOrder: 4,
		},
		{
			nombre: "Editados Recientemente",
			idOrder: 5,
		},
	];

	comunidades = [
		{
			nombre: "Comunidad de Madrid",
			centros_educativos: [
				{
					nombre: "Colegio San Juan",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeQUkIKh6RRKXBIqm8RPJxjRmYoS2c_0b5Yg&s",
					tipo: "Privado",
					color: "#FC9A27",
				},
				{
					nombre: "Instituto Cervantes",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3_rvV-H1bksdUTRa2xn28R2rhcJ77S1on-A&s",
					tipo: "Público",
					color: "#FEE533",
				},
				{
					nombre: "Escuela Primaria El Prado",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYnzK-8JcGHt1HdxbGadx49GrHu7mzd0jlRw&s",
					tipo: "Público",
					color: "#FFFD38",
				},
				{
					nombre: "Colegio Santa María",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRDFlKNkjrHAGHnLxqz6ur7lTMtJOPoG7YPw&s",
					tipo: "Privado",
					color: "#D4FD35",
				},
				{
					nombre: "Liceo Velázquez",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR7g__LlwHAzLSCzZWSGHO7sXnjy6LB7m4qQ&s",
					tipo: "Privado",
					color: "#FC5C20",
				},
			],
		},
		{
			nombre: "Cataluña",
			centros_educativos: [
				{
					nombre: "Colegio La Merced",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHAf9uFGdraUw1lemhrC0z52yZJKKzefEcCg&s",
					tipo: "Privado",
					color: "#FFFD38",
				},
				{
					nombre: "Instituto Gaudí",
					imagen:
						"https://guia33.com/wp-content/uploads/cache/images/remote/www-guia33-com/guia33-cornella-academias-institut-gaudi-de-la-construccio-cornella-17194-3741999357.jpg",
					tipo: "Público",
					color: "#FEE533",
				},
				{
					nombre: "Escuela Montserrat",
					imagen:
						"https://www.fundaciocatalunya-lapedrera.com/sites/default/files/2019-11/LOGO%20ESCOLA%20MONTSERRAT.jpg",
					tipo: "Público",
					color: "#9CFD32",
				},
				{
					nombre: "Colegio San Pedro",
					imagen:
						"https://www.micole.net/imagenes/colegio/logo/20691/san-pedro_512.png?v=MjAyNC0wNS0yMiAwMjowMzoxMA==",
					tipo: "Privado",
					color: "#D4FD35",
				},
				{
					nombre: "Liceo Miró",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWIo_TH9XzV-_GpscFmmWpOMBgiVdYHlpMBw&s",
					tipo: "Privado",
					color: "#FC9A27",
				},
			],
		},
		{
			nombre: "Andalucía",
			centros_educativos: [
				{
					nombre: "Colegio Andalucía",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_cEcINrd5ZvSGC9ArPli5XaoRjKCfxYvJdw&s",
					tipo: "Público",
					color: "#FEE533",
				},
				{
					nombre: "Instituto Alhambra",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS2Mao6YgOJXUVFaV4HKDLv-kkg82x0Y_ieQ&s",
					tipo: "Público",
					color: "#FFFD38",
				},
				{
					nombre: "Escuela Sierra Nevada",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTez5rX518vxptLoDqjE1HhO690y3HzPQYxPA&s",
					tipo: "Privado",
					color: "#FC5C20",
				},
				{
					nombre: "Colegio Logopedas",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9sdPFxiBofBqPE1Ai_c6teYp-f8NPTXfSPA&s",
					tipo: "Privado",
					color: "#D4FD35",
				},
				{
					nombre: "Liceo Picasso",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVMwppjOJsDHQf4henPhmznnuOG5-X8jnt9g&s",
					tipo: "Privado",
					color: "#9CFD32",
				},
			],
		},
		{
			nombre: "Valencia",
			centros_educativos: [
				{
					nombre: "Colegio El Cid",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbpc7mVLcMysMjn-GnrxkzhOLk5kM1j0ryig&s",
					tipo: "Privado",
					color: "#FFFD38",
				},
				{
					nombre: "Instituto Sorolla",
					imagen:
						"https://portal.edu.gva.es/iessorolla/wp-content/uploads/sites/1247/2022/02/arquitectura_iessorolla_valencia19-1.jpg",
					tipo: "Público",
					color: "#FEE533",
				},
				{
					nombre: "Escuela Las Fallas",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFRC5k8XKMu6EsO_PqNOryFOdXET0PNoSoPA&s",
					tipo: "Público",
					color: "#FC9A27",
				},
				{
					nombre: "Colegio La Albufera",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRgktzAt1idkgQn7g0VakfQciK7DCXYqVHMA&s",
					tipo: "Privado",
					color: "#D4FD35",
				},
				{
					nombre: "Liceo San Vicente",
					imagen:
						"https://media.licdn.com/dms/image/C4D0BAQEUHvotN1KWmw/company-logo_200_200/0/1630560737576/colegio_san_vicente_ferrer_p_p_dominicos_logo?e=2147483647&v=beta&t=bTeArnDZl_aedeJ-Qa9H6yF3S1meB1W6wiHbMv3XLfk",
					tipo: "Privado",
					color: "#9CFD32",
				},
			],
		},
		{
			nombre: "Galicia",
			centros_educativos: [
				{
					nombre: "Colegio Rosalía de Castro",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9bLm_1RDFxeKtJmQBZ8SWxlfqyLXJqBg3QA&s",
					tipo: "Público",
					color: "#FFFD38",
				},
				{
					nombre: "Instituto Santiago Apóstol",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnUZjpH87IAV2TB7ggz2VJM2dl_sIDKMlbVw&s",
					tipo: "Público",
					color: "#FEE533",
				},
				{
					nombre: "Escuela A Coruña",
					imagen:
						"https://upload.wikimedia.org/wikipedia/commons/e/e9/Universidade_da_Coru%C3%B1a.png",
					tipo: "Privado",
					color: "#FC5C20",
				},
				{
					nombre: "Colegio Lugo",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOqT6Y7_0K_QD7lxK30NCJzW5C5K9uwm7vRg&s",
					tipo: "Privado",
					color: "#D4FD35",
				},
				{
					nombre: "Liceo Pontevedra",
					imagen:
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsHaejoQI5WMyTPKTG0qUk9BR4BuV7KWTIUQ&s",
					tipo: "Privado",
					color: "#FC9A27",
				},
			],
		},
	];

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	changeOrder(order: any) {
		console.log(order);
	}
	historyFilter() {}

	openFiltersPanel() {}
	closeFiltersPanel() {}

	modalActionsOfCenter() {
		this.modalService.open(ActionsOfCenterComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	}
}
