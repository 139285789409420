<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62">
	<nb-card-header class="border-bottom-0 p-0">
		<div class="d-flex background_white p-4">
			<h4>{{'ENCUESTAS.TITLE' | translate}}</h4>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<!-- VISTA TARJETAS -->
		<div *ngIf="!viewList" class="background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="d-flex justify-content-center w-100">
				<app-loading [isVisible]="cargando"></app-loading>
				<div class="d-flex flex-wrap justify-content-center w-100 gap-4" style="height: fit-content;">
					<div *ngIf="surveysList.length !== 0" class="card-surveys">
						<div *ngFor="let survey of surveysList" class="d-flex">
							<nb-card class="pointer mb-0 surveys-info" (click)="openSurvey(survey)" nbTooltip="{{ survey.title }}">
								<nb-card-header class="border-bottom-0">
									<h6 class="text-center surveys-title">
										{{ survey.title }}
									</h6>
								</nb-card-header>
								<nb-card-body class="d-flex h-100 p-2">
									<div class="d-flex text-center justify-content-center align-items-center">
										<p class="m-0 surveys-descriptions">
											{{ survey.description }}
										</p>
									</div>
								</nb-card-body>
								<nb-card-footer class="border-top-0 text-center">
									<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;"
										[text]="survey.questions + ' Preguntas'">
									</nb-tag>
								</nb-card-footer>
						</nb-card>
						</div>
					</div>
					<!--NUEVA ENCUESTA-->
					<div class="card-surveys">
						<nb-card class="pointer mb-0 new-survey" (click)="newSurvey()" nbTooltip="{{ 'ENCUESTAS.NEWTITLE' | translate }}">
							<nb-card-body>
								<div class="d-flex justify-content-center align-items-center h-100">
									<nb-icon style="font-size: xxx-large;" class="icon-new" icon="plus-circle-outline"></nb-icon>
								</div>
							</nb-card-body>
						</nb-card>
					</div>
				</div>
			</div>
		</div>
		<!-- VISTA MODO LISTA -->
		<!-- <div *ngIf="viewList" class="row min-h-100 gap-4">

		</div> -->
	</nb-card-body>
</nb-card>
