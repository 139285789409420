<div [style.border]="editMode ? '3px solid green' : ''">
	<nb-card class="pointer mb-0">
		<!--===== MODO VISUALIZACION =====-->
		<!--==============================-->
		<div *ngIf="!editMode" (click)="changeMode()">
			<nb-card-header>
				<h6 class="pl-4">{{ question.text }}</h6>
			</nb-card-header>
			<!--========== PREGUNTA TIPO 1 - ESCALA LIKERT ==========-->
			<nb-card-body class="p-2 d-flex justify-content-center align-items-center" *ngIf="question.type == 1">
				<div class="d-flex w-50 justify-content-between align-items-center">
					{{ question.initialTag }}
					<div *ngFor="let option of likertOptions" class="d-flex flex-column align-items-center flex-grow-1">
						<div>
							<label>{{ option }}</label>
						</div>
						<div>
							<input type="radio"/>
							<label></label>
						</div>
					</div>
					{{ question.finalTag }}
				</div>
			</nb-card-body>
			<!--========== PREGUNTA TIPO 2 - SELECCION UNICA ==========-->
			<nb-card-body class="p-2 d-flex justify-content-center align-items-center"  *ngIf="question.type == 2">
				<div class="d-flex flex-column w-50 justify-content-between">
					<div *ngFor="let option of question.options" class="d-flex align-items-center mb-2">
						<div>
							<input type="radio"/>
							<label>{{ option.text }}</label>
						</div>
					</div>
				</div>
			</nb-card-body>
			<!--========== PREGUNTA TIPO 3 - SELECCION MULTIPLE ==========-->
			<nb-card-body class="p-2 d-flex justify-content-center align-items-center"  *ngIf="question.type == 3">
				<div class="d-flex flex-column w-50 justify-content-between">
					<div *ngFor="let option of question.options" class="d-flex align-items-center mb-2">
						<div>
							<input type="checkbox"/>
							<label>{{ option.text }}</label>
						</div>
					</div>
				</div>
			</nb-card-body>
			<!--========== PREGUNTA TIPO 4 - RESPUESTA ABIERTA ==========-->
			<nb-card-body class="p-2 d-flex justify-content-center align-items-center"  *ngIf="question.type == 4">
				<div class="d-flex flex-column w-50 justify-content-between">
					<textarea rows="4" cols="50" [(ngModel)]="openResponseDefault"></textarea>
				</div>
			</nb-card-body>
			<nb-card-footer *ngIf="question.required == 1" style="color: red;">
				*{{'ENCUESTAS.QUESTIONMANDATORY' | translate}}
			</nb-card-footer>
		</div>
		<!--===== MODO EDICION =====-->
		<!--========================-->
		<div *ngIf="editMode">
			<nb-card-header class="p-2 m-2 d-flex align-items-center flex-grow-1">
				<div class="col-7">
					<textarea nbInput fullWidth [(ngModel)]="question.text" rows="3" maxlength="500"
					(focus)="selectText($event)" (ngModelChange)="onEditQuestion()"></textarea>
				</div>
				<div class="col-3 flex-column align-items-center row">
					<nb-select name="type" placeholder="{{'ENCUESTAS.QUESTIONTYPE' | translate}}" [(ngModel)]="question.type"
					(ngModelChange)="onEditQuestion()">
						<nb-option *ngFor="let type of questionsTypes" [value]="type.id">
							{{ type.title }}
						</nb-option>
					</nb-select>
				</div>
			</nb-card-header>
			<!--========== PREGUNTA TIPO 1 - ESCALA LIKERT ==========-->
			<div *ngIf="question.type == 1 && question.type != 4">
				<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
					<div class="justify-content-between">
						<div class="d-flex flex-row">
							<p>{{ 'ENCUESTAS.FROMONETO' | translate }}</p>
							<nb-select name="type" [(ngModel)]="question.likertOptions" (ngModelChange)="onEditQuestion()">
								<nb-option *ngFor="let num of numbers" [value]="num">
									{{ num }}
								</nb-option>
							</nb-select>
						</div>
						<div class="mt-3 d-flex flex-column justify-content-center align-items-center">
							<p>{{ 'ENCUESTAS.TAGINICIAL' | translate }}</p>
							<input nbInput fullWidth [(ngModel)]="question.initialTag" type="text"
								maxlength="100" (ngModelChange)="onEditQuestion()">
							<p>{{ 'ENCUESTAS.TAGFINAL' | translate }}</p>
							<input nbInput fullWidth [(ngModel)]="question.finalTag" type="text"
								maxlength="100" (ngModelChange)="onEditQuestion()">
						</div>
					</div>
				</nb-card-body>
			</div>
			<div *ngIf="question.type != 1 && question.type != 4">
				<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
					<div class="justify-content-between">
						<div *ngFor="let option of question.options">
							<div class="mt-2 d-flex flex-grow-1">
								<input nbInput fullWidth type="text" id="exampleFormControlInput1"
								placeholder="Op" maxlength="100" [(ngModel)]="option.text" (focus)="selectText($event)">
								<nb-icon class="question-icons" icon="close-outline" ngbTooltip="{{ 'GLOBALS.UNCHECK' | translate }}" (click)="deleteOption(option)"></nb-icon>
							</div>
						</div>
						<div class="mt-3 d-flex justify-content-center align-items-center">
							<nb-icon class="question-icons" icon="plus-circle-outline" ngbTooltip="{{ 'ENCUESTAS.OPTIONNEW' | translate }}" (click)="newOption()"></nb-icon>
						</div>
					</div>
				</nb-card-body>
			</div>
			<nb-card-footer class="p-2 m-2 d-flex justify-content-end align-items-center flex-grow-1">
				<div class="col-3 flex-column align-items-center row">
					<nb-toggle labelPosition="start" [(ngModel)]="obligatory" (ngModelChange)="onToggleChange($event)">
						{{'GLOBALS.MANDATORY' | translate}}
					</nb-toggle>
				</div>
				<div class="col-1">
					<nb-icon class="question-icons" icon="eye-outline" ngbTooltip="{{ 'GLOBALS.PREVIEW' | translate }}" (click)="viewPreview()"></nb-icon>
				</div>
				<div class="col-1">
					<nb-icon class="question-icons" icon="trash-2-outline" ngbTooltip="{{ 'GLOBALS.DELETE' | translate }}" (click)="onDelete()"></nb-icon>
				</div>
			</nb-card-footer>
		</div>
	</nb-card>
</div>
