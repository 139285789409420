<nb-card [nbSpinner]="loading == true" nbSpinnerStatus="danger" class="mb-0 no-edge-card">
	<ng-container *ngIf="loading == true; else form">
		<nb-card-body>{{ 'SALWIZAR.GENERATINGAI' | translate }}</nb-card-body>
	</ng-container>

	<ng-template #form>
		<nb-card-header class="border-bottom-0">
			<div class="col-11">
				<div class="d-flex">
					<h5>{{ 'SALWIZAR.ENTERPDF' | translate }}</h5>
				</div>
			</div>
		</nb-card-header>

		<div class="closeModal">
			<nb-icon class="m-1" icon="checkmark-circle-2"
				[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
				ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
			</nb-icon>
		</div>

		<nb-card-body>
			<div class="container">
				<div *ngIf="salwizarForm">
					<form [formGroup]="salwizarForm" autocomplete="off">

						<div class="d-flex w-100 gap-4 justify-content-between flex-column flex-sm-row">
							<div class="mb-0 mb-sm-4 w-100">
								<label for="topic">{{ 'SALWIZAR.INDICATESTOPIC' | translate }}:</label>
								<input type="text" nbInput fullWidth hero fieldSize="medium" class="form-control" formControlName="topic" [disabled]="fileSelected">
							</div>
							<div class="form-group d-flex flex-column w-100 position-relative">
								<label for="file">{{ 'SALWIZAR.ORUPLOAD' | translate }}:</label>
								<input type="file" formControlName="topic" (change)="onFileSelected($event)"
									style="line-height: 1.9rem; font-size: 12px;">
								<nb-icon *ngIf="fileSelected == true" icon="trash-2-outline" status="danger" (click)="clearFile()" style="position: absolute; top: 0; right: 0; cursor: pointer;"></nb-icon>
							</div>
						</div>

						<div class="d-flex w-100 justify-content-center">
							<div class="form-group w-100 d-flex flex-column">
								<label for="level">{{ 'SALWIZAR.EDUCATIONLEVEL' | translate }}:</label>
								<nb-select formControlName="level" class="SelectForm" hero size="medium" id="level" style="max-width: 100%;">
									<nb-option value="Preescolar" class="SelectFormOpt">{{ 'SALWIZAR.PRESCHOOL' | translate }}</nb-option>
									<nb-option value="Primaria" class="SelectFormOpt">{{ 'SALWIZAR.PRIMARY' | translate }}</nb-option>
									<nb-option value="Secundaria" class="SelectFormOpt">{{ 'SALWIZAR.SECONDARY' | translate }}</nb-option>
									<nb-option value="Preparatoria" class="SelectFormOpt">{{ 'SALWIZAR.PREPARATORY' | translate }}</nb-option>
									<nb-option value="Universidad" class="SelectFormOpt">{{ 'SALWIZAR.UNIVERSITY' | translate }}</nb-option>
								</nb-select>
							</div>
						</div>

						<div class="d-flex w-100 justify-content-center">
							<div class="form-group w-100 d-flex flex-column">
								<label for="lang">{{ 'FORM.LANGUAGE' | translate }}:</label>
								<nb-select formControlName="lang" class="SelectForm" hero size="medium" id="lang" style="max-width: 100%;">
									<nb-option value="Español" class="SelectFormOpt">Spanish</nb-option>
									<nb-option value="Ingles" class="SelectFormOpt">English</nb-option>
									<nb-option value="Frances" class="SelectFormOpt">French</nb-option>
								</nb-select>
							</div>
						</div>

						<div class="d-flex w-100 justify-content-center">
							<div class="form-group d-flex flex-column w-100">
								<label for="extra">{{ 'SALWIZAR.ADDITIONALINSTRUCTIONS' | translate }}:</label>
								<textarea formControlName="extra" id="extra" nbInput fullWidth fieldSize="medium" class="form-control"></textarea>
							</div>
						</div>

					</form>
				</div>
			</div>
		</nb-card-body>
		<nb-card-footer class="border-top-0">
			<div class="d-flex justify-content-center form-group">
				<button nbButton status="primary" [disabled]="!enableGenerateButton" size="small" (click)="generateProposals()">
					{{ 'SALWIZAR.SHOWPROPOSALS' | translate }}
				</button>
			</div>
		</nb-card-footer>
	</ng-template>
</nb-card>
