<nb-card class="mb-0" size="giant" style="width: 60rem;">
	<nb-card-body >
		<div class="row d-flex">
			<div style="width: 60rem;">

				<div class="row justify-content-center">
					<div class="col-12">
						<label for="select2">Forma:</label>
						<select [(ngModel)]="selectedForm" id="select2" class="form-control">
							<option value="circle">Circular</option>
							<!-- <option value="rectangle">Rectangulo</option>
							<option value="triangle">Triangulo</option>
							<option value="line">Linea Ascendente</option> -->
						</select>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-12">
						<label for="myRange">Radio: {{radioSelected}}</label>
						<div class="slidecontainer">
							<input [(ngModel)]="radioSelected" type="range" min="400" max="800" value="100" class="slider" id="myRange">
						</div>
					</div>
				</div>
				<nb-accordion *ngIf="generatedIdeas" multi>
					<nb-accordion-item *ngFor="let idea of generatedIdeas; let i = index">
						<nb-accordion-item-header>
							<div class="flex justify-content-between">
								<div>{{ idea.Titulo_de_la_Idea | slice:0:30 }}</div>
								<div>

									<ng-container *ngIf="idea.alreadyAdded == true; else greenButton">
										<button class="mr-3" nbButton shape="round" size="small" status="danger"
									 >
										<nb-icon icon="minus-circle-outline"></nb-icon>
									</button>
									</ng-container>
									<ng-template #greenButton>
										<button class="mr-3" nbButton shape="round" size="small" status="success"
										(click)="addSingleIdea(idea,$event)" [disabled]="isLoading">
										<nb-icon icon="plus-circle-outline"></nb-icon>
									</button>
									</ng-template>





								</div>

							</div>


						</nb-accordion-item-header>
						<nb-accordion-item-body>
							<textarea class="form-control" rows="5" [value]="idea.Cuerpo_Explicativo_de_la_Idea"
								(keyup)="updateGeneratedIdeas(i,$event)">{{idea.Cuerpo_Explicativo_de_la_Idea}}</textarea>
							<!-- {{idea.Cuerpo_Explicativo_de_la_Idea}} -->
						</nb-accordion-item-body>
					</nb-accordion-item>


				</nb-accordion>
			</div>
		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<div class="mb-2 row justify-content-center">
			<div class="col-12 d-flex justify-content-between">
				<ng-container *ngIf="AllIdeasAdded == false; else disabledAddAll">
					<button nbButton class="mr-1 btn-themecolor" (click)="openDesignIdeasModal()" >Agregar
						todas</button>
				</ng-container>
				<ng-template #disabledAddAll>
					<button nbButton disabled>Agregar
						todas</button>
				</ng-template>


				<button nbButton status="danger" (click)="closeModal()">Terminar</button>
			</div>
		</div>
	</nb-card-footer>
</nb-card>
