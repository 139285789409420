<div style="height: 70vh">
    <div class="modal-body fondoEditarCurso" align="center">
        <form name="form" [formGroup]="form" class="form-horizontal">
            <div *ngIf="progress" class="plantilla col-lg-11 col-md-11 mt-3">
                <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0"
                     aria-valuemax="100">100%</div>
            </div>
            <div *ngIf="!progress" class="row">
                <div *ngIf="!elements" class="plantilla col-lg-11 col-md-11 mt-3">
                    <span
                          class="textoFontSize1 colorTextoActivado font-weight-bold">{{'QUIZZES.MENSAJEINICIAL' | translate }}</span>
                </div>
                <div *ngIf="elements" class="plantilla col-lg-11 col-md-11">
                    <div class="col-12 col-sm-4 col-md-4 col-lg-4 mx-auto">
                        <mat-form-field *ngIf="template" class="w-100">
                            <mat-label>{{ 'QUIZFORMCOMPONENT.NODENAME' | translate }}</mat-label>
                            <input matInput type="text" class="form-control fondoBlanco"
                                   id="quizTittle"
                                   placeholder="{{ 'QUIZFORMCOMPONENT.NODENAME' | translate }}"
                                   formControlName="quizTittle">
                            <mat-error *ngFor="let validation of validationMessages.title">
                                <mat-error class="error-message" *ngIf="titleControl?.hasError(validation.type) && (titleControl?.dirty || titleControl?.touched)">
                                    {{validation.message}}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-12 col-sm-6 mx-auto mt-2">
                        <div class="row" *ngIf="template">
                            <div class="col-sm-2 d-none d-sm-block"></div>
                            <div class="col-sm-8 mb-2">
                                <mat-form-field class="w-100">
                                    <textarea matInput class="form-control fondoBlanco" id="quizInstructions" rows="3" placeholder="{{ 'QUIZFORMCOMPONENT.INSTRUCTIONS' | translate }}" formControlName="quizInstructions">
                                    </textarea>

                                    <mat-error *ngFor="let validation of validationMessages.instructions">
                                        <mat-error class="error-message" *ngIf="instructionsControl?.hasError(validation.type) && (instructionsControl?.dirty || instructionsControl?.touched)">
                                            {{validation.message}}
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div style="position: relative;" class="container p-0 mt-2" id="boundary">
                        <div *ngFor="let element of elements">
                            <app-quiz-edit-template-element [element]="element" [editable]="template.template.compuCorrect || !getElementType(element).isOption" (templateElementFileUpload)="cargarRecurso($event, element)" (templateElementSetDescription)="setDescription($event)" (templateElementOptionValue)="changeValue($event, element)">
                            </app-quiz-edit-template-element>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
