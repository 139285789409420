import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

const NOIMAGE = '../../../assets/images/no-image.png'


@Pipe({ name: 'imagen' })
export class ImagenPipe implements PipeTransform {

    constructor() { }

    transform(img: string, tipo: string): any {

        let url = '';

        if (!img || img === '' || img === null)
            return NOIMAGE

        if (img.includes('blob'))
            return img;



        switch (tipo) {
            case 'cursos':
                url += environment.imagesCourses;
                break;
            case 'mapas':
                url += environment.imagesTargets;
                break;
            case 'quizElement':
                url += environment.quizzesContent;
                break;
            case 'graphBg':
                url += environment.graphBackground;
                break;
            case 'stickers':
                url +=environment.stickers;
                break
            case 'group':
                url += environment.groupImagen;
                break;
            case 'userAvatar':
                url += environment.imagesUsers;
                break;
            case 'expContenido':
                url += environment.imagesTargets;
                break;
            case 'nodes':
                url += environment.nodeContentPicture;
                break;
            case 'logoLogin':
                url += environment.logoLogin;
                break;
            case 'logoCenter':
                url += environment.logoCenter;
        }


        return url += `${img}`
    }

}
