import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators, UntypedFormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SigmaNode } from 'src/app/core/models/graph/sigmaNode.model';
import { LoginService } from 'src/app/core/services/login';
import { StickersService } from 'src/app/core/services/stickers/stickers.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { ModalAceptarCancelarComponent } from 'src/app/shared/components/modal';
import { SigmaCanvasService } from '../sigma-canvas/sigma-canvas.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { ModalCursosListadoComponent } from '../../cursos/modal-cursos-listado';
import { environment } from 'src/environments/environment';

const MINRANGE = 1
const MAXRANGE = 500

@Component({
  selector: 'app-modal-list-stickers',
  templateUrl: './modal-list-stickers.component.html',
  styleUrls: ['./modal-list-stickers.component.scss']
})
export class ModalListStickersComponent implements OnInit {

  public stickerForm: UntypedFormGroup
  private _file:File | null = null
  public tempNode: any // Temp node when create a new node
  public node: any // Current node to edit
  public idCourse: number
  public idGraph: number
  private _validImageFormats: string[] = ['image/png','image/jpeg']


  validationMessages = { file: [],size: [], link: [] };
  saving:boolean = false
  progress:number = 0
	modalOpen: boolean = false;

  rangeNumber(min:number,max:number): ValidatorFn {
    return (control: UntypedFormControl): {[key: string]: any} => {

      if(Validators.required(control)) {
        return null;
      }

      let val: number = control.value;

      if(isNaN(val) || /\D/.test(val.toString())) {
        return {"rangeNumber": true};
      } else if(!isNaN(min) && !isNaN(max)) {
        return val < min || val > max ? {"rangeNumber": true} : null;
      } else if(!isNaN(min)) {

        return val < min ? {"rangeNumber": true} : null;
      } else if(!isNaN(max)) {

        return val > max ? {"rangeNumber": true} : null;
      } else {
        return null;
      }
    };
  }

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private stickerServices: StickersService,
    private loginService:LoginService,
    private modalService: NgbModal,
    private sigmaCanvasService: SigmaCanvasService,
		private toaster: ToasterService
  ) { }



  ngOnInit() {
    this._translateText()
    this.createForm()
  }

  onFileSelected(ev):void{
    const element = ev.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;



    if(fileList)
      this._file = fileList[0]
    else
      this._file = null

    if(!this._validImageFormats.includes(this._file.type))
      this.stickerForm.get('file').setErrors({'format': true});
  }


  private _translateText():void {
    this.translateService.get('VALIDACIONES.FILEREQUIRED').subscribe((res: string) => {
        this.validationMessages.file.push({ type: 'required', message: res });
    });

    this.translateService.get('VALIDACIONES.FORMATNOTVALID').subscribe((res: string) => {
      this.validationMessages.file.push({ type: 'format', message: res });
   });

    this.translateService.get('VALIDACIONES.SIZEREQUIRED').subscribe((res: string) => {
        this.validationMessages.size.push({ type: 'required', message: res });
    });

    this.translateService.get('VALIDACIONES.SIZERANGE').subscribe((res: string) => {
        this.validationMessages.size.push({ type: 'rangeNumber', message: res });
    });

  }


  createForm():void{
    this.stickerForm = this.fb.group({
			size: [MINRANGE, [Validators.required, this.rangeNumber(MINRANGE,MAXRANGE)]],
			link : ['']
		})
    //If the user create a new sticker, it will create a new file control on the form
    if(this.tempNode)
      this.stickerForm.addControl('file', this.fb.control('', Validators.required))

    if(this.node) // Add image size to size control
		{
			this.stickerForm.get('size').patchValue(this.node.sizeImg)
			this.stickerForm.get('link').patchValue(this.node.link)
		}


  }

  closeModal(sendData?: any):void{
    this.activeModal.close(sendData);
  }

  saveSticker():void{
    if (this.stickerForm.valid) {

      this.saving = true

      let value = {}

      let data = {
        nodeType: SIGMA_CONSTANTS.STICKER_TYPE,
        sizeImg: this.stickerForm.value.size,
        type: SIGMA_CONSTANTS.STICKER_NODETYPE,
        xposition: this.tempNode ? this.tempNode.x : this.node.x,
        yposition: this.tempNode ? this.tempNode.y : this.node.y,
				link: this.stickerForm.value.link
      }

      if(this.node)
        value = {
          ...data,
          idImageTarget: this.node.idImageTarget,
          high : this.node.high,
          width: this.node.width,
          nameImg: this.node.nameImg
        }

      else
        value = {...data}

      this.stickerServices.createSticker(this.idCourse, this.idGraph, value, this._file).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
              setTimeout(() => {
                  this.progress = 0
                  this.saving = false
									if(!this.modalOpen){
										this.closeModal(event.body.data) //con esto evitamos que cuando estemos editando un sticker se cierre el componente
									} else{
										this.modalOpen = false;
									}
              }, 500);
        }


      }, err => {
        this.saving = false
        console.error("Error saving the sticker: ", err)
      })

    }
  }

  deleteSticker():void{

    if (!this.loginService.esAutor()) {
      return;
    }

  // Abro un modal preguntando si desea borrar el curso o no
    const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
    {
      scrollable: true,
      windowClass: MODAL_DIALOG_TYPES.W30
    });


    modalRef.componentInstance.mensaje = this.translateService.instant('STICKERS.DELETESTICKERMSG');

    modalRef.result.then((result: boolean) => {

        if (result) {

            let sticker:SigmaNode = this.node as SigmaNode

            this.sigmaCanvasService.deleteSigmaNode(sticker).subscribe(
                (res: any) => {
                    this.toaster.success(this.translateService.instant('STICKERS.DELETEOK'));
                    this.activeModal.close(false);
                },
                err => {
                    this.toaster.error(this.translateService.instant('STICKERS.DELETEKO'));
                    this.activeModal.close(false);
                }
            )
        }
    });
  }

	buscarProyecto(){
		this.modalOpen = true;
		const modalRef = this.modalService.open(ModalCursosListadoComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.fromSticker_proyecto = true;
		modalRef.componentInstance.fromSticker_grafo = false;
		modalRef.componentInstance.parentModal = this.activeModal; // Mantener una referencia al modal actual para que no se cierre

		modalRef.result.then(
				(result) => {
						if(result !== ''){
								const idCurso = result.idCurso;
								const link = environment.domainUrl + '/course/' + idCurso;
								// Actualizar el valor del input en el formulario
								this.stickerForm.get('link').setValue(link);
								//this.toaster.success(this.translateService.instant('GLOBALS.SELECTIONCORRECT'));
						}
				}
		)
	}

	buscarGrafo(){
		this.modalOpen = true;
		const modalRef = this.modalService.open(ModalCursosListadoComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.fromSticker_proyecto = true;
		modalRef.componentInstance.fromSticker_grafo = true;
		modalRef.componentInstance.parentModal = this.activeModal; // Mantener una referencia al modal actual para que no se cierre

		modalRef.result.then(
				(result) => {
						if(result !== ''){
								const idCurso = result.idCurso;
								const idGrafo = result.idGrafo;
								const link = environment.domainUrl + '/course/' + idCurso + '/graph/' + idGrafo;
								// Actualizar el valor del input en el formulario
								this.stickerForm.get('link').setValue(link);
								//this.toaster.success(this.translateService.instant('GLOBALS.SELECTIONCORRECT'));
						}
				}
		)
	}


}
