<div *ngIf="soloAudio" class="card text-center">
    <div class="card-header">
      <h5 *ngIf="!grabando" class="card-title textoFontSize1 colorTextoActivado font-weight-bold m-0">{{'MODALAUDIOMICRO.STARTRECORD' | translate}}</h5>
      <h5 *ngIf="grabando" class="card-title textoFontSize1 colorTextoActivado font-weight-bold m-0">{{'MODALAUDIOMICRO.LISTENING' | translate}}</h5>
    </div>
    <div class="card-body">

      <ng-container *ngIf="!grabando">
        <div class="row">
          <div class="col-12">
          </div>
          <div class="col-12">
            <mat-icon title="{{'GLOBALS.DELETE' | translate}}" (click)="startRecording()" class="icon-mat-type mic_off pointer mt-2" color="primary">mic_off</mat-icon>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="grabando">
        <div class="col-12">
        </div>
        <div class="col-12">
          <mat-icon title="{{'GLOBALS.DELETE' | translate}}" (click)="stopRecording()" class="icon-mat-type mic_on pointer mt-2" color="primary">mic</mat-icon>
        </div>
        <div class="col-12">
          <img src="/assets/images/dots_2.gif" width="50px" height="50px" class="loading-gif" alt="{{ 'LOADING.CARGANDO' | translate }}" />
        </div>
      </ng-container>

      <div class="row mt-2">
        <div class="col-12 d-flex justify-content-center">
          <p class="text-break card-text font-weight-bold"><span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span></p>
        </div>
      </div>

      <div class="row flex justify-content-center align-items-center">
        <div class="col-4">
          <button type="button" class="btn btn-outline-danger btn-block"
          [disabled]="!grabando" (click)="stopRecording()">
            <mat-icon class="stop">stop</mat-icon>
          </button>
        </div>
      </div>

    </div>
    <div class="card-footer text-muted">
        <audio *ngFor="let audio of audioFiles" controls='true' [src]="audio"></audio>
        <div class="row flex justify-content-center align-items-center pt-2">
          <div class="col-4">
            <button type="button" class="btn btn-primary btn-block"
            [disabled]="audioFiles.length === 0? true: false"  (click)="aceptar()" > {{'MODALAUDIOMICRO.OK' | translate}} </button>
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-secondary btn-block"  (click)="salir()" >{{'MODALAUDIOMICRO.Cancel' | translate}}</button>
          </div>
        </div>

    </div>
</div>

<div *ngIf="soloVideo" class="card text-center">
  <div class="card-header">
    <h5 *ngIf="!grabando" class="card-title textoFontSize1 colorTextoActivado font-weight-bold m-0">{{'MODALAUDIOMICRO.STARTRECORD' | translate}}</h5>
    <h5 *ngIf="grabando" class="card-title textoFontSize1 colorTextoActivado font-weight-bold m-0">{{'MODALAUDIOMICRO.RECORDING' | translate}}</h5>
  </div>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
  <div class="card-body">

    <ng-container *ngIf="videoFiles.length > 0">
      <div class="d-flex justify-content-center">
        <div class="embed-responsive embed-responsive-4by3">
          <video class="embed-responsive-item" *ngFor="let audio of videoFiles" controls='true' [src]="audio" muted></video>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="videoFiles.length === 0">
      <div class="d-flex justify-content-center">
        <div class="embed-responsive embed-responsive-4by3">
          <video  class="embed-responsive-item" id="videoRef" autoplay  ></video>
        </div>
      </div>
    </ng-container>
    <div class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <p class="text-break card-text font-weight-bold"><span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span></p>
      </div>
    </div>

    <ng-container *ngIf="!grabando">
      <div class="row mb-4">
        <div *ngIf="videoFiles.length > 0" class="col-12 d-flex flex-column align-items-center gap-3 mt-2">
          <mat-icon ngbTooltip="{{'MODALAUDIOMICRO.REPEATRECORD' | translate}}" (click)="reloadVid()" class="icon-mat-type vid_on pointer mt-2" color="red" style="color: #dc3545;">restart_alt</mat-icon>
					{{'MODALAUDIOMICRO.REPEATRECORD' | translate}}
        </div>
        <div *ngIf="videoFiles.length === 0" class="col-12 d-flex flex-column align-items-center gap-3 mt-2">
          <mat-icon ngbTooltip="{{'MODALAUDIOMICRO.STARTRECORD' | translate}}" (click)="startCamera()" class="icon-mat-type vid_on pointer mt-2" color="red" style="color: #dc3545;">radio_button_checked</mat-icon>
					{{'MODALAUDIOMICRO.STARTRECORD' | translate}}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="grabando">
      <div class="row mb-4">
				<div class="col-12 d-flex flex-column align-items-center gap-3 mt-2">
					<mat-icon ngbTooltip="{{'MODALAUDIOMICRO.STOPRECORD' | translate}}" (click)="stopCamera()" class="icon-mat-type mic_on pointer mt-2" color="primary">stop</mat-icon>
					{{'MODALAUDIOMICRO.STOPRECORD' | translate}}
				</div>
				<div *ngIf="grabando" class="w-100">
					<img src="/assets/images/dots_2.gif" width="50px" height="50px" class="loading-gif" alt="{{ 'LOADING.CARGANDO' | translate }}" />
				</div>
			</div>
    </ng-container>
  </div>
  <div *ngIf="videoFiles.length > 0" class="row flex justify-content-center align-items-center mb-4">
		<div class="col-2">
			<button type="button" class="btn btn-primary btn-block"
			[disabled]="videoFiles.length === 0? true: false"  (click)="aceptar()" >{{'MODALAUDIOMICRO.OK' | translate}}</button>
		</div>
		<div class="col-2">
			<button type="button" class="btn btn-secondary btn-block" [disabled]="grabando"  (click)="salir()" >{{'MODALAUDIOMICRO.Cancel' | translate}}</button>
		</div>
	</div>
</div>

<div *ngIf="soloFoto" class="card text-center">
  <div style="text-align:center">
    <div class="sticky-top sticky-top-header">
      <div class="modal-header" align="center">
          <span class="textoFontSize1 colorTextoActivado font-weight-bold">{{'MODALAUDIOMICRO.USECAMERA' | translate}}</span>

          <button type="button" class="close" data-dismiss="modal" (click)="salir('')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
  </div>

    <div class="row">
      <div class="col-12">

        <div class="snapshot card" *ngIf="webcamImage">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-6 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <img class="card-img-top" [src]="webcamImage.imageAsDataUrl" alt="Card image cap">
            </div>
            <div class="card-body w-100">

              <div class="row  d-flex justify-content-center align-items-center">
                <div class="col-4 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <button class="btn btn-success btn-block" (click)="savePicture();">{{'MODALAUDIOMICRO.SAVEPHOTO' | translate}}</button>
                </div>
                <div class="col-4 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <button class="btn btn-danger btn-block" (click)="newFoto();">{{'MODALAUDIOMICRO.NEWPHOTO' | translate}}</button>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="snapshot card" *ngIf="newPicture">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-12">
                <webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions"
                [imageQuality]="1"
                (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"
                ></webcam>
            </div>
            <div class="card-body w-100">

              <div class="row  d-flex justify-content-center align-items-center">
                <div class="col-4 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <button [disabled]="!showWebcam" class="btn btn-success btn-block" (click)="triggerSnapshot();">{{'MODALAUDIOMICRO.TAKEPHOTO' | translate}}</button>
                </div>
                <div class="col-4 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <button *ngIf="showWebcam" class="btn btn-secondary btn-block" (click)="toggleWebcam();">{{'MODALAUDIOMICRO.HIDECAMERA' | translate}}</button>
                  <button *ngIf="!showWebcam" class="btn btn-secondary btn-block" (click)="toggleWebcam();">{{'MODALAUDIOMICRO.SHOWCAMERA' | translate}}</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
    <div class="card-footer text-muted">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-6 mb-2">
          <div class="row">
            <div class="col-6">
              <button type="button" class="btn btn-danger btn-block  btn-sm" [disabled]="grabando"  (click)="salir()" >
                {{'MODALAUDIOMICRO.Cancel' | translate}}
              </button>
            </div>
            <div class="col-6">
              <button ngbTooltip="{{'MODALAUDIOMICRO.CHANGECAMERAINFO' | translate}}" class="actionBtn btn btn-warning btn-block btn-sm" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">{{'MODALAUDIOMICRO.CHANGECAMERA' | translate}}</button>
            </div>

          </div>
        </div>
        <div class="col-4">

					<div *ngIf="mediaDevices" class="form-group">
            <label for="exampleFormControlSelect1">{{'MODALAUDIOMICRO.MEDIADEVICES' | translate}}</label>
						<select [(ngModel)]="deviceId" class="form-control" id="exampleFormControlSelect1" >
              <option *ngFor="let media of mediaDevices" >{{media.label}}</option>
            </select>
          </div>
          <button ngbTooltip="{{'MODALAUDIOMICRO.CHANGECAMERAINFO' | translate}}" class="actionBtn btn btn-warning  btn-sm" (click)="showNextWebcam(deviceId);">{{'MODALAUDIOMICRO.ACTIVE' | translate}}</button>
        </div>
      </div>
      <input id="cameraSwitchCheckbox" type="checkbox" [(ngModel)]="allowCameraSwitch" ><label for="cameraSwitchCheckbox">{{'MODALAUDIOMICRO.ALLOWCAMERASWITCH' | translate}}</label>
      <br/>

      <h4 *ngIf="errors.length > 0">{{'MODALAUDIOMICRO.MESSAGES' | translate}}</h4>
      <ul *ngFor="let error of errors">
        <li>{{error | json}}</li>
      </ul>
    </div>
  </div>
</div>
