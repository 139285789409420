<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="d-flex flex-column gap-1">
		<div class="row">
			<div class="col-12">
				<h5>
					{{ "COMPETITION.COMPETITION" | translate }} - {{ "COMPETITION.LOBBYCREATION" | translate }}
				</h5>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="d-flex flex-column align-items-center justify-content-center">

			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<section class="room-preparation">
			<div><h5>Nombre</h5>
				<nb-card size="tiny" >
					<nb-card-body>

							<span>{{ "COMPETITION.GAMECODE" | translate }}: {{roomCode}}
								<button nbButton size="small" class="btn-themecolor" nbTooltip="Copiar código" (click)="copyRoomCode()">
									<nb-icon icon="copy-outline"></nb-icon>
								</button>
							</span>
							<div class="d-flex flex-column">
								<label for="nameSession" class="label">{{ "COMPETITION.GAMENAME" | translate }}</label>
								<input id="nameSession" nbInput fieldSize="small" type="text" [(ngModel)]="nameSession">
							</div>


					</nb-card-body>
				</nb-card>
			</div>
			<div>
				<h5>Modo</h5>
				<nb-card size="tiny">
					<nb-card-body>
						<nb-select placeholder="Modo de juego" size="small" (selectedChange)="gameModeChange($event)">
							<nb-option value="0">1vs1</nb-option>
							<nb-option value="1">{{ "COMPETITION.TEAMS" | translate }}</nb-option>
							<nb-option *ngIf="isTeacher" value="2">{{ "COMPETITION.ALLVALL" | translate }}</nb-option>
						</nb-select>
						<div class="d-flex flex-column">
							<label for="maxUsers" class="label">{{ "COMPETITION.MAXUSERS" | translate }} </label>
							<input id="maxUsers" nbInput fieldSize="small" type="text" [(ngModel)]="maxUsers">
						</div>
						<nb-toggle class="item-profile" [(ngModel)]="isPrivateRoom" status="primary" labelPosition="start">
							{{ "COMPETITION.PRIVATEGAME" | translate }}
						</nb-toggle>
					</nb-card-body>
				</nb-card>
			</div>
			<div>
				<h5>Contenido</h5>
				<nb-card size="tiny">
					<nb-card-body>
						<div [class.blinking-border]="contentSetted==false">
							<button nbButton size="small" class="btn-themecolor" (click)="selectContent()" >
								{{ "COMPETITION.CONTENT" | translate }}
							</button>
						</div>
					</nb-card-body>
				</nb-card>
			</div>
			<div>
				<h5>Tiempo</h5>
				<nb-card size="tiny">
					<nb-card-body>
						<div class="d-flex flex-column">
							<label for="maxUsers" class="label">{{ "COMPETITION.TIMETOINIT" | translate }} </label>
							<input id="maxUsers" nbInput fieldSize="small" type="number" [(ngModel)]="timeBeforeStart">
						</div>
					</nb-card-body>
				</nb-card>
			</div>
		</section>
		<!-- <div class="row mb-5">

			<div class="col-12 col-md-4 d-flex flex-column gap-4 align-items-center">
				<div class="d-flex flex-column">
					<label for="nameSession">{{ "COMPETITION.GAMENAME" | translate }}</label>
					<input id="nameSession" nbInput fieldSize="small" type="text" [(ngModel)]="nameSession">
				</div>

				<nb-select placeholder="Modo de juego" size="small" (selectedChange)="gameModeChange($event)">
					<nb-option value="0">1vs1</nb-option>
					<nb-option value="1">{{ "COMPETITION.TEAMS" | translate }}</nb-option>
					<nb-option *ngIf="isTeacher" value="2">{{ "COMPETITION.ALLVALL" | translate }}</nb-option>
				</nb-select>
			</div>

			<div class="col-12 col-md-4 d-flex flex-column gap-4 align-items-center my-4 my-md-0">
				<div class="d-flex flex-column">
					<label for="maxUsers">{{ "COMPETITION.MAXUSERS" | translate }} </label>
					<input id="maxUsers" nbInput fieldSize="small" type="text" [(ngModel)]="maxUsers">
				</div>

				<nb-toggle class="item-profile" [(ngModel)]="isPrivateRoom" status="primary" labelPosition="start">
					{{ "COMPETITION.PRIVATEGAME" | translate }}
				</nb-toggle>
			</div>

			<div class="col-12 col-md-4 d-flex flex-column gap-4 align-items-center">
				<div class="d-flex flex-column">
					<label for="maxUsers">{{ "COMPETITION.TIMETOINIT" | translate }} </label>
					<input id="maxUsers" nbInput fieldSize="small" type="number" [(ngModel)]="timeBeforeStart">
				</div>

				<div class="d-flex align-items-center gap-4">
					<div [class.blinking-border]="contentSetted==false">
						<button nbButton size="small" class="btn-themecolor" (click)="selectContent()" >
							{{ "COMPETITION.CONTENT" | translate }}
						</button>
					</div>
					<div [class.blinking-border]="contentSetted==true">
						<button nbButton size="small" class="btn-themecolor" (click)="generateSessionToPlay()" [disabled]="contentSetted == false || isLoading == true">
							{{ "COMPETITION.CREATELOBBY" | translate }}
						</button>
					</div>
				</div>
			</div>
		</div> -->

		<div class="row m-1 justify-content-center" *ngIf="group">
			<div class="col-md-5 col-sm-12 col-12 p-0" mwlDroppable (drop)="userDroppedOnUsersInGroup(usersInGroup)" >
				<nb-card class="no-edge-card mb-0">
					<nb-card-header class="d-flex flex-wrap justify-content-between align-items-center gap-3">
						{{ "COMPETITION.USERSOFGROUP" | translate }}
						<button nbButton hero size="small" status="success" (click)="autoFillTeams()">
							{{ "COMPETITION.AUTOFILLTEAMS" | translate }}
						</button>
					</nb-card-header>
					<nb-card-body>
						<div class="row" style="flex-flow: column;">
							<div class="d-flex flex-wrap justify-content-center" *ngIf="usersInGroup">

								<div *ngFor="let user of usersInGroup" mwlDraggable (dragEnd)="dragEnd(user)" class="card cursor-grab">
									<div class="avatar" [ngStyle]="{ 'background-image': getUserAvatar(user.pictureUser) }"></div>
									<div class="card-body">
										<strong class="card-title">{{user.firstName}} {{user.surname}}</strong>
									</div>
								</div>

							</div>
						</div>
					</nb-card-body>
				</nb-card>
			</div>
			<div class="col-md-1 col-sm-12 col-12 flex justify-content-center align-items-center my-md-0 my-4">
				<nb-icon class="arrow" icon="arrow-circle-right" status="basic"> </nb-icon>
			</div>
			<div class="col-md-5 col-sm-12 col-12 p-0">
				<nb-card class="no-edge-card mb-0">
					<nb-card-header class="d-flex flex-wrap justify-content-between align-items-center gap-3">
						{{ "COMPETITION.TEAMS" | translate }}
						<button nbButton hero size="small" status="success" (click)="clearAllTeamsOfUsers()">
							{{ "COMPETITION.CLEARTEAMS" | translate }}
						</button>
					</nb-card-header>
					<nb-card-body>
						<div class="d-flex flex-wrap justify-content-around align-items-start">
							<ng-container *ngFor="let team of teams; index as i">
								<nb-card class="col-12 col-lg-5 m-2" *ngIf="team.show" mwlDroppable (drop)="userDroppedOnTeam(team)">
									<nb-card-header class="border-bottom-0 p-2">
										<ng-container *ngIf="teams[i].teamEditing == false; else editTeamName">
											<h6>{{team.name}}</h6>
										</ng-container>
										<ng-template #editTeamName>
											<input nbInput type="text" nbInput fieldSize="tiny" fullWidth [(ngModel)]="team.name" (blur)="switchEditTeamName(i)">
										</ng-template>
									</nb-card-header>
									<nb-card-body class="p-2 min-height-card-body" *ngIf="team.usersInfo && team.show" >
										<div *ngFor="let user of team.usersInfo" class="card-mini mb-3" mwlDraggable (dragEnd)="dragEnd(user)">
											<div class="avatar-mini" [ngStyle]="{ 'background-image': getUserAvatar(user.pictureUser) }"></div>
											<div class="card-body">
												<strong class="card-title">{{user.firstName}} {{user.surname}}</strong>
											</div>
										</div>
									</nb-card-body>
									<nb-card-footer class="d-flex justify-content-center border-top-0 p-2" *ngIf="team.show">
										<div *ngIf="team.show">
											<button nbButton hero size="tiny" status="success" (click)="switchEditTeamName(i)">
												{{ team.textoBoton }}
											</button>
										</div>
									</nb-card-footer>
								</nb-card>
							</ng-container>

							<nb-card class="no-edge-card col-12 col-lg-5 m-2" *ngIf="notAddTeams == false">
								<nb-card-body class="p-0 d-flex justify-content-center" (click)="addTeam()">
									<div class="card">
										<div class="img-container">
											<img src="../../../../../assets/images/new-item.png" class="img-fluid" />
										</div>
									</div>
								</nb-card-body>
							</nb-card>
						</div>
					</nb-card-body>
				</nb-card>
			</div>
		</div>
	</nb-card-body>
	<nb-card-footer>
		<div class="submit-button-wrapper">
			<div [class.blinking-border]="contentSetted==true">
				<button nbButton size="small" class="btn-themecolor" (click)="generateSessionToPlay()" [disabled]="contentSetted == false || isLoading == true">
					{{ "COMPETITION.CREATELOBBY" | translate }}
				</button>
			</div>
		</div>

	</nb-card-footer>
</nb-card>
