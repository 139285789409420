import { GruposService, SOCKETMESSAGES } from 'src/app/core/services/groups/grupos.service';
import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, ViewEncapsulation, ElementRef, AfterViewInit } from '@angular/core';
import * as countdown from 'countdown';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login/login.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetsService } from 'src/app/core/services/targets';
import { QuizzesstackService, ListQuizzesStackChallengesModel} from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { ModalReproducirNodoComponent } from 'src/app/shared/components/sigma-canvas/modal-reproducir-nodo/modal-reproducir-nodo.component';
import { QuizModel } from 'src/app/core/models/quizzes';
import { TEMPLATE_ELEMENTS_TYPES } from 'src/app/core/utils/template-elements-types';
import { QuizTemplateElement } from 'src/app/core/models/quizzes/quiz-template-element.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { QuizEditTemplateTextEditorComponent } from '../../../quiz-edit/components/quiz-edit-template/components/quiz-edit-template-text-editor/quiz-edit-template-text-editor.component'
import { TranslateService } from '@ngx-translate/core';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { QuizElement } from 'src/app/core/models/quizzes/quiz-element.model';
import { QuizElementsPipe } from 'src/app/shared/pipes/quiz-elements.pipe';
import { QuizPlayMultipleBodyOptionComponent } from '../quiz-play-multiple-body-option/quiz-play-multiple-body-option.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { QuizPlayMultipleBodyAnswerComponent } from '../quiz-play-multiple-body-answer/quiz-play-multiple-body-answer.component';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { API_KEY_CHAT_GTP } from 'src/app/core/models/masters/masters.enum';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { SpeechService } from 'src/app/core/services/speech-recognition/speech-recognition.service';
import { SocketService } from 'src/app/core/services/socket/socket-service.service';
import { finalize, take, takeUntil } from "rxjs/operators";

export interface Time {
    hours: number,
    minutes: number,
    seconds: number;
}

@Component({
	selector: "app-quiz-play-multiple-body",
	templateUrl: "./quiz-play-multiple-body.component.html",
	styleUrls: ["./quiz-play-multiple-body.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class QuizPlayMultipleBodyComponent implements OnInit, OnDestroy, AfterViewInit  {
	@Input() quiz: QuizModel;
	@Input() courseId: number;
	@Input() graphId: number;
	@Input() elements: any[];

	@Input() modeAuto: boolean = false;
	@Input() disabledButton: boolean = false;
	@Input() answered: boolean;
	@Input() selectedType: string = SOCKETMESSAGES.OPEN;
	@Input() modeSocket: boolean;
	@Input() option: number;
	@Output() answeredChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() notResponseGif: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() result: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onTimeEnd: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output() colorChange: EventEmitter<string> = new EventEmitter<string>();

	@Input() discoverAnswer$: Observable<boolean>;
	@Input() evaluate$: Observable<boolean>;
	@Input() playNext$: Observable<boolean>;
	@Input() tryClose$: Observable<boolean>;
	@Input() listQM: any;
	isLoading: boolean = false;

	time: Time;
	iniBlock: boolean;
	countSeconds: number;
	timerId: number = null;
	template: any;

	scrollBotton: boolean = true;

	arrayQuiz: any[] = [];
	isResponse: boolean;
	userQuiz: any;
	automatic: boolean;
	node: any;
	currentGraph: any;
	type: any;
	nextNodo: any;
	variablesPublicUtils: any;
	hideCheckButton: boolean;
	mostrarMensajeTimeAcabado: boolean;
	configMultiples: any;
	challengeOn: boolean = false;
	desafioProfe: boolean = false;
	item: ListQuizzesStackChallengesModel;
	public quizType: number;

	elementsQP: QuizTemplateElement[] = [];
	elementsCorrect: QuizTemplateElement[] = [];
	optionCheckedId: number[] = [];
	// ============================================================
	private timeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public time$: Observable<any> = this.timeSubject.asObservable();
	public questions: any[] = [];
	public options: any[] = [];
	public answers: any[] = [];
	//==============================================================
	//EXTENSIONES DE FICHEROS
	quizElementsPipe: QuizElementsPipe = new QuizElementsPipe();
	@ViewChild(QuizPlayMultipleBodyOptionComponent)
	optionManualComponent: QuizPlayMultipleBodyOptionComponent;

	public filesUploads = {
		answersImagen: "",
		answersDocs: "",
		answersAudio: "",
		answersVideo: "",
		answersText: "",
	};
	public arrayFileView = [];
	public imagenExt: any[] = ["png", "gif", "jpg", "jpeg"];
	public docExt: any[] = ["pdf"];
	public audioExt: any[] = ["mp3"];
	public videoExt: any[] = ["mp4", "mov", "flv", "webm"];
	//==============================================================

	private openTime: number;
	private questionTypes: QuizElementTypes[] = [
		QuizElementTypes.Q_TEXTS,
		QuizElementTypes.Q_AUDIOS,
		QuizElementTypes.Q_PDFS,
		QuizElementTypes.Q_PICTURES,
		QuizElementTypes.Q_VIDEOS,
	];

	private optionTypes: QuizElementTypes[] = [
		QuizElementTypes.O_TEXTS,
		QuizElementTypes.O_AUDIOS,
		QuizElementTypes.O_PDFS,
		QuizElementTypes.O_PICTURES,
		QuizElementTypes.O_VIDEOS,
	];

	private answerTypes: QuizElementTypes[] = [
		QuizElementTypes.A_TEXTS,
		QuizElementTypes.A_AUDIOS,
		QuizElementTypes.A_PDFS,
		QuizElementTypes.A_PICTURES,
		QuizElementTypes.A_VIDEOS,
	];

	@ViewChild(QuizPlayMultipleBodyOptionComponent)
	optionsComponent: QuizPlayMultipleBodyOptionComponent;
	files: File[] = [];
	textRespuesta: any;
	multipleTextoResponse: boolean = false;
	textodeAudio: string = "";
	private httpClient: HttpClient;
	idGrupo: number = 0;
	autoCorrection: boolean = false;
	stopTime: boolean = false;
	constructor(
		public quizService: QuizzesService,
		public loginService: LoginService,
		public modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public targetsService: TargetsService,
		public quizzesStackService: QuizzesstackService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		public speech: SpeechService,
		private el: ElementRef,
		handler: HttpBackend,
		private socketService: SocketService
	) {
		this.httpClient = new HttpClient(handler);
	}

	ngOnInit() {
		if(this.loginService.esProfesor() && JSON.parse(localStorage.getItem('challengeOn'))){
			this.answered = false;
			this.challengeOn = false;
			this.desafioProfe = true;
		} else if(!this.loginService.esProfesor() && JSON.parse(localStorage.getItem('challengeOn'))){
			this.answered = false;
			this.challengeOn = true;
		} else if(this.loginService.esProfesor() && !JSON.parse(localStorage.getItem('challengeOn'))){
			this.answered = true;
			this.challengeOn = false;
		} else if(!this.loginService.esProfesor() && !JSON.parse(localStorage.getItem('challengeOn'))){
			this.answered = false;
			this.challengeOn = false;
		}
		//this.answered = this.loginService.esProfesor();
		//this.challengeOn = JSON.parse(localStorage.getItem('challengeOn')) ? true : false;
		this.quizType = Number(
			this.quiz.idMultiplexQuiz === 1 ? 1 : this.quiz.quizType
		);
		this.openTime = Date.now();
		if (this.elements !== undefined) {
			this.questions = this.elements
				.filter((e) => this.questionTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);

			this.options = this.elements
				.filter((e) => this.optionTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);

			this.answers = this.elements
				.filter((e) => this.answerTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);
		}
		this.showClock(this.quiz);
		this.quizService.checkQuiz.subscribe(() => {
			this.discoverAnswer();
		});
		//si no estamos en algun grupo ejecutamos la resolucion por IA
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		this.idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		this.targetsService
			.getListTargetQuizzesMultiplesActiveByTarget(this.graphId, this.idGrupo)
			.subscribe((res) => {
				this.configMultiples = res.data;
				this.autoCorrection = res.data.solveWithIA === 1 ? true : false;
			});

			/*this.speech._backEnabled.subscribe((res) => {
				if (res == true) {
					this.textodeAudio = this.speech.text;
				}
			});*/
	}

	ngAfterViewInit() {
    this.checkCardHeight();
  }

	private checkCardHeight() {
    const cardElement = this.el.nativeElement.querySelector('.max-w-900');
    const cardHeight = cardElement.clientHeight;

    if (cardHeight <= window.innerHeight) {
      this.scrollBotton = false;
    }
  }

	scrollToBottom() {
		document.getElementById("footer").scrollIntoView({behavior: "smooth"});
  }

	ngOnDestroy() {
		if (this.timerId) {
			clearInterval(this.timerId);
		}
	}

	discoverAnswer() {
		// this.result.emit(false);
		if (!this.answered) {
			// this.answered = true;
			this.answeredChange.emit(this.answered);
			this.stopTime = true;
			this.isResponse = false;
			this.notResponseGif.emit(false);
			if (this.timerId) {
				clearInterval(this.timerId);
			}
			if (this.quiz.idMultiplexQuiz === 1) {
				this.socketService.getSyncChallenge().pipe(take(1))
						.subscribe((res) => {
							if (res) {
								this.stopTime = false;
							}
						});
				if (this.containResponseTypeText() &&	this.textRespuesta !== undefined) {
					if (
						this.idGrupo === 0 &&
						!this.challengeOn) {
						this.evaluationWithChatGPT(this.textRespuesta.answer);
					} else if(this.challengeOn){
						this.evaluationWithChatGPT(this.textRespuesta.answer);
					}	else if (
						this.idGrupo != 0 &&
						this.configMultiples.solveWithIA === 1 &&
						!this.challengeOn
					) {
						this.evaluationWithChatGPT(this.textRespuesta.answer);
					} else if (
						this.idGrupo != 0 &&
						this.configMultiples.solveWithIA === 0 &&
						!this.challengeOn
					) {
						this.successOrNotSuccessful(true);
					}
					//                    if (this.textRespuesta !== undefined) {
					//                        let data: string = '';
					//                        this.answers.forEach(respuesta => {
					//                            if (respuesta.elementType === QuizElementTypes.A_TEXTS) {
					//                                data = respuesta.data;
					//                            }
					//                        });
					//                        this.successOrNotSuccessful(this.evaluateTextoAndSolution(this.textRespuesta.answer, data))
					//                    } else{
					//                        this.successOrNotSuccessful(false);
					//                        this.toaster.success(this.translateService.instant('QUIZZES.NOTREGISTEREDQUESTION'));
					//                    }
				} else if (this.textRespuesta === undefined && this.challengeOn){
					this.textRespuesta = {answer: ''};
					this.successOrNotSuccessful(false);
				}	else if (this.textodeAudio !== "") {
					///quiere decir que es una respuesta enviada por el micro
					if (this.idGrupo === 0) {
						this.evaluationWithChatGPT(this.textodeAudio);
					} else if (
						this.idGrupo != 0 &&
						this.configMultiples.solveWithIA === 1
					) {
						this.evaluationWithChatGPT(this.textodeAudio);
					} else if (
						this.idGrupo != 0 &&
						this.configMultiples.solveWithIA === 0
					) {
						this.successOrNotSuccessful(true);
					}
					//                    let data: string = '';
					//                    this.answers.forEach(respuesta => {
					//                        if (respuesta.elementType === QuizElementTypes.A_TEXTS) {
					//                            data = respuesta.data;
					//                        }
					//                    });
					//                    this.successOrNotSuccessful(this.evaluateTextoAndSolution(this.textodeAudio, data))
				} else if (this.textodeAudio === "" && this.files.length > 0) {
					///quiere decir que es una respuesta subida desde un fichero
					this.successOrNotSuccessful(true);
					//                    let data: string = '';
					//                    this.answers.forEach(respuesta => {
					//                        if (respuesta.elementType === QuizElementTypes.A_TEXTS) {
					//                            data = respuesta.data;
					//                        }
					//                    });
					//                    this.successOrNotSuccessful(this.evaluateTextoAndSolution(this.textodeAudio, data))
				} else {
					this.toaster.success(
						this.translateService.instant("QUIZZES.NOTQUESTION")
					);
					this.successOrNotSuccessful(false);
				}
			}
			//            const modalRef = this.modalService.open( QuizPlayMultipleBodyAnswerComponent, {
			//                scrollable: false,
			//                centered: true,
			//                // backdropClass: MODAL_DIALOG_BORDER_TYPES.W50_BORDER,
			//                windowClass: MODAL_DIALOG_TYPES.W55,
			//            })
			//            modalRef.componentInstance.answers = this.answers;
			//            modalRef.result.then((result) => {}, (reason) => {});
		}
	}

	containResponseTypeText(): boolean {
		let existResponseTypeText: boolean = false;
		this.options.forEach((element) => {
			if (element.elementType === QuizElementTypes.O_TEXTS) {
				existResponseTypeText = true;
			}
		});

		return existResponseTypeText;
	}

	evaluateTextoAndSolution(textRespuesta: string, data: string): boolean {
		this.multipleTextoResponse = true;
		const regex = /(<([^>]+)>)/gi;
		var match = data.match(regex);
		if (match.length > 0) {
			match.forEach((element) => {
				data = data.replace(element, "");
			});
		}
		//Ahora comparamos los string para saber si son iguales
		//Convertimos la cadena a minusculas y le quitamos los espacios en blanco
		data = data.replace(/\s+/g, "");
		textRespuesta = textRespuesta.replace(/\s+/g, "");
		data = data.toLowerCase();
		textRespuesta = textRespuesta.toLowerCase();
		if (data.length > 0 && textRespuesta.length > 0) {
			if (data === textRespuesta) {
				return true;
			}
		}
		return false;
	}

	async evaluationWithChatGPT(text: any) {
		//https://api.openai.com/v1/engines/text-ada-001/completions
		//Cuando usamos ada, las respuestas de la api son imprecisas y no se ajustan a lo que se le pide
		const apiEndpoint = "https://api.openai.com/v1/chat/completions";
		const apiKey = API_KEY_CHAT_GTP;

		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${apiKey}`,
		});
		let solution = "";
		if (this.quiz.quizTittle === "Pregunta 3") {
			solution = this.quiz.text1;
		} else {
			solution = this.quiz.text2;
		}
		//			let solution = this.elements.find(
		//				(e) => e.elementType === QuizElementTypes.A_TEXTS
		//			).data;
		//			solution = solution.replace("<p>" , "");
		//			solution = solution.replace("</p>" , "");
		let instrucciones = "";
		if (this.quiz.quizTittle === "Pregunta 3") {
			instrucciones = this.quiz.text2;
		} else {
			instrucciones = this.quiz.text1;
		}
		//			let instrucciones = this.elements.find(
		//				(e) => e.elementType === QuizElementTypes.O_TEXTS
		//			).data;
		let preDefinedPrompt: string = `Actúa como un profesor de la asignatura de inglés
			que va a corregir una pregunta de examen a
			un estudiante, la pregunta estará en idioma español.
			${
				this.quiz.quizInstructions !== null
					? "La pregunta tiene el siguiente enunciado: " +
					  this.quiz.quizInstructions +
					  "."
					: ""
			}
			${
				instrucciones != null
					? "Al estudiante se le han dado las siguientes instrucciones: " +
					  instrucciones +
					  "."
					: ""
			}
			La respuesta que ha dado el estudiante a esta pregunta es: ${text}.
			La solución a la pregunta es: ${solution}.
			Analiza las instrucciones, el enunciado si los hay y la solución y compara la respuesta del
			estudiante con la solución de la pregunta,
			Para evaluar tu decisión no consideres faltas de ortografía, no consideres espacios ni signos de puntuación.
			Las respuestas con abreviaturas son equivalente a las respuestas sin abreviaturas.
			Es importante que la respuesta y la solución estén en el mismo idioma, si no es así considéralo una respuesta erronea.
			Ten en cuenta que la coincidencia entre la solucion y la respuesta sea superior a un 80%.
			Si la  respuesta que ha dado el estudiante a esta pregunta es un numero o tiene caracteres numericos conviertelo a letras y comparalo, es decir si la respuesta es 8 3 6, entonces comparalo con ocho tres seis.
			contesta unica y solamente un 0 si no estaría bien la respuesta del estudiante y unica y solamente 1 en el caso de que si lo esté.`;

			const body = {
				model: "gpt-4",
				messages: [
					{
						role: "user",
						content: preDefinedPrompt.replace(/(\r\n|\n|\r|\t)/gm, " "),
					},
				], // 'text-ada-001' o 'text-davinci-003
				//prompt: preDefinedPrompt.replace(/(\r\n|\n|\r|\t)/gm, " "),
				max_tokens: 150, // Cantidad máxima de tokens en la respuesta
				temperature: 0.1, // Controla la creatividad de la respuesta (0.0 a 1.0)
			};

		try {
			const respuesta = await this.httpClient
				.post<any>(apiEndpoint, body, { headers })
				.toPromise();
			// La respuesta de ChatGPT estará en respuesta.choices[0].text
			// Evaluamos si chatGPT ha respondido con un 0 o con un 1 usamos el includes por que la respuesta puede contener caracteres extraños que hacen que la condicion falle
			if (respuesta.choices[0].message.content.includes("0")) {
				this.successOrNotSuccessful(false);
			} else {
				this.successOrNotSuccessful(true);
			}
			if(!this.challengeOn && !this.desafioProfe){
				const modalRef = this.modalService.open(
					QuizPlayMultipleBodyAnswerComponent,
					{
						scrollable: false,
						centered: true,
						// backdropClass: MODAL_DIALOG_BORDER_TYPES.W50_BORDER,
						windowClass: MODAL_DIALOG_TYPES.W55,
					}
				);
				modalRef.componentInstance.answers = this.answers;
				modalRef.result.then(
					(result) => {},
					(reason) => {}
				);
			}
		} catch (error) {
			console.error("Error al hacer la consulta a ChatGPT:", error.message);
		}
	}

	evaluationNegative() {
		this.answeredChange.emit(true);
		this.isResponse = false;
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		this.toaster.success(this.translateService.instant("QUIZZES.MANUALNOK"));
		this.evaluateResponse(false);
	}

	evaluationPositive() {
		this.answeredChange.emit(true);
		this.isResponse = false;
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		this.toaster.success(this.translateService.instant("QUIZZES.MANUALOK"));
		this.evaluateResponse(true);
	}

	evaluateResponse(result: boolean) {
		this.result.emit(result);
		if(!this.challengeOn){
			this.quizTypes.TEXT === +this.quiz.quizType && !this.modeAuto
			? this.closeModal()
			: "";
		}
		this.disabledButton = true;
		const files = this.getAnswerFiles();
		this.quiz.answersText = this.getTextRespuestaEscrita();
		if(this.challengeOn){
			this.item = {
				idQuiz: +this.quiz.id,
				reponses: [],
				quiz: this.quiz,
				openTime: this.openTime,
				idTarget: this.graphId,
				idCourse: this.courseId,
				answered: Date.now(),
				result: result,
				text: this.textRespuesta.answer,
			  };
			  if (this.containsObject(this.item, this.quizzesStackService.responseListQuizzesStackChallenges)) {
					var index = this.quizzesStackService.responseListQuizzesStackChallenges.findIndex(
						quiz => quiz.idQuiz === this.item.idQuiz
					);
					if (index !== -1) {
						// El elemento se encuentra en el array, lo eliminamos y luego lo agregamos de nuevo
						this.quizzesStackService.responseListQuizzesStackChallenges.splice(index, 1);
					}
				// Agregamos el elemento al final del array
				this.quizzesStackService.responseListQuizzesStackChallenges.push(this.item);
				} else {
					this.quizzesStackService.responseListQuizzesStackChallenges.push(this.item);
				}
				this.socketService.getSyncChallenge().pipe(take(1))
						.subscribe((res) => {
							if (res) {
								this.quizzesStackService
								.createQuizzesStack(
									this.quiz,
									this.loginService.getUser(),
									this.courseId,
									this.graphId,
									this.openTime,
									result,
									files,
									this.filesUploads,
									true
								)
								.subscribe((res) => {
									if (this.quiz.compuCorrect) {
										this.toaster.success(
											this.translateService.instant("QUIZZES.ANSWERSAVED")
										);
									}
									this.colorChange.emit(res.data);
								});
							}
						});
		} else{
			this.quizzesStackService
			.createQuizzesStack(
				this.quiz,
				this.loginService.getUser(),
				this.courseId,
				this.graphId,
				this.openTime,
				result,
				files,
				this.filesUploads
			)
			.subscribe((res) => {
				if (this.quiz.compuCorrect) {
					this.toaster.success(
						this.translateService.instant("QUIZZES.ANSWERSAVED")
					);
				}
				this.colorChange.emit(res.data);
			});
		}
	}

	containsObject(obj, list) {
		//console.log(list, obj)
		var i;
		for (i = 0; i < list.length; i++) {
			if (list[i].idQuiz === obj.idQuiz) {
				return true;
			}
		}
		return false;
	}

	emitAudioFile($event: File) {
		this.files.push($event);
	}

	emitText(event: string) {
		this.textRespuesta = event;
	}

	getTextRespuestaEscrita() {
		return this.textRespuesta;
	}

	nextQuiz(quiz) {
		/*this.userQuiz.idQuizOriginal=quiz.idQuiz;
        var index = this.arrayQuiz.findIndex(function(el){
              return el.idQuiz == quiz.idQuiz
        });*/

		//Verificar si es el ultimo quiz
		//Invocar al array de nodos ordenado
		//Verificar si el nodo es el ultimo
		//Si no es ultimo sigues al siguiente
		//Si es el ultimo cierra el modal.

		if (
			this.automatic &&
			!this.isLastNode(this.node) &&
			index == this.arrayQuiz.length - 1
		) {
			this.nextNode(this.nextNodo);
		} else {
			this.userQuiz.idQuizOriginal = quiz.idQuiz;
			var index = this.arrayQuiz.findIndex(function (el) {
				return el.idQuiz == quiz.idQuiz;
			});

			if (index != -1 && index < this.arrayQuiz.length - 1) {
				var nexQuiz = this.arrayQuiz[index + 1];
			} else {
				this.closeModal();
			}
		}
	}

	showQuiz(quiz: any) {
		//this.cargando=false;
		this.answered = false;
		this.answeredChange.emit(false);
		//this.isDisabled=(this.isDisabled==true)?false:true;
		this.quiz = quiz;
		this.template = undefined;

		this.elementsQP = [...quiz.template]
			.filter((e) => e.elementsType.idElementType <= 9)
			.map((e) => ({
				idTemplateElement: e.idTemplateElement,
				idTemplate: e.idTemplate,
				elementsType: e.elementsType,
				xPosition: e.xPosition,
				yPosition: e.yPosition,
				xSize: e.xSize,
				ySize: e.ySize,
				style: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].name,
				icon: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].icon,
				data: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].data
					: undefined,
				responseCheck: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].responseCheck
					: undefined,
			}));

		this.elementsCorrect = [...quiz.template]
			.filter((e) => e.elementsType.idElementType > 9)
			.map((e) => ({
				idTemplateElement: e.idTemplateElement,
				idTemplate: e.idTemplate,
				elementsType: e.elementsType,
				xPosition: e.xPosition,
				yPosition: e.yPosition,
				xSize: e.xSize,
				ySize: e.ySize,
				style: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].name,
				icon: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].icon,
				data: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].data
					: undefined,
			}));

		this.template = {
			idTemplate: this.elementsQP[0]
				? this.elementsQP[0].idTemplate
				: undefined,
			idQuiz: quiz.template.idQuiz,
			templateTittle: quiz.quizTittle,
			quizInstructions: quiz.quizInstructions,
			writable: quiz.writable,
			compuCorrect: quiz.compuCorrect,
			multiplexed: quiz.multiplexed,
			elementsQP: this.elementsQP,
			elementsCorrect: this.elementsCorrect,
		};

		this.showClock(this.quiz);

		if (!this.template) {
			if (this.timerId) {
				clearInterval(this.timerId);
			}

			// var lienzo = this.get2dCanvas(this.clockCanvas.nativeElement);
			// if (lienzo) {
			//     lienzo.clearRect(0, 0, 300, 150);
			// }
		}
	}

	private showClock(quiz: QuizModel) {
		if (quiz && quiz.duration) {
			if (this.timerId) {
				clearInterval(this.timerId);
			}
			this.iniBlock = false;
			let date = new Date();
			date.setSeconds(date.getSeconds() + quiz.duration);
			this.timeSubject.next({ time: this.time, max: this.countSeconds });
			// this.timerId = countdown(date, timeStamp => {
			//     this.time = timeStamp;
			//     this.timeSubject.next({ time: this.time, max: this.countSeconds })

			//     if (!this.iniBlock) {
			//         this.iniBlock = true;
			//         this.countSeconds = this.time.seconds + this.time.minutes * 60;
			//     }

			//     if (this.time.minutes === 0 && this.time.seconds === 0) {
			//         clearInterval(this.timerId);
			//         this.discoverAnswer();
			//     }

			// }, countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
		}
	}

	onTimeEnded(event) {
		clearInterval(this.timerId);
		this.mostrarMensajeTimeAcabado = true;
		this.discoverAnswer();
		if (this.selectedType !== SOCKETMESSAGES.OPEN){
			this.onTimeEnd.emit(true);
		}
	}

	private getAnswerFiles(): any {
		return this.files;
	}

	nextNode(nextNode) {
		const modalRef = this.modalService.open(ModalReproducirNodoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W65,
		});
		modalRef.componentInstance.node = nextNode;
		modalRef.componentInstance.currentGraph = this.currentGraph;
		modalRef.componentInstance.type = "node";
		modalRef.result.then(
			(result) => {},
			(reason) => {}
		);
	}

	isLastNode(node) {
		var nodes = this.currentGraph.nodes.filter(
			(node) => node.nodeType == "Node"
		);
		var array = nodes.sort(function (a, b) {
			return a["idOriginal"] - b["idOriginal"];
		});

		var n = array[array.length - 1];
		if (n.idOriginal == node.idOriginal) {
			return true;
		} else {
			var pos = 0;
			for (n of array) {
				pos = pos + 1;
				if (n.idOriginal == node.idOriginal) {
					this.nextNodo = array[pos];
					return false;
				}
			}
		}
	}

	calculateAchieveKnowledge(quiz: any) {}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	onQuizPlayElementChecked(value: boolean, idTemplateElement: number) {
		if (value) {
			this.optionCheckedId = [idTemplateElement, ...this.optionCheckedId];
		} else {
			this.optionCheckedId = [
				...this.optionCheckedId.filter((e) => e !== idTemplateElement),
			];
		}
	}

	onQuizPlayElementFileUploaded(element, eventFile) {
		element.data = URL.createObjectURL(eventFile.target.files[0]);
		element.file = eventFile.target.files[0];
	}

	onQuizPlayElementSetDescription(element: QuizTemplateElement) {
		const modalRef = this.modalService.open(
			QuizEditTemplateTextEditorComponent,
			{
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W95,
				backdrop: "static",
			}
		);

		modalRef.componentInstance.text = element.data;

		modalRef.result.then(
			(result) => {
				element.data = result;
				element.file = result;
			},
			(reason) => {}
		);
	}

	resumeInterval() {
		let date = new Date();
		date.setSeconds(
			date.getSeconds() + this.time.seconds + this.time.minutes * 60
		);

		this.timerId = countdown(
			date,
			(timeStamp) => {
				this.time = timeStamp;

				this.timeSubject.next({ time: this.time, max: this.countSeconds });

				if (!this.iniBlock) {
					this.iniBlock = true;
					this.countSeconds = this.time.seconds + this.time.minutes * 60;
				}

				if (this.time.minutes === 0 && this.time.seconds === 0) {
					clearInterval(this.timerId);
					this.answered = true;
					this.answeredChange.emit(true);
					this.discoverAnswer();
				}
			},
			countdown.HOURS | countdown.MINUTES | countdown.SECONDS
		);
	}

	saveTxt(text) {
		this.filesUploads.answersText = text;
	}

	public saveOptionMultiple(option: any) {
		option.index =
			this.quiz.idMultiplexQuiz === 1 ? option.index : option.index;
		const element: QuizElement = this.elementToQuizElement(option.element);
		this.isLoading = true;
	}

	public onSaveOption(option: any) {
		option.index =
			this.quiz.idMultiplexQuiz === 1 ? option.index : option.index;
		const element: QuizElement = this.elementToQuizElement(option.element);
		// this.isLoading = true;
		this.files.push(option.file);
		this.optionManualComponent.arrayFileView[option.index].url =
			option.element.data;
		this.optionManualComponent.arrayFileView[option.index].view = true;
	}
	addValueToArray(
		answersImagen: string,
		data: any,
		index: any,
		url: string
	): string {
		answersImagen = answersImagen + "," + data;
		this.optionManualComponent.arrayFileView[index].url =
			this.optionManualComponent.arrayFileView[index].url + data;
		this.optionManualComponent.arrayFileView[index].view = true;
		return answersImagen;
	}
	replaceValue(
		answersArrays: string,
		data: any,
		type: string,
		index: number,
		url: string
	) {
		let arrayDeCadenas = answersArrays.split(",");
		arrayDeCadenas[arrayDeCadenas.length - 1] = data;
		this.optionManualComponent.arrayFileView[index].url = "";
		this.optionManualComponent.arrayFileView[index].url = url + type + data;
		if (type === "picture/") {
			this.filesUploads.answersImagen = "";
			this.filesUploads.answersImagen = arrayDeCadenas.toString();
		} else if (type === "video/") {
			this.filesUploads.answersVideo = "";
			this.filesUploads.answersVideo = arrayDeCadenas.toString();
		} else if (type === "audio/") {
			this.filesUploads.answersAudio = "";
			this.filesUploads.answersAudio = arrayDeCadenas.toString();
		} else if (type === "pdf/") {
			this.filesUploads.answersDocs = "";
			this.filesUploads.answersDocs = arrayDeCadenas.toString();
		}
	}

	//Obtener extencion del archivo
	getFileExtension(filename) {
		return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
	}

	private elementToQuizElement(element: any): QuizElement {
		const quizElement: QuizElement = {
			idQuizzesDataElements: element.id,
			data: element.data,
			idQuiz: this.quiz.idOriginal,
			elementType: element.type,
			responseCheck: +element.isCorrect,
			xPosition: 0,
			xSize: 0,
			yPosition: element.order,
			ySize: 0,
		};

		return quizElement;
	}

	successOrNotSuccessful(succes: boolean) {
		this.answeredChange.emit(succes);
		this.isResponse = false;
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		if (succes && !this.challengeOn && !this.desafioProfe) {
			this.configMultiples.solveWithIA === 0
				? this.toaster.success(
						this.translateService.instant("QUIZZES.ANSWERSAVED")
				  )
				: this.toaster.success(
						this.translateService.instant("QUIZZES.MANUALOK")
				  );
		} else if(!this.challengeOn  && !this.desafioProfe) {
			this.toaster.success(this.translateService.instant("QUIZZES.MANUALNOK"));
		}
		this.evaluateResponse(succes);
	}

	public get quizTypes() {
		return QuizTypes;
	}

	compararTexto(textodeAudio: string) {
		this.textodeAudio = textodeAudio;
		// this.answered = true;
		//this.discoverAnswer();
	}
}
