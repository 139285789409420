<div class="sticky-top sticky-top-header w-100">
	<div class="tittle py-2">
		<div class="row d-flex justify-content-between align-items-center">
			<div class="col-12 text-center">
				<span *ngIf="quiz.id !== 'temp'; else newNodeTitle" class="textoFontSize08 font-weight-bold span-text-center">
					{{ 'NODOS.GRAFO' | translate }}: {{quiz.quizTittle}}
				</span>
				<ng-template #newNodeTitle>
					<span class="textoFontSize08 font-weight-bold span-text-center">
						{{'NODOS.REGISTRARNODO' | translate}}
					</span>
				</ng-template>
			</div>
		</div>
	</div>
</div>
<div class="p-3">
	<div class="well d-flex">
		<div class="w-100">
			<div>
				<div class="row">
					<div class="col">
						<form [formGroup]="form">
							<div class="row mb-3">
								<div class="col-sm-6 text-dark">
									<div class="w-100">
										<p>{{ 'QUIZFORMCOMPONENT.NODENAME' | translate }}: {{quiz.quizTittle}}</p>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-3">
									<mat-form-field class="w-100">
										<mat-label>{{ 'QUIZFORMCOMPONENT.DURATION' | translate }}</mat-label>
										<input name="duration" matInput type="time" [placeholder]="'QUIZFORMCOMPONENT.DURATION' | translate"
											formControlName="duration" required>
										<mat-error *ngFor="let validation of validationMessages.duration">
											<mat-error class="error-message"
												*ngIf="durationControl?.hasError(validation.type) && (durationControl?.dirty || durationControl?.touched)">
												{{validation.message}}
											</mat-error>
										</mat-error>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-3">
									<mat-form-field class="w-100">
										<mat-label>{{ 'QUIZFORMCOMPONENT.LEVEL' | translate }}</mat-label>
										<input name="level" matInput type="number" [placeholder]="'QUIZFORMCOMPONENT.LEVEL' | translate"
											formControlName="level" required>
										<mat-error *ngFor="let validation of validationMessages.level">
											<mat-error class="error-message"
												*ngIf="levelControl?.hasError(validation.type) && (levelControl?.dirty || levelControl?.touched)">
												{{validation.message}}
											</mat-error>
										</mat-error>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-3">
									<mat-form-field class="w-100">
										<mat-label>{{ 'QUIZFORMCOMPONENT.ORDINAL' | translate }}</mat-label>
										<input name="ordinal" matInput type="number" [(ngModel)]="quiz.ordinal"
											[placeholder]="'QUIZFORMCOMPONENT.ORDINAL' | translate" formControlName="ordinal" required>
										<mat-error *ngFor="let validation of validationMessages.ordinal">
											<mat-error class="error-message"
												*ngIf="ordinalControl?.hasError(validation.type) && (ordinalControl?.dirty || ordinalControl?.touched)">
												{{validation.message}}
											</mat-error>
										</mat-error>
									</mat-form-field>
								</div>
							</div>

							<div class="row mb-3">
								<div class="col-sm-6 text-dark">
									<div class="w-100">
										<p>{{ 'QUIZFORMCOMPONENT.ID' | translate }}: {{quiz.idOriginal}}</p>
									</div>
								</div>
							</div>

							<div class="row mb-3">
								<div class="col-sm-6 text-dark">
									<div class="w-100">
										<p>{{ 'QUIZFORMCOMPONENT.WRITABLE' | translate }}: {{quiz.writable}}</p>
									</div>
								</div>
							</div>

							<div class="row mb-3">
								<div class="col-sm-6 text-dark">
									<div class="w-100">
										<p>{{ 'QUIZFORMCOMPONENT.COMPUCORRECT' | translate }}: {{quiz.compuCorrect}}</p>
									</div>
								</div>
							</div>

							<div class="row mb-3">
								<div class="col-sm-6 text-dark">
									<div class="w-100">
										<p>{{ 'QUIZFORMCOMPONENT.MULTIPLEXED' | translate }}: {{quiz.multiplexed}}</p>
									</div>
								</div>
							</div>

							<div class="row mb-3">
								<div class="col-sm-6 text-dark">
									<div class="w-100">
										<p>{{ 'QUIZFORMCOMPONENT.CREATION' | translate }}: {{quiz.creationDate | date:'short'}}</p>
									</div>
								</div>
							</div>

							<div class="row mb-3">
								<div class="col-sm-6 text-dark">
									<div class="w-100">
										<p>{{ 'QUIZFORMCOMPONENT.EDITION' | translate }}: {{quiz.lastEditionDate | date:'short'}}</p>
									</div>
								</div>
							</div>

						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="text-right px-3 pb-3 pt-0">
	<button mat-flat-button color="primary" type="button" class="mr-3" [disabled]="isLoading"
		(click)="save()">{{'NODEFORMCOMPONENT.SAVE' | translate}}</button>
	<button mat-flat-button type="button" (click)="closeModal()">{{'NODEFORMCOMPONENT.VOLVER' | translate}}</button>
</div>
