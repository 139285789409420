import { Component, OnInit, Input } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalReproducirQuizzesComponent } from '../../modal-reproducir-quizzes/modal-reproducir-quizzes.component';
import { ModalCompetitionCouplesComponent } from '../../modal-competition-couples/modal-competition-couples.component';
import { ModalCompetitionGroupsComponent } from '../../modal-competition-groups/modal-competition-groups.component';
import { TaskService } from 'src/app/core/services/task';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
    selector: 'app-body-tasks-list',
    templateUrl: './body-tasks-list.component.html',
    styleUrls: ['./body-tasks-list.component.scss']
})
export class BodyTasksListComponent implements OnInit {
    @Input() idCurso: any;
    events: string[] = [];
    @Input() option: string;
    tasksCompetition: any[];

    constructor(private modalService: NgbModal,
        public taskService: TaskService) { }

    ngOnInit() {
        this.tasksCompetition = [];
    }

    tasks = [
        {
            id: 1, nomTarea: 'Tarea 1', nombre: 'profesor 1', img: '../../../assets/images/profesor.png', tema: 'HISTOR',
            explicacion: "Explicacion 1", imgExplicacion: '../../../assets/images/grafo_prueba/historia.png', grafo: 1, nomGrafo: "Grafo 1", imgGrafo: '../../../assets/images/grafo_prueba/atenas-partenon-acropolis.jpg',
            curso: 1, nomCurso: "curso 1", fechaLimite: 'Mon,Nov 17', imgCurso: '../../../assets/images/cursos/1.jpg',
            observacion: "Lorem ipsum dolor sit amet,consectetur adipiscing elit, sed esmod tempor incidunt ut labore et dolor magna aliqua.",
            estado: 1
        },
        {
            id: 2, nomTarea: 'Tarea 2', nombre: 'profesor 2', img: '../../../assets/images/profesor.png', tema: 'HISTOR',
            explicacion: "Explicacion 2", imgExplicacion: '../../../assets/images/grafo_prueba/itza-mexico.jpg', grafo: 2, nomGrafo: "Grafo 2", imgGrafo: '../../../assets/images/grafo_prueba/calc.jpg',
            curso: 2, nomCurso: "curso 2", imgCurso: '../../../assets/images/cursos/2.jpg', fechaLimite: 'Tue, Nov 18',
            observacion: "Lorem ipsum dolor sit amet,consectetur adipiscing elit.",
            estado: 10
        },
        {
            id: 3, nomTarea: 'Tarea 3', nombre: 'alumno 1', img: '../../../assets/images/alumno.png', tema: 'HISTOR',
            explicacion: "Explicacion 3", imgExplicacion: '../../../assets/images/grafo_prueba/mexico.jpg', grafo: 3, nomGrafo: "Grafo 3", imgGrafo: '../../../assets/images/grafo_prueba/fondo1.jpg',
            curso: 3, nomCurso: "curso 3", imgCurso: '../../../assets/images/cursos/3.jpg', fechaLimite: 'Wed, Nov 19',
            observacion: "Lorem ipsum dolor sit amet,consectetur adipiscing elit, sed esmod tempor incidunt ut labore et dolor magna aliqua. Ut enim adminim veniam, quis nostrud.",
            estado: 20
        },
        {
            id: 4, nomTarea: 'Tarea 4', nombre: 'alumna 1', img: '../../../assets/images/alumna.png', tema: 'LATIN',
            explicacion: "Explicacion 4", imgExplicacion: '../../../assets/images/grafo_prueba/La-condena-de-Socrates.jpg', grafo: 4, nomGrafo: "Grafo 4", imgGrafo: '../../../assets/images/grafo_prueba/fondo2.jpg', curso: 4, nomCurso: "curso 4", imgCurso: '../../../assets/images/cursos/4.jpg', fechaLimite: 'Mon, Nov 24',
            observacion: "Lorem ipsum dolor.",
            estado: 21
        },
        {
            id: 5, nomTarea: 'Tarea 5', nombre: 'alumna 2', img: '../../../assets/images/alumna.png', tema: 'MATEM',
            explicacion: "Explicacion 5", imgExplicacion: '../../../assets/images/grafo_prueba/numeros.jpg', grafo: 5, nomGrafo: "Grafo 5", imgGrafo: '../../../assets/images/grafo_prueba/fondo4.jpg', curso: 5, nomCurso: "curso 5", imgCurso: '../../../assets/images/cursos/5.jpg', fechaLimite: 'Mon, Dic 1',
            observacion: "Lorem ipsum dolor sit amet,consectetur adipiscing elit, sed esmod tempor incidunt ut labore et dolor magna aliqua. Ut enim adminim veniam, quis nostrud.",
            estado: 40
        }
    ];

    getColor(estado: number) {
        switch (true) {
            case (0 < estado && estado <= 10):
                return 'rgb(255, 11, 18)';
            case (11 < estado && estado <= 20):
                return 'rgb(255, 252, 56)';
            case (20 < estado && estado <= 30):
                return 'rgb(109, 252, 48)';
            case (31 <= estado && estado <= 40):
                return 'rgb(26, 177, 86)';
            default:
                return 'rgb(165, 165, 165)';
        }
    }

    addEvent(type: string, event: MatDatepickerInputEvent<Date>, remitente: any) {
        var date = this.events.push(`${type}: ${event.value}`);
    }

    competition(option: any, tasksCompetition: any) {
        switch (option) {
            case 'lonely':
                const modalQuiz = this.modalService.open(ModalReproducirQuizzesComponent,
                    {
                        scrollable: true,
                        windowClass: MODAL_DIALOG_TYPES.W95
                    })

                modalQuiz.componentInstance.idCurso = 1;
                modalQuiz.componentInstance.node = { idOriginal: 590 };
                modalQuiz.result.then((result) => {
                    console.log(result);
                }, (reason) => {
                });
                break;
            case 'couples':
                const modalCouples = this.modalService.open(ModalCompetitionCouplesComponent,
                    {
                        scrollable: true,
                        windowClass: MODAL_DIALOG_TYPES.W65
                    })

                modalCouples.componentInstance.idCurso = 1;
                modalCouples.componentInstance.remitente = tasksCompetition[0];
                modalCouples.result.then((result) => {
                    console.log(result);
                }, (reason) => {
                });
                break;
            case 'groups':
                const modalGroups = this.modalService.open(ModalCompetitionGroupsComponent,
                    {
                        scrollable: true,
                        windowClass: MODAL_DIALOG_TYPES.W65
                    })

                modalGroups.componentInstance.idCurso = 1;
                modalGroups.componentInstance.remitente = tasksCompetition[0];
                modalGroups.result.then((result) => {
                    console.log(result);
                }, (reason) => {
                });
                break;
            default:
                break;
        }
    }

    checkTask(task: any) {
        if (!this.tasksCompetition.includes(task)) {
            this.tasksCompetition.push(task);
        } else {
            var i = this.tasksCompetition.indexOf(task);
            this.tasksCompetition.splice(i, 1);
        }
    }
}
