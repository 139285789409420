<nb-card class="no-edge-card modal-max-height-100-65 mb-0">
	<nb-card-body class="p-0">
		<nb-card class="no-edge-card mb-0">
			<nb-card-header class="background_themecolor d-flex flex-column gap-2">
				<div class="d-flex flex-column gap-3 align-items-center justify-content-center">
					<!-- Titulo -->
					<h2 class="mb-0 text-center">
						{{ "ASISTENCIA.CONTROLCALENDAR" | translate }}
					</h2>
					<!-- Titulo - Mes, semana o dia -->
					<div class="d-flex">
						<h4 class="mb-0 text-center text-capitalize">{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h4>
					</div>
				</div>
				<div class="d-flex flex-column flex-xl-row gap-3 align-items-center justify-content-between">
					<!-- Filtros -->
					<div class="d-flex">
						<div class="btn-group gap-3 position-relative">
							<div class="d-flex">
								<nb-select *ngIf="levelsOfCountry" class="SelectForm" hero size="small"
									placeholder="{{ 'NODEFORMCOMPONENT.LEVEL' | translate }}" (selectedChange)="filterByLevel($event)">
									<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry" [value]="level.countryLevel">
										{{ level.countryLevel }}
									</nb-option>
								</nb-select>
							</div>
							<div class="d-flex">
								<nb-select *ngIf="subjectsList" class="SelectForm" hero size="small" placeholder="Asignatura"
									(selectedChange)="filterBySubject($event)">
									<nb-option *ngFor="let item of subjectsList" [value]="item.idSubject">{{ item.subject }}</nb-option>
								</nb-select>
							</div>
							<div class="d-flex justify-content-center align-content-center icon-position">
								<nb-icon icon="refresh-outline" (click)="resetFilters()"></nb-icon>
							</div>
						</div>
					</div>
					<div class="d-flex gap-3">
						<!-- Navegacion -->
						<div class="d-flex">
							<div class="btn-group gap-3">
								<div class="btn pointer" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
									(viewDateChange)="closeOpenMonthViewDay()">
									<nb-icon icon="arrow-back-outline" nbTooltip="{{ 'ASISTENCIA.PREVIOUS' | translate }}"
										nbTooltipPlacement="top"></nb-icon>
								</div>
								<div class="btn pointer" mwlCalendarToday [(viewDate)]="viewDate">
									<nb-icon icon="home-outline" nbTooltip="{{ 'CENTROS.TODAY' | translate }}"
										nbTooltipPlacement="top"></nb-icon>
								</div>
								<div class="btn pointer" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
									(viewDateChange)="closeOpenMonthViewDay()">
									<nb-icon icon="arrow-forward-outline" nbTooltip="{{ 'ASISTENCIA.NEXT' | translate }}"
										nbTooltipPlacement="top"></nb-icon>
								</div>
							</div>
						</div>
						<!-- Navegacion - Mes, semana o dia -->
						<!-- <div class="btn-group">
							<div class="btn btn-primary pointer" (click)="setView(CalendarView.Month)"
								[class.active]="view === CalendarView.Month">
								{{ "INFORMES.MONTH" | translate }}
							</div>
							<div class="btn btn-primary pointer" (click)="setView(CalendarView.Week)"
								[class.active]="view === CalendarView.Week">
								{{ "CENTROS.WEEK" | translate }}
							</div>
							<div class="btn btn-primary pointer" (click)="setView(CalendarView.Day)"
								[class.active]="view === CalendarView.Day">
								{{ "INFORMES.DAY" | translate }}
							</div>
						</div> -->
					</div>
				</div>
			</nb-card-header>

			<div class="closeModal">
				<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
					nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
				</nb-icon>
			</div>

			<nb-card-body>
				<!-- Calendario -->
				<div [ngSwitch]="view">
					<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
						[locale]="locale" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
						[excludeDays]="excludeDays"
						(dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
						(eventTimesChanged)="eventTimesChanged($event)" [weekStartsOn]="1" [cellTemplate]="customCellTemplate">
					</mwl-calendar-month-view>
					<mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
						[locale]="locale" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
						(eventTimesChanged)="eventTimesChanged($event)">
					</mwl-calendar-week-view>
					<mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
						[locale]="locale" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
						(eventTimesChanged)="eventTimesChanged($event)">
					</mwl-calendar-day-view>
				</div>
				<!-- Modal - crear evento -->
				<ng-template #modalEvents let-close="close">
					<nb-card class="no-edge-card mb-0" style="max-width: 30rem;">
						<nb-card-header class="border-bottom-0">
							<div class="d-flex justify-content-between align-items-center gap-2 pr-5">
								<h5>
									{{ "MENUACTIONS.QUALITYCONTROL" | translate }}
								</h5>
								<nb-icon icon="info-outline" class="pointer m-1" nbTooltip="{{ 'CENTROS.TOOLTIINFO' | translate }}"
									nbTooltipPlacement="button">
								</nb-icon>
							</div>
						</nb-card-header>

						<div class="closeModal">
							<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="close()"
								nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
							</nb-icon>
						</div>

						<nb-card-body>
							<div class="container">
								<div class="d-flex flex-column gap-4">
									<!-- Nivel -->
									<section class="layout">

										<div class="d-flex align-items-center">
											<nb-radio-group [(value)]="selectedOption" (click)="updateProjectTypes()">
												<nb-radio [value]="1">Académico</nb-radio>
												<nb-radio [value]="2">Encuesta</nb-radio>
											</nb-radio-group>
										</div>

										<div class="d-flex align-items-center">
											<nb-select style="margin-bottom: 24px;" class="SelectForm fullWidth-select" hero size="small" placeholder="Tipo de prueba"
													(selectedChange)="setProjectType($event)">

													<nb-option class="SelectFormOpt" *ngFor="let item of projectTypes" [value]="item.value">
														{{ item.label}}
													</nb-option>
												</nb-select>
										</div>




										<div class="d-flex align-items-center">
											<nb-select class="SelectForm fullWidth-select" hero size="small" #levelSelector
												placeholder="{{ 'NODEFORMCOMPONENT.LEVEL' | translate }}" [(ngModel)]="selectedLevel"
												(selectedChange)="setLevel($event)">
												<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry"
													[value]="level.countryLevel">
													{{ level.countryLevel }}
												</nb-option>
											</nb-select>
										</div>
									</section>
									<!-- Asignatura -->
									<section class="layout">
										<div class="d-flex align-items-center">
											<nb-select class="SelectForm fullWidth-select" hero size="small" placeholder="Asignatura"
												(selectedChange)="setSubject($event)">
												<nb-option class="SelectFormOpt" *ngFor="let item of subjectsList" [value]="item.idSubject">
													{{ item.subject}}
												</nb-option>
											</nb-select>
										</div>
									</section>
									<!-- Seleccionar grupo -->
									<section class="layout">
										<div class="d-flex gap-3 align-items-center">
											<nb-select multiple class="fullWidth-select" hero size="small" placeholder="Selecciona grupos"
												(selectedChange)="updateGroupsIds($event)">
												<nb-option *ngFor="let group of groupsNamesList" [value]="group.idGroup" [checked]="true">{{ group.title }}</nb-option>
											</nb-select>
											<!-- <button nbButton size="small" class="btn-themecolor w-100" (click)="openGroupsSelector()">
												{{ "ALERTCHALLENGENODE.SELECTGROUPTITLEESU" | translate }}
											</button> -->
											<!-- <button nbButton class="btn-themecolor" size="small" (click)="open(dialog)">
												Grupos
												<nb-icon icon="eye-outline"></nb-icon>
											</button>
											<ng-template #dialog let-data let-ref="dialogRef">
												<nb-card class="mb-0 position-relative">
													<nb-card-body>
														<div class="container">
															<div class="d-flex flex-wrap gap-3 justify-content-center">
																<div *ngFor="let group of groupsNamesList" class="card-groupCentros">
																	<nb-card class="mb-0 group-info">
																		<nb-card-body>
																			<div>
																				<p class="Tittle-group m-0">
																					{{ group.title | titlecase | truncate : [50, "..."] }}
																				</p>
																			</div>
																		</nb-card-body>
																	</nb-card>
																</div>
															</div>
														</div>
													</nb-card-body>
													<div class="closeModal">
														<nb-icon class="m-1" icon="close-circle"
															nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left"
															[options]="{ animation: { type: 'zoom' } }" (click)="ref.close()">
														</nb-icon>
													</div>
												</nb-card>
											</ng-template> -->
										</div>
									</section>
									<!-- Tipo de prueba -->

									<!-- Ideografo -->
									<section class="layout">
										<div class="d-flex gap-3 align-items-center">
											<button [ngClass]="{'pulsating': contentSetted == false}" nbButton size="small" status="primary"
												class="w-100" (click)="selectContent()">
												<!-- {{ "CENTROS.CHOOSEIDEOGRAPH" | translate }} -->
												Seleccionar prueba
											</button>

										</div>
									</section>

									<section class="layout">
										<div class="d-flex gap-3 align-items-center">
											<div *ngIf="asignatura">
												{{ asignatura }}
											</div>

										</div>
									</section>


									<!-- Fecha inicio -->
									<section class="layout">
										<div class="d-flex">
											<div class="d-flex flex-column w-100">
												<label>{{ "TOTALHISTORYUSER.DATEFROM" | translate }}</label>
												<input nbInput fullWidth fieldSize="small" class="form-control" type="text" mwlFlatpickr
													[(ngModel)]="editingEvent.Fecha_Inicio" (ngModelChange)="refresh.next()" [altInput]="true"
													[convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i"
													placeholder="Not set" />
											</div>
										</div>
									</section>
									<!-- Fecha fin -->
									<section class="layout">
										<div class="d-flex">
											<div class="d-flex flex-column w-100">
												<label>{{ "TOTALHISTORYUSER.DATETO" | translate }}</label>
												<input nbInput fullWidth fieldSize="small" class="form-control" type="text" mwlFlatpickr
													[(ngModel)]="editingEvent.Fecha_Fin" (ngModelChange)="refresh.next()" [altInput]="true"
													[convertModelValue]="true" [locale]="locale" [enableTime]="true" dateFormat="Y-m-dTH:i"
													altFormat="F j, Y H:i" placeholder="Not set" />
											</div>
										</div>
									</section>
									<!-- Titulo -->
									<section class="layout">
										<div class="d-flex flex-column w-100">
											<label>{{ "EDITARCURSO.TITULO" | translate }}</label>
											<input type="text" nbInput placeholder="{{ 'QUIZZES.TITULO' | translate }}" fullWidth
												fieldSize="small" class="form-control" [(ngModel)]="editingEvent.Name"
												(keyup)="refresh.next()" />
										</div>
									</section>
								</div>
							</div>
						</nb-card-body>

						<nb-card-footer class="border-top-0">
							<div class="container">
								<div class="d-flex justify-content-end align-items-center">
									<button [disabled]="contentSetted == false" nbButton class="btn-themecolor" size="small" type="button"
										(click)="saveNewEvent(editingEvent);close()">
										Crear control
									</button>
								</div>
							</div>
						</nb-card-footer>
					</nb-card>
				</ng-template>
			</nb-card-body>
		</nb-card>
		<!-- Info por cada dia del mes -->
		<ng-template #customCellTemplate let-day="day" let-event="event" let-locale="locale">
			<div class="cal-cell-top">
				<span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
				<span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
			</div>

			<div class="custom-event">
				<div class="event-item" *ngFor="let eve of day.events">
					<div class="d-flex">
						<span class="event-dot" [style.backgroundColor]="eve?.color?.primary || 'gray'"></span>
					</div>
					<span class="event-title" nbTooltip="{{ eve?.title }}" nbTooltipPlacement="top">
						{{ eve?.title }}
					</span>
				</div>
			</div>
		</ng-template>
		<!-- Modal para ver el listado de los eventos creados -->
		<ng-template #modalEventsList let-modal>
			<nb-card class="mb-0">
				<nb-card-header class="border-bottom-0">
					<h6 class="pr-4">Eventos del día {{ viewDate | date: 'fullDate':'':'es' }}</h6>
				</nb-card-header>
				<div class="closeModal">
					<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="modal.close()"
						nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
					</nb-icon>
				</div>
				<nb-card-body>
					<nb-list style="max-height: 400px;">
						<nb-list-item *ngFor="let event of dayEvents">
							<div class="d-flex align-items-center gap-3">
								<p class="m-0">{{ event.title }} ({{ event.start | date: 'shortTime':'':'es' }} - {{ event.end | date: 'shortTime':'':'es' }})</p>
								<button *ngIf="!isCenter" nbButton status="primary" size="small" (click)="playEvent(event)">
									<nb-icon icon="play-circle-outline"></nb-icon>
								</button>
								<button *ngIf="isCenter" nbButton status="info" size="small" (click)="handleEvent('Edited',event)">
									<nb-icon icon="edit-outline"></nb-icon>
								</button>
								<button *ngIf="isCenter" nbButton status="danger" size="small" (click)="handleEvent('Deleted',event)">
									<nb-icon icon="trash-2-outline"></nb-icon>
								</button>
							</div>
						</nb-list-item>
					</nb-list>
				</nb-card-body>
				<nb-card-footer class="border-top-0">
					<div class="d-flex justify-content-center">
						<button *ngIf="isCenter" nbButton status="success" size="small" (click)="addCleanEvent(viewDate)">
							Agregar Evento
						</button>
					</div>
				</nb-card-footer>
			</nb-card>
		</ng-template>
	</nb-card-body>
</nb-card>
