<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62" (click)="closeFiltersPanel()">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">

		<div class="row p-4 background_white gap">
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4>{{'CURSOS.TITLE' | translate}}</h4>
			</div>
		</div>

		<!-- Filtros -->
		<div class="row px-3 py-2 mt-3">
			<div class="col-12 col-xl-8">
				<!-- Version movil -->
				<div *ngIf="isMobile">
					<div class="d-flex align-items-center justify-content-between gap-4">
						<button nbButton *ngIf="!viewList" class="btn-themecolor" size="small" (click)="toggleFilters()">
							{{ buttonText}}
						</button>

						<nb-action *ngIf="viewList" icon="menu-outline" nbTooltip="{{ 'INFORMES.EXPANDIRVISTA' | translate }}"
							nbTooltipPlacement="top" (click)="toggleMenu()">
						</nb-action>

						<nb-action *ngIf="viewList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(false)">
						</nb-action>

						<nb-action *ngIf="!viewList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(true)">
						</nb-action>

						<nb-action class="icon-option icon-proyecto-muchos-grafos position-relative pointer"
							nbTooltip="{{ 'CURSOS.NUEVOCURSO' | translate }}" nbTooltipPlacement="top" *ngIf="loginService.esAutor()"
							(click)="nuevoCurso()">
							<nb-icon class="plus-editor" icon="plus-outline"></nb-icon>
						</nb-action>
					</div>

					<div *ngIf="viewList" class="left-files" [ngClass]="{'show-files': isMenuOpen}">
						<div class="flex flex-column gap-4">
							<div class="closeModal">
								<nb-icon class="m-1 pointer" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
									(click)="toggleMenu()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
								</nb-icon>
							</div>

							<div class="d-flex flex-column gap-3 pr-5">
								<div>
									<button nbButton class="btn-themecolor" size="small" (click)="toggleFilters()">
										{{ buttonText}}
									</button>
								</div>

								<nb-form-field [formGroup]="formulario" class="w-100">
									<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
										placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
									<button nbSuffix nbButton ghost size="small">
										<nb-icon class="pointer" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
									</button>
								</nb-form-field>
							</div>

							<h5>
								{{ 'INFORMES.EXPANDIRVISTA' | translate }}
								<nb-icon icon="info-outline" class="pointer m-1" nbTooltip="{{ 'CURSOS.INFOGRAPHIC' | translate }}"
									nbTooltipPlacement="top" (click)="open(dialog)" *ngIf="loginService.esEstudiante()">
								</nb-icon>
							</h5>

							<div class="filtros" [ngClass]="{ 'show-filters': showElement }">
								<div class="item-filtros">
									<nb-form-field [formGroup]="formulario" class="w-100">
										<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
											placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
										<button nbSuffix nbButton ghost size="small">
											<nb-icon class="pointer" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
										</button>
									</nb-form-field>
									<div>
										<nb-select name="lang1" placeholder="small" class="SelectForm" hero size="small"
											[(selected)]="selectedOrder" (selectedChange)="changeOrder($event)">
											<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">{{
												order.nombre }}
											</nb-option>
										</nb-select>
									</div>
									<div>
										<nb-actions size="small">
											<nb-action icon="clock-outline" nbTooltip="Historial" nbTooltipPlacement="top"
												(click)="historyFilter()">
											</nb-action>
											<nb-action icon="funnel-outline" [nbPopover]="filtersTemplate" nbPopoverPlacement="bottom"
												nbPopoverTrigger="noop" (click)="openFiltersPanel()">
											</nb-action>
											<ng-template #filtersTemplate>
												<nb-card class="m-0 background_themecolor" style="box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,.4);">
													<nb-card-header>
														<p>{{'FILTER.FILTER' | translate}}</p>
														<button nbButton class="btn-themecolor" size="small" type="button" (click)="clearFilters()">
															<span>{{'FILTER.CLEARFILTER' | translate}}</span>
														</button>
													</nb-card-header>
													<nb-card-body class="container-filters">

														<div class="column-filters">
															<nb-select name="idProjectsTypes" class="SelectForm"
																placeholder="{{'FILTER.PROJECTTYPE' | translate}}" hero size="small"
																[(selected)]="selectedProjectType" (selectedChange)="applyFilters($event, 1)">
																<nb-option class="SelectFormOpt" *ngFor="let projectType of projectTypeList"
																	[value]="projectType.idProjectsTypes">{{ projectType.descripcion }}</nb-option>
															</nb-select>

															<nb-select name="lang1" class="SelectForm" placeholder="{{'FILTER.LANGUAGE' | translate}}"
																hero size="small" [(selected)]="selectedLang" (selectedChange)="applyFilters($event,2)">
																<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">{{
																	lang.nombre }}
																</nb-option>
															</nb-select>

															<nb-select name="subject" class="SelectForm"
																placeholder="{{'FILTER.SUBJECT' | translate}}" hero size="small"
																[(selected)]="selectedSubject" (selectedChange)="applyFilters($event,3)">
																<nb-option class="SelectFormOpt" *ngFor="let subject of subjectsList"
																	[value]="subject.idSubject">{{ subject.subject }}
																</nb-option>
															</nb-select>

															<!-- <nb-select name="idCountry" class="SelectForm" hero size="small" [(selected)]="selectedCountry" placeholder="pais"
															(selectedChange)="searchLevels($event);applyFilters($event,3);">
															<nb-option class="SelectFormOpt" *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{ country.country_es }}
															</nb-option>
														</nb-select> -->

															<nb-select name="idCountry" class="SelectForm" [(selected)]="selectedCountry" hero
																size="small" placeholder="{{'FILTER.COUNTRY' | translate}}"
																(change)="searchLevels($event);applyFilters($event,4);">
																<ng-option class="SelectFormOpt" *ngFor="let country of countriesList"
																	value="{{ country.idCountry }}">{{ country.country_es }}</ng-option>
															</nb-select>

															<nb-select name="idCountryLevel" class="SelectForm" hero size="small"
																[(selected)]="selectedLevelCountry"
																[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
																[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,5)">
																<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry"
																	[value]="level.idCountryLevel">{{ level.countryLevel }}
																</nb-option>

																<nb-option
																	*ngIf="notCountryLevelsForThisCountry">{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY'
																	|
																	translate}}</nb-option>
															</nb-select>





														</div>
														<div class="column-filters">
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
																(click)="clearFiltersList(1)" [disabled]="selectedProjectType == null"><nb-icon
																	icon="close"></nb-icon></button>
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
																(click)="clearFiltersList(2)" [disabled]="selectedLang == null"><nb-icon
																	icon="close"></nb-icon></button>
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
																(click)="clearFiltersList(3)" [disabled]="selectedSubject == null"><nb-icon
																	icon="close"></nb-icon></button>
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
																(click)="clearFiltersList(4)" [disabled]="selectedCountry == null"><nb-icon
																	icon="close"></nb-icon></button>
															<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
																(click)="clearFiltersList(5)" [disabled]="selectedLevelCountry == null"><nb-icon
																	icon="close"></nb-icon></button>
														</div>

													</nb-card-body>
												</nb-card>
											</ng-template>
										</nb-actions>
									</div>
									<div class="d-flex flex-column gap-2 align-items-center" *ngIf="loginService.esEstudiante()">
										<nb-toggle class="item-profile" id="mycourses" [formControl]="isMyCourses"
											(change)="checkValue()"></nb-toggle>
										<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{'CURSOS.MYCOURSES' |
											translate}}</span>
									</div>

									<div class="d-flex flex-column gap-2 align-items-center"
											*ngIf="loginService.esEstudiante() && infoGrupo != null">
										<nb-toggle #toggleGroupProjects class="item-profile" id="mycourses" (change)="getListadoCursosGrupo($event)"
												checked="true"></nb-toggle>
										<span class="text-center" style="line-height: 1rem; font-size: 12px;">
											{{ 'MENUACTIONS.STUDENTTITLE2b' | translate }}
										</span>
									</div>

									<div class="d-flex flex-column gap-2 align-items-center" *ngIf="viewList">
										<nb-toggle class="item-profile" id="activateHelpList" [formControl]="activateHelpList"
											(change)="helpListToggle(activateHelpList.value)"></nb-toggle>
										<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{ 'SOS.HELPLIST' |
											translate}}</span>
									</div>

								</div>
							</div>

							<app-mat-tree-cursos [isHelpList]="activateHelpList.value" [idCourse]="idCourse"
								[treeCourses]="treeCourses" [myCourses]="myCourses" [coursesInGroup]="coursesInGroup" (clickElement)="clickElement($event)"
								(createNewElement)="createNewElement($event)">
							</app-mat-tree-cursos>

						</div>
					</div>

					<div *ngIf="!viewList" class="filtros" [ngClass]="{ 'show-filters': showElement }">
						<div class="item-filtros">
							<nb-form-field [formGroup]="formulario" class="w-100">
								<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
									placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
								<button nbSuffix nbButton ghost size="small">
									<nb-icon class="pointer" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
								</button>
							</nb-form-field>
							<div>
								<nb-select name="lang1" placeholder="small" class="SelectForm" hero size="small"
									[(selected)]="selectedOrder" (selectedChange)="changeOrder($event)">
									<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">{{
										order.nombre }}
									</nb-option>
								</nb-select>
							</div>
							<div>
								<nb-actions size="small">
									<nb-action icon="clock-outline" nbTooltip="Historial" nbTooltipPlacement="top"
										(click)="historyFilter()">
									</nb-action>
									<nb-action icon="funnel-outline" [nbPopover]="filtersTemplate" nbPopoverPlacement="bottom"
										nbPopoverTrigger="noop" (click)="openFiltersPanel()">
									</nb-action>
									<ng-template #filtersTemplate>
										<nb-card class="m-0 background_themecolor" style="box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,.4);">
											<nb-card-header>
												<p>{{'FILTER.FILTER' | translate}}</p>
												<button nbButton class="btn-themecolor" size="small" type="button" (click)="clearFilters()">
													<span>{{'FILTER.CLEARFILTER' | translate}}</span>
												</button>
											</nb-card-header>
											<nb-card-body class="container-filters">

												<div class="column-filters">
													<nb-select name="idProjectsTypes" class="SelectForm"
														placeholder="{{'FILTER.PROJECTTYPE' | translate}}" hero size="small"
														[(selected)]="selectedProjectType" (selectedChange)="applyFilters($event, 1)">
														<nb-option class="SelectFormOpt" *ngFor="let projectType of projectTypeList"
															[value]="projectType.idProjectsTypes">{{ projectType.descripcion }}</nb-option>
													</nb-select>

													<nb-select name="lang1" class="SelectForm" placeholder="{{'FILTER.LANGUAGE' | translate}}"
														hero size="small" [(selected)]="selectedLang" (selectedChange)="applyFilters($event,2)">
														<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">{{
															lang.nombre }}
														</nb-option>
													</nb-select>

													<nb-select name="subject" class="SelectForm" placeholder="{{'FILTER.SUBJECT' | translate}}"
														hero size="small" [(selected)]="selectedSubject" (selectedChange)="applyFilters($event,3)">
														<nb-option class="SelectFormOpt" *ngFor="let subject of subjectsList"
															[value]="subject.idSubject">{{ subject.subject }}
														</nb-option>
													</nb-select>

													<!-- <nb-select name="idCountry" class="SelectForm" hero size="small" [(selected)]="selectedCountry" placeholder="pais"
													(selectedChange)="searchLevels($event);applyFilters($event,3);">
													<nb-option class="SelectFormOpt" *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{ country.country_es }}
													</nb-option>
												</nb-select> -->

													<nb-select name="idCountry" class="SelectForm" [(selected)]="selectedCountry" hero
														size="small" placeholder="{{'FILTER.COUNTRY' | translate}}"
														(change)="searchLevels($event);applyFilters($event,4);">
														<ng-option class="SelectFormOpt" *ngFor="let country of countriesList"
															value="{{ country.idCountry }}">{{ country.country_es }}</ng-option>
													</nb-select>

													<nb-select name="idCountryLevel" class="SelectForm" hero size="small"
														[(selected)]="selectedLevelCountry"
														[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
														[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,5)">
														<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry"
															[value]="level.idCountryLevel">{{ level.countryLevel }}
														</nb-option>

														<nb-option
															*ngIf="notCountryLevelsForThisCountry">{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY'
															|
															translate}}</nb-option>
													</nb-select>

												</div>
												<div class="column-filters">
													<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
														(click)="clearFiltersList(1)" [disabled]="selectedProjectType == null"><nb-icon
															icon="close"></nb-icon></button>
													<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
														(click)="clearFiltersList(2)" [disabled]="selectedLang == null"><nb-icon
															icon="close"></nb-icon></button>
													<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
														(click)="clearFiltersList(3)" [disabled]="selectedSubject == null"><nb-icon
															icon="close"></nb-icon></button>
													<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
														(click)="clearFiltersList(4)" [disabled]="selectedCountry == null"><nb-icon
															icon="close"></nb-icon></button>
													<button nbButton shape="round" size="small" class="btn-themecolor" type="button"
														(click)="clearFiltersList(5)" [disabled]="selectedLevelCountry == null"><nb-icon
															icon="close"></nb-icon></button>
												</div>

											</nb-card-body>
										</nb-card>
									</ng-template>
								</nb-actions>
							</div>
							<div class="d-flex flex-column gap-2 align-items-center" *ngIf="loginService.esEstudiante()">
								<nb-toggle class="item-profile" id="mycourses" [formControl]="isMyCourses"
									(change)="checkValue()"></nb-toggle>
								<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{'CURSOS.MYCOURSES' |
									translate}}</span>
							</div>

							<div class="d-flex flex-column gap-2 align-items-center"
								*ngIf="(loginService.esEstudiante() || loginService.esProfesor()) && infoGrupo != null">
								<nb-toggle class="item-profile" id="mycourses" (change)="getListadoCursosGrupo($event)"
									[checked]="loginService.esEstudiante()"></nb-toggle>
								<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{'MENUACTIONS.STUDENTTITLE2b' |
									translate}}</span>
							</div>

							<div class="d-flex flex-column gap-2 align-items-center" *ngIf="viewList">
								<nb-toggle class="item-profile" id="activateHelpList" [formControl]="activateHelpList"
									(change)="helpListToggle(activateHelpList.value)"></nb-toggle>
								<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{ 'SOS.HELPLIST' |
									translate}}</span>
							</div>

						</div>
					</div>
				</div>
				<!-- Version web -->
				<div *ngIf="!isMobile;" class="d-flex align-items-center gap-4">
					<div class="d-flex flex-column align-items-center">
						<nb-select name="lang1" class="SelectForm" [(selected)]="selectedOrder" placeholder="small" hero
							size="small" (selectedChange)="changeOrder($event)">
							<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">{{ order.nombre
								}}
							</nb-option>
						</nb-select>
					</div>

					<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center" *ngIf="loginService.esEstudiante()">
						<nb-toggle class="item-profile" id="mycourses" [formControl]="isMyCourses"
							(change)="changeMyCourses($event)"></nb-toggle>
						<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{'CURSOS.MYCOURSES' |
							translate}}</span>
					</div>

					<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center"
						*ngIf="(loginService.esEstudiante() || loginService.esProfesor()) && infoGrupo != null">
						<nb-toggle class="item-profile" id="coursesInGroup" (change)="changeCoursesInGroup($event)"
							[checked]="loginService.esEstudiante()"></nb-toggle>
						<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{'MENUACTIONS.STUDENTTITLE2b' |
							translate}}</span>
					</div>

					<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center" *ngIf="viewList">
						<nb-toggle class="item-profile" id="activateHelpList" [formControl]="activateHelpList"
							(change)="helpListToggle(activateHelpList.value)"></nb-toggle>
						<span class="text-center" style="line-height: 1rem; font-size: 12px;">{{ 'SOS.HELPLIST' | translate}}</span>
					</div>

					<nb-actions size="small">
						<nb-action icon="clock-outline" [ngClass]="{'selected-icons': historyFilterActive}" nbTooltip="Historial"
							nbTooltipPlacement="top" (click)="historyFilter()"></nb-action>

						<nb-action icon="funnel-outline" [ngClass]="{'selected-icons': iconSelected}" [nbPopover]="filtersTemplate"
							nbPopoverPlacement="bottom" nbTooltip="Filtros" nbTooltipPlacement="top" nbPopoverTrigger="noop"
							(click)="openFiltersPanel()"></nb-action>

						<ng-template #filtersTemplate>
							<nb-card class="m-0 background_themecolor" style="box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,.4);">
								<nb-card-header class="border-bottom-0">
									<p>{{'FILTER.FILTER' | translate}}</p>
									<div class="d-flex justify-content-center">
										<button nbButton size="small" class="btn-themecolor" type="button"
											(click)="clearFilters();selectLang.handleClearClick();selectSubject.handleClearClick();selectCountry.handleClearClick();clearFiltersList(4);clearFilters();">
											<span>{{'FILTER.CLEARFILTER' | translate}}</span>
										</button>
									</div>
								</nb-card-header>
								<nb-card-body class="container-filters">

									<div class="column-filters">
										<!-- <nb-select name="lang1" class="SelectForm" placeholder="Idioma" hero size="small" [(selected)]="selectedLang"
														(selectedChange)="applyFilters($event,1)">
														<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">{{ lang.nombre }}
														</nb-option>
													</nb-select> -->
										<nb-select #selectProjectType name="idProjectsTypes" class="SelectForm" hero size="small"
											placeholder="{{'FILTER.PROJECTTYPE' | translate}}" [(selected)]="selectedProjectType"
											(selectedChange)="applyFilters($event,0)" *ngIf="!filtroInicial">
											<nb-option class="SelectFormOpt" *ngFor="let projectType of projectTypeList"
												[value]="projectType.id">{{ projectType.descripcion }}
											</nb-option>
										</nb-select>

										<nb-select #selectLang name="lang1" class="SelectForm" hero size="small"
											placeholder="{{'FILTER.LANGUAGE' | translate}}" [(selected)]="selectedLang"
											(selectedChange)="applyFilters($event,1)">
											<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">{{
												lang.nombre }}</nb-option>
										</nb-select>

										<!-- <nb-select name="subject" class="SelectForm" placeholder="Asignatura" hero size="small" [(selected)]="selectedSubject"
										(selectedChange)="applyFilters($event,2)">
										<nb-option class="SelectFormOpt" *ngFor="let subject of subjectsList" [value]="subject.idSubject">{{ subject.subject }}
										</nb-option>
									</nb-select> -->

										<nb-select #selectSubject name="subject" class="SelectForm" hero size="small"
											placeholder="{{'FILTER.SUBJECT' | translate}}" [(selected)]="selectedSubject"
											(selectedChange)="applyFilters($event,2)">
											<nb-option class="SelectFormOpt" *ngFor="let subject of subjectsList"
												[value]="subject.idSubject">{{ subject.subject }}</nb-option>
										</nb-select>

										<!-- <nb-select name="idCountry" class="SelectForm" hero size="small" [(selected)]="selectedCountry" placeholder="pais"
										(selectedChange)="searchLevels($event);applyFilters($event,3);">
										<nb-option class="SelectFormOpt" *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{ country.country_es }}
										</nb-option>
									</nb-select> -->

										<nb-select #selectCountry name="idCountry" class="SelectForm" hero size="small"
											[(selected)]="selectedCountry" placeholder="{{'FORM.SPAIN' | translate}}"
											(selectedChange)="searchLevels($event);applyFilters($event,3);">
											<nb-option class="SelectFormOpt" *ngFor="let country of countriesList"
												value="{{ country.idCountry }}">{{ country.country_es }}</nb-option>
										</nb-select>

										<nb-select name="idCountryLevel" class="SelectForm" hero size="small"
											[(selected)]="selectedLevelCountry"
											[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
											[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,4)">
											<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry"
												[value]="level.idCountryLevel">{{ level.countryLevel }}</nb-option>
											<nb-option class="SelectFormOpt"
												*ngIf="notCountryLevelsForThisCountry">{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' |
												translate}}</nb-option>
										</nb-select>

										<nb-toggle labelPosition="end" class="item-profile" id="mycourses" [(ngModel)]="trasversalProjects"
										(change)="applyFilters($event, 5)">{{'CURSOS.TRANSVERSAL' | translate}}</nb-toggle>


									</div>
									<!-- <div class="column-filters">
									<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(1)"
										[disabled]="selectedLang == null"><nb-icon icon="close"></nb-icon></button>
									<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(2)"
										[disabled]="selectedSubject == null"><nb-icon icon="close"></nb-icon></button>
									<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(3)"
										[disabled]="selectedCountry == null"><nb-icon icon="close"></nb-icon></button>
									<button nbButton shape="round" size="small" class="btn-themecolor" type="button" (click)="clearFiltersList(4)"
										[disabled]="selectedLevelCountry == null"><nb-icon icon="close"></nb-icon></button>
								</div> -->


								</nb-card-body>
							</nb-card>
						</ng-template>

						<nb-action *ngIf="viewList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(false)"></nb-action>

						<nb-action *ngIf="!viewList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(true)"></nb-action>

						<nb-action class="icon-option icon-proyecto-muchos-grafos position-relative pointer"
							nbTooltip="{{ 'CURSOS.NUEVOCURSO' | translate }}" nbTooltipPlacement="top" *ngIf="loginService.esAutor()"
							(click)="nuevoCurso()">
							<nb-icon class="plus-editor" icon="plus-outline"></nb-icon>
						</nb-action>

					</nb-actions>
				</div>
			</div>
			<!-- Buscador -->
			<div *ngIf="!isMobile" class="col-12 col-xl-4 d-flex align-items-center">
				<nb-form-field [formGroup]="formulario" class="w-100">
					<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
						placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
					<button nbSuffix nbButton ghost size="small">
						<nb-icon class="pointer" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
					</button>
				</nb-form-field>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
		<!-- VISTA MODO GRILLA -->
		<div *ngIf="!viewList" class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="col-12">
				<app-loading [isVisible]="cargando"></app-loading>
				<div *ngIf="cursos.length === 0">
					<nb-card class="no-edge-card">
						<nb-card-body>
							<h4 class="text-center">{{ 'CURSOS.FILTERNOTMATCH' | translate }}</h4>
						</nb-card-body>
					</nb-card>
				</div>
				<div *ngIf="cursos.length !== 0">
					<div class="d-flex flex-wrap gap-5 justify-content-center" *ngIf="!viewList">
						<div *ngFor="let curso of cursos" class="card-projects"
								[ngStyle]="{'display':
								(myCourses && coursesInGroup && curso.inGroup == 1 && curso.isEditor == 1) ||
								(myCourses && !coursesInGroup && curso.isEditor == 1) ||
								(!myCourses && coursesInGroup && curso.inGroup == 1) ||
								(!myCourses && !coursesInGroup)
								? 'block' : 'none'}">
							<div [ngClass]="{'nb-card-dark-overlay' : curso.inGroup == 0 && loginService.esProfesor()}">
								<nb-card (click)="verDetalleCurso(curso)" class="pointer mb-0"
								[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4}">
									<nb-card-header class="pb-2 pl-0 pr-0 pt-0 border-bottom-0" nbTooltip="{{curso.courseTittle}}"
										nbTooltipPlacement="top">
										<!--ocultamos la imagen si la conexion es lenta-->
										<div class="img-container" nbTooltip="{{curso.description}}" nbTooltipPlacement="top">
											<img [src]="curso.cpicture | imagen:'cursos'" class="img-fluid" (error)="updateUrl($event)"
												*ngIf="networkSpeed >= 1">
											<img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
										</div>
									</nb-card-header>
									<nb-card-body class="p-2 d-flex justify-content-center align-items-center">
										<p *ngIf="isStudent == true" class="courseTittle">
											{{ curso.courseTittle }} <span style="font-weight: bold;">({{ curso.countryLevelObj[0][2] ? curso.countryLevelObj[0][2] : 'Sin nivel especifico' | truncate:[15, '...'] }})</span>
										</p>
										<p *ngIf="isStudent == false" class="courseTittle-teacher">
											{{ curso.courseTittle }} <span style="font-weight: bold;">({{ curso.countryLevelObj[0][2] ? curso.countryLevelObj[0][2] : 'Sin nivel especifico' | truncate:[15, '...'] }})</span>
										</p>
									</nb-card-body>
									<nb-card-footer *ngIf="!actividadesFlash" class="border-top-0">
										<div style="position: relative;" class="d-flex justify-content-center flex-wrap pb-2">
											<div *ngFor="let subject of curso.courseSubjects" style="margin-right: 5px;">
												<nb-tag size="tiny" [style.background]="subject.color" appearance="filled" style="position: relative; z-index: 99999;"
																[text]="subject.acronym" nbTooltip="{{subject.subject}}" nbTooltipPlacement="top"></nb-tag>
											</div>
											<!--
											<nb-tag size="tiny" [style.background]="'#'+curso.countryLevelObj[0][3]" appearance="filled"
												[text]="curso.countryLevelObj[0][2] ? curso.countryLevelObj[0][2] : 'Sin nivel especifico' | truncate:[15, '...'] "></nb-tag>
											<button [nbPopover]="listOfSubjects" nbPopoverPlacement="bottom"
												style="position: relative; z-index: 99999;" nbPopoverTrigger="click" size="tiny" nbButton
												status="basic" (click)="preventDetalle($event)">
												<nb-icon icon="more-horizontal-outline"></nb-icon>
											</button>
											<ng-template #listOfSubjects>
												<nb-card class="mb-0 no-edge-card background_themecolor" style="box-shadow: 0px 0px 20px #000 !important;">
													<nb-card-header class="border-bottom-0">
														{{ 'CURSOS.SUBJECTS' | translate }}
													</nb-card-header>
													<nb-card-body>
														<nb-list>
															<nb-list-item *ngFor="let subject of curso.courseSubjects" class="border-bottom-0 border-top-0">
																<nb-tag size="tiny" [style.background]="getSubjectColor(subject.subject)" appearance="filled"
																	[text]="subject.subject"></nb-tag>
															</nb-list-item>
														</nb-list>
													</nb-card-body>
												</nb-card>
											</ng-template>
											-->
										</div>
										<div class="footer d-flex justify-content-center align-items-center mr-1 ml-1" *ngIf="isStudent == true">
											<div class="averageKnowledge mr-1">{{curso.averageKnowledge }}</div>
											<div class="progress half-width">
												<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
													aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
													[style.backgroundColor]="curso.barColor" [style.width]="curso.progress+'%'"></div>
											</div>
											<div class="averageKnowledge ml-1">{{curso.progress+'%'}}</div>
										</div>
									</nb-card-footer>
								</nb-card>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex justify-content-center mt-5">
					<nb-card class="no-edge-card mb-0">
						<nb-card-body>
							<span class="text-center pointer" style="color: green; font-size: 40px;" (click)="showGuiaModal()">
								{{ 'CURSOS.NOTFOUND' | translate }}
							</span>
						</nb-card-body>
					</nb-card>
				</div>
			</div>
		</div>
		<!-- VISTA MODO LISTA -->
		<div *ngIf="viewList" class="row min-h-100 gap-4">

			<div *ngIf="!isMobile;" class="col-12 col-xl-4 py-4 pl-4 pr-0 container-tree background_white">
				<div class="d-flex align-items-center pr-5">
					<h5>
						{{ 'INFORMES.EXPANDIRVISTA' | translate }}
						<nb-icon icon="info-outline" class="pointer m-1" nbTooltip="{{ 'CURSOS.INFOGRAPHIC' | translate }}"
							nbTooltipPlacement="top" (click)="open(dialog)" *ngIf="loginService.esEstudiante()">
						</nb-icon>
					</h5>
				</div>
				<app-mat-tree-cursos [isHelpList]="activateHelpList.value" [idCourse]="idCourse" [treeCourses]="treeCourses"
					[myCourses]="myCourses" [coursesInGroup]="coursesInGroup" (clickElement)="clickElement($event)" (createNewElement)="createNewElement($event)">
				</app-mat-tree-cursos>
			</div>

			<div class="col py-4 pl-xl-4 pr-xl-0 container-tree background_white">
				<!-- BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->
				<div id="botones-multiples" *ngIf="!loginService.esEstudiante() && (quiz && quiz.idMultiplexQuiz === 1)"
					class="d-flex justify-content-center align-items-center flex-wrap">
					<button *ngFor="let hijo of listQM; let i = index"
						[ngClass]="{'boton-qM': true, 'boton-qM-selected': i === selectedButtonIndex}" [class.flex-grow-1]="true"
						(click)="selectButton(i, hijo.quizMultiple.idQuizMultiple)">
						{{ i + 1 }}
					</button>
				</div>
				<!-- END BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->

				<!-- <div class="row justify-content-center">
					<div class="w-100 col-sm-12 col-md-10 mb-3">

						<h5 class="text-title text-center font-weight-bold">
							{{ 'INFORMES.TABLA_DATOS_QUIZES' | translate }}
						</h5>

					</div>
				</div> -->

				<div class="w-100 height-max">
					<div *ngIf="!(node || quiz)" class="d-flex align-items-center justify-content-center h-100">
						<h6>
							{{ 'CURSOS.SELECTPROJECT' | translate }}
						</h6>
					</div>
					<!-- CONTAINER CON LAS OPCIONES SELECCIONADAS EN LA COLUMNA DE LA IZQUIERDA-->
					<div class="d-flex align-items-center justify-content-center pt-5" *ngIf="isLoading && !idCourse">
						<!--   <mat-spinner [diameter]="50" ></mat-spinner>           -->
					</div>
					<!-- VISUALIZAR NODO -->
					<div (mousemove)="showFiles(true)" (click)="showFiles(true)" *ngIf="node && !isLoading">
						<app-node-detail-files [showInContainer]="true" [showFilesContainer]="isShowFiles" [node]="node"
							[onSaveClicked$]="saveClicked$" [canEdit]="false" (fileSelected)="onFileSelected($event)"
							(onHoverElements)="showFiles($event)"></app-node-detail-files>
					</div>
					<!--FIN VISUALIZAR NODO -->
					<!-- VISUALIZAR QUIZ -->
					<div *ngIf="quiz && !isLoading">
						<!-- SIMPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container p-3 pr-xl-4 hide-scrollbars" id="container">
								<app-quiz-play-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse" [graphId]="idGraph"
									[quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true" [modeSocket]="false"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()">
								</app-quiz-play-body>
							</div>
						</div>
						<!-- END SIMPLE QUIZ -->
						<!-- MULTIPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container p-3 pr-xl-4 hide-scrollbars">
								<app-quiz-play-multiple-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse"
									[graphId]="idGraph" [quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()"
									[listQM]="listQM">
								</app-quiz-play-multiple-body>
							</div>
						</div>
						<!-- END MULTIPLE QUIZ -->
					</div>
					<!-- FIN VISUALIZAR QUIZ -->
				</div>
			</div>

			<ng-template #dialog let-data let-ref="dialogRef">
				<nb-card class="position-relative">
					<nb-card-body>
						<div class="d-flex">
							<img src="assets/images/leyenda-ideografo.png" class="img-info">
						</div>
					</nb-card-body>
					<div class="closeModal">
						<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
							placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="ref.close()">
						</nb-icon>
					</div>
				</nb-card>
			</ng-template>

		</div>
		<!-- FIN VISTA MODO ARBOL -->
	</nb-card-body>

</nb-card>
