<div class="d-flex flex-wrap gap-5 justify-content-center">
	<div *ngFor="let dataset of datasets[0].data; let j = index" class="card-Mygroup">
		<nb-card class="group-info border-body mb-0" [ngStyle]="{'background-color': colorKnowledge(dataset)}">
			<nb-card-header class="border-header">
				<div class="img-container">
					<img [src]="fotosEstudiantes[j]" class="img-fluid" *ngIf="consultaID == 8 || consultaID == 9 || consultaID == 21 || consultaID == 22">
					<img [src]="fotosProfesores[j]" class="img-fluid" *ngIf="consultaID == 1 || consultaID == 2">
					<img [src]="fotosPadres[j + 1]" class="img-fluid" *ngIf="consultaID == 12 || consultaID == 13">
				</div>
			</nb-card-header>
			<nb-card-footer class="p-2">
				<div class="d-flex flex-column" nbTooltip="{{labels[j]}}" nbTooltipPlacement="left">
					<span class="text-name">{{labels[j]}}</span>
					<ng-container [ngSwitch]="parametroID">
						<span *ngSwitchCase="1">({{dataset}}%)</span>
						<span *ngSwitchCase="2">({{dataset}})</span>
					</ng-container>
				</div>
			</nb-card-footer>
		</nb-card>
	</div>
</div>
