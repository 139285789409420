<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62" (click)="closeFiltersPanel()">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">

		<div class="row p-4 background_white gap">
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4>Estudiantes</h4>
			</div>
		</div>

		<!-- Filtros -->
		<div class="row px-3 py-2 mt-3">
			<div class="col-12 col-xl-8">
				<div  class="d-flex align-items-center gap-4">
					<div class="d-flex flex-column align-items-center">
						<nb-select name="lang1" class="SelectForm" [(selected)]="selectedOrder" placeholder="small" hero
							size="small" (selectedChange)="changeOrder($event)">
							<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">{{ order.nombre
								}}
							</nb-option>
						</nb-select>
					</div>


					<nb-actions size="small">
						<nb-action icon="clock-outline" [ngClass]="{'selected-icons': historyFilterActive}" nbTooltip="Historial"
							nbTooltipPlacement="top" (click)="historyFilter()"></nb-action>

						<nb-action icon="funnel-outline" [ngClass]="{'selected-icons': iconSelected}"
							nbPopoverPlacement="bottom" nbTooltip="Filtros" nbTooltipPlacement="top" nbPopoverTrigger="noop"
							(click)="openFiltersPanel()"></nb-action>

						<ng-template #filtersTemplate>
							<nb-card class="m-0 background_themecolor" style="box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,.4);">
								<nb-card-header class="border-bottom-0">
									<p>{{'FILTER.FILTER' | translate}}</p>
									<div class="d-flex justify-content-center">
										<button nbButton size="small" class="btn-themecolor" type="button"
											(click)="clearFilters();selectLang.handleClearClick();selectSubject.handleClearClick();selectCountry.handleClearClick();clearFiltersList(4);clearFilters();">
											<span>{{'FILTER.CLEARFILTER' | translate}}</span>
										</button>
									</div>
								</nb-card-header>

							</nb-card>
						</ng-template>


					</nb-actions>
				</div>
			</div>
			<div *ngIf="formulario" class="col-12 col-xl-4 d-flex align-items-center">
				<!-- <nb-form-field [formGroup]="formulario" class="w-100">
					<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
						placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
					<button nbSuffix nbButton ghost size="small">
						<nb-icon class="pointer" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
					</button>
				</nb-form-field> -->
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
		 (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
		<!-- VISTA MODO GRILLA -->
		<div  class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="col-12">
				<div *ngIf="comunidades.length === 0">
					<nb-card class="no-edge-card">
						<nb-card-body>
							<h4 class="text-center">{{ 'CURSOS.FILTERNOTMATCH' | translate }}</h4>
						</nb-card-body>
					</nb-card>
				</div>
				<div *ngIf="comunidades.length !== 0">

					<nb-accordion *ngFor="let comunidad of comunidades">
						<nb-accordion-item>
						 <nb-accordion-item-header>{{comunidad.nombre}}</nb-accordion-item-header>
						 <nb-accordion-item-body>
							<div class="row d-flex cards-container">
								<div *ngFor="let center of comunidad.centros_educativos" class="col-xxl-1 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
									<nb-card size="small" (click)="modalActionsOfCenter()" [ngStyle]="{'background-color': center.color}">
										<nb-card-header>{{center.nombre}}</nb-card-header>
										<nb-card-body>
											<div class="d-flex justify-content-center">
												<img [src]="center.imagen" alt="logo de el centro" class="student-avatar" width="150" height="150">
											</div>
										</nb-card-body>
										<nb-card-footer>{{center.tipo}}</nb-card-footer>
									</nb-card>
								</div>
							</div>
						 </nb-accordion-item-body>
						</nb-accordion-item>
					</nb-accordion>


				</div>
			</div>
		</div>

	</nb-card-body>

</nb-card>
