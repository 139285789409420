import { Component, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalTasksComponent } from '../../modal-tasks/modal-tasks.component';

@Component({
  selector: 'app-body-select-recipient',
  templateUrl: './body-select-recipient.component.html',
  styleUrls: ['./body-select-recipient.component.scss']
})
export class BodySelectRecipientComponent implements OnInit {
    profesores = [
        { id:1, nombre: 'profesor 1'
        },
        { id:2, nombre: 'profesor 2'
        },
        { id:3, nombre: 'profesor 3'
        },
        { id:4, nombre: 'profesor 4'
        },
        { id:5, nombre: 'profesor 5'
        },
        { id:6, nombre: 'profesor 6'
        },
        { id:7, nombre: 'profesor 7'
        },
        { id:8, nombre: 'profesor 8'
        }
      ];
    grupos= [
        { id:1, nombre: 'grupo 1'
        },
        { id:2, nombre: 'grupo 2'
        },
        { id:3, nombre: 'grupo 3'
        },
        { id:4, nombre: 'grupo 4'
        },
        { id:5, nombre: 'grupo 5'
        },
        { id:6, nombre: 'grupo 6'
        },
        { id:7, nombre: 'grupo 7'
        },
        { id:8, nombre: 'grupo 8'
        }
      ];

      alumnos= [
        { id:1, nombre: 'estudiante 1', img:'../../../assets/images/estudiante.png', fecha:'11-10-2020'
        },
        { id:2, nombre: 'estudiante 2', img:'../../../assets/images/estudiante.png', fecha:'11-10-2020'
        },
        { id:3, nombre: 'estudiante 3', img:'../../../assets/images/estudiante.png', fecha:'10-10-2020'
        },
        { id:4, nombre: 'estudiante 4', img:'../../../assets/images/estudiante.png', fecha:'10-10-2020'
        },
        { id:5, nombre: 'estudiante 5', img:'../../../assets/images/estudiante.png', fecha:'09-10-2020'
        },
        { id:6, nombre: 'estudiante 6', img:'../../../assets/images/estudiante.png', fecha:'08-10-2020'
        },
        { id:7, nombre: 'estudiante 7', img:'../../../assets/images/estudiante.png', fecha:'08-10-2020'
        },
        { id:8, nombre: 'estudiante 8', img:'../../../assets/images/estudiante.png', fecha:'07-10-2020'
        }
      ];

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  public compartirTareas(){
    const modalRef = this.modalService.open(ModalTasksComponent,
        {
          scrollable: true,
          windowClass: MODAL_DIALOG_TYPES.W95
        })

        modalRef.componentInstance.idCurso = 1;
        modalRef.result.then((result) => {
            console.log(result);
          }, (reason) => {
        });
}
}
