import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableInformeIndividualComponent } from './table-informe-individual.component';
import { NbButtonModule, NbCardModule, NbLayoutModule, NbProgressBarModule, NbActionsModule, NbInputModule, NbIconModule, NbSearchModule, NbToggleModule, NbSelectModule, NbDatepickerModule, NbTooltipModule, NbTabsetModule } from '@nebular/theme';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from '@angular/material/core'
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
	declarations: [TableInformeIndividualComponent],
	imports: [
		CommonModule,
		NbButtonModule,
		NbCardModule,
		NbTabsetModule,
		NbLayoutModule,
		NbProgressBarModule,
		NbActionsModule,
		NbInputModule,
		NbIconModule,
		NbSearchModule,
		NbToggleModule,
		NbSelectModule,
		NbDatepickerModule,
		NbTooltipModule,
		MaterialModule, // <----- this module will be deprecated in the future version.
		MatDatepickerModule, // <----- import(must)
		MatNativeDateModule, // <----- import for date formating(optional)
		MatMomentDateModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModalModule,
		NgSelectModule,
	],
})
export class TableInformeIndividualModule {}
