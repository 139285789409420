<nb-card class="mb-0 modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10 modal-title">
			<h2>{{ "SETTINGS.GENERALTITLE" | translate }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
			placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body *ngIf="userData">
		<div *ngIf="screenView === 1">
			<form
				[formGroup]="formularioDatos"
				(ngSubmit)="grabarDatos()"
				#thisForm="ngForm">
				<nb-card class="project-info no-edge-card mb-0">
					<app-loading [isVisible]="loading"></app-loading>
					<nb-card-body class="p-0">
						<app-alert context="UserData"></app-alert>
						<nb-card class="no-edge-card mb-0" >
							<nb-card-header>
								<p class="text-alert mb-4" *ngIf="!userData.profileEditor">
									{{ "PARENTS.ACCESSLIMITED" | translate }}
									<a class="pointer" (click)="ajustes()"><b>{{ "PARENTS.PUSHTOASSIGN" | translate }}</b></a>
								<h6>{{ "LOGIN.DATAPROFILE" | translate }}</h6>
							</nb-card-header>

							<nb-card-body>
								<div class="row align-items-center">
									<div class="col-md-6">
										<app-image-profile [image]="userData.pictureUser"	*ngIf="userData" ></app-image-profile>
									</div>
									<div class="col-md-6 container-profile">
										<div class="profile-toggle">
											<div class="item-toggle">
												<nb-toggle labelPosition="end" [checked]="true" disabled>
												</nb-toggle>
												<span nbTooltip="{{ 'LOGIN.TITLEEDITOR' | translate }}" nbTooltipPlacement="bottom"
													nbTooltipStatus="primary" nbButton status="primary">{{ "LOGIN.EDITOR" | translate }}</span>
											</div>

											<ng-container *ngIf="isAdult">
												<div class="item-toggle">
													<nb-toggle labelPosition="end" formControlName="profileParent" (change)="guardarPerfil()"></nb-toggle>
													<span nbTooltip="{{ 'LOGIN.PARENT' | translate }}" nbTooltipPlacement="bottom" nbTooltipStatus="primary"
														nbButton status="primary">{{ "LOGIN.PARENT" | translate }}</span>
												</div>
											</ng-container>
										</div>

										<div class="profile-toggle">
											<div class="item-toggle">
												<nb-toggle labelPosition="end" formControlName="profileStudent"(change)="guardarPerfil()"></nb-toggle>
												<span nbTooltip="{{ 'LOGIN.TITLESTUDENT' | translate }}" nbTooltipPlacement="bottom"
													nbTooltipStatus="primary" nbButton status="primary">{{ "LOGIN.STUDENT" | translate }}</span>
											</div>

											<ng-container *ngIf="isAdult">
												<div class="item-toggle">
													<nb-toggle labelPosition="end" formControlName="profileTeacher" (change)="guardarPerfil()"></nb-toggle>
													<span nbTooltip="{{ 'LOGIN.TITLETEACHER' | translate }}" nbTooltipPlacement="bottom"
														nbTooltipStatus="primary" nbButton status="primary">{{ "LOGIN.TEACHER" | translate }}</span>
												</div>
											</ng-container>

											<ng-container *ngIf="isAdult">
												<div class="item-toggle">
													<nb-toggle labelPosition="end" formControlName="profileCenter" (change)="guardarPerfil()"></nb-toggle>
													<span nbTooltip="{{ 'LOGIN.TITLECENTER' | translate }}" nbTooltipPlacement="bottom"
														nbTooltipStatus="primary" nbButton status="primary">{{ "LOGIN.CENTER" | translate }}</span>
												</div>
											</ng-container>
										</div>
									</div>
								</div>
							</nb-card-body>
						</nb-card>

						<nb-card class="no-edge-card mb-0">
							<nb-card-header>
								<h6>{{ "USERDATA.DATOSGENERALES" | translate }}</h6>
							</nb-card-header>
							<nb-card-body class="py-4 px-0">
								<div class="col-12">
									<div class="row">
										<div class="col-12 col-md-6 form-group">
											<label for="username" class="label">{{"USERDATA.NOMBRE" | translate}}</label>
											<input
												type="text"
												nbInput
												fullWidth
												class="form-control"
												status="basic"
												fieldSize="small"
												placeholder="{{ 'USERDATA.NOMBRE' | translate }}"
												name="username"
												formControlName="firstName"
												required />
											<div
												*ngFor="let validation of validationMessages.firstName">
												<mat-error
													*ngIf="(userData.profileEditor)&&
														(formularioDatos
															.get('firstName')
															?.hasError(validation.type) &&
														(formularioDatos.get('firstName')?.dirty ||
															formularioDatos.get('firstName')?.touched ||
															thisForm.submitted))">
													<div class="error-message" >
														{{ validation.message }}
													</div>
												</mat-error>
											</div>
										</div>

										<div class="col-12 col-md-6 form-group">
											<label for="surname" class="label">{{"USERDATA.APELLIDOS" | translate}}</label>
											<input
												type="text"
												nbInput
												fullWidth
												class="form-control"
												status="basic"
												fieldSize="small"
												placeholder="{{ 'USERDATA.APELLIDOS' | translate }}"
												name="surname"
												formControlName="surname"
												required />
											<div
												*ngFor="let validation of validationMessages.surname">
												<mat-error
													*ngIf="userData.profileEditor &&
														(formularioDatos
															.get('surname')
															?.hasError(validation.type) &&
														(formularioDatos.get('surname')?.dirty ||
															formularioDatos.get('surname')?.touched ||
															thisForm.submitted))">
													<div class="error-message">
														{{ validation.message }}
													</div>
												</mat-error>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-12 col-md-6 form-group" *ngIf="!isDemo">
											<label for="mail" class="label">{{"USERDATA.EMAIL" | translate}}</label>
											<input
												type="email"
												nbInput
												fullWidth
												class="form-control"
												status="basic"
												fieldSize="small"
												placeholder="{{ 'USERDATA.EMAIL' | translate }}"
												name="mail"
												formControlName="mail"
												[readonly]="true"
    										[disabled]="true"
												required />
											<div *ngFor="let validation of validationMessages.mail">
												<mat-error
													*ngIf="userData.profileEditor &&
														(formularioDatos
															.get('mail')
															?.hasError(validation.type) &&
														(formularioDatos.get('mail')?.dirty ||
															formularioDatos.get('mail')?.touched ||
															thisForm.submitted))">
													<div class="error-message">
														{{ validation.message }}
													</div>
												</mat-error>
											</div>
										</div>

										<div class="col-12 col-md-6 form-group">
											<label for="birthdate" class="label">{{"USERDATA.DATE" | translate}}</label>
											<input
												type="date"
												nbInput
												fullWidth
												class="form-control"
												status="basic"
												fieldSize="small"
												name="birthdate"
												formControlName="birthdate"/>
											<!-- <mat-form-field
												*ngIf="showBirthdate"
												appearance="fill"
												class="datePickerGeneric"
												style="display: block"
											>
												<input
													matInput
													[matDatepicker]="picker"
													formControlName="birthdate"
													[readonly]="userData.mail === '' && userData.mobile === ''"
													name="birthdate"
													required
													disabled
												/>
												<mat-datepicker-toggle
													matSuffix
													[for]="picker"
												></mat-datepicker-toggle>
												<mat-datepicker #picker></mat-datepicker>
											</mat-form-field> -->
											<div
												*ngFor="let validation of validationMessages.birthdate">
												<mat-error
													*ngIf="userData.profileEditor &&
														(formularioDatos
															.get('birthdate')
															?.hasError(validation.type) &&
														(formularioDatos.get('birthdate')?.dirty ||
															formularioDatos.get('birthdate')?.touched ||
															thisForm.submitted))">
													<div class="error-message">
														{{ validation.message }}
													</div>
												</mat-error>
											</div>
										</div>
									</div>

									<div class="row" *ngIf="!isDemo">
										<div class="col-12 col-md-6 form-group">
											<label for="prefix" class="label">{{"LOGIN.PREFIX" | translate}}</label>
											<nb-select
												fullWidth
												name="prefix"
												hero size="small"
												class="SelectForm"
												placeholder="{{ 'LOGIN.PREFIX' | translate }}"
												formControlName="prefix"
												(click)="onClickSelect($event)">
												<nb-option class="SelectFormOpt"
													*ngFor="let prefix of $phonePrefixes | async"value="{{ prefix.dial_code }}">{{ prefix.name_en }}
													<span>
														<strong>{{ prefix.dial_code }}</strong>
													</span>
												</nb-option>
											</nb-select>
											<div
												*ngFor="let validation of validationMessages.prefix"
												class="mt-2">
												<div
													class="error-message"
													*ngIf="userData.profileEditor &&
														(prefixControl?.hasError(validation.type) &&
														(prefixControl?.dirty ||
															prefixControl?.touched ||
															thisForm.submitted))">
													{{ validation.message }}
												</div>
											</div>
										</div>

										<div class="col-12 col-md-6 form-group">
											<label for="mobile" class="label">{{"USERDATA.TELEFONOMOVIL" | translate}}</label>
											<input
												type="text"
												nbInput
												fullWidth
												class="form-control"
												status="basic"
												fieldSize="small"
												placeholder="{{ 'USERDATA.TELEFONOMOVIL' | translate }}"
												name="mobile"
												formControlName="mobile"
												required
												[readonly]="true"
    										[disabled]="true"
											/>
											<div *ngFor="let validation of validationMessages.mobile">
												<mat-error
													*ngIf="userData.profileEditor &&
														(formularioDatos
															.get('mobile')
															?.hasError(validation.type) &&
														(formularioDatos.get('mobile')?.dirty ||
															formularioDatos.get('mobile')?.touched ||
															thisForm.submitted))">
													<div class="error-message">
														{{ validation.message }}
													</div>
												</mat-error>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-12 col-md-6 form-group">
											<label for="idGenre" class="label">{{"USERDATA.GENRE" | translate}}</label>
											<nb-select
												fullWidth
												name="idGenre"
												hero size="small"
												class="SelectForm"
												placeholder="{{ 'USERDATA.GENRE' | translate }}"
												formControlName="idGenre"
												(click)="onClickSelect($event)">
												<nb-option value="null" disabled>{{"USERDATA.GENRE" | translate}}</nb-option>
												<nb-option class="SelectFormOpt"
													*ngFor="let gender of genres"
													[value]="gender.idGenre">{{ gender.genre | translate }}
												</nb-option>
											</nb-select>

											<mat-error
												*ngFor="let validation of validationMessages.gender">
												<mat-error
													class="error-message break-line"
													*ngIf="userData.profileEditor &&
														(formularioDatos
															.get('idGenre')
															?.hasError(validation.type) &&
														(formularioDatos.get('idGenre')?.dirty ||
															formularioDatos.get('idGenre')?.touched ||
															thisForm.submitted))">{{ validation.message }}
												</mat-error>
											</mat-error>
										</div>

										<div class="col-12 col-md-6 form-group">
											<label for="idLanguageIso" class="label">{{"IDIOMA.SELECCIONEELIDIOMA" | translate }}</label>
											<nb-select
												fullWidth
												name="idLanguageIso"
												hero size="small"
												class="SelectForm"
												placeholder="{{'IDIOMA.SELECCIONEELIDIOMA' | translate}}"
												formControlName="idLanguageIso"
												(selectionChange)="selectLang($event)"
												(click)="onClickSelect($event)">
												<nb-option value="null" disabled>{{"IDIOMA.SELECCIONEELIDIOMA" | translate}}</nb-option>
												<nb-option class="SelectFormOpt"
													*ngFor="let lang of langList"
													value="{{ lang.idLanguageIso }}">
													{{ lang.idLanguageIso | translate }}
												</nb-option>
											</nb-select>

											<mat-error
												*ngFor="let validation of validationMessages.gender">
												<mat-error
													class="error-message break-line"
													*ngIf="userData.profileEditor &&
														(formularioDatos
															.get('idLanguageIso')
															?.hasError(validation.type) &&
														(formularioDatos.get('idLanguageIso')?.dirty ||
															formularioDatos.get('idLanguageIso')?.touched ||
															thisForm.submitted))">{{ validation.message }}
												</mat-error>
											</mat-error>
										</div>
									</div>

									<div class="row">
										<div class="mt-5" *ngIf="userData.profileEditor">
											<div class="px-3" (click)="changePass()">
												<a class="pointer">
													<span>{{ "CAMBIARPASS.CAMBIARPASS" | translate }}</span>
												</a>
											</div>
											<div class="px-3 my-4" (click)="changeMobile()">
												<a class="pointer">
													<span>{{ "CAMBIARMOVIL.CAMBIARMOVIL" | translate	}}</span>
												</a>
											</div>
											<div class="px-3 my-4" (click)="changeMail()">
												<a class="pointer">
													<span>{{ "CAMBIARMAIL.CAMBIARMAIL" | translate	}}</span>
												</a>
											</div>
											<div class="px-3" (click)="dropOut()">
												<a class="pointer text-danger">
													<span>{{ "DARSEBAJA.DARSEBAJA" | translate }}</span>
												</a>
											</div>
										</div>
									</div>
								</div>
								<nb-card-footer *ngIf="userData.profileEditor" class="d-flex border-top-0 mt-5">
									<div class="fixed_foot p-3 w-100">
										<!--
										<button nbButton class="btn-themecolor" type="submit">
											<span>{{ "USERDATA.GRABAR" | translate }}</span>
										</button>
										-->
									</div>
								</nb-card-footer>
							</nb-card-body>
						</nb-card>
					</nb-card-body>
				</nb-card>
			</form>
		</div>
		<div *ngIf="screenView === 2">
			<nb-card class="no-edge-card mb-0">
				<nb-card-header>
					<h6>{{ "CAMBIARPASS.CAMBIARPASS" | translate }}</h6>
				</nb-card-header>
				<nb-card-body class="p-0">
					<app-modal-change-pass
						(action)="backToGeneral($event)"
					></app-modal-change-pass>
				</nb-card-body>
			</nb-card>
		</div>
		<div *ngIf="screenView === 3">
			<nb-card class="no-edge-card mb-0">
				<nb-card-header>
					<h6>{{ "CAMBIARMOVIL.CAMBIARMOVIL" | translate }}</h6>
				</nb-card-header>
				<nb-card-body>
					<app-modal-change-mobile
						(action)="backToGeneral($event)"
					></app-modal-change-mobile>
				</nb-card-body>
			</nb-card>
		</div>
		<div *ngIf="screenView === 4">
			<nb-card class="no-edge-card mb-0">
				<nb-card-header>
					<h6>{{ "CAMBIARMAIL.CAMBIARMAIL" | translate }}</h6>
				</nb-card-header>
				<nb-card-body>
					<app-modal-change-mail
						(action)="backToGeneral($event)" [userData]="userData"
					></app-modal-change-mail>
				</nb-card-body>
			</nb-card>
		</div>
		<div *ngIf="screenView === 5">
			<nb-card class="no-edge-card mb-0">
				<nb-card-header>
					<h6>{{ "DARSEBAJA.DARSEBAJA" | translate }}</h6>
				</nb-card-header>
				<nb-card-body>
					<app-modal-drop-out
						(action)="backToGeneral($event)"
					></app-modal-drop-out>
				</nb-card-body>
			</nb-card>
		</div>
	</nb-card-body>

</nb-card>


