import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
declare const webkitSpeechRecognition: any;

@Injectable({
	providedIn: "root",
})
export class SpeechService {
	error = true;

	recognition = new webkitSpeechRecognition();
	isStoppedSpeechRecog = false;
	text = "";
	tempWords: any;
	public _backEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	public getBackEnabled() {
		return this._backEnabled.asObservable();
	}

	public setBackEnabled(value: boolean): void {
		this._backEnabled.next(value);
	}

	constructor(private router: Router) {}

	init(): void {
		this.recognition.interimResults = true;
		this.recognition.lang = "en";
		this.recognition.continuous = true
		this.recognition.EndSilenceTimoutMs = 900
		this.recognition.addEventListener("result", (e: any) => {
			const transcript = Array.from(e.results)
				.map((result) => result[0])
				.map((result) => result.transcript)
				.join("");
			this.tempWords = transcript;
			this.setBackEnabled(true)
		});
	}

	start(): void {
		this.isStoppedSpeechRecog = false;
		this.recognition.start();
		this.recognition.addEventListener("end", () => {
			if (this.isStoppedSpeechRecog) {
				this.recognition.stop();
			} else {
				//this.wordConcat();
				this.recognition.start();
			}
		});
	}
	stop(): void {
		this.text = "";
		this.recognition.stop();
		this.isStoppedSpeechRecog = true;
		this.wordConcat();
	}

	wordConcat(): void {
		if(this.tempWords == undefined){
			this.text = ''
		}else{
			this.text = this.text + this.tempWords + " ";
			this.tempWords = " ";
			console.log(this.text);
		}
	}

	getText(): string {
		return this.text;
	}
}
