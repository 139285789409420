import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { TablaInformeService } from "./../../core/services/tabla-informe.service";
import { AlertService } from "src/app/core/services/shared";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { LiveAnnouncer } from "@angular/cdk/a11y";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";


@Component({
	selector: "app-table-informe",
	templateUrl: "./table-informe.component.html",
	styleUrls: ["./table-informe.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class TableInformeComponent implements OnInit {

	displayedColumns: string[] = [
		'fecha',
		'accesos_diarios',
		'us_totals',
		'us_estudiantes',
		'us_profesores',
		'us_padres',
		'proyectos',
		'ideografos',
		'ideas',
		'actividades',
		'videos_subidos',
		'imagenes_subidas',
		'audios_subidos',
		'documentos_subidos',
		'textos_subidos',
		'desafios',
		'ideografos_abiertos',
		'ideas_abiertas',
		'actividades_resp',
		'bajas_us_total',
		'bajas_us_estudiantes',
		'bajas_us_profesores',
		'bajas_us_padres'
	];
	formDate: UntypedFormGroup;
	data: [];
	p: number = 1;
	currentDate = new Date();
	dateNow = "";
	showTabla = false;
	today = new Date();
	year = this.today.getFullYear();
	month = this.today.getMonth() + 1; // Los meses en JavaScript son 0-11
	day = this.today.getDate();
	formattedDate = `${this.year}-${this.month.toString().padStart(2, '0')}-${this.day.toString().padStart(2, '0')}`;
	dataSource: MatTableDataSource<unknown>;
	auxDataSource: MatTableDataSource<unknown>;
	private destroy$ = new Subject();

	constructor(
		private fb: UntypedFormBuilder,
		private service: TablaInformeService,
		private alertService: AlertService,
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private _liveAnnouncer: LiveAnnouncer
	) { }

	ngOnInit() {
		this.createForm();
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	/** Announce the change in sort state for assistive technology. */
	announceSortChange(sortState: Sort) {
		// This example uses English messages. If your application supports
		// multiple language, you would internationalize these strings.
		// Furthermore, you can customize the message to add additional
		// details about the values being sorted.
		if (sortState.direction) {
			this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
		} else {
			this._liveAnnouncer.announce('Sorting cleared');
		}
	}

	createForm() {
		this.formDate = this.fb.group({
			desde: [this.formattedDate],
			hasta: [this.formattedDate],
			search: ['']
		});
	}
	/**
	 *hacec la petición al endpoint para obtener los datos de la tabla
	 *
	 * @memberof TableInformeComponent
	 */
	filterDate() {
		const desde = new Date(this.formDate.get("desde").value);
		const hasta = new Date(this.formDate.get("hasta").value);
		let desdeFormat = this.formatearFecha(desde)
		let hastaFormat = this.formatearFecha(hasta)
		this.service.getData(desdeFormat, hastaFormat).pipe(takeUntil(this.destroy$), take(1))
			.subscribe((res: any) => {
				if (res) {
					try {
						this.data = res.data;
						this.dataSource = new MatTableDataSource(res.data);
						this.auxDataSource = this.dataSource;
						setTimeout(() => {
							this.dataSource.sort = this.sort;
							this.dataSource.paginator = this.paginator;
						}, 100);

						this.showTabla = true;
						this.formDate.get("search").setValue('');
					} catch (error) {
						this.alertService.error(
							this.translateService.instant("NOPAGEFOUND.HAOCURRIDOUNERROR")
						);
						this.showTabla = false;
					}
				}
			});
	}

	/**
	 *formate la fecha en formato dd-MM-yyyy para enviarlo al endpoint
	 *
	 * @param {*} date
	 * @return formatted date
	 * @memberof TableInformeComponent
	 */
	formatearFecha(date) {
		var d = new Date(date),
			month = ("0" + (d.getMonth() + 1)).slice(-2),
			day = ("0" + d.getUTCDate()).slice(-2),
			year = d.getFullYear();
		return [year, month, day].join("-");
	}

	/**
	 *Epoxrtar a excel los datos de la tabla
	 *
	 * @param {MatTableDataSource<any>} dataSource
	 * @memberof TableInformeComponent
	 */
	exportToXLS = (dataSource: MatTableDataSource<any>) => {
		const data = dataSource.data;
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const blobData: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
		saveAs(blobData, `${this.formatearFecha(this.formDate.get("desde").value)}_${this.formatearFecha(this.formDate.get("hasta").value)}.xlsx`);
	};

	/**
	 *filtra los datos de la tabla
	 *
	 * @param {MatTableDataSource<any>} dataSource
	 * @param {*} event
	 * @memberof TableInformeComponent
	 */
	filterDataSource = (event) => {

		//if the key is enter ignore it
		if (event.keyCode === 13) {
			return;
		}

		const filterValue = this.formDate.get('search').value.trim().toLowerCase();

		if (filterValue === '') {
			this.filterDate();
		}

		if (filterValue.length >= 3) {
			const filteredData = this.auxDataSource.data.filter((item: any) => {
				let match = false;
				Object.values(item).forEach((value: any) => {
					if (value.toString().toLowerCase().includes(filterValue)) {
						match = true;
					}
				});
				return match;
			});
			this.dataSource = new MatTableDataSource(filteredData);
			setTimeout(() => {
				this.dataSource.sort = this.sort;
				this.dataSource.paginator = this.paginator;
			}, 100);
		} else {
			// Si el valor del filtro es menor a 3 caracteres

		}
	};

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	clearFilter() {
		this.filterDate();
	}

}
