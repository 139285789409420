import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { gNode } from 'src/app/core/models/graph/gNode.model';
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { LoginService } from 'src/app/core/services/login';
import { MastersService } from 'src/app/core/services/masters';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LocalStorage } from 'src/app/core/utils';
import { NodeFormComponent } from 'src/app/shared/components/forms/node-form/node-form.component';
import { NodeDetailInfoComponent } from '../node-detail-info/node-detail-info.component';
import { SigmaNode } from 'src/app/core/models/graph/sigmaNode.model';
import { SigmaCanvasService } from '../../../sigma-canvas/sigma-canvas.service';
import { NgxCopilotService } from 'ngx-copilot';
import { Estado } from 'src/app/core/models/tuturial/tutorial.model';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $: any;

@Component({
    selector: 'app-node-detail-header',
    templateUrl: './node-detail-header.component.html',
    styleUrls: ['./node-detail-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NodeDetailHeaderComponent implements OnInit, OnDestroy {
    @ViewChild('nodeForm') private nodeForm: NodeFormComponent;
    @Input() modeAuto: boolean = false;
    @Input() nomQuiz: string;
    @Input() nomNode: string;
    @Input() nomGrafo: string;
    @Input() type: string;
    @Input() canEdit: boolean = false;
    informacionQuiz: string;
    // A.G.

    @Input() node;
    @Input() course;
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Output() newNode = new EventEmitter<gNode>();
    currentGraph: any;
    public pendingChanges = false;

    // Fin A.G.

    subscriptions: Subscription[] = [];
    public form: UntypedFormGroup;

    fabTogglerState = 'inactive';
    @ViewChild('quizzes', { static: true }) printEl: ElementRef;

    constructor(public activeModal: NgbActiveModal,
        public loginService: LoginService,
        private modalService: NgbModal,
        private quizService: QuizzesService,
        public graphServ: GraphService, public nodeService: NodeService,  private localStorage: LocalStorage,
        private translateService: TranslateService, private mastersService: MastersService,
        private fb: UntypedFormBuilder,
				private toaster: ToasterService,
        private sigmaCanvasService: SigmaCanvasService,
        private copilot: NgxCopilotService
        ) {
        this.subscriptions = [...this.subscriptions, this.quizService.currentGraph.subscribe((g) => { this.currentGraph = g; })];

        this.form = this.fb.group({
            published: new UntypedFormControl(false)
        });
    }

    ngOnInit() {
        this.form.get('published').patchValue(this.node.published ? true : false)
        if(this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE && !this.loginService.getDataTutorial().estudiante.cambiarContenido){
            this.initPosition(8)
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }
  /*Next Step*/
  nextStep = (stepNumber:any) =>  this.copilot.next(stepNumber);

  done = () => {
    let data = this.loginService.getDataTutorial();
    data.estudiante.cambiarContenido = true;
    this.loginService.updateDataLocalTutorial(data);
      this.copilot.removeWrapper()
  }

  initPosition = (o: any) => {
      this.copilot.checkInit(o)
  };
    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }

    save(type: string) {
        this.nodeForm.save();
        this.activeModal.close();
    }

    imprimir() {
        console.log("Proceso de impresion");
    }

    deleteNode(){
        if (!this.loginService.esAutor()) {
            return;
        }
        let node:SigmaNode = this.node as SigmaNode

        this.sigmaCanvasService.deleteSigmaNode(node).subscribe(
            (res: any) => {
                this.toaster.success(this.translateService.instant('NODOS.DELETEOK'));
                this.closeModal('')
            },
            err => {
                this.toaster.error(this.translateService.instant('NODOS.DELETENOK'));
                this.closeModal('')
            }
        )

    }

    // showAutomaticStudy() {
    //     if (this.loginService.esEstudiante()) {
    //         const modalRef = this.modalService.open(ModalReproducirQuizzesComponent,
    //             {
    //                 scrollable: true,
    //                 windowClass: MODAL_DIALOG_TYPES.W95
    //             })
    //         modalRef.componentInstance.node = this.node;
    //         modalRef.componentInstance.idCurso = this.idCurso;
    //         modalRef.componentInstance.idMapa = this.idMapa;
    //         modalRef.componentInstance.currentGraph = this.currentGraph;
    //         modalRef.componentInstance.type = 'node';
    //         modalRef.componentInstance.nomGrafo = '';
    //         modalRef.result.then((result) => {
    //             console.log(result);
    //         }, (reason) => {
    //         });
    //     }

    // }

    showInfo() {
        const modalRef = this.modalService.open(NodeDetailInfoComponent,
            {
                scrollable: false,
                windowClass: MODAL_DIALOG_TYPES.W60
            }
        );

        modalRef.componentInstance.node = this.node;
        modalRef.componentInstance.idCurso = this.idCurso;
        modalRef.componentInstance.idMapa = this.idMapa;
        modalRef.componentInstance.canEdit = this.canEdit;
        modalRef.componentInstance.course = this.course;
    }



    publish($ev) {

        $ev.preventDefault()
        $ev.stopImmediatePropagation()
        let currentValue: boolean = !this.form.get('published').value
        let modalMessage: string = currentValue ? this.translateService.instant('NODEFORMCOMPONENT.PUBLISHMSG') : this.translateService.instant('NODEFORMCOMPONENT.UNPUBLISHMSG')
        let errorMessage: string = currentValue ? this.translateService.instant('GENERAL.KOPUBLISH') : this.translateService.instant('GENERAL.KOUNPUBLISH')
        let okMessage: string = currentValue ? this.translateService.instant('GENERAL.OKPUBLISH') : this.translateService.instant('GENERAL.OKUNPUBLISH')

        this.mastersService.setPublishType('node', Number(this.node.idOriginal), currentValue).subscribe(result => {
            if (result.data) {
                this.toaster.success(okMessage)
                this.form.get('published').setValue(currentValue) // Update form value
                this.node.published = currentValue
            }
            else
                this.toaster.error(this.translateService.instant('NODEFORMCOMPONENT.KOPUBLISHCONTENT'))
        }, err => {
            this.toaster.error(errorMessage)
        })

    }

}
