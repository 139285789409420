
import { Utils } from "./../../../utils/utils";
import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "src/app/core/models/users/user.models";
import { LoginService } from "src/app/core/services/login";
import { UserDataGeneralComponent } from "src/app/pages/user-data/user-data-general/user-data-general.component";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { UsersService } from "src/app/core/services/users";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ModalProfileActionsComponent } from "src/app/shared/components/modal-profile-actions/modal-profile-actions.component";
import { Store } from "@ngrx/store";
import { State } from "src/app/store/models/store.model";
import { availableProfiles } from "src/app/store/models/profiles.model";
import { MeetingsComponent } from "src/app/pages/meetings/meetings.component";
import { LocalStorage } from "src/app/core/utils";
import { MastersService } from "src/app/core/services/masters";

const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg";
@Component({
	selector: "app-user-account",
	templateUrl: "./user-account.component.html",
	styleUrls: ["./user-account.component.scss"],
	providers: [ImagenPipe],
	encapsulation: ViewEncapsulation.None,
})
export class UserAccountComponent implements OnInit {
	showUserAccount: boolean = false;
	user: User;
	profiles = Profiles;
	inside: boolean;
	actualProfile: string;
	private destroy$ = new Subject();
	profilesOfUser: availableProfiles;
	comeFromProfile: string;

	@HostListener("document:mousedown", ["$event"])
	onGlobalClick(event): void {
		if (!this.eRef.nativeElement.contains(event.target)) {
			this.showUserAccount = false;
			this.destroy$.next(true);
		}
	}

	constructor(
		private loginService: LoginService,
		private modalService: NgbModal,
		private imagePipe: ImagenPipe,
		private utils: Utils,
		public usersService: UsersService,
		private store: Store<State>,
		private eRef: ElementRef,
		private localStorage: LocalStorage,
		private mastersService: MastersService,
	) {
		this.user = this.loginService.getUser();
	}

	ngOnInit() {
		this.store
			.select((store) => store.profiles)
			.subscribe((profiles) => {
				this.profilesOfUser = profiles;
			});
		this.store
			.select((store) => store.selectedProfile)
			.subscribe((selectedProfile) => {
				//this.actualProfile = selectedProfile["selectedProfile"];
				this.actualProfile = this.loginService.getProfile();
			});

		let lastSelectedProfile = localStorage.getItem("lastSelectedProfile");

		if (lastSelectedProfile != null) {
			if (
				lastSelectedProfile == this.profiles.Author &&
				this.profilesOfUser.profileEditor == true
			) {
				this.setProfile(this.profiles.Author);
			} else if (
				lastSelectedProfile == this.profiles.Teacher &&
				this.profilesOfUser.profileTeacher == true
			) {
				this.setProfile(this.profiles.Teacher);
			} else if (
				lastSelectedProfile == this.profiles.Student &&
				this.profilesOfUser.profileStudent == true
			) {
				this.setProfile(this.profiles.Student);
			} else if (
				lastSelectedProfile == this.profiles.Admin &&
				this.profilesOfUser.profileAdmin == true
			) {
				this.setProfile(this.profiles.Admin);
			}else if (
				lastSelectedProfile == this.profiles.Center &&
				this.profilesOfUser.profileCenter == true
			) {
				this.setProfile(this.profiles.Center);
			}
		}
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}

	showMenuPanel() {
		if (!this.showUserAccount) {
			this.showUserAccount = !this.showUserAccount;
		} else {
			this.showUserAccount = !this.showUserAccount;
			this.destroy$.next(true);
		}
	}

	setProfile(profile) {
		if (profile == this.profiles.Author) {
			this.comeFromProfile = this.actualProfile;
		}
		if (
			profile == this.profiles.Teacher &&
			this.actualProfile == "ESTUDIANTE"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}
		if (profile == this.profiles.Student && this.actualProfile == "PROFESOR") {
			localStorage.removeItem("selectedGroupInfo");
		}
		if (
			profile == this.profiles.Teacher &&
			this.comeFromProfile == "ESTUDIANTE"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}

		if (
			profile == this.profiles.Student &&
			this.comeFromProfile == "PROFESOR"
		) {
			localStorage.removeItem("selectedGroupInfo");
		}
		localStorage.setItem("lastSelectedProfile", profile);
		this.loginService.setProfile(profile);
		console.log("paso por aqui???????", profile);
		this.showModalProfileActions();
		//Creamos el log
		const bodyLog = {
			action: "cambio perfil",
			actionData: "El usuario con id " + this.user.idUser + " ha activado el perfil " + profile + ".",
			startDate: new Date(),
			endingDate: null,
			role: profile,
			idGroup: 0
		};
		this.mastersService.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
	}

	showGeneralSettings(): void {
		this.modalService.dismissAll();
		this.showUserAccount = false;
		let config = {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W80}`,
		};
		this.modalService.open(UserDataGeneralComponent, config);
		//Creamos el log
		const bodyLog = {
			action: "ajustes generales",
			actionData: "El usuario con id " + this.user.idUser + " ha entrado en los Ajustes Generales.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0
		};
		this.mastersService.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
	}

	getUserAvatar(): string {
		return this.utils.getUserAvatar(this.user.pictureUser);
	}

	logout(): void {
		//Creamos el log
		const bodyLog = {
			action: "logout",
			actionData: "El usuario con id " + this.user.idUser + " se ha deslogeado.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: 0
		};
		this.mastersService.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
		this.modalService.dismissAll();
		localStorage.removeItem("selectedGroupInfo");
		this.loginService.logout(true);

	}

	showModalProfileActions() {
		this.modalService.dismissAll();

		let dontLoadMenu = this.localStorage.getItem("dontLoadMenu");
		if (
			(this.utils.loadMenu === true && dontLoadMenu === "false") ||
			dontLoadMenu == null
		) {
			this.modalService.open(ModalProfileActionsComponent, {
				scrollable: false,
				windowClass: MODAL_DIALOG_TYPES.W40,
			});
			this.showUserAccount = false;
			this.destroy$.next(true);
		} else {
			setTimeout(() => {
				localStorage.setItem("dontLoadMenu", "false");
				this.utils.loadMenu = true;
			}, 300);
		}

		// this.modalService.open(ModalProfileActionsComponent, {
		// 	scrollable: false,
		// 	windowClass: MODAL_DIALOG_TYPES.W40,
		// });

	}

	sos() {
		this.modalService.open(MeetingsComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W80,
		});
		this.showUserAccount = false;
		this.destroy$.next(true);
	}
}
