<nb-card class="mb-0">
	<nb-card-header class="flex justify-content-between border-bottom-0">
		<div class="col-11">
			<div> <h5>{{ 'ACTIVIDADESFLASH.AJUSTES' | translate }}</h5></div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="m-3 m-md-5 row d-flex">
			<form [formGroup]="formulario" class="col-12">
				<div class="col-12 d-flex justify-content align-items-center">
					<span class="col-6 text-right">{{ "ACTIVIDADESFLASH.SIZEDECK" | translate }} (max {{ maxSize }})</span>
					<input class="col-1" style="max-width: 5rem;" nbInput fieldSize="small" class="form-control"
						type="number" min="1" [max]="maxSize" formControlName="sizeDeck" [value]="formulario.controls['sizeDeck'].value"/>
				</div>
				<div class="col-12 d-flex justify-content align-items-center">
					<span class="col-12 text-center" style="color: #B7181F; font-size: 12px;">{{ "ACTIVIDADESFLASH.ALERTMINSIZE" | translate }}</span>
				</div>
				<div class="col-12 d-flex justify-content align-items-center mt-4">
					<span class="col-6 text-right">{{ "ACTIVIDADESFLASH.INCLUDESMALL" | translate }}</span>
					<nb-toggle labelPosition="end" formControlName="includeSmall"></nb-toggle>
				</div>
			</form>
		</div>
	</nb-card-body>
	<nb-card-footer class="border-top-0">
		<div class="mb-2 row" ngClass ="justify-content-center" >
			<div class="col-6" align="right">
				<button *ngIf="formulario.value.sizeDeck > 9 && formulario.value.sizeDeck <= maxSize" nbButton (click)="aceptar()" class="mr-1 btn-themecolor">
					{{ 'ACEPTARCANCELAR.ACEPTAR' | translate }}
				</button>
				<button *ngIf="formulario.value.sizeDeck <= 9 || formulario.value.sizeDeck > maxSize" nbButton status="basic" class="mr-1">
					{{ 'ACEPTARCANCELAR.ACEPTAR' | translate }}
				</button>
			</div>

			<div class="col-6" align="left">

				<button nbButton status="danger" (click)="cancelar()" class="">{{ 'ACEPTARCANCELAR.CANCELAR' | translate
					}}</button>
			</div>
		</div>
	</nb-card-footer>
</nb-card>
