import { ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject, timer } from 'rxjs';

@Component({
	selector: "app-modal-microfono-audio",
	templateUrl: "./modal-microfono-audio.component.html",
	styleUrls: ["./modal-microfono-audio.component.scss"],
})
export class ModalMicrofonoAudioComponent implements OnInit, OnChanges {
	mediaRecorder: any;
	@ViewChild("multiMediaContainer", { static: true })
	multiMediaContainer: ElementRef<HTMLElement>;

	chunks = [];
	audioFiles = [];

	_second = 1000;
	_minute = this._second * 60;
	_hour = this._minute * 60;
	_day = this._hour * 24;
	end: any;
	now: any;
	day: any;
	hours: any;
	minutes: any | 0 = "00";
	seconds: any | 0 = "00";
	source = timer(0, 1000);
	clock: any;
	grabando: boolean = false;
	files: File;

	soloAudio: boolean = false;
	soloVideo: boolean = false;
	soloFoto: boolean = false;
	videoFiles = [];
	videoRef: any;
	mediaStream: MediaStream;

	// toggle webcam on/off
	newPicture = true;
	public showWebcam = true;
	public allowCameraSwitch = true;
	public multipleWebcamsAvailable = false;
	public deviceId: string;
	mediaDevices: MediaDeviceInfo[];
	public videoOptions: MediaTrackConstraints = {
		// width: {ideal: 1024},
		// height: {ideal: 576}
	};
	public errors: WebcamInitError[] = [];

	// latest snapshot
	public webcamImage: WebcamImage = null;

	// webcam snapshot trigger
	private trigger: Subject<void> = new Subject<void>();
	// switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
	private nextWebcam: Subject<boolean | string> = new Subject<
		boolean | string
	>();
	imagen: any;

	constructor(
		private activeModal: NgbActiveModal,
		private dom: DomSanitizer,
		private cd: ChangeDetectorRef,
		private modalService: NgbModal
	) {}

	ngOnChanges(changes: SimpleChanges): void {}

	ngOnInit() {}
	ngAfterViewInit() {
		this.chunks = [];
		this.videoFiles = [];
		this.mediaRecorder = null;
		if (this.soloAudio) {
			this.permisoMicro();
		} else if (this.soloVideo) {
			this.videoRef = document.getElementById("videoRef");
			this.videoRef.muted = true;
			this.grabarVideo();
		} else if (this.soloFoto) {
			this.tomarFoto();
		}
	}

	showDate() {
		let distance = this.end - this.now;
		this.day = Math.floor(distance / this._day);
		this.hours = Math.floor((distance % this._day) / this._hour);
		this.minutes = Math.floor((distance % this._hour) / this._minute);

		this.seconds = Math.floor((distance % this._minute) / this._second);
		if (parseInt(this.minutes) < 10) {
			this.minutes = "0" + this.minutes;
		}
		if (parseInt(this.seconds) < 10) {
			this.seconds = "0" + this.seconds;
		}
	}

	startRecording() {
		this.grabando = true;
		this.audioFiles = [];
		this.now = new Date();
		this.mediaRecorder.start();
		this.clock = this.source.subscribe((t) => {
			this.end = new Date();
			this.showDate();
		});
	}
	stopRecording() {
		this.end = new Date();
		this.clock.unsubscribe();
		setTimeout(() => {
			this.mediaRecorder.stop();
			this.grabando = false;
		}, 500);
	}

	permisoMicro() {
		navigator.mediaDevices
			.getUserMedia({ audio: true, video: false })
			.then((stream) => {
				this.mediaRecorder = new MediaRecorder(stream);
				this.mediaStream = stream;
				this.mediaRecorder.onstop = (e) => {
					var blob = new Blob(this.chunks, { type: "audio/mp3; codecs=opus" });
					const id = Math.floor(Math.random() * 10000);

					this.files = new File(this.chunks, `${id}-wav.mp3`, {
						type: "audio/mp3",
					});
					var audioURL = URL.createObjectURL(blob);
					this.audioFiles.push(this.dom.bypassSecurityTrustUrl(audioURL));
					this.cd.detectChanges();
					stream.getTracks().forEach(function (track) {
						track.stop();
					});
				};
				this.mediaRecorder.ondataavailable = (e) => {
					this.chunks = [];
					this.chunks.push(e.data);
				};
			});
	}

	aceptar() {
		this.activeModal.close({ files: this.files, videoUrl: this.videoFiles });
	}
	salir() {
		this.activeModal.close();
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	grabarVideo() {
		navigator.mediaDevices
			.getUserMedia({
				audio: true,
				video: {
					width: 1280,
					height: 720,
				},
			})
			.then((stream) => {
				this.mediaRecorder = new MediaRecorder(stream);
				this.mediaStream = stream;
				this.videoRef.srcObject = this.mediaStream;
				this.mediaRecorder.ondataavailable = (e) => {
					this.chunks.push(e.data);
				};
				this.mediaRecorder.onstop = (e) => {
					var blob = new Blob(this.chunks, { type: "video/webm" });
					const id = Math.floor(Math.random() * 10000);
					this.files = new File(this.chunks, `${id}-vid.mp4`, {
						type: "video/webm",
					});
					var audioURL = URL.createObjectURL(blob);
					this.videoFiles.push(this.dom.bypassSecurityTrustUrl(audioURL));
					this.cd.detectChanges();
				};
			});
	}

	capturePhoto() {
		this.mediaRecorder.pause();
	}

	startCamera() {
		this.grabando = true;
		this.now = new Date();
		this.clock = this.source.subscribe((t) => {
			this.end = new Date();
			this.showDate();
		});
		this.mediaRecorder.start();
	}

	stopCamera() {
		this.grabando = false;
		this.clock.unsubscribe();
		setTimeout(() => {
			this.end = new Date();
			this.mediaRecorder.stop();
		}, 300);
	}

	finalizarTrans() {
		this.mediaRecorder.stop();
		this.mediaStream.getVideoTracks()[0].stop();
	}

	reloadVid() {
		this.activeModal.close({ reload: true });
	}

	tomarFoto() {
		WebcamUtil.getAvailableVideoInputs().then(
			(mediaDevices: MediaDeviceInfo[]) => {
				this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 0;
				this.mediaDevices = mediaDevices;
			}
		);
	}

	public triggerSnapshot(): void {
		this.trigger.next();
	}

	public toggleWebcam(): void {
		this.showWebcam = !this.showWebcam;
	}

	public handleInitError(error: WebcamInitError): void {
		this.errors.push(error);
	}

	public showNextWebcam(directionOrDeviceId: boolean | string): void {
		// true => move forward through devices
		// false => move backwards through devices
		// string => move to device with given deviceId
		this.nextWebcam.next(directionOrDeviceId);
	}

	public handleImage(webcamImage: WebcamImage): void {
		this.webcamImage = webcamImage;
		this.newPicture = false;
		this.toggleWebcam();
	}

	public cameraWasSwitched(deviceId: string): void {
		this.deviceId = deviceId;
	}

	public get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	public get nextWebcamObservable(): Observable<boolean | string> {
		return this.nextWebcam.asObservable();
	}

	savePicture() {
		const id = Math.floor(Math.random() * 10000);
		let nameFilePic = `${id}-pic.jpeg`;
		//Usage example:
		this.urltoFile(
			this.webcamImage.imageAsDataUrl,
			nameFilePic,
			"image/jpeg"
		).then((file) => {
			this.activeModal.close({ file, webcamImage: this.webcamImage });
		});
	}

	//return a promise that resolves with a File instance
	urltoFile(url, filename, mimeType) {
		return fetch(url)
			.then((res) => {
				return res.arrayBuffer();
			})
			.then((buf) => {
				return new File([buf], filename, { type: mimeType });
			});
	}
	newFoto(webcamImage) {
		this.newPicture = true;
		this.webcamImage = null;
		this.toggleWebcam();
	}
}
