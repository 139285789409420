import { Component, OnInit, ElementRef, ViewChild, Input, Renderer2, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as printJS from 'print-js';
import { LoginService } from 'src/app/core/services/login';
import { gNode } from 'src/app/core/models/graph/gNode.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ModalSelectRecipientComponent } from '../../modal-select-recipient/modal-select-recipient.component';
import { NodeFormComponent } from '../../../forms/node-form/node-form.component';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
//import { PrintService } from 'src/app/core/services/shared/print.service';

declare var $: any;

@Component({
    selector: 'app-head-reproducir-quizzes',
    templateUrl: './head-reproducir-quizzes.component.html',
    styleUrls: ['./head-reproducir-quizzes.component.scss']
})

export class HeadReproducirQuizzesComponent implements OnInit {


    @ViewChild('nodeForm') private nodeForm: NodeFormComponent;

    @Input() nomQuiz: string;
    @Input() nomNode: string;
    @Input() nomGrafo: string;
    @Input() type: string;
    informacionQuiz: string;
    buttons = [
        {
            id: 1, icon: 'fab fa-instagram', color: '#517fa4'
        },
        {
            id: 2, icon: 'fab fa-whatsapp', color: '#4dc247'
        },
        {
            id: 3, icon: 'fab fa-twitter', color: '#55acee'
        },
        {
            id: 4, icon: 'fab fa-facebook-f', color: '#3b5998'
        },
        {
            id: 5, icon: 'salware', color: 'rgb(33, 76, 152)'
        },
        {
            id: 6, icon: 'fab fa-linkedin-in', color: '#007bb5'
        },
        {
            id: 7, icon: 'fab fa-youtube', color: '#bb0000'
        },
        {
            id: 8, icon: 'fab fa-pinterest', color: '#cb2027'
        }
    ];
    cargando = false;

    // A.G.

    public quiz: any;
    @Input() node;
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Output() newNode = new EventEmitter<gNode>();

    private pendingChanges = false;

    setPendingChanges($event) {
        this.pendingChanges = $event;
    }

    receiveNewNodeData($event) {
        this.newNode.emit($event);
    }
    // Fin A.G.

    fabTogglerState = 'inactive';
    @ViewChild('quizzes', { static: true }) printEl: ElementRef;

    constructor(public activeModal: NgbActiveModal,
        public loginService: LoginService,
        private modalService: NgbModal,
        private quizService: QuizzesService) {
        /*this.quizService.selectQuiz.subscribe((n) => {
              this.quiz = n;
            });*/
    }

    ngOnInit() {
        this.cargando = true;
        this.informacionQuiz = "Aquí se va mostrar información del quiz";
        //console.log("*********QUIZ EN HEAD : " , this.quiz);

    }

    closeModal(sendData?: any) {
        //console.log("Cerrar modal");
        this.activeModal.close(sendData);
    }

    enviarRedesSociales(id) {
        //console.log("enviar redes sociales");
    }

    imprimir() {
        printJS({
            printable: 'quiz', type: 'html', targetStyles: ['*']
        });
    }

    private seleccionarDestinatario() {
        const modalRef = this.modalService.open(ModalSelectRecipientComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W55
            })

        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }
}
