import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoginService } from '../login';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
	private inactivityTime: number = 0;
  private inactivityTimeout: number = 7200000; // 2h en milisegundos
	private inactivityResetSubject = new Subject<void>();

  inactivityReset$ = this.inactivityResetSubject.asObservable();
	private destroy$ = new Subject();

  constructor(
		private loginService: LoginService,
		public activeModal: NgbActiveModal,
		private cookieService: CookieService,
	) { }

	resetInactivityTimer() {
    this.inactivityTime = 0;
		this.inactivityResetSubject.next();
  }

  startInactivityTimer() {
    setInterval(() => {
      this.inactivityTime += 1000;
			//const formattedTime = this.formatTiempoTranscurrido(this.inactivityTime);
			//console.log("El tiempo transcurrido es: " + formattedTime);
      if (this.inactivityTime >= this.inactivityTimeout) {
        // Lógica para cerrar la sesión.
				this.loginService.logout(true);
				this.cookieService.deleteAll();
				window.location.reload();
				this.resetInactivityTimer();
      }
    }, 1000);
  }

	formatTiempoTranscurrido(inactivityTime) {
		// Calcula las horas, minutos y segundos
		const horas = Math.floor(inactivityTime / 3600000);
		const minutos = Math.floor((inactivityTime % 3600000) / 60000);
		const segundos = Math.floor((inactivityTime % 60000) / 1000);

		// Crea el formato de tiempo
		const formattedTime = `${horas}h-${minutos}min-${segundos}seg`;

		return formattedTime;
	}
}
