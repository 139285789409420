import { ModalCompetitionResultsComponent } from './modal-competition-results/modal-competition-results.component';
import { PopUpReloadComponent } from './../../pages/login/pop-up-reload/pop-up-reload.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { ModalInstagramVideoComponent } from "./nodes/node-detail/components/node-detail-files/components/modal-instagram-video/modal-instagram-video.component";
import { ModalTiktokVideoComponent } from "./nodes/node-detail/components/node-detail-files/components/modal-tiktok-video/modal-tiktok-video.component";
import { ModalEliminarEditorComponent } from "./cursos/modal-curso-edit-detail/modal-eliminar-editor/modal-eliminar-editor.component";
import { ModalListadoEditoresComponent } from "./cursos/modal-curso-edit-detail/modal-listado-editores/modal-listado-editores.component";
import { FileUploadersComponent } from "./node-form/file-uploader/file-uploader.component";
import { NodeFormsComponent } from "./node-form/node-form.component";
import { NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { AlertComponent } from "./alert/alert.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { FormsComponent } from "./forms/forms.component";
import { QuizFormComponent } from "./forms/quiz-form/quiz-form.component";
import { PipesModule } from "../pipes/pipes.module";
import { PatternsComponent } from "./patterns/patterns.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/pages/material.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoadingComponent } from "./loading/loading.component";
import { RatingComponent } from "./rating/rating.component";
import { ModalAceptarCancelarComponent, ModalComponent } from "./modal";
import { ModalReproducirComponent } from "./modal-reproducir/modal-reproducir.component";
import { PatternDraftComponent } from "./patterns/pattern-draft/pattern-draft.component";
import { PatternFormComponent } from "./patterns/pattern-form/pattern-form.component";
import { ModalCompetitionCouplesComponent } from "./sigma-canvas/modal-competition-couples/modal-competition-couples.component";
import { ModalCompetitionGroupsComponent } from "./sigma-canvas/modal-competition-groups/modal-competition-groups.component";
import { ModalConectarNodoComponent } from "./sigma-canvas/modal-conectar-nodo/modal-conectar-nodo.component";
import { ModalOperadoresComponent } from "./sigma-canvas/modal-operadores/modal-operadores.component";
import { ModalRegistrarNodoComponent } from "./sigma-canvas/modal-registrar-nodo/modal-registrar-nodo.component";
import { ModalRegistarQuizzesComponent } from "./sigma-canvas/modal-registar-quizzes/modal-registar-quizzes.component";
import { ModalReproducirNodoComponent } from "./sigma-canvas/modal-reproducir-nodo/modal-reproducir-nodo.component";
import { ModalReproducirQuizzesComponent } from "./sigma-canvas/modal-reproducir-quizzes/modal-reproducir-quizzes.component";
import { ModalSelectRecipientComponent } from "./sigma-canvas/modal-select-recipient/modal-select-recipient.component";
import { ModalTasksComponent } from "./sigma-canvas/modal-tasks/modal-tasks.component";
import { PowerComponentComponent } from "./sigma-canvas/power-component/power-component.component";
import { RegistrarCursoComponent } from "./sigma-canvas/registrar-curso/registrar-curso.component";
import { RegistrarNodoComponent } from "./sigma-canvas/registrar-nodo/registrar-nodo.component";
import { BodySelectRecipientComponent } from "./sigma-canvas/modal-select-recipient/body-select-recipient/body-select-recipient.component";
import { HeadSelectRecipientComponent } from "./sigma-canvas/modal-select-recipient/head-select-recipient/head-select-recipient.component";
import { BodyReproducirQuizzesComponent } from "./sigma-canvas/modal-reproducir-quizzes/body-reproducir-quizzes/body-reproducir-quizzes.component";
import { FooterReproducirQuizzesComponent } from "./sigma-canvas/modal-reproducir-quizzes/footer-reproducir-quizzes/footer-reproducir-quizzes.component";
import { HeadReproducirQuizzesComponent } from "./sigma-canvas/modal-reproducir-quizzes/head-reproducir-quizzes/head-reproducir-quizzes.component";
import { HeadNodesComponent } from "./sigma-canvas/modal-reproducir-nodo/head-nodes/head-nodes.component";
import { BodyQuizzesComponent } from "./sigma-canvas/modal-registar-quizzes/body-quizzes/body-quizzes.component";
import { FooterQuizzesComponent } from "./sigma-canvas/modal-registar-quizzes/footer-quizzes/footer-quizzes.component";
import { HeadQuizzesComponent } from "./sigma-canvas/modal-registar-quizzes/head-quizzes/head-quizzes.component";
import { BodyCompetitionGroupsComponent } from "./sigma-canvas/modal-competition-groups/body-competition-groups/body-competition-groups.component";
import { HeadCompetitionCouplesComponent } from "./sigma-canvas/modal-competition-couples/head-competition-couples/head-competition-couples.component";
import { HeadCompetitionGroupsComponent } from "./sigma-canvas/modal-competition-groups/head-competition-groups/head-competition-groups.component";
import { BodyCompetitionCouplesComponent } from "./sigma-canvas/modal-competition-couples/body-competition-couples/body-competition-couples.component";
import { BodyTasksListComponent } from "./sigma-canvas/modal-tasks/body-tasks-list/body-tasks-list.component";
import { BodyTasksOptionsComponent } from "./sigma-canvas/modal-tasks/body-tasks-options/body-tasks-options.component";
import { HeadTasksComponent } from "./sigma-canvas/modal-tasks/head-tasks/head-tasks.component";
import { NodeFormComponent } from "./forms/node-form/node-form.component";
import { FileUploaderComponent } from "./forms/node-form/file-uploader/file-uploader.component";
import { ColorPickerModule } from "ngx-color-picker";
import { RouterModule } from "@angular/router";
import { FileUploadModule } from "ng2-file-upload";
import { WebcamModule } from "ngx-webcam";
import { NgxPrintModule } from "ngx-print";
import { ResizableModule } from "angular-resizable-element";
import { NgxMaskModule } from "ngx-mask";
import { PlantillaReproducirQuizComponent } from "./sigma-canvas/modal-reproducir-quizzes/body-reproducir-quizzes/plantilla-reproducir-quiz/plantilla-reproducir-quiz.component";
import { ModalCursosComponent } from "./cursos/modal-cursos";
import { ModalEditarCursoComponent } from "./cursos/modal-editar-curso";
import { ModalEditarCursoMapaComponent } from "./cursos/modal-editar-curso-mapa";
import { ImguploadComponent } from "./imgupload/imgupload.component";
import { ShareButtonComponent } from "./share-button/share-button.component";
import { ReportButtonComponent } from "./report-button/report-button.component";
import { AcceptDialogComponent } from "./accept-dialog/accept-dialog.component";
import { AngularDraggableModule } from "angular2-draggable";
import { ModalHelpComponent } from "./modal-help/modal-help.component";
import { ModalTasksHomeComponent } from "./modal-tasks-home/modal-tasks-home.component";
import { ModalCalendarComponent } from "./modal-calendar/modal-calendar.component";
import { ModalCertifyComponent } from "./modal-certify/modal-certify.component";
import { OperatorsTableComponent } from "./sigma-canvas/operators-table/operators-table.component";
import { OperatorIconComponent } from "./sigma-canvas/operators-table/components/operator-icon/operator-icon.component";
import { GraphLateralIconsComponent } from "./graph-lateral-icons/graph-lateral-icons.component";
import { ConditionsDialogComponent } from "./conditions-dialog/conditions-dialog.component";
import { PrivacyDialogComponent } from "./privacy-dialog/privacy-dialog.component";
import { UploadProgressBarComponent } from "./upload-progress-bar/upload-progress-bar.component";
import { PadButtonsComponent } from "./pad-buttons/pad-buttons.component";
import { PadControllerComponent } from "./pad-controller/pad-controller.component";
import { PowerSelectorComponent } from "./power-selector/power-selector.component";
import { ModalCompetitionComponent } from "./modal-competition/modal-competition.component";
import { ModalImageComponent } from "./modal-image/modal-image.component";
import { ModalTextEditComponent } from "./modal-text-edit/node-detail-files-text-edit.component";
import { QuillModule } from "ngx-quill";
import { ModalGruposEditarComponent } from "./modal-grupos-editar/modal-grupos-editar.component";
import { ModalEditarGrupoComponent } from "./grupos/modal-editar-grupo/modal-editar-grupo.component";
import { GruposOpcionesComponent } from "./grupos/grupos-opciones/grupos-opciones.component";
import { ModalTutorialGrafoComponent } from "./modal-tutorial-grafo/modal-tutorial-grafo.component";
import { MessageMultiTabsComponent } from "./message-multi-tabs/message-multi-tabs.component";
import { NgxCopilotModule } from "ngx-copilot";
import { ModalContactComponent } from "./modal-contact/modal-contact.component";
import { ModalMicrofonoAudioComponent } from "./modal-microfono-audio/modal-microfono-audio.component";
import { ViewFileModalComponent } from "./view-file-modal/view-file-modal.component";
import { GraphButtonsComponent } from "./graph-buttons/graph-buttons.component";
import { ModalVerCursoComponent } from "./cursos/modal-ver-curso/modal-ver-curso.component";
import { HamburgerMenuComponent } from "./hamburger-menu/hamburger-menu.component";
import { ConfirmModalTutorialComponent } from "./confirm-modal-tutorial/confirm-modal-tutorial.component";
import { ExplorarContenidoComponent } from "./explorar-contenido/explorar-contenido.component";
import { QuizesIdeografoComponent } from "./explorar-contenido/quizes-ideografo/quizes-ideografo.component";

import { ModalRadarComponent } from "./modal-radar/modal-radar.component";
import { ActividadesBuscarComponent } from "./actividades-buscar/actividades-buscar.component";
import { ActividadQuizPlayComponent } from "./actividades-buscar/quiz-play/actividad-quiz-play.component";
import { ActividadQuizPlayHeaderComponent } from "./actividades-buscar/quiz-play/components/actividad-quiz-play-header/actividad-quiz-play-header.component";
import { ActividadQuizPlayBodyAnswerComponent } from "./actividades-buscar/quiz-play/components/quiz-play-body-answer/actividad-quiz-play-body-answer.component";
import { ActividadQuizPlayBodyDataComponent } from "./actividades-buscar/quiz-play/components/quiz-play-body-data/actividad-quiz-play-body-data.component";
import { ActividadQuizPlayBodyInfoComponent } from "./actividades-buscar/quiz-play/components/quiz-play-body-info/actividad-quiz-play-body-info.component";
import { ActividadQuizPlayBodyOptionComponent } from "./actividades-buscar/quiz-play/components/quiz-play-body-option/actividad-quiz-play-body-option.component";
import { ActividadQuizPlayBodyComponent } from "./actividades-buscar/quiz-play/components/quiz-play-body/actividad-quiz-play-body.component";
import { ActividadQuizPlayElementComponent } from "./actividades-buscar/quiz-play/components/quiz-play-body/components/quiz-play-element/actividad-quiz-play-element.component";
import { ActividadQuizPlayFooterComponent } from "./actividades-buscar/quiz-play/components/quiz-play-footer/actividad-quiz-play-footer.component";
import { OpenQuizPreviewComponent } from "./open-quiz-preview/open-quiz-preview.component";
import { QuizPreviewBodyAnswerComponent } from "./open-quiz-preview/quiz-play-body-answer/quiz-play-body-answer.component";
import { QuizPreviewBodyDataComponent } from "./open-quiz-preview/quiz-play-body-data/quiz-play-body-data.component";
import { QuizPreviewBodyInfoComponent } from "./open-quiz-preview/quiz-play-body-info/preview-quiz-play-body-info.component";
import { QuizPreviewBodyOptionComponent } from "./open-quiz-preview/quiz-play-body-option/quiz-play-body-option.component";
import { DatosResultadoQuizesComponent } from "./datos-resultado-quizes/datos-resultado-quizes.component";
import { YoutubePlayerComponent } from "./youtube-player/youtube-player.component";
import { ReportingComponent } from "./reporting/reporting.component";
import { EstudianteQuizesDatosComponent } from "./reporting/estudiante-quizes-datos/estudiante-quizes-datos.component";

import { ModalAssociationsEditNodosComponent } from "./modal-associations-edit-nodos/modal-associations-edit-nodos.component";
import { InfoEstudianteComponent } from "./info-estudiante/info-estudiante.component";
import { CenterIconComponent } from "./center-icon/center-icon.component";
import { ModalCursosListadoComponent } from "./cursos/modal-cursos-listado";
import { ModalCursoDetailComponent } from "./cursos/modal-curso-detail/modal-curso-detail.component";
import { ModalInformationCourseComponent } from "./cursos/modal-information-course/modal-information-course.component";
import { ModalCursoEditDetailComponent } from "./cursos/modal-curso-edit-detail/modal-curso-edit-detail.component";
import { ModalEliminarCursoComponent } from "./modal/modal-eliminar-curso/modal-eliminar-curso.component";

import { RecordarQuizPlayComponent } from "./quiz-open/quiz-play/quiz-play.component";
import { RecordarQuizPlayBodyComponent } from "./quiz-open/quiz-play/components/quiz-play-body/quiz-play-body.component";
import { RecordarQuizPlayBodyAnswerComponent } from "./quiz-open/quiz-play/components/quiz-play-body-answer/quiz-play-body-answer.component";
import { RecordarQuizPlayBodyDataComponent } from "./quiz-open/quiz-play/components/quiz-play-body-data/quiz-play-body-data.component";
import { RecordarQuizPlayBodyOptionComponent } from "./quiz-open/quiz-play/components/quiz-play-body-option/quiz-play-body-option.component";
import { RecordarQuizPlayBodyInfoComponent } from "./quiz-open/quiz-play/components/quiz-play-body-info/quiz-play-body-info.component";
import { RecordarQuizPlayHeaderComponent } from "./quiz-open/quiz-play/components/quiz-play-header/quiz-play-header.component";
import { RecordarQuizPadControllerComponent } from "./quiz-open/quiz-pad-controller/quiz-pad-controller.component";
import { RecordarQuizPadButtonsComponent } from "./quiz-open/quiz-pad-buttons/quiz-pad-buttons.component";
import { RecordarQuizPlayMultipleComponent } from "./quiz-open/quiz-play-multiple/quiz-play-multiple.component";
import { RecordarQuizPlayMultipleBodyComponent } from "./quiz-open/quiz-play-multiple/components/quiz-play-multiple-body/quiz-play-multiple-body.component";
import { RecordarQuizPlayMultipleBodyAnswerComponent } from "./quiz-open/quiz-play-multiple/components/quiz-play-multiple-body-answer/quiz-play-multiple-body-answer.component";
import { RecordarQuizPlayMultipleBodyDataComponent } from "./quiz-open/quiz-play-multiple/components/quiz-play-multiple-body-data/quiz-play-multiple-body-data.component";
import { RecordarQuizPlayMultipleBodyInfoComponent } from "./quiz-open/quiz-play-multiple/components/quiz-play-multiple-body-info/quiz-play-multiple-body-info.component";
import { RecordarQuizPlayMultipleHeaderComponent } from "./quiz-open/quiz-play-multiple/components/quiz-play-multiple-header/quiz-play-multiple-header.component";
import { RecordarQuizPlayMultipleBodyOptionComponent } from "./quiz-open/quiz-play-multiple/components/quiz-play-multiple-body-option/quiz-play-multiple-body-option.component";
import { RecordarQuizPlayMultipleElementComponent } from "./quiz-open/quiz-play-multiple/components/quiz-play-multiple-body/components/quiz-play-multiple-element/quiz-play-multiple-element.component";
import { RecordarQuizPlayElementComponent } from "./quiz-open/quiz-play/components/quiz-play-body/components/quiz-play-element/quiz-play-element.component";
import { RecordarAudioComponent } from "./quiz-open/quiz-play/components/quiz-play-body-option/audio.component/audio.component";
import { ModalListadoEstudiantesComponent } from "./grupos/modal-listado-estudiantes/modal-listado-estudiantes.component";
import { ModalQuizesMultiplesComponent } from "./reporting/modal-quizes-multiples/modal-quizes-multiples.component";
import { ModalListadoCursosComponent } from "./grupos/modal-listado-cursos/modal-listado-cursos.component";
import { ModalCelebrationComponent } from "./modal-celebration/modal-celebration.component";
import { MatTreeCursosComponent } from "./cursos/modal-cursos-listado/mat-tree-cursos/mat-tree-cursos.component";

import { ModalListStickersComponent } from "./nodes/modal-list-stickers/modal-list-stickers.component";
import { ModalAddTextComponent } from "./nodes/modal-add-text/modal-add-text.component";
import { NodeModeAutoComponent } from "./nodes/node-auto/node-mode-auto.component";
import { NodeMenuComponent } from "./nodes/node-menu/node-menu.component";
import { NodePadControllerComponent } from "./nodes/node-pad-controller/node-pad-controller.component";
import { NodePadButtonsComponent } from "./nodes/node-pad-buttons/node-pad-buttons.component";
import { QuizPadButtonsComponent } from "./nodes/quiz-pad-buttons/quiz-pad-buttons.component";
import { NodeDetailComponent } from "./nodes/node-detail/node-detail.component";
import { NodeDetailFilesComponent } from "./nodes/node-detail/components/node-detail-files/node-detail-files.component";
import { QuizPlayHeaderComponent } from "./nodes/quiz-play/components/quiz-play-header/quiz-play-header.component";
import { QuizPlayBodyComponent } from "./nodes/quiz-play/components/quiz-play-body/quiz-play-body.component";
import { QuizPlayMultipleHeaderComponent } from "./nodes/quiz-play-multiple/components/quiz-play-multiple-header/quiz-play-multiple-header.component";
import { QuizPlayMultipleBodyComponent } from "./nodes/quiz-play-multiple/components/quiz-play-multiple-body/quiz-play-multiple-body.component";
import { NodeDetailTransparentHeaderComponent } from "./nodes/node-detail/components/node-detail-transparent-header/node-detail-transparent-header.component";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NodeDetailFilesPlayerComponent } from "./nodes/node-detail/components/node-detail-files/components/node-detail-files-player/node-detail-files-player.component";
import { QuizPlayBodyInfoComponent } from "./nodes/quiz-play/components/quiz-play-body-info/quiz-play-body-info.component";
import { QuizPlayMultipleBodyInfoComponent } from "./nodes/quiz-play-multiple/components/quiz-play-multiple-body-info/quiz-play-multiple-body-info.component";
import { QuizPlayMultipleBodyDataComponent } from "./nodes/quiz-play-multiple/components/quiz-play-multiple-body-data/quiz-play-multiple-body-data.component";
import { QuizPlayMultipleBodyOptionComponent } from "./nodes/quiz-play-multiple/components/quiz-play-multiple-body-option/quiz-play-multiple-body-option.component";
import { AudioComponent } from "./nodes/quiz-play/components/quiz-play-body-option/audio.component/audio.component";
import { QuizHypermediaComponent } from "./nodes/quiz-hypermedia/quiz-hypermedia.component";
import { QuizEditInfoComponent } from "./nodes/quiz-edit/components/quiz-edit-info/quiz-edit-info.component";
import { QuizPlayBodyAnswerComponent } from "./nodes/quiz-play/components/quiz-play-body-answer/quiz-play-body-answer.component";
import { SigmaCanvasComponent } from "./nodes/sigma-canvas/sigma-canvas.component";
import { QuizPlayMultipleBodyAnswerComponent } from "./nodes/quiz-play-multiple/components/quiz-play-multiple-body-answer/quiz-play-multiple-body-answer.component";
import { QuizEditTemplateTextEditorComponent } from "./nodes/quiz-edit/components/quiz-edit-template/components/quiz-edit-template-text-editor/quiz-edit-template-text-editor.component";
import { QuizPlayBodyOptionComponent } from "./nodes/quiz-play/components/quiz-play-body-option/quiz-play-body-option.component";
import { QuizEditBodyComponent } from "./nodes/quiz-edit/components/quiz-edit-body/quiz-edit-body.component";
import { QuizEditComponent } from "./nodes/quiz-edit/quiz-edit.component";
import { NodeLinkComponent } from "./nodes/node-link/node-link.component";
import { QuizEditMultipleBodyComponent } from "./nodes/quiz-edit-multiple/components/quiz-edit-multiple-body/quiz-edit-multiple-body.component";
import { QuizModalHypermediaComponent } from "./nodes/quiz-edit-multiple/components/quiz-modal-hypermedia/quiz-modal-hypermedia.component";
import { ElementsSubQuizzesComponent } from "./nodes/quiz-edit-multiple/components/quiz-edit-multiple-footer/components/elements-sub-quizes/elements-sub-quizzes.component";
import { SubQuizzesComponent } from "./nodes/quiz-edit-multiple/components/quiz-edit-multiple-footer/components/sub-quizzes/sub-quizzes.component";
import { QuizEditMultipleFooterComponent } from "./nodes/quiz-edit-multiple/components/quiz-edit-multiple-footer/quiz-edit-multiple-footer.component";
import { QuizEditMultipleComponent } from "./nodes/quiz-edit-multiple/quiz-edit-multiple.component";
import { QuizEditBodyAnswerComponent } from "./nodes/quiz-edit/components/quiz-edit-body-answer/quiz-edit-body-answer.component";
import { QuizEditBodyDataComponent } from "./nodes/quiz-edit/components/quiz-edit-body-data/quiz-edit-body-data.component";
import { QuizEditBodyInfoComponent } from "./nodes/quiz-edit/components/quiz-edit-body-info/quiz-edit-body-info.component";
import { QuizEditBodyOptionComponent } from "./nodes/quiz-edit/components/quiz-edit-body-option/quiz-edit-body-option.component";
import { QuizEditHeaderComponent } from "./nodes/quiz-edit/components/quiz-edit-header/quiz-edit-header.component";
import { QuizEditTemplateComponent } from "./nodes/quiz-edit/components/quiz-edit-template/quiz-edit-template.component";
import { QuizEditTemplateElementComponent } from "./nodes/quiz-edit/components/quiz-edit-template/components/quiz-edit-template-element/quiz-edit-template-element.component";
import { QuizEditMultipleHeaderComponent } from "./nodes/quiz-edit-multiple/components/quiz-edit-multiple-header/quiz-edit-multiple-header.component";
import { QuizPadControllerComponent } from "./nodes/quiz-pad-controller/quiz-pad-controller.component";
import { QuizPlayComponent } from "./nodes/quiz-play/quiz-play.component";
import { QuizPlayElementComponent } from "./nodes/quiz-play/components/quiz-play-body/components/quiz-play-element/quiz-play-element.component";
import { QuizPlayBodyDataComponent } from "./nodes/quiz-play/components/quiz-play-body-data/quiz-play-body-data.component";
import { QuizPlayFooterComponent } from "./nodes/quiz-play/components/quiz-play-footer/quiz-play-footer.component";
import { QuizPlayMultipleComponent } from "./nodes/quiz-play-multiple/quiz-play-multiple.component";
import { QuizPlayMultipleElementComponent } from "./nodes/quiz-play-multiple/components/quiz-play-multiple-body/components/quiz-play-multiple-element/quiz-play-multiple-element.component";
import { NodeDetailInfoComponent } from "./nodes/node-detail/components/node-detail-info/node-detail-info.component";
import { NodeDetailFilesTextEditComponent } from "./nodes/node-detail/components/node-detail-files/components/node-detail-files-text-edit/node-detail-files-text-edit.component";
import { NodeColumnFilesComponent } from "./nodes/node-detail/components/node-column-files/node-column-files.component";
import { EditorModule } from "@tinymce/tinymce-angular";
import { NodeDetailFilesUploaderComponent } from "./nodes/node-detail/components/node-detail-files/components/node-detail-files-uploader/node-detail-files-uploader.component";
import { NodeDetailHeaderComponent } from "./nodes/node-detail/components/node-detail-header/node-detail-header.component";
import { SocketQuizPreviewComponent } from "./socket-quiz-preview/socket-quiz-preview.component";
import { ModalCursosGrupoComponent } from "./grupos/modal-cursos-grupo/modal-cursos-grupo.component";
import { ModalSocketCoursesComponent } from "./grupos/modal-socket-courses/modal-socket-courses.component";
import { ModalYoutubeVideoComponent } from "./nodes/node-detail/components/node-detail-files/components/modal-youtube-video/modal-youtube-video.component";
import { DialogWeComponent } from "./dialog-we/dialog-we.component";
import { DialogPolicyComponent } from "./dialog-policy/dialog-policy.component";
import { DialogCookiesComponent } from "./dialog-cookies/dialog-cookies.component";
import { DialogContactComponent } from "./dialog-contact/dialog-contact.component";
import { ModalConfigSocketCoursesComponent } from "./grupos/modal-socket-courses/modal-config-socket-courses/modal-config-socket-courses.component";
import {
	NbButtonModule,
	NbButtonGroupModule,
	NbCardModule,
	NbLayoutModule,
	NbProgressBarModule,
	NbActionsModule,
	NbInputModule,
	NbIconModule,
	NbSearchModule,
	NbToggleModule,
	NbRadioModule,
	NbTooltipModule,
	NbCheckboxModule,
	NbPopoverModule,
	NbListModule,
	NbSelectModule,
	NbBadgeModule,
	NbAlertModule,
	NbTabsetModule,
	NbUserComponent,
	NbUserModule,
	NbFormFieldModule,
	NbSpinnerModule,
	NbTagComponent,
	NbTagListComponent,
	NbTagModule,
} from "@nebular/theme";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { ModalProfileActionsComponent } from "./modal-profile-actions/modal-profile-actions.component";
import { SearchGroupComponent } from "./modal-grupos-editar/search-groups/search-group.component";
import { PadCenterComponent } from "./pad-center/pad-center.component";
import { ModalSendCommentsComponent } from "./modal-send-comments/modal-send-comments.component";
import { RegisterNickComponent } from "src/app/pages/register/register-nick/register-nick.component";

import { LiveReportingTableComponent } from "./grupos/modal-socket-courses/live-reporting-table/live-reporting-table.component";
import { ModalAlertChallengesComponent } from "./modal-profile-actions/modal-alert-challenges/modal-alert-challenges.component";
import { ModalFinishSocketCoursesComponent } from "./grupos/modal-socket-courses/modal-finish-socket-courses/modal-finish-socket-courses.component";
import { ModalChangePassComponent } from "src/app/pages/user-data/user-data-general/modal-change-pass/modal-change-pass.component";
import { ModalChangeMobileComponent } from "src/app/pages/user-data/user-data-general/modal-change-mobile/modal-change-mobile.component";
import { QuizPromptEditComponent } from "./nodes/quiz-edit/components/quiz-prompt-edit/quiz-prompt-edit.component";
import { ModalDropOutComponent } from "src/app/pages/user-data/user-data-general/modal-drop-out/modal-drop-out.component";
import { ModalAjustesUsuarioComponent } from "./modal-ajustes-usuario/modal-ajustes-usuario.component";
import { ListaPadresComponent } from "./modal-ajustes-usuario/lista-padres/lista-padres.component";
import { NuevoPadreComponent } from "./modal-ajustes-usuario/nuevo-padre/nuevo-padre.component";
import { InvitarPadreComponent } from "./modal-ajustes-usuario/invitar-padre/invitar-padre.component";
import { TableInformeModule } from "src/app/pages/table-informe/table-informe.module";
import { TableInformeIndividualModule } from "src/app/pages/table-informe-individual/table-informe-individual.module";
import { TableInformeComponent } from "src/app/pages/table-informe/table-informe.component";
import { SeleccionMultiplesComponent } from "./cursos/modal-editar-curso-mapa/seleccion-multiples/seleccion-multiples.component";
import { TableInformeIndividualComponent } from "src/app/pages/table-informe-individual/table-informe-individual.component";
import { UserCardInfoComponent } from "./user-card-info/user-card-info.component";
import { RecuperarPassNickComponent } from "src/app/pages/forgot-password/components/mobile-form/recuperar-pass-nick/recuperar-pass-nick.component";
import { ProyectoDestinoComponent } from "./cursos/modal-editar-curso-mapa/proyecto-destino/proyecto-destino.component";
import { ModalAlertFlashComponent } from "./modal-profile-actions/modal-alert-flash/modal-alert-flash.component";
import { ActividadesFlashComponent } from "./nodes/actividades-flash/app-actividades-flash.component";
import { CountdownModule, CountdownComponent } from 'ngx-countdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { MeetingsComponent } from "src/app/pages/meetings/meetings.component";
import { MeetingsListComponent } from 'src/app/pages/meetings-list/meetings-list.component';
import { ChallengesLobbyComponent } from './challenges-lobby/challenges-lobby.component';
import { ChallengesCreateRoomComponent } from './challenges-create-room/challenges-create-room.component';
import { ChallengesHostWaitingComponent } from './challenges-host-waiting/challenges-host-waiting.component';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { ModalChangeMailComponent } from 'src/app/pages/user-data/user-data-general/modal-change-mail/modal-change-mail.component';
import { ModalIdeasOrderComponent } from './modal-ideas-order/modal-ideas-order.component';
import { DndListModule } from "@ryware/ngx-drag-and-drop-lists";
import { ModalSpotifyAudioComponent } from './nodes/node-detail/components/node-detail-files/components/modal-spotify-audio/modal-spotify-audio.component';
import { NgxSortableModule } from 'ngx-sortable';
import { GraficosComponent } from './reporting/graficos/graficos.component';
import { ModalAlertAsistenciaComponent } from './modal-profile-actions/modal-alert-asistencia/modal-alert-asistencia.component';
import { ModalAlertPensarCreandoComponent } from './modal-profile-actions/modal-alert-pensar-creando/modal-alert-pensar-creando.component';
import { ModalAsistenciaHistorialComponent } from './modal-asistencia-historial/modal-asistencia-historial.component';
import { ModalCursosPensarCreandoComponent } from './cursos/modal-cursos-pensar-creando';
import { ModalAsistenciaEstudianteComponent } from './modal-asistencia-historial/modal-asistencia-estudiante/modal-asistencia-estudiante.component';
import { TablaComponent } from './reporting/tabla/tabla.component';
import { GuiaProyectosComponent } from './cursos/modal-cursos-listado/guia-proyectos/guia-proyectos.component';
import { ModalStudentWaitingComponent } from './grupos/modal-student-waiting/modal-student-waiting.component';
import { AlertDarkProjectComponent } from './cursos/modal-cursos-listado/alert-darkProject/alert-darkProject.component';
import { ModalSchedulesComponent } from './modal-schedules/modal-schedules.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from "angularx-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import localeES from "@angular/common/locales/es";

registerLocaleData(localeES);
import { InformesCentroComponent } from './informes-centro/informes-centro.component';
import { BarrasComponent } from './informes-centro/barras/barras.component';
import { ListaComponent } from './informes-centro/lista/lista.component';
import { SolarComponent } from './informes-centro/solar/solar.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { SecondStepStatisticsComponent } from './control-panel/second-step-statistics/second-step-statistics.component';
import { BarrasMiniComponent } from './control-panel/second-step-statistics/charts/barras-mini/barras-mini.component';
import { SolarMiniComponent } from './control-panel/second-step-statistics/charts/solar-mini/solar-mini.component';
import { ListaMiniComponent } from './control-panel/second-step-statistics/charts/lista-mini/lista-mini.component';
import { RadialComponent } from './informes-centro/radial/radial.component';
import { RadialMiniComponent } from './control-panel/second-step-statistics/charts/radial-mini/radial-mini.component';
import { SolarCentroComponent } from './control-panel/charts-control-panel/solar-centro/solar-centro.component';
import { BarrasProfesorComponent } from './control-panel/charts-control-panel/barras-profesor/barras-profesor.component';
import { BarrasPadresComponent } from './control-panel/charts-control-panel/barras-padres/barras-padres.component';
import { ListaAlumnosComponent } from './control-panel/charts-control-panel/lista-alumnos/lista-alumnos.component';
import { ListaNivelesComponent } from './control-panel/charts-control-panel/lista-niveles/lista-niveles.component';
import { RadialGruposComponent } from './control-panel/charts-control-panel/radial-grupos/radial-grupos.component';
import { RadialNivelesComponent } from './control-panel/charts-control-panel/radial-niveles/radial-niveles.component';
import { BarrasAsignaturasComponent } from './control-panel/charts-control-panel/barras-asignaturas/barras-asignaturas.component';
import { TortaGruposComponent } from './control-panel/charts-control-panel/torta-grupos/torta-grupos.component';
import { ChatAssistantComponent } from './chat-assistant/chat-assistant.component';
import { DispersionComponent } from './informes-centro/dispersion/dispersion.component';
import { DispersionAsignaturasComponent } from './control-panel/charts-control-panel/dispersion-asignaturas/dispersion-asignaturas.component';
import { EncuestasListadoComponent } from './encuestas/encuestas-listado/encuestas-listado.component';
import { EncuestasEditComponent } from './encuestas/encuestas-edit/encuestas-edit.component';
import { EncuestasPreguntasEditComponent } from './encuestas/encuestas-preguntas-edit/encuestas-preguntas-edit.component';
import { SurveysListComponent } from './surveys/surveys-list/surveys-list.component';
import { SurveysDetailsComponent } from './surveys/surveys-details/surveys-details.component';
import { SurveysQuestionEditComponent } from './surveys/surveys-question-edit/surveys-question-edit.component';
import { SurveysAnswerComponent } from './surveys/surveys-answer/surveys-answer.component';
import { SurveysQuestionAnswerComponent } from './surveys/surveys-question-answer/surveys-question-answer.component';
@NgModule({
	declarations: [
		AlertComponent,
		ConfirmDialogComponent,
		FormsComponent,
		NodeFormComponent,
		FileUploaderComponent,
		LoadingComponent,
		PatternsComponent,
		QuizFormComponent,
		RatingComponent,
		ModalComponent,
		ModalAceptarCancelarComponent,
		ModalAlertFlashComponent,
		ModalChangePassComponent,
		ModalDropOutComponent,
		ModalChangeMobileComponent,
		ModalChangeMailComponent,
		ModalFinishSocketCoursesComponent,
		ModalReproducirComponent,
		PatternsComponent,
		PatternDraftComponent,
		PatternFormComponent,
		RatingComponent,
		ModalCompetitionCouplesComponent,
		ModalCompetitionGroupsComponent,
		ModalConectarNodoComponent,
		ModalOperadoresComponent,
		ModalRegistarQuizzesComponent,
		ModalRegistrarNodoComponent,
		ModalReproducirNodoComponent,
		ModalReproducirQuizzesComponent,
		ModalSelectRecipientComponent,
		ModalTasksComponent,
		PowerComponentComponent,
		RegistrarCursoComponent,
		RegistrarNodoComponent,
		BodySelectRecipientComponent,
		HeadSelectRecipientComponent,
		BodyReproducirQuizzesComponent,
		FooterReproducirQuizzesComponent,
		HeadReproducirQuizzesComponent,
		HeadNodesComponent,
		BodyQuizzesComponent,
		FooterQuizzesComponent,
		HeadQuizzesComponent,
		BodyCompetitionGroupsComponent,
		HeadCompetitionGroupsComponent,
		BodyCompetitionCouplesComponent,
		HeadCompetitionCouplesComponent,
		BodyTasksListComponent,
		BodyTasksOptionsComponent,
		HeadTasksComponent,
		PlantillaReproducirQuizComponent,
		ModalCursosComponent,
		ModalEditarCursoComponent,
		ModalVerCursoComponent,
		ModalEditarCursoMapaComponent,
		NodeFormsComponent,
		FileUploadersComponent,
		ImguploadComponent,
		ShareButtonComponent,
		ReportButtonComponent,
		AcceptDialogComponent,
		ModalHelpComponent,
		ModalTasksHomeComponent,
		ModalCalendarComponent,
		ModalCertifyComponent,
		OperatorsTableComponent,
		OperatorIconComponent,
		GraphLateralIconsComponent,
		ConditionsDialogComponent,
		PrivacyDialogComponent,
		UploadProgressBarComponent,
		PadButtonsComponent,
		PadControllerComponent,
		PowerSelectorComponent,
		ModalCompetitionComponent,
		ModalImageComponent,
		ModalTextEditComponent,
		ModalGruposEditarComponent,
		ModalAsistenciaHistorialComponent,
		ModalAjustesUsuarioComponent,
		ListaPadresComponent,
		NuevoPadreComponent,
		InvitarPadreComponent,
		SeleccionMultiplesComponent,
		ProyectoDestinoComponent,
		ModalEditarGrupoComponent,
		GruposOpcionesComponent,
		ModalTutorialGrafoComponent,
		MessageMultiTabsComponent,
		ModalContactComponent,
		ModalMicrofonoAudioComponent,
		ViewFileModalComponent,
		GraphButtonsComponent,
		HamburgerMenuComponent,
		GraphButtonsComponent,
		ConfirmModalTutorialComponent,
		ExplorarContenidoComponent,
		QuizesIdeografoComponent,
		OpenQuizPreviewComponent,
		QuizPreviewBodyDataComponent,
		QuizPreviewBodyAnswerComponent,
		QuizPreviewBodyOptionComponent,
		QuizPreviewBodyInfoComponent,
		ActividadesBuscarComponent,
		ActividadQuizPlayComponent,
		ActividadQuizPlayBodyAnswerComponent,
		ActividadQuizPlayBodyComponent,
		ActividadQuizPlayBodyDataComponent,
		ActividadQuizPlayBodyInfoComponent,
		ActividadQuizPlayBodyOptionComponent,
		ActividadQuizPlayElementComponent,
		ActividadQuizPlayFooterComponent,
		ActividadQuizPlayHeaderComponent,
		ModalRadarComponent,
		DatosResultadoQuizesComponent,
		YoutubePlayerComponent,
		ReportingComponent,
		EstudianteQuizesDatosComponent,
		GraficosComponent,
		InfoEstudianteComponent,
		CenterIconComponent,
		ModalCursosListadoComponent,
		GuiaProyectosComponent,
		ModalCursosPensarCreandoComponent,
		MatTreeCursosComponent,
		ModalCursoDetailComponent,
		ModalInformationCourseComponent,
		ModalCursoEditDetailComponent,
		ModalEliminarCursoComponent,
		ModalEliminarEditorComponent,
		RecordarQuizPlayComponent,
		RecordarQuizPlayBodyComponent,
		RecordarQuizPlayBodyAnswerComponent,
		RecordarQuizPlayBodyDataComponent,
		RecordarQuizPlayBodyOptionComponent,
		RecordarQuizPlayBodyInfoComponent,
		RecordarQuizPlayHeaderComponent,
		RecordarQuizPadControllerComponent,
		RecordarQuizPadButtonsComponent,
		RecordarQuizPlayElementComponent,
		RecordarAudioComponent,
		RecordarQuizPlayMultipleComponent,
		RecordarQuizPlayMultipleBodyComponent,
		RecordarQuizPlayMultipleBodyAnswerComponent,
		RecordarQuizPlayMultipleBodyDataComponent,
		RecordarQuizPlayMultipleBodyInfoComponent,
		RecordarQuizPlayMultipleHeaderComponent,
		RecordarQuizPlayMultipleBodyOptionComponent,
		RecordarQuizPlayMultipleElementComponent,
		ModalListadoEstudiantesComponent,
		ModalListadoCursosComponent,
		ModalListadoEditoresComponent,
		ModalCursosGrupoComponent,
		ModalListadoEstudiantesComponent,
		ModalQuizesMultiplesComponent,
		ModalCelebrationComponent,
		ModalListStickersComponent,
		NodeModeAutoComponent,
		PopUpReloadComponent,
		ActividadesFlashComponent,
		NodeMenuComponent,
		NodePadControllerComponent,
		NodePadButtonsComponent,
		QuizPadButtonsComponent,
		NodeDetailComponent,
		NodeDetailFilesComponent,
		QuizPlayHeaderComponent,
		QuizPlayBodyComponent,
		QuizPlayMultipleHeaderComponent,
		QuizPlayMultipleBodyComponent,
		NodeDetailTransparentHeaderComponent,
		NodeDetailFilesPlayerComponent,
		QuizPlayBodyInfoComponent,
		QuizPlayMultipleBodyInfoComponent,
		QuizPlayMultipleBodyDataComponent,
		QuizPlayMultipleBodyOptionComponent,
		AudioComponent,
		QuizHypermediaComponent,
		QuizEditInfoComponent,
		QuizPlayBodyAnswerComponent,
		SigmaCanvasComponent,
		QuizPlayMultipleBodyAnswerComponent,
		QuizEditTemplateTextEditorComponent,
		QuizPlayBodyOptionComponent,
		QuizEditBodyComponent,
		QuizEditComponent,
		NodeLinkComponent,
		QuizEditMultipleBodyComponent,
		QuizModalHypermediaComponent,
		ElementsSubQuizzesComponent,
		SubQuizzesComponent,
		QuizEditMultipleFooterComponent,
		QuizEditMultipleComponent,
		QuizEditBodyAnswerComponent,
		QuizEditBodyDataComponent,
		QuizEditBodyInfoComponent,
		QuizEditBodyOptionComponent,
		QuizEditHeaderComponent,
		QuizEditTemplateComponent,
		QuizPromptEditComponent,
		QuizEditTemplateElementComponent,
		QuizEditMultipleHeaderComponent,
		QuizPadControllerComponent,
		QuizPlayComponent,
		QuizPlayElementComponent,
		QuizPlayBodyDataComponent,
		QuizPlayFooterComponent,
		QuizPlayMultipleComponent,
		QuizPlayMultipleElementComponent,
		NodeDetailInfoComponent,
		NodeDetailFilesTextEditComponent,
		NodeColumnFilesComponent,
		NodeDetailFilesUploaderComponent,
		NodeDetailHeaderComponent,
		SocketQuizPreviewComponent,
		ModalSocketCoursesComponent,
		ModalStudentWaitingComponent,
		ModalAlertChallengesComponent,
		ModalYoutubeVideoComponent,
		DialogWeComponent,
		DialogPolicyComponent,
		DialogCookiesComponent,
		DialogContactComponent,
		ModalTiktokVideoComponent,
		ModalSpotifyAudioComponent,
		ModalInstagramVideoComponent,
		ModalConfigSocketCoursesComponent,
		ModalProfileActionsComponent,
		SearchGroupComponent,
		PadCenterComponent,
		ModalSendCommentsComponent,
		RegisterNickComponent,
		RecuperarPassNickComponent,
		LiveReportingTableComponent,
		UserCardInfoComponent,
		ModalAddTextComponent,
		MeetingsComponent,
		MeetingsListComponent,
		ChallengesLobbyComponent,
		ChallengesCreateRoomComponent,
		ChallengesHostWaitingComponent,
		ModalIdeasOrderComponent,
		ModalAlertAsistenciaComponent,
		AlertDarkProjectComponent,
		ModalAsistenciaEstudianteComponent,
		ModalAlertPensarCreandoComponent,
		ModalSchedulesComponent,
		TablaComponent,
		InformesCentroComponent,
		BarrasComponent,
		SolarComponent,
		RadialComponent,
		DispersionComponent,
		ListaComponent,
		ControlPanelComponent,
		SecondStepStatisticsComponent,
		BarrasMiniComponent,
		SolarMiniComponent,
		ListaMiniComponent,
		RadialMiniComponent,
		SolarCentroComponent,
		BarrasProfesorComponent,
		BarrasPadresComponent,
		ListaAlumnosComponent,
		ListaNivelesComponent,
		RadialGruposComponent,
		RadialNivelesComponent,
		BarrasAsignaturasComponent,
		TortaGruposComponent,
		ChatAssistantComponent,
    DispersionAsignaturasComponent,
    EncuestasListadoComponent,
    EncuestasEditComponent,
    EncuestasPreguntasEditComponent,
    SurveysListComponent,
    SurveysDetailsComponent,
    SurveysQuestionEditComponent,
    SurveysAnswerComponent,
    SurveysQuestionAnswerComponent,
	],
	imports: [
		CommonModule,
		PipesModule,
		TranslateModule,
		MaterialModule,
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		ColorPickerModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		FileUploadModule,
		WebcamModule,
		NgxPrintModule,
		ResizableModule,
		NgxMaskModule,
		AngularDraggableModule,
		QuillModule,
		NgxCopilotModule,
		SortablejsModule,
		NgxDocViewerModule,
		EditorModule,
		NbEvaIconsModule,
		NbLayoutModule,
		NbButtonModule,
		NbButtonGroupModule,
		NbCardModule,
		NbProgressBarModule,
		NbActionsModule,
		NbInputModule,
		NbIconModule,
		NbSearchModule,
		NbToggleModule,
		NbRadioModule,
		NbTooltipModule,
		NbCheckboxModule,
		NbPopoverModule,
		NbListModule,
		NbSpinnerModule,
		NbSelectModule,
		NbBadgeModule,
		NbAlertModule,
		NbUserModule,
		NbFormFieldModule,
		NbTagModule,
		TableInformeModule,
		TableInformeIndividualModule,
		CountdownModule,
		CountdownComponent,
		NgSelectModule,
		NbTabsetModule,
		DragAndDropModule,
		DndListModule,
		NgxSortableModule,
		ModalCompetitionResultsComponent,
		FlatpickrModule.forRoot({
			locale: Spanish,
		}),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
	],
	exports: [
		AlertComponent,
		ConfirmDialogComponent,
		FormsComponent,
		NodeFormComponent,
		FileUploaderComponent,
		LoadingComponent,
		PatternsComponent,
		QuizFormComponent,
		RatingComponent,
		ModalComponent,
		ModalAceptarCancelarComponent,
		ModalAlertFlashComponent,
		ModalChangePassComponent,
		ModalDropOutComponent,
		ModalChangeMobileComponent,
		ModalChangeMailComponent,
		ModalFinishSocketCoursesComponent,
		ModalReproducirComponent,
		PatternsComponent,
		PatternDraftComponent,
		PatternFormComponent,
		RatingComponent,
		ModalCompetitionCouplesComponent,
		ModalCompetitionGroupsComponent,
		ModalConectarNodoComponent,
		ModalOperadoresComponent,
		ModalRegistarQuizzesComponent,
		ModalRegistrarNodoComponent,
		ModalReproducirNodoComponent,
		ModalReproducirQuizzesComponent,
		ModalSelectRecipientComponent,
		ModalTasksComponent,
		PowerComponentComponent,
		RegistrarCursoComponent,
		RegistrarNodoComponent,
		PlantillaReproducirQuizComponent,
		ModalCursosComponent,
		ModalEditarCursoComponent,
		ModalVerCursoComponent,
		ModalEditarCursoMapaComponent,
		NodeFormsComponent,
		FileUploadersComponent,
		//ColorPickerModule,
		ImguploadComponent,
		ShareButtonComponent,
		ReportButtonComponent,
		AcceptDialogComponent,
		ModalHelpComponent,
		ModalTasksHomeComponent,
		ModalCalendarComponent,
		ModalCertifyComponent,
		GraphLateralIconsComponent,
		ConditionsDialogComponent,
		PrivacyDialogComponent,
		DialogWeComponent,
		DialogPolicyComponent,
		DialogCookiesComponent,
		DialogContactComponent,
		UploadProgressBarComponent,
		PadButtonsComponent,
		PadControllerComponent,
		ModalCompetitionComponent,
		ModalImageComponent,
		ModalTextEditComponent,
		MessageMultiTabsComponent,
		ModalContactComponent,
		GraphButtonsComponent,
		HamburgerMenuComponent,
		ConfirmModalTutorialComponent,
		ExplorarContenidoComponent,
		QuizesIdeografoComponent,
		ActividadesBuscarComponent,
		QuizPreviewBodyInfoComponent,
		ActividadesBuscarComponent,
		ActividadQuizPlayComponent,
		ActividadQuizPlayBodyAnswerComponent,
		ActividadQuizPlayBodyComponent,
		ActividadQuizPlayBodyDataComponent,
		ActividadQuizPlayBodyInfoComponent,
		ActividadQuizPlayBodyOptionComponent,
		ActividadQuizPlayElementComponent,
		ActividadQuizPlayFooterComponent,
		ActividadQuizPlayHeaderComponent,
		ModalRadarComponent,
		DatosResultadoQuizesComponent,
		YoutubePlayerComponent,
		ReportingComponent,
		EstudianteQuizesDatosComponent,
		InfoEstudianteComponent,
		CenterIconComponent,
		ModalCursosListadoComponent,
		GuiaProyectosComponent,
		ModalCursosPensarCreandoComponent,
		MatTreeCursosComponent,
		ModalCursoDetailComponent,
		ModalInformationCourseComponent,
		ModalCursoEditDetailComponent,
		ModalEliminarCursoComponent,
		ModalEliminarEditorComponent,
		ModalListadoEstudiantesComponent,
		ModalListadoCursosComponent,
		ModalListadoEditoresComponent,
		ModalCursosGrupoComponent,
		ModalQuizesMultiplesComponent,
		ModalCelebrationComponent,
		ModalListStickersComponent,
		NodeModeAutoComponent,
		PopUpReloadComponent,
		ActividadesFlashComponent,
		NodeMenuComponent,
		NodePadControllerComponent,
		NodePadButtonsComponent,
		QuizPadButtonsComponent,
		NodeDetailComponent,
		NodeDetailFilesComponent,
		QuizPlayHeaderComponent,
		QuizPlayBodyComponent,
		QuizPlayMultipleHeaderComponent,
		QuizPlayMultipleBodyComponent,
		NodeDetailTransparentHeaderComponent,
		NodeDetailFilesPlayerComponent,
		QuizPlayBodyInfoComponent,
		QuizPlayMultipleBodyInfoComponent,
		QuizPlayMultipleBodyDataComponent,
		QuizPlayMultipleBodyOptionComponent,
		AudioComponent,
		QuizHypermediaComponent,
		QuizEditInfoComponent,
		QuizPlayBodyAnswerComponent,
		SigmaCanvasComponent,
		QuizPlayMultipleBodyAnswerComponent,
		QuizEditTemplateTextEditorComponent,
		QuizPlayBodyOptionComponent,
		QuizEditBodyComponent,
		QuizEditComponent,
		NodeLinkComponent,
		QuizEditMultipleBodyComponent,
		QuizModalHypermediaComponent,
		ElementsSubQuizzesComponent,
		SubQuizzesComponent,
		QuizEditMultipleFooterComponent,
		QuizEditMultipleComponent,
		QuizEditBodyAnswerComponent,
		QuizEditBodyDataComponent,
		QuizEditBodyInfoComponent,
		QuizEditBodyOptionComponent,
		QuizEditHeaderComponent,
		QuizEditTemplateComponent,
		QuizPromptEditComponent,
		QuizEditTemplateElementComponent,
		QuizEditMultipleHeaderComponent,
		QuizPadControllerComponent,
		QuizPlayComponent,
		QuizPlayElementComponent,
		QuizPlayBodyDataComponent,
		QuizPlayFooterComponent,
		QuizPlayMultipleComponent,
		QuizPlayMultipleElementComponent,
		NodeDetailInfoComponent,
		NodeDetailFilesTextEditComponent,
		NodeColumnFilesComponent,
		NodeDetailFilesUploaderComponent,
		NodeDetailHeaderComponent,
		SocketQuizPreviewComponent,
		ModalSocketCoursesComponent,
		ModalStudentWaitingComponent,
		ModalAlertChallengesComponent,
		ModalYoutubeVideoComponent,
		ModalTiktokVideoComponent,
		ModalSpotifyAudioComponent,
		ModalInstagramVideoComponent,
		ModalConfigSocketCoursesComponent,
		ModalProfileActionsComponent,
		SearchGroupComponent,
		PadCenterComponent,
		ModalSendCommentsComponent,
		RegisterNickComponent,
		RecuperarPassNickComponent,
		LiveReportingTableComponent,
		UserCardInfoComponent,
		ModalAddTextComponent,
		MeetingsComponent,
		MeetingsListComponent,
		ChallengesLobbyComponent,
		ChallengesCreateRoomComponent,
		ChallengesHostWaitingComponent,
		ModalIdeasOrderComponent,
		ModalSchedulesComponent,
		InformesCentroComponent,
		BarrasComponent,
		SolarComponent,
		RadialComponent,
		DispersionComponent,
		ListaComponent,
		ChatAssistantComponent,
	],
})
export class ComponentsModule {}
