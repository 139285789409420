import { LocalStorage } from './../../utils/local-storage';
import { AuthorResponseModel } from './../../models/masters/author-response.model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CenterModel, MateriaModel, NivelModel, PaisModel, IdiomaModel, RegionModel } from '../../models/masters';

// Models
import { GeneroModel } from '../../models/masters/genero.model';
import { PublishResponseModel } from '../../models/masters/publish.model';
import { MenuRol } from '../../models/shared/menu-rol.model';
import { Profiles } from '../../utils/profiles.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCursosComponent } from 'src/app/shared/components/cursos/modal-cursos';
import { ModalHelpComponent } from 'src/app/shared/components/modal-help/modal-help.component';
import { ModalCalendarComponent } from 'src/app/shared/components/modal-calendar/modal-calendar.component';
import { ModalTasksHomeComponent } from 'src/app/shared/components/modal-tasks-home/modal-tasks-home.component';
import { ModalCertifyComponent } from 'src/app/shared/components/modal-certify/modal-certify.component';
import { ModalEditarCursoComponent } from 'src/app/shared/components/cursos/modal-editar-curso';
import { UserDataEstudianteComponent } from 'src/app/pages/user-data/user-data-estudiante/user-data-estudiante.component';
import { UserDataPadreComponent } from 'src/app/pages/user-data/user-data-padre/user-data-padre.component';
import { UserDataProfesorComponent } from 'src/app/pages/user-data/user-data-profesor/user-data-profesor.component';
import { UserDataAutorComponent } from 'src/app/pages/user-data/user-data-autor/user-data-autor.component';
import { UserDataCentroComponent } from 'src/app/pages/user-data/user-data-centro/user-data-centro.component';
import { UserDataEmpleadorComponent } from 'src/app/pages/user-data/user-data-empleador/user-data-empleador.component';
import { LoginService } from '../login';
import { TranslateService } from '@ngx-translate/core';
import { TitleService } from '../shared/title.service';
import { ModalCompetitionComponent } from 'src/app/shared/components/modal-competition/modal-competition.component';
import { ModalGruposEditarComponent } from 'src/app/shared/components/modal-grupos-editar/modal-grupos-editar.component';
import { ExplorarContenidoComponent } from 'src/app/shared/components/explorar-contenido/explorar-contenido.component';
import { ActividadesBuscarComponent } from 'src/app/shared/components/actividades-buscar/actividades-buscar.component';
import { ReportingComponent } from 'src/app/shared/components/reporting/reporting.component';
import { InfoEstudianteComponent } from 'src/app/shared/components/info-estudiante/info-estudiante.component';
import { ModalCursosListadoComponent } from 'src/app/shared/components/cursos/modal-cursos-listado';
import { MODAL_DIALOG_TYPES } from '../../utils/modal-dialog-types';
import { CoursesService } from '../courses';
import { Router } from '@angular/router';
import { Utils } from '../../utils/utils';
import { ModalCursosPensarCreandoComponent } from 'src/app/shared/components/cursos/modal-cursos-pensar-creando';
import { SurveysListComponent } from 'src/app/shared/components/surveys/surveys-list/surveys-list.component';



const GETALLSUBJECTS = 'commons/getDatosMaestrosSubjects'
const GETREGIONSBYCOUNTRY = 'commons/getCountriesRegionsByIdCountry'
const GETCENTERS = 'group/getDatosMaestrosCenters'
const GETLANGUAGES = 'commons/getDatosMaestrosLanguage'
const GETCENTERBYID = ''
const GETVERSIONAPI = 'commons/getVersionAPI'
const profiles = Profiles

declare var $: any;


@Injectable({
    providedIn: 'root'
})
export class MastersService {

    public appLanguages: IdiomaModel[] = []
    constructor(private http: HttpClient, private modalService: NgbModal, private loginService: LoginService, public translateService: TranslateService, public titleService: TitleService,
			public coursesService: CoursesService, public router: Router, public utils: Utils, private localStorage: LocalStorage) {}

    /**
     * Function that returns version api
     * @returns
     */
    getVersionApi(): Observable<any> { return this.http.get<any>(GETVERSIONAPI) }

    /**
     * Function that return all languages avilables in the app
     * @returns
     */
    getAllLanguages(): Observable<IdiomaModel[]> { return this.http.get<IdiomaModel[]>(GETLANGUAGES) }

    /**
     * Function that return all the centers in the app
     * @returns
     */
    getAllCenters(): Observable<CenterModel[]> { return this.http.get<CenterModel[]>(GETCENTERS) }

    /**
     * Function that return the info of the center
     * @param idCenter ID of the center (ex: 1)
     * @returns Center detail
     */

    getCenterById(idCenter: number): Observable<CenterModel> { return this.http.get<CenterModel>(GETCENTERBYID) }

    getAllGenders(): Observable<GeneroModel[]> {
        const generos: GeneroModel[] = [];
        generos.push(new GeneroModel(1, 'MISC.MALE'));
        generos.push(new GeneroModel(2, 'MISC.FEMALE'));
        generos.push(new GeneroModel(3, 'MISC.OTHER'));
        return of(generos);
    }

    getMenuRol(): MenuRol[] {
        const menu: MenuRol[] = [];

        menu.push(new MenuRol('HEADER.ESTUDIANTE', 'student', profiles.Student, true, null, this.getMenuSidebar(profiles.Student)))
        menu.push(new MenuRol('HEADER.PROFESOR', 'teacher', profiles.Teacher, true, null, this.getMenuSidebar(profiles.Teacher)))
        menu.push(new MenuRol('HEADER.AUTOR', 'author', profiles.Author, true, null, this.getMenuSidebar(profiles.Author)))
        menu.push(new MenuRol('HEADER.PADRE', 'father', profiles.Father, true, null, this.getMenuSidebar(profiles.Father), 'disabled-button'), )
        menu.push(new MenuRol('HEADER.CENTRO', 'center', profiles.Center, true, null, this.getMenuSidebar(profiles.Center), 'disabled-button'))

        return menu
    }

    getMenuSidebar(profile: string): MenuRol[] {

        const menu: MenuRol[] = [];
        const profiles = Profiles
        const submenuCompetition: MenuRol[] = []
        const isGuest:boolean = this.loginService.isGuestUser()

        if (!profile)
            return

        switch (profile.toUpperCase()) {
            case profiles.Student:
                menu.push(new MenuRol('SIDEBAR.ENTENDER', 'link', profiles.Student, true, () => this.verListadoCursos('estudiar', profiles.Student),[],'', 'SIDEBAR.ENTENDERALTERNATIVE',1))
                // menu.push(new MenuRol('SIDEBAR.RECORDAR', 'repasar', profiles.Student, true, () => this.verListadoCursosRecordar('estudiar', profiles.Student), [],'', 'SIDEBAR.REPASARALTERNATIVE',2))
								menu.push(new MenuRol('SIDEBAR.RECORDAR', 'repasar', profiles.Student, true, '', [],'', 'SIDEBAR.REPASARALTERNATIVE',2))
                menu.push(new MenuRol('SIDEBAR.COMPETIR', 'psychology', profiles.Student, true, '',  [],'', 'SIDEBAR.COMPETIRALTERNATIVE',3))
                menu.push(new MenuRol('SIDEBAR.AYUDAR', 'sos-picos', profiles.Student, true, '',  [],'', 'SIDEBAR.AYUDARALTERNATIVE', 4))
                menu.push(new MenuRol('---------------------', '', profiles.Student, true, ''))
                menu.push(new MenuRol('SIDEBAR.GRUPOS', 'classroom', profiles.Teacher, true, () => this.openGroups(profiles.Student)))
								//menu.push(new MenuRol('SIDEBAR.ACTIVIDADES', 'inte', profiles.Student, true, () => this.actividadesBuscar(profiles.Student),  [],'', 'SIDEBAR.ACTIVIDADESALTERNATIVE'))
								menu.push(new MenuRol('SIDEBAR.ACTIVIDADES', 'inte', profiles.Student, true, '',  [],'', 'SIDEBAR.ACTIVIDADESALTERNATIVE'))
                menu.push(new MenuRol('SIDEBAR.REPORTING', 'informes', profiles.Student, true, () => this.informeEstudiante(profiles.Student),  [],'', 'SIDEBAR.ACTIVIDADESALTERNATIVE'))
                menu.push(new MenuRol('SIDEBAR.PROYECTOS', 'project-provisional', profiles.Student, true,''))

                menu.push(new MenuRol('---------------------', '', profiles.Student, true, ''))

                // menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Student, true, () => this.openSettings(profiles.Student),[]))
								menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Student, true, '',[]))

                break;
            case profiles.Author:
                if(!isGuest)
                    menu.push(new MenuRol('SIDEBAR.CREARCURSOS', 'add', profiles.Author, true, () => this.nuevoCurso(profiles.Author)))
                		menu.push(new MenuRol('SIDEBAR.VERCURSOS', 'book', profiles.Author, true, () => this.verListadoCursos('editar', profiles.Author)))
                if(!isGuest)
                    //menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Author, true, () => this.openSettings(profiles.Author), [],'last-child'))
										menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Author, true, '', [],'last-child'))
                break;
            case profiles.Teacher:
                    //menu.push(new MenuRol('SIDEBAR.SCHEDULE', 'calendar_today', profiles.Teacher, true, () => this.openCalendar(profiles.Teacher)))
										menu.push(new MenuRol('SIDEBAR.SCHEDULE', 'calendar_today', profiles.Teacher, true, ''))
                    menu.push(new MenuRol('SIDEBAR.GRUPOS', 'classroom', profiles.Teacher, true, () => this.openGroups(profiles.Teacher)))
                    menu.push(new MenuRol('SIDEBAR.REPORTING', 'informes', profiles.Teacher, true, () => this.reportingProfesor('reporting_profesor', profiles.Teacher)))
                    menu.push(new MenuRol('SIDEBAR.SESION', 'buscar_curso', profiles.Teacher, true))
                    menu.push(new MenuRol('SIDEBAR.ENSENAR', 'sos', profiles.Teacher, true, null))
                    menu.push(new MenuRol('SIDEBAR.TASKS', 'fact_check', profiles.Teacher, true))
                    menu.push(new MenuRol('SIDEBAR.CONTENIDO', 'buscar_curso', profiles.Teacher, true, () => this.verListadoCursos('estudiar', profiles.Teacher)))
                    menu.push(new MenuRol('SIDEBAR.SELECACTIVIDADES', 'student', profiles.Teacher, true))
                    menu.push(new MenuRol('SIDEBAR.ACTINSTANTANEAS', 'act_instantaneas', profiles.Teacher, true, () => this.explorarContenido('explorar_contenido', profiles.Teacher)))
										menu.push(new MenuRol('SIDEBAR.ACTINSTANTANEAS', 'act_instantaneas', profiles.Teacher, true, ''))
                    menu.push(new MenuRol('SIDEBAR.PROYECTOS', 'project-provisional', profiles.Teacher, true,))
                    //menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Teacher, true, () => this.openSettings(profiles.Teacher),[],'last-child'))
										menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Teacher, true, '',[],'last-child'))
                break;
            case profiles.Father:
                menu.push(new MenuRol('SIDEBAR.SON', 'person', profiles.Father, true, null))
                menu.push(new MenuRol('SIDEBAR.CALENDAR', 'calendar_today', profiles.Father, true, () => this.openCalendar(profiles.Father)))
                menu.push(new MenuRol('SIDEBAR.TASKS', 'fact_check', profiles.Father, true, () => this.showTasks(profiles.Father)))
                menu.push(new MenuRol('SIDEBAR.ESTUDIAR', 'psychology', profiles.Father, true, () => this.verListadoCursos('estudiar', profiles.Father)))
                menu.push(new MenuRol('SIDEBAR.CERTIFICATES', 'military_tech', profiles.Father, true, () => this.openCertify(profiles.Father)))
                menu.push(new MenuRol('SIDEBAR.JOB', 'work', profiles.Father, true, null))
                if(!isGuest)
                    menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Father, true, () => this.openSettings(profiles.Father), [],'last-child'))
                break;
            case profiles.Center:
                menu.push(new MenuRol('SIDEBAR.CALENDARS', 'calendar_today', profiles.Center, true, () => this.openCalendar(profiles.Center)))
                menu.push(new MenuRol('SIDEBAR.STUDENTS', 'student', profiles.Center, true, null))
                menu.push(new MenuRol('SIDEBAR.TEACHERS', 'group', profiles.Center, true, null))
                menu.push(new MenuRol('SIDEBAR.ESTUDIAR', 'psychology', profiles.Center, true, null))
                menu.push(new MenuRol('SIDEBAR.CERTIFICATES', 'military_tech', profiles.Center, true, () => this.openCertify(profiles.Center)))
                menu.push(new MenuRol('SIDEBAR.JOB', 'work', profiles.Center, true))
                if(!isGuest)
                    menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Center, true, () => this.openSettings(profiles.Center), [],'last-child'))
                break;
            case profiles.Employer:
                menu.push(new MenuRol('SIDEBAR.CANDIDATES', 'person_search', profiles.Employer, true, () => this.openCalendar(profiles.Employer)))
                menu.push(new MenuRol('SIDEBAR.CERTIFICATES', 'military_tech', profiles.Employer, true, () => this.openCertify(profiles.Employer)))
                menu.push(new MenuRol('SIDEBAR.JOB', 'work', profiles.Center, true, null))
                menu.push(new MenuRol('SIDEBAR.ESTUDIAR', 'psychology', profiles.Employer, true, null))
                if(!isGuest)
                    menu.push(new MenuRol('SIDEBAR.SETTINGS', 'settings', profiles.Employer, true, () => this.openSettings(profiles.Employer), [],'last-child'))
        }
        return menu
    }
    informeEstudiante(Student: Profiles) {
        // this.cambiarPerfil(Student)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        this.modalService.open(InfoEstudianteComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100 })
    }


    explorarContenido(opcion: string, profile: Profiles) {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();

        const modalRef = this.modalService.open(ExplorarContenidoComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W95 })
    }

    reportingProfesor(opcion: string, profile: Profiles) {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();

        const modalRef = this.modalService.open(ReportingComponent, {scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` })
    }

    /**
     * Function that publishes or unpublishes an item type
     * @param type Type of element: “course” – “target” – “node” – “quiz”
     * @param id ID of the element
     * @param value Value of the switch component: true/false
     * @returns Boolean: True or false if successful
     */
    setPublishType(type: string, id: number, value: boolean): Observable<PublishResponseModel> {
        let endpoint = `commons/published/${type}/${id}/${value}`
        return this.http.get<PublishResponseModel>(endpoint);
    }

    verListadoCursos(opcion?: string, profile?: any, idGroup?: number, filtroInicial?: string): void {
      	this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        const modalRef = this.modalService.open(ModalCursosListadoComponent, {windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` })
        modalRef.componentInstance.opcion = opcion || '';
				modalRef.componentInstance.idGroup = idGroup;
				modalRef.componentInstance.filtroInicial = filtroInicial;
    }

		verListadoEncuestas(profile?: any): void {
			this.cambiarPerfil(profile)
			$('.popover, .popover2, .popover3, #pEditMenu3').hide();
			const modalRef = this.modalService.open(SurveysListComponent, {windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` })
	}

		verListadoCursosPensarCreando(opcion?: string, profile?: any, idGroup?: number, filtroInicial?: string): void {
			this.cambiarPerfil(profile)
			$('.popover, .popover2, .popover3, #pEditMenu3').hide();
			const modalRef = this.modalService.open(ModalCursosPensarCreandoComponent, {windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` })
			modalRef.componentInstance.opcion = opcion || '';
			modalRef.componentInstance.idGroup = idGroup;
			modalRef.componentInstance.filtroInicial = filtroInicial;
	}

    verListadoCursosActividadesFlash(opcion?: string, profile?: Profiles): void {
        this.cambiarPerfil(profile);
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        const modalRef = this.modalService.open(ModalCursosListadoComponent, {scrollable: true, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` })
        modalRef.componentInstance.opcion = opcion || ''
        modalRef.componentInstance.actividadesFlash = true;
    }

    openGroups(profile: Profiles) {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        this.modalService.open(ModalGruposEditarComponent, {windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });
    }

    actividadesBuscar(profile: Profiles): void {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        this.modalService.open(ActividadesBuscarComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W80 })
    }

    verEnsenar(profile: Profiles): void {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        this.modalService.open(ModalHelpComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W80 })
    }

    openCalendar(profile: Profiles): void {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        this.modalService.open(ModalCalendarComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W80 })
    }

    showTasks(profile: Profiles): void {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        this.modalService.open(ModalTasksHomeComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W80 } )
    }

    openCertify(profile: Profiles): void {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();
        this.modalService.open(ModalCertifyComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W80 } )
    }

    nuevoCurso(profile: Profiles): void {
        this.cambiarPerfil(profile)
        //const modalRef = this.modalService.open(ModalEditarCursoComponent, { scrollable: true, windowClass: `${MODAL_DIALOG_TYPES.W100} ${MODAL_DIALOG_TYPES.w100NEW}` })
        //modalRef.componentInstance.id = 'nuevo';
        //modalRef.componentInstance.clickSidebar = true;
				this.utils.loadMenu = false;
				this.coursesService.createProjectAutomatically().subscribe(res => {
					this.router.navigate([`/course/${res.data.idCourse}/graph/${res.data.idTarget}`]);
				})
    }

		nuevoControl(profile: Profiles): void {
			this.cambiarPerfil(profile)
			this.utils.loadMenu = false;
			this.coursesService.createControlAutomatically().subscribe(res => {
				this.router.navigate([`/course/${res.data.idCourse}/graph/${res.data.idTarget}`]);
			})
			localStorage.setItem("proyectoControl", "true")
		}

    openSettings(rol: Profiles): void {
        this.cambiarPerfil(rol)

        let modalComponent;
        let config = { scrollable: true, windowClass: `${MODAL_DIALOG_TYPES.W90} ${MODAL_DIALOG_TYPES.w100NEW}` }

        if (!rol)
            return

        switch (rol.toUpperCase()) {
            case profiles.Student:
                modalComponent = UserDataEstudianteComponent
                break
            case profiles.Father:
                modalComponent = UserDataPadreComponent
                break
            case profiles.Teacher:
                modalComponent = UserDataProfesorComponent
                break
            case profiles.Author:
                modalComponent = UserDataAutorComponent
                break
            case profiles.Center:
                modalComponent = UserDataCentroComponent
                break
            case profiles.Employer:
                modalComponent = UserDataEmpleadorComponent
        }
        this.modalService.open(modalComponent, config)
    }

    openModalCompetition(option: string, profile: Profiles): void {
        this.cambiarPerfil(profile)
        $('.popover, .popover2, .popover3, #pEditMenu3').hide();

        const modalRef = this.modalService.open(ModalCompetitionComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W80 })
        modalRef.componentInstance.option = option;
    }

    // La funcion que cambia el perfil
    cambiarPerfil(newProfile: Profiles, sendEvent:boolean = true):void {
				//this.localStorage.removeItem('selectedGroupInfo'); // cada vez que cambiemos de perfil hay que salir del grupo que estabamos
        this.loginService.setProfile(newProfile,sendEvent);

        switch (newProfile) {
            case Profiles.Student:
                this.translateService.get('HEADER.ESTUDIANTE').subscribe((res: string) => this.titleService.barTitle = res );
                break;
            case Profiles.Father:
                this.translateService.get('HEADER.PADRE').subscribe((res: string) => this.titleService.barTitle = res);
                break;
            case Profiles.Teacher:
                this.translateService.get('HEADER.PROFESOR').subscribe((res: string) => this.titleService.barTitle = res );
                break;
            case Profiles.Author:
                this.translateService.get('HEADER.AUTOR').subscribe((res: string) => this.titleService.barTitle = res );
                break;
            case Profiles.Center:
                this.translateService.get('HEADER.CENTRO').subscribe((res: string) => this.titleService.barTitle = res );
                break;
            case Profiles.Employer:
                this.translateService.get('HEADER.EMPLEADOR').subscribe((res: string) => this.titleService.barTitle = res );
                break;
            default:
                this.translateService.get('HEADER.USUARIO').subscribe((res: string) => this.titleService.barTitle = res );
        }
    }

			/**
	 * Endpoint para crear registro en la tabla activity_history para registrar las acciones de los usuarios a modo de log
	 */
		createActivityHistory(
			action: string,
			actionData: string,
			startDate: Date,
			endingDate: Date,
			role: string,
			idGroup: number,
		): Observable<any> {
			const url = `commons/createactivityhistory`;
			const body = {
				action: action,
				actionData: actionData,
				startDate: startDate,
				endingDate: endingDate,
				role: role,
				idGroup: idGroup,
			};
			return this.http.put(url, body);
		}

	/**
	 * Endpoint para crear registro en la tabla country_regions
	 */
		createupdateCountryRegions(idCountry: string, regionName: string) {
			const url = `commons/createupdateCountryRegions`;
			const q = {
				country: { idCountry: idCountry},
				regionName: regionName,
			};
			return this.http.post<any>(url, q);
		}

}
