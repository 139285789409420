<nb-card [nbSpinner]="loading == true" nbSpinnerStatus="danger" class="mb-0">
	<ng-container *ngIf="loading == true; else form">
		<nb-card-body>{{ 'SALWIZAR.GENERATINNGQUIZZES' | translate }}</nb-card-body>
	</ng-container>
	<ng-template #form>
		<nb-card-header class="border-bottom-0">
			<div class="col-11">
				<div>
					<h5>{{ 'SALWIZAR.PROVIDESINSTRUCTIONS' | translate }}</h5>
				</div>
			</div>
		</nb-card-header>
		<div class="closeModal">
			<nb-icon class="m-1" icon="checkmark-circle-2" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
				placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
			</nb-icon>
		</div>
		<nb-card-body>
			<div class="m-5 row d-flex">

				<div class="modal-body">
					<div *ngIf="salwizarForm">
						<form [formGroup]="salwizarForm" autocomplete="off">
							<div class="d-flex w-100 flex-column gap-3">
								<!-- <div class="row justify-content-center">
								<div class="col-12">
									<label for="select1">Nivel Educativo:</label>
									<select formControlName="level" id="select1" class="form-control">
										<option value="Preescolar">Preescolar</option>
										<option value="Primaria">Primaria</option>
										<option value="Secundaria">Secundaria</option>
										<option value="Preparatoria">Preparatoria</option>
										<option value="Universidad">Universidad</option>
									</select>
								</div>
							</div> -->

								<div class="row justify-content-center">
									<div class="col-12 d-flex flex-column w-100">
										<label for="select2">{{ 'FORM.LANGUAGE' | translate }}:</label>
										<nb-select formControlName="lang" id="select2" class="SelectForm" hero size="small" style="max-width: 100%;">
											<nb-option class="SelectFormOpt" value="Español">Spanish</nb-option>
											<nb-option class="SelectFormOpt" value="Ingles">English</nb-option>
											<nb-option class="SelectFormOpt" value="Frances">French</nb-option>
										</nb-select>
									</div>
								</div>

								<div class="row justify-content-center">
									<div class="col-12">
										<label>{{ 'SALWIZAR.NUMBEROFACTIVITIES' | translate }}:</label>
										<input type="number" formControlName="cant" min="1" max="3" nbInput fullWidth fieldSize="medium"
											class="form-control">
									</div>
								</div>

								<!-- <div class="row justify-content-center">
								<div class="col-12">
									<label >Cantidad opciones por actividad:</label>
									<input type="number" formControlName="options" min="2" max="4" class="form-control">
								</div>
							</div> -->
							</div>
						</form>
					</div>
				</div>
			</div>
		</nb-card-body>
		<nb-card-footer class="border-top-0">
			<div class="mb-2 row justify-content-center">
				<div class="col-6 d-flex justify-content-center">
					<button nbButton class="btn-themecolor" size="small" (click)="generateQuizzes()">
						{{ 'SALWIZAR.GENERATEQUIZZES' | translate }}
					</button>
				</div>
			</div>
		</nb-card-footer>
	</ng-template>
</nb-card>
