import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTasksComponent } from '../../modal-tasks/modal-tasks.component';
import { TaskService } from 'src/app/core/services/task';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ModalReproducirQuizzesComponent } from '../../modal-reproducir-quizzes/modal-reproducir-quizzes.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
  selector: 'app-body-competition-groups',
  templateUrl: './body-competition-groups.component.html',
  styleUrls: ['./body-competition-groups.component.scss']
})
export class BodyCompetitionGroupsComponent implements OnInit {

    profesores = [
        { id:1, nombre: 'profesor 1'
        },
        { id:2, nombre: 'profesor 2'
        },
        { id:3, nombre: 'profesor 3'
        },
        { id:4, nombre: 'profesor 4'
        },
        { id:5, nombre: 'profesor 5'
        },
        { id:6, nombre: 'profesor 6'
        },
        { id:7, nombre: 'profesor 7'
        },
        { id:8, nombre: 'profesor 8'
        }
      ];

    grupos= [
        { id:1, nombre: 'grupo 1'
        },
        { id:2, nombre: 'grupo 2'
        },
        { id:3, nombre: 'grupo 3'
        },
        { id:4, nombre: 'grupo 4'
        },
        { id:5, nombre: 'grupo 5'
        },
        { id:6, nombre: 'grupo 6'
        },
        { id:7, nombre: 'grupo 7'
        },
        { id:8, nombre: 'grupo 8'
        }
      ];

      alumnos= [
        { id:1, nombre: 'estudiante 1', img:'../../../assets/images/estudiante.png', fecha:'11-10-2020'
        },
        { id:2, nombre: 'estudiante 2', img:'../../../assets/images/estudiante.png', fecha:'11-10-2020'
        },
        { id:3, nombre: 'estudiante 3', img:'../../../assets/images/estudiante.png', fecha:'10-10-2020'
        },
        { id:4, nombre: 'estudiante 4', img:'../../../assets/images/estudiante.png', fecha:'10-10-2020'
        },
        { id:5, nombre: 'estudiante 5', img:'../../../assets/images/estudiante.png', fecha:'09-10-2020'
        },
        { id:6, nombre: 'estudiante 6', img:'../../../assets/images/estudiante.png', fecha:'08-10-2020'
        },
        { id:7, nombre: 'estudiante 7', img:'../../../assets/images/estudiante.png', fecha:'08-10-2020'
        },
        { id:8, nombre: 'estudiante 8', img:'../../../assets/images/estudiante.png', fecha:'07-10-2020'
        },
        { id:8, nombre: 'estudiante 9', img:'../../../assets/images/estudiante.png', fecha:'07-10-2020'
        }
      ];
      units = ["Kms","Mts"];
      task:any;
      users:boolean;

  constructor(private modalService: NgbModal,
              public taskService:TaskService) {
               this.taskService.task.subscribe((t) => {
               this.task = t;
              }); }

  ngOnInit() {
    this.users=false;
  }

  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    center: new UntypedFormControl(''),
    location: new UntypedFormControl(''),
    distance: new UntypedFormControl(''),
    unit: new UntypedFormControl(''),
    date: new UntypedFormControl(''),
    time: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  });

  private competitionTask(){
    const modalQuiz = this.modalService.open(ModalReproducirQuizzesComponent,
        {
          scrollable: true,
          windowClass: MODAL_DIALOG_TYPES.W95
        })

        modalQuiz.componentInstance.idCurso = 1;
        modalQuiz.componentInstance.node={idOriginal:590};
        modalQuiz.result.then((result) => {
            console.log(result);
          }, (reason) => {
        });
  }

  public showUnits(){

  }

  public selectNow(){

  }

  public random(){
    this.users=true;
  }

  public getUser(){
     this.users=true;
  }
}
