import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { DEFAULTMOBILEPREFIX } from 'src/app/core/models/masters/masters.enum';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { LocalStorage } from 'src/app/core/utils';
import { formsValidations } from 'src/app/core/utils/forms-validations';
import { PrefixCountry } from 'src/app/core/models/shared/prefixCountry.model';
import { RegisterService } from 'src/app/core/services/register/register.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { RegisterModel } from 'src/app/core/models/users/register.model';
import { User } from 'src/app/core/models/users/user.models';

@Component({
  selector: 'app-modal-change-mail',
  templateUrl: './modal-change-mail.component.html',
  styleUrls: ['./modal-change-mail.component.scss']
})
export class ModalChangeMailComponent implements OnInit{

	@Input() userData: any;
	@Output() action: EventEmitter<boolean> = new EventEmitter<boolean>()

 	mensaje: string;
	optionalTitle: string;
	disableCancel: boolean;
	formulario: UntypedFormGroup;
	validationMessages = {
		mail: [],
		code: [],
	};
	lang: string;

	private mailMaxLength: number = formsValidations.EMAIL_MAX_LENGTH;
	private mailPattern: string = formsValidations.EMAIL_PATTERN;
	introCode: boolean = false;
	sameMail: boolean = true;
	newMail: any;

  constructor(
		public translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private formBuild: UntypedFormBuilder,
		private localStorage: LocalStorage,
		private dateAdapter: DateAdapter<Date>,
		private userService: UsersService,
		private loginService: LoginService,
		private registerService: RegisterService,
		private toaster: ToasterService,
		public usersService: UsersService,
		){}

	ngOnInit(): void {
		this.crearInitialForm();
		this.traducirOpciones();
		this.verificarMail();
	}

	aceptar(){
		if(this.formulario.valid && !this.sameMail){
			const fm = this.formulario.value;
			this.registerService.mailValidation(fm.mail).subscribe( res => {
				if(res.error.msg === 'MAIL.TOOSHORT'){
					this.toaster.error(this.translateService.instant('MAIL.TOOSHORT'));
				} else if(res.error.msg === 'MAIL.EXIST'){
					this.toaster.error(this.translateService.instant('MAIL.EXIST'));
				} else {
					//this.toaster.success(this.translateService.instant('CAMBIARMOVIL.CODESENDED'));
					this.introCode = false;
					this.userData.identification =  this.loginService.getUser().identification;
					this.userData.mail = fm.mail;
					//CAMBIAMOS EL MAIL
					this.usersService.updateUser(this.userData).pipe().subscribe((resp: any) => {
						this.toaster.success(this.translateService.instant('USERDATA.LOSDATOSSEHANGRABADOCORRECTAMENTE'));
					},
					(error) => {
						//console.log(error);
						this.toaster.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'));
					}
					)
					this.closeModal();
				}
			})
		}
	}

  cancelar() {
    //this.activeModal.close(false);
		this.action.emit(false);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

	crearInitialForm() {
		this.formulario = this.formBuild.group({
			mail: new UntypedFormControl("", [
				Validators.maxLength(this.mailMaxLength),
				Validators.pattern(this.mailPattern),
			]),
			code: [""],
		});
	}

	verificarMail(){
		const fm = this.formulario.value;
		if(fm.mail === this.loginService.getUser().mail){
			this.sameMail = true;
		} else{
			this.sameMail = false;
		}
	}

	validacionPin(ev): void {
		//Cuando sean 6 digitos ejecutamos la llamada
		if (ev.target.value.length === 6){
			const userToRegister = new RegisterModel(
				this.userData.keyWord,
				this.userData.firstName,
				this.userData.surname,
				this.userData.birthdate,
				this.newMail,
				this.userData.mobile,
				this.userData.idLanguageIso,
				this.userData.idGenre,
				this.userData.extension,
				this.userData.profileEditor,
				this.userData.profileStudent,
				this.userData.profileTeacher,
				this.userData.profileParent
			);
			this.registerService.mobileVerifiedByMail(ev.target.value, userToRegister).subscribe(res => {
				//CAMBIAMOS EL MAIL
				this.userData.mail = this.newMail;
				this.usersService.updateUser(this.userData).pipe().subscribe((resp: any) => {
					this.toaster.success(this.translateService.instant('USERDATA.LOSDATOSSEHANGRABADOCORRECTAMENTE'));
						},
						(error) => {
							//console.log(error);
							this.toaster.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'));
						}
					)
					this.usersService.updateUserProfile(userToRegister).subscribe(
						(resp: any) => {},
						(error) => {}
					);
				//this.toaster.success(this.translateService.instant('CAMBIARMAIL.MAILEDITED'));
				this.closeModal();
				//this.logout();
			}, (error) => {
				this.toaster.error(this.translateService.instant('CAMBIARMOVIL.ERRORCODE'));
			})
		}
	}

	logout(): void {
		localStorage.removeItem('selectedGroupInfo');
		this.loginService.logout(true);
	}

	traducirOpciones() {
		// Recupero el lenguaje
		this.lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(this.lang);
		this.dateAdapter.setLocale(this.lang);

		this.translateService
			.get("VALIDACIONES.ELEMAILESOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.mail.push({ type: "required", message: res });
			});

		this.translateService
			.get("VALIDACIONES.COURSETITLEMAXLENGTH")
			.subscribe((res: string) => {
				this.validationMessages.mail.push({	type: "maxlength", message: res	});
			});

		this.translateService
			.get("VALIDACIONES.ELEMAILNOESVALIDO")
			.subscribe((res: string) => {
				this.validationMessages.mail.push({ type: "pattern", message: res });
			});

		this.translateService
			.get("VALIDACIONES.CODEREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.code.push({ type: "required", message: res });
			});
	}

	get mobileControl(): UntypedFormControl {
		if (this.formulario) {
			return this.formulario.get("mail") as UntypedFormControl;
		} else {
			return null;
		}
	}

}
