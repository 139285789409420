<div class="header-options">
    <div class="col flex justify-content-between align-items-center px-4 py-3">
        <div>
        </div>
        <div>
            <button  *ngIf="!modeAuto || hideBar" mat-icon-button (click)="onCloseModal()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>
