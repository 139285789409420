export class RegisterModel {
    keyWord: string;
    firstName: string;
    surname: string;
    birthdate: number;
    mail: string;
    mobile: string;
    idLanguageIso: string;
    idGenre: number;
    extension: string;
		profileEditor: boolean;
		profileStudent: boolean;
		profileTeacher: boolean;
		profileParent: boolean;

    constructor(keyword: string, firstName: string, surname: string, birthdate: number, mail: string, mobile: string, idLanguageIso: string, idGenre: number, extension: string, profileEditor: boolean, profileStudent: boolean, profileTeacher: boolean, profileParent: boolean) {
        //this.nick = nick;
        this.keyWord = keyword;
        this.firstName = firstName;
        this.surname = surname;
        this.birthdate = birthdate;
        this.mail = mail;
        this.mobile = mobile;
        this.idLanguageIso = idLanguageIso;
        this.idGenre = idGenre;
        this.extension = extension;
				this.profileEditor = profileEditor;
				this.profileStudent = profileStudent;
				this.profileTeacher = profileTeacher;
				this.profileParent = profileParent;
    }
}

export class RegisterNickModel {
	nick: string;
	fatherEmail: string;
	keyWord: string;
	idLanguageIso: string;
	idSecurityQuestions: number;
	securityAnswer: string;
	idSecurityQuestions2: number;
	securityAnswer2: string;
	profileEditor: boolean;
	profileStudent: boolean;
	profileTeacher: boolean;
	profileParent: boolean;

	constructor(nick: string, fatherEmail: string, keyword: string, idLanguageIso: string, idSecurityQuestions: number, securityAnswer: string, idSecurityQuestions2: number, securityAnswer2: string, profileEditor: boolean, profileStudent: boolean, profileTeacher: boolean, profileParent: boolean) {
			this.nick = nick;
			this.fatherEmail = fatherEmail;
			this.keyWord = keyword;
			this.idLanguageIso = idLanguageIso;
			this.idSecurityQuestions = idSecurityQuestions;
			this.securityAnswer = securityAnswer;
			this.idSecurityQuestions2 = idSecurityQuestions2;
			this.securityAnswer2 = securityAnswer2;
			this.profileEditor = profileEditor;
			this.profileStudent = profileStudent;
			this.profileTeacher = profileTeacher;
			this.profileParent = profileParent;
	}
}


export class RegisterNewUser {
    mail: string;
    keyWord: string;
    constructor( mail: string,  keyword: string) {
        this.mail = mail;
        this.keyWord = keyword;
    }
}

class UserLanguage {
    idLanguage: number;

    constructor() {
        this.idLanguage = 0;
    }
}
