<div class="row justify-content-end mt-4 mr-4 ml-4 mb-0">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>

	<div class="w-100 d-flex justify-content-center">
		<form [formGroup]="formulario" class="searchBar w-100 mb-4" #thisForm="ngForm">
			<div class="col-12 flex">
				<h6>{{ "MODALGROUPSEDIT.SEARCHAGROUPTOJOIN" | translate }}</h6>
			</div>
			<div class="col-12 flex justify-content-between" style="margin-left: -1em">
				<div class="col-12">
					<input type="text" nbInput fullWidth placeholder="{{ 'MODALGROUPSEDIT.SEARCHGROUPS' | translate }}"
						name="fiilter" formControlName="filtrado" autocomplete="off" (keyup)="filtrarEstudiantes()" />
				</div>
			</div>
		</form>
	</div>

	<!--<div *ngIf="gruposListBuscar.length === 0" class="d-flex justify-content-center">
			<div class="alert alert-info" role="alert">
				{{'MODALGROUPSEDIT.TYPESEARCHBELONG' | translate}}
			</div>
		</div>-->
</div>
<div class="row mr-4 ml-4 mb-4 mt-0">
	<app-loading [isVisible]="cargando"></app-loading>
	<nb-list class="w-100" *ngIf="gruposListBuscar.length > 0">
		<nb-list-item *ngFor="let grupo of gruposListBuscar | sortBy : 'asc' : 'firstName'"
			class="w-100 d-flex justify-content-between align-items-center">
			{{ grupo.title }}&nbsp;
			<button nbButton size="small" *ngIf="!cargando" class="btn-themecolor" (click)="solicitarUnirGrupo(grupo)">
				{{ 'MODALGROUPSEDIT.REQUESTJOIN' | translate }}
			</button>
		</nb-list-item>
	</nb-list>
</div>
