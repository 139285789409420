import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NbCardModule, NbIconModule, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalStudentsListComponent } from '../modal-students-list/modal-students-list.component';
import { ModalTeachersListComponent } from '../modal-teachers-list/modal-teachers-list.component';
import { ModalCentrosComponent } from '../modal-centros/modal-centros.component';

@Component({
	selector: "app-modal-structure",
	standalone: true,
	imports: [CommonModule, NbCardModule, NbIconModule, NbTooltipModule],
	templateUrl: "./modal-structure.component.html",
	styleUrls: ["./modal-structure.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalStructureComponent {
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal
	) {
		//this.inactivityService.startInactivityTimer();
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	studentsList() {
		this.modalService.open(ModalStudentsListComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	}

	teachersList(){
		this.modalService.open(ModalTeachersListComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	}

	centersList(){
		this.modalService.open(ModalCentrosComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	}
}
