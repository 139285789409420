<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex flex-column border-bottom-0">
		<div class="row">
			<div class="col-10 modal-title">
				<h4>{{ curso?.courseTittle }}</h4>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
			placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body class="p-0">
		<div *ngIf="curso">
			<app-loading [isVisible]="cargando"></app-loading>

			<nb-card class="no-edge-card background_themecolor mb-0">
				<nb-card-body>
					<form [formGroup]="formulario" #thisForm="ngForm" class="row d-flex justify-content-center p-2">
						<div class="col-12 col-lg-3">
							<nb-card class="project-info pointer background_themecolor no-edge-card mb-0 align-items-center">
								<div class="imagecontainer">
									<app-imgupload [isShowDelete]="true" formControlName="image" *ngIf="networkSpeed >= 1"></app-imgupload>
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
									[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}" *ngIf="networkSpeed < 1"></div>
								</div>
							</nb-card>
						</div>
						<div class="col-12 col-lg-7 my-4 my-lg-0">
							<nb-card class="mb-0 background_themecolor pointer no-edge-card ">
								<nb-card-header style="padding: 0;">
									<div class="row d-flex justify-content-end icons-positions">
										<nb-icon class="icon-color-blue pointer text-right m-1 float-right z-index-999" icon="info-outline"
											[options]="{ animation: { type: 'pulse' } }" (click)="openInformationCourse()"></nb-icon>
									</div>
									<div class="form-group">
										<label for="exampleFormControlInput1">{{ 'EDITARCURSO.TITLE' | translate }}</label>
										<input nbInput fullWidth formControlName="title" type="text" id="exampleFormControlInput1"
											placeholder="Titulo" maxlength="100">
									</div>
								</nb-card-header>

								<nb-card-body style="padding: 0;">
									<div class="form-group">
										<label for="exampleFormControlTextarea1">{{ 'EDITARCURSO.DESCRIPCION' | translate }}</label>
										<textarea nbInput fullWidth formControlName="description" id="exampleFormControlTextarea1" rows="3"
											maxlength="500"></textarea>
									</div>
								</nb-card-body>
							</nb-card>
						</div>
						<div class="col-12 col-lg-2 my-4 my-lg-0">
							<nb-card class="mb-0 background_themecolor pointer no-edge-card">

								<h6 class="text-center mt-2">{{'MODALINFORMATIONCOURSE.AUTHOR' | translate}}:</h6>
								<div class="w-100 d-flex justify-content-center">
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
										[ngStyle]="{'background-image': getUserAvatar()}" *ngIf="networkSpeed >= 1"></div>
									<div class="text-center avatar-detail-course mb-2 d-flex align-content-center"
										[ngStyle]="{'background-image': 'url(../../../../assets/images/no-image.png)'}" *ngIf="networkSpeed < 1"></div>
								</div>
								<h6 class="text-center">{{cursoDatos.user.name}} {{cursoDatos.user.surname}}</h6>
								<div class="w-100 d-flex justify-content-center">
									<button nbButton class="btn-themecolor" (click)="listadoEditores()">{{'EDITORS.EDITORS' | translate}}</button>
								</div>
							</nb-card>
						</div>
						<div class="row mt-4 cont-opt justify-content-center">
							<div class="flex cont-opt justify-content-center flex-lg-row flex-column">
								<nb-toggle labelPosition="start" *ngIf="id!='nuevo' && loginService.esAutor()"
									formControlName="isVisible" (click)="makeVisibleCourse()">{{ formulario.get('isVisible').value
									? ('EDITARCURSO.VISIBLE' |
									translate) : ('EDITARCURSO.NOVISIBLE' | translate) }}</nb-toggle>

								<nb-toggle labelPosition="start" *ngIf="id!='nuevo' && loginService.esAutor()"
									formControlName="courseType" (click)="changeCourseType()">{{
									formulario.get('courseType').value ? ('EDITARCURSO.PUBLIC' |
									translate) : ('EDITARCURSO.PRIVATE' | translate) }}</nb-toggle>

								<!-- <ng-select name="idCountry" placeholder="pais" formControlName="idCountry" (selectedChange)="searchLevels($event)"
										>
										<ng-option  *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{
											country.country_es }}</ng-option>
								 </ng-select> -->

							</div>
							<div class="flex cont-opt flex-lg-row flex-column">
								<nb-select name="idCountry" placeholder="{{'FILTER.COUNTRY' | translate}}" formControlName="idCountry"
									(selectedChange)="searchLevels($event)">
									<nb-option *ngFor="let country of countriesList" value="{{ country.idCountry }}">{{
										country.country_es }}
									</nb-option>
								</nb-select>

								<nb-select name="idCountryLevel" placeholder="{{'FILTER.LEVEL' | translate}}" formControlName="idCountryLevel">
									<nb-option *ngFor="let level of levelsOfCountry" [value]="level.idCountryLevel">{{ level.countryLevel
										}}</nb-option>
									<nb-option *ngIf="notCountryLevelsForThisCountry">{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' |
										translate}}</nb-option>
								</nb-select>

								<nb-select name="lang1" placeholder="{{'FILTER.LANGUAGE' | translate}}" formControlName="lang1">
									<nb-option *ngFor="let lang of langList" [value]="lang.idLang">{{ lang.nombre }}
									</nb-option>
								</nb-select>

								<nb-select name="project_type" placeholder="{{'FILTER.PROJECTTYPE' | translate}}" formControlName="project_type">
									<nb-option *ngFor="let projectType of projectsTypes" [value]="projectType.id">{{
										projectType.descripcion }}
									</nb-option>
								</nb-select>
							</div>
						</div>
					</form>
				</nb-card-body>
			</nb-card>

			<!-- TARJETA IDEOGRAFOS -->
			<nb-card class="no-edge-card">
				<nb-card-body>
					<div class="flex flex-row align-items-center mt-3" style="gap: 20px;" *ngIf="curso.project_type != 5">
						<div class="modal-title">
							<h2>{{ 'EDITARCURSO.GRAFOS' | translate }}</h2>
						</div>
						<nb-actions>
							<nb-action *ngIf="graphView == 'inlineList'" icon="grid-outline" (click)="graphView = 'gridList'"
								class="p-1" nbTooltip="{{'CURSOS.VERMOSAICO' | translate}}" nbTooltipPlacement="top"></nb-action>
							<nb-action *ngIf="graphView == 'gridList'" icon="list-outline" (click)="graphView = 'inlineList'"
								class="p-1 px-3" nbTooltip="{{'CURSOS.VERARBOL' | translate}}" nbTooltipPlacement="top"></nb-action>
							<nb-action *ngIf="loginService.esAutor() && id!='nuevo'" icon="plus-circle-outline"
								(click)="nuevoMapa(false)" class="p-1 pl-3" nbTooltip="{{'EDITARCURSO.NUEVOGRAFO' | translate}}" nbTooltipPlacement="top"></nb-action>
						</nb-actions>
					</div>
					<div class="row d-flex justify-content-center align-items-center mt-4">
						<div class="col-12">
							<!-- GRID VIEW -->
							<div [sortablejs]="courseTargets" [sortablejsOptions]="options"
								[ngClass]="graphView == 'gridList' ? 'row' : ''" *ngIf="curso.project_type != 5">
								<div title="{{mapa.target.description}}"
									*ngFor="let mapa of courseTargets;let i=index"
									[ngClass]="graphView == 'gridList' ? 'col-xxl-1 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6' : ''"
									[class.draggable]="true" [class.disabled]="false">

									<nb-card size="small" class="pointer" *ngIf="graphView == 'gridList' && mapa.translating == 0"
										[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
										<nb-card-header class="pb-2 pt-0 pl-0 pr-0 border-bottom-0" (click)="verNodosMapa(mapa.target)">
											<div class="img-container">
												<img [src]="mapa.target.backgroundImage | imagen:'mapas'" (error)="updateUrl($event)" class="img-fluid"
													alt="{{ mapa.target.tittle | titlecase}}" *ngIf="networkSpeed >= 1">
												<img [src]="'../../assets/images/no-image.png'" class="img-fluid"	alt="{{ mapa.target.tittle | titlecase}}"  *ngIf="networkSpeed < 1">
											</div>
										</nb-card-header>
										<nb-card-body class="p-2" (click)="verNodosMapa(mapa.target)">
											<p class="paragraph courseTittle">{{ mapa.target.tittle | truncate:[50, '...'] }}</p>
										</nb-card-body>
										<nb-card-footer class="flex flex-row justify-content-end pt-0 pl-1 pr-1 pb-1 border-top-0">
											<div class="icon-option icon-settings pointer" nbTooltip="{{ 'GRUPOS.AJUSTES' | translate }}"
												(click)="verDetalleMapa(mapa.target.idTarget, mapa.translating)">
											</div>
										</nb-card-footer>
									</nb-card>
									<!-- *** grafos con traduccion en curso *** -->
									<div [ngClass]="{'nb-card-overlay1': curso.project_type == 1, 'nb-card-overlay2': curso.project_type == 2}">
										<nb-card size="small" class="pointer" *ngIf="graphView == 'gridList' && mapa.translating == 1"
										[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
										<nb-card-header class="pb-2 pt-0 pl-0 pr-0 border-bottom-0">
											<div class="img-container">
												<img [src]="mapa.target.backgroundImage | imagen:'mapas'" (error)="updateUrl($event)" class="img-fluid"
													alt="{{ mapa.target.tittle | titlecase}}"  *ngIf="networkSpeed >= 1">
												<img [src]="'../../assets/images/no-image.png'" class="img-fluid"	alt="{{ mapa.target.tittle | titlecase}}"  *ngIf="networkSpeed < 1">
											</div>
										</nb-card-header>
										<nb-card-body class="p-2">
											<p class="paragraph courseTittle">{{ mapa.target.tittle | truncate:[50, '...'] }}</p>
										</nb-card-body>
									</nb-card>
									<div class="overlay-content" *ngIf="mapa.translating == 1">
										<h6 class="icon-text">{{ 'GLOBALS.TRANSLATEINPROCESS' | translate }}</h6>
									</div>
									</div>

									<!-- LIST VIEW -->
									<div *ngIf="graphView !== 'gridList'">
										<div class="inline-list d-flex flex-row align-items-center mr-3 p-2 mb-2">
											<div class="square-image"
												[style.background-image]="getImageBackground(mapa.target.backgroundImage)"></div>
											<h6 class="card-title ml-2">{{ mapa.target.tittle | titlecase | truncate:[50,'...'] }}</h6>
										</div>
									</div>
									<!-- END LIST VIEW -->
								</div>
							</div>

							<!-- CHALLENGE GRAPHS CONTAINER -->
							<div class="mt-4 container-detail-grafos" *ngIf="showModalFormat">
								<div class="flex-row flex align-items-center">
									<h2>{{ 'EDITARCURSO.EXCLUSIVEGRAPHS2' | translate }}</h2>
									<nb-actions>
										<nb-action *ngIf="loginService.esAutor() && id!='nuevo'" icon="plus-circle-outline" (click)="nuevoMapa(true)"
											class="p-1 pl-3" nbTooltip="{{'EDITARCURSO.NUEVOGRAFO' | translate}}" nbTooltipPlacement="top"></nb-action>
									</nb-actions>
									<div class="pointer ml-3 info-outline" nbTooltip="{{'EDITARCURSO.INFOOUTLINE' | translate}}" nbTooltipPlacement="top">
										<nb-icon icon="info-outline"
											[options]="{ animation: { type: 'pulse' } }">
										</nb-icon>
									</div>
								</div>
								<p class="paragraph ml-2 mt-06">({{ 'EDITARCURSO.ONLYTEACHERIDEA' | translate }})</p>
								<div [sortablejs]="exclusiveCourseTargets" [sortablejsOptions]="exclusiveOptions"
									[ngClass]="graphView == 'gridList' ? 'row' : ''">

									<div title="{{mapa.target.description}}"
										*ngFor="let mapa of exclusiveCourseTargets;let i=index"
										[ngClass]="graphView == 'gridList' ? 'col-xxl-1 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6' : ''"
										[class.draggable]="loginService.esAutor() && user.idUser === curso?.user?.idUser ? true : false"
										[class.disabled]="loginService.esAutor() && user.idUser === curso?.user?.idUser ? false : true">

										<nb-card size="small" class="pointer" *ngIf="graphView == 'gridList'"
											[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
											<nb-card-header class="pb-2 pt-0 pl-0 pr-0 border-bottom-0" (click)="verNodosMapa(mapa.target)">
												<div class="img-container">
													<img [src]="mapa.target.backgroundImage | imagen:'mapas'" (error)="updateUrl($event)" class="img-fluid"
														alt="{{ mapa.target.tittle | titlecase}}"  *ngIf="networkSpeed >= 1">
													<img [src]="'../../assets/images/no-image.png'" class="img-fluid"	alt="{{ mapa.target.tittle | titlecase}}"  *ngIf="networkSpeed < 1">
												</div>
											</nb-card-header>
											<nb-card-body class="p-2" (click)="verNodosMapa(mapa.target)">
												<p class="paragraph courseTittle">{{ mapa.target.tittle | truncate:[50, '...'] }}</p>
											</nb-card-body>
											<nb-card-footer class="flex flex-row justify-content-end pt-0 pl-1 pr-1 pb-1 border-top-0">
												<div class="icon-option icon-settings pointer" nbTooltip="{{ 'GRUPOS.AJUSTES' | translate }}"
													(click)="verDetalleMapa(mapa.target.idTarget, mapa.translating)">
												</div>
											</nb-card-footer>
										</nb-card>

										<!-- LIST VIEW -->
										<div *ngIf="graphView !== 'gridList'">
											<div class="inline-list d-flex flex-row align-items-center mr-3 p-2 mb-2">
												<div class="square-image" [style.background-image]="getImageBackground(mapa.target.backgroundImage)">
												</div>
												<h6 class="card-title ml-2">{{ mapa.target.tittle | titlecase | truncate:[50,'...'] }}
												</h6>
											</div>
										</div>
										<!-- END LIST VIEW -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</nb-card-body>
			</nb-card>
		</div>
	</nb-card-body>
</nb-card>
