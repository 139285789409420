import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';
import { LABELS, DATASETS_ACIERTOS, DATASETS_TRABAJO} from "./solar_constants"

@Component({
  selector: 'app-solar',
  templateUrl: './solar.component.html',
  styleUrls: ['./solar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SolarComponent implements OnInit {
	@Input() parametroID: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];
	auxData: any[] = [];
	step: number = 0;
	title1: string = '';
	title2: string = '';

  constructor(
		private translateService: TranslateService,
	) { }

  ngOnInit() {
		if (this.chart) {
			this.chart.destroy();
		}
		if (this.consultaID !== null) {
			this.labels = this.obtenerLabels(this.datasetsImported, this.parametroID - 1);
			this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, this.parametroID - 1)];
    }
		setTimeout(() =>	{
			if(this.parametroID == 1){
				this.createChartAciertos();
			} else if(this.parametroID == 2){
				this.createChartConocimiento();
			}
			this.step = 1; //mostramos el primer paso del informe
		}, 200);
  }

	calcularPromedios(dataSets) {
    const numDatasets = dataSets.length;
    const numDataPoints = dataSets[0].data.length;
    const promedios = new Array(numDataPoints).fill(null).map(() => 0);
    const count = new Array(numDataPoints).fill(0);
    dataSets.forEach(profesor => {
        profesor.data.forEach((value, index) => {
            if (value !== null && value !== 0) {
                promedios[index] += value;
                count[index] += 1;
            }
        });
    });
    const promedioData = promedios.map((total, index) => {
        return count[index] > 0 ? parseFloat((total / count[index]).toFixed(1)) : null;
    });
    const backgroundColor = promedioData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: promedioData,
        backgroundColor: backgroundColor,
    };
	}

	colorKnowledge(valor: number): string {
    let color = "grey";
		if(this.parametroID == 1){
			if (valor == 0) color =  "#9A9B9C";
			else if (valor > 0 && valor < 11) color = "#FB0D1C";
			else if (valor < 21) color = "#FC5C20";
			else if (valor < 31) color = "#FC9A27";
			else if (valor < 41) color = "#FEE533";
			else if (valor < 51) color = "#FFFD38";
			else if (valor < 61) color = "#D4FD35";
			else if (valor < 71) color = "#9CFD32";
			else if (valor < 81) color = "#29F82E";
			else if (valor < 91) color = "#2AD52D";
			else if (valor < 101) color = "#1AAF54";
		} else if(this.parametroID == 2){
			if (valor == 0) color =  "#9A9B9C";
			else if (valor > 0 && valor < 1.1) color = "#FB0D1C";
			else if (valor < 2.1) color = "#FC5C20";
			else if (valor < 3.1) color = "#FC9A27";
			else if (valor < 4.1) color = "#FEE533";
			else if (valor < 5.1) color = "#FFFD38";
			else if (valor < 6.1) color = "#D4FD35";
			else if (valor < 7.1) color = "#9CFD32";
			else if (valor < 8.1) color = "#29F82E";
			else if (valor < 9.1) color = "#2AD52D";
			else if (valor < 10.1) color = "#1AAF54";
		}

    return color;
  }

	obtenerLabels(dataSets, index) {
		const filteredData = dataSets
		.map(info => info.data[index])
		.filter(value => value !== null);
    return dataSets.map(info => info.label);
	}
	obtenerLabelsConEstructura(dataSets, index) {
    const filteredData = dataSets
        .map(info => info.data[index])
        .filter(value => value !== null);
    const backgroundColor = filteredData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: filteredData,
        backgroundColor: backgroundColor,
    };
	}

	createChartAciertos(){
    var canvas = document.getElementById('myChart2') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
    if (this.chart) {
        this.chart.destroy();
    }
    this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: this.labels,
            datasets: this.datasets
        },
				options: {
					responsive: true,
					plugins: {
						legend: {
							position: 'right',
						},
						title: {
							display: false,
							text: this.title2,
							font: {
								size: 20  // Tamaño de la fuente del título
							},
						},
						tooltip: {
							callbacks: {
								label: function(context) {
									let label = context.dataset.label || ' ';
									label += context.raw + '%'; // Formatea los valores del tooltip como porcentajes
									return label;
								}
							}
						},
					},
					backgroundColor: 'rgba(0, 123, 255, 0.5)',  // Color de fondo con transparencia
					/**
					onClick: (event, elements) => {
						if (elements.length > 0) {
							if(this.step == 1){
								this.actualizarChart(elements[0].index);
							}
						}
					}
					*/
				},
    });
	}

	createChartConocimiento(){
    var canvas = document.getElementById('myChart2') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
    if (this.chart) {
        this.chart.destroy();
    }
    this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: this.labels,
            datasets: this.datasets
        },
				options: {
					responsive: true,
					plugins: {
						legend: {
							position: 'right',
						},
						title: {
							display: false,
							text: this.title2,
							font: {
								size: 20  // Tamaño de la fuente del título
							},
						},
						tooltip: {
							callbacks: {
								label: function(context) {
									let label = context.dataset.label || ' ';
									label += context.raw; // Formatea los valores del tooltip como porcentajes
									return label;
								}
							}
						},
					},
					backgroundColor: 'rgba(0, 123, 255, 0.5)',  // Color de fondo con transparencia
					/**
					onClick: (event, elements) => {
						if (elements.length > 0) {
							if(this.step == 1){
								this.actualizarChart(elements[0].index);
							}
						}
					}
					*/
				},
    });
	}

	createChartTrabajo(){
    var canvas = document.getElementById('myChart2') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
    if (this.chart) {
        this.chart.destroy();
    }
    this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: this.labels,
            datasets: this.datasets
        },
				options: {
					responsive: true,
					plugins: {
						legend: {
							position: 'bottom',
						},
						title: {
							display: true,
							text: this.title1,
							font: {
								size: 24  // Tamaño de la fuente del título
							},
						},
						tooltip: {
							callbacks: {
								label: function(context) {
									let label = context.dataset.label || '';
									if (label) {
										label += ': ';
									}
									const totalMinutes: any = context.raw;
									const hours = Math.floor(totalMinutes / 60);
									const minutes = totalMinutes % 60;
									label += `${hours}h ${minutes}min/sem`; // Formatea los valores del tooltip como horas y minutos
									return label;
								}
							}
						}
					},
					onClick: (event, elements) => {
						if (elements.length > 0) {
							if(this.step == 1){
								this.actualizarChart(elements[0].index);
							}
						}
					}
				},
    });
	}

	actualizarChart(index){
		this.chart.options.plugins.title.text = this.title2;
		this.chart.data.labels = this.obtenerLabels(this.auxData, index);
		this.chart.data.datasets = [this.obtenerLabelsConEstructura(this.auxData, index)];
		this.chart.update();
		this.step ++; //avanzamos un paso
	}

	backStep(){
		this.chart.options.plugins.title.text = this.title1;
		this.chart.data.labels = this.labels;
		this.chart.data.datasets = this.datasets;
		this.chart.update();
		this.step --; //volvemos un paso
	}

}
