import { Profiles } from "src/app/core/utils/profiles.enum";
import { finalize, take, takeUntil } from "rxjs/operators";
import { QuizzesService } from "src/app/core/services/quizzes";
import { Subject, Subscription } from "rxjs";
import { SocketService } from "./../../../../core/services/socket/socket-service.service";
import {
	Component,
	OnInit,
	OnDestroy,
	Input,
	ViewEncapsulation,
	ViewChild,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GruposService, SOCKETMESSAGES } from "src/app/core/services/groups/grupos.service";
import { CourseListModel } from "../../cursos/modal-cursos-listado/interface/modal-cursos-listado";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "src/app/core/services/login";
import * as countdown from "countdown";
import { ModalAceptarCancelarComponent } from "../../modal";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { QuizzesstackService } from "src/app/core/services/quizzesstack/quizzesstack.service";
import { QuizStack } from "src/app/core/models/quizzes/quiz-stack.model";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { CompetitionsService } from "src/app/core/services/competitions/competitions.service";

const MODELIST = {
	CLASSICLIST: 1,
	AUTOLIST: 2,
};

const ELEMENTTYPE = {
	COURSE: "COURSE",
	GRAPH: "GRAPH",
	NODE: "NODE",
	QUIZ: "QUIZ",
};

export interface Time {
	hours: number;
	minutes: number;
	seconds: number;
}

const QUIZDURATION = 15; // 15 segundos de duracion del quiz para cuando sea automático

@Component({
	selector: "app-modal-student-waiting",
	templateUrl: "./modal-student-waiting.component.html",
	styleUrls: ["./modal-student-waiting.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ModalStudentWaitingComponent implements OnInit, OnDestroy {
	@Input() idGroup: number;
	@Input() onlineUsers: number;
	@ViewChild("cd", { static: false }) private countdown: CountdownComponent;
	competitionStarted: boolean = false;
	roomCode: string = "";
	isLoading = true;
	private subscriptions: Subscription[] = [];
	quiz: any = null;
	elements: any[] = [];
	rol = Profiles.Student;
	answered = false;
	answeredBySocket = false;
	listQuizzes: CourseListModel[] = [];
	currentIdArrayQuiz: number = 0;
	currentPosition: number = this.currentIdArrayQuiz;
	buttonText = "";
	option: number; //
	synchronizedStudents = false;
	randomOrder = false;
	selectedType = SOCKETMESSAGES.OPEN;
	selectedQuiz: number = null;
	challengeTime: number;
	idTarget: number;
	idCourse: number;
	arrayQuizzesLength: number;

	timerId: number = null;
	time: Time;
	iniBlock: boolean;
	countSeconds: number;
	openTime: number = Date.now();
	private destroy$ = new Subject();
	lastQuizStack = [];
	configCountDown: CountdownConfig;
	clockIsShown = false;
	multiDesafio: boolean;
	challengeOn: boolean = true;

	constructor(
		private activeModal: NgbActiveModal,
		private socketService: SocketService,
		private quizzesService: QuizzesService,
		private translateService: TranslateService,
		private toaster: ToasterService,
		private loginService: LoginService,
		private modalService: NgbModal,
		public quizzesStackService: QuizzesstackService,
		private competitionsService: CompetitionsService,
		private groupService: GruposService
	) {}
	ngOnDestroy(): void {
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	ngOnInit() {
		// this.socketService.getForceCloseQuizzPreviewComponent().subscribe((res) => {
		// 	if (res == true) {
		// 		this.activeModal.close();
		// 	}
		// });
		this.competitionsService.getCompetitionStarted().subscribe((res) => {
			this.competitionStarted = res;
		});
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.getScreenConfig();
		this.getSocketData();
		localStorage.removeItem("challengeOn");
		localStorage.setItem("challengeOn", JSON.stringify(this.challengeOn));
	}

	private getSocketData() {
		const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			switch (type) {
				case SOCKETMESSAGES.OPEN:
					this.socketService.setansweredBySocket(false);
					this.answered = false;
					this.answeredBySocket = false;
					const idQuiz = parseInt(dataArray[1]);
					const idTarget = parseInt(dataArray[2]);
					const idCourse = parseInt(dataArray[3]);
					const typeQuiz = dataArray[4];
					const multi = typeQuiz === "multiple" ? true : false;
					this.getDataQuiz(idQuiz, idTarget, idCourse, [], multi); //Recupero el quiz
					break;
				case SOCKETMESSAGES.CLOSE:
					//Mando las respuestas del quiz al back
					this.socketService.setansweredBySocket(true);
					//this.answeredBySocket = true; //Le digo al componente que tiene que mandar las respuestas al back
					break;
				case SOCKETMESSAGES.FINISH:
					break;
				case SOCKETMESSAGES.CLOSESOCKET:
					this.closeQuiz();
					break;
				case SOCKETMESSAGES.USERSCOUNTER:
					const onlineUsers = parseInt(dataArray[1]);
					this.onlineUsers = onlineUsers;
					break;
				case SOCKETMESSAGES.ORDERMODAUTO:
					const idTargetModAuto = parseInt(dataArray[1]);
					this.idTarget = parseInt(dataArray[1]);
					const idCase = parseInt(dataArray[2]);
					this.option = idCase;
					this.selectedType = SOCKETMESSAGES.ORDERMODAUTO;
					this.getModAutoList(idTargetModAuto, idCase);
					break;
				case SOCKETMESSAGES.ORDERMODLIST:
					this.idTarget = parseInt(dataArray[1]);
					const idTargetModList = parseInt(dataArray[1]); //Id Target
					this.challengeTime = parseInt(dataArray[2]); //Tiempo de duración del examen
					this.getModList(idTargetModList);
					this.selectedType = SOCKETMESSAGES.ORDERMODLIST;
					break;
			}
		});
		this.subscriptions.push(msgSubs); // Añadimos susbcribe para poder destruirlo al cerrar el modal
	}

	private getDataQuiz(
		idQuiz: number,
		idTarget: number,
		idCourse: number,
		selectedResponses?: number[],
		multi?: boolean
	): void {
		this.quiz = null;
		this.elements = [];
		let duration = 0;

		this.multiDesafio = multi;
		if (this.multiDesafio) {
			this.quizzesService
				.getQuizMultipleCanvasQuizMultiple(idQuiz, idCourse, idTarget)
				.pipe(finalize(() => (this.isLoading = false)))
				.subscribe(
					(res) => {
						if (this.selectedType === SOCKETMESSAGES.ORDERMODLIST)
							duration = 0; //Deshabilito el tiempo en los que son en modo lista, pero libre para el usuario: OPCION 1 en la configuración del desafío
						else {
							if (this.option === 1 || this.option === 2)
								duration = QUIZDURATION;
							//En el modo automático, se pone un tiempo por defecto para los quizzes
							else duration = 0; // llevamos el tiempo a cero por que si el quiz tiene su porpio tiempo, el reloj aparece cuando no es sincronizado
						}

						this.quiz = {
							...res.quiz,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							duration: duration,
							selectedResponses: selectedResponses,
						};
						this.elements = res.elements;
					},
					(err) => {
						this.quiz = null;
						this.elements = [];
						this.toaster.error(
							this.translateService.instant(
								"SOCKETQUIZPREVIEW.ERRORLOADINGQUIZ"
							)
						);
					}
				);
		} else {
			this.quizzesService
				.getQuizSimpleCanvasQuizSimple(idQuiz, idCourse, idTarget)
				.pipe(finalize(() => (this.isLoading = false)))
				.subscribe(
					(res) => {
						if (this.selectedType === SOCKETMESSAGES.ORDERMODLIST)
							duration = 0; //Deshabilito el tiempo en los que son en modo lista, pero libre para el usuario: OPCION 1 en la configuración del desafío
						else if (this.option === undefined) {
							duration = res.quiz.duration;
						} else if (this.option !== undefined) {
							if (this.option === 1 || this.option === 2) {
								duration = QUIZDURATION; //En el modo automático, se pone un tiempo por defecto para los quizze
							} else {
								duration = 0; // llevamos el tiempo a cero por que si el quiz tiene su porpio tiempo, el reloj aparece cuando no es sincronizado
							}
						}
						this.quiz = {
							...res.quiz,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							duration: duration,
							selectedResponses: selectedResponses,
						};
						this.elements = res.elements;
					},
					(err) => {
						this.quiz = null;
						this.elements = [];
						this.toaster.error(
							this.translateService.instant(
								"SOCKETQUIZPREVIEW.ERRORLOADINGQUIZ"
							)
						);
					}
				);
		}
	}

	private getModList(idTarget: number) {
		this.quizzesService
			.getModeList(idTarget)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe(
				(result) => {
					let quizzes = result.data.quizzes as CourseListModel[];
					quizzes.forEach((q) => {
						q.hasResponse = false;
						q.responses = [];
					});
					this.listQuizzes = quizzes;
					this.idCourse =
						this.listQuizzes[
							this.currentIdArrayQuiz == this.listQuizzes.length
								? this.currentIdArrayQuiz - 1
								: this.currentIdArrayQuiz
						].idCourse;
					this.showClock(); // Muestro el reloj para que lo vean los alumnos
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("SOCKETQUIZPREVIEW.ERRORLOADINGQUIZ")
					);
				}
			);
	}

	private getModAutoList(idTarget: number, idCase: number): void {
		this.quizzesService
			.getModeAutoList(idTarget, idCase)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe(
				(result) => {
					this.listQuizzes = result.data as CourseListModel[];
					this.idCourse =
						this.listQuizzes[
							this.currentIdArrayQuiz == this.listQuizzes.length
								? this.currentIdArrayQuiz - 1
								: this.currentIdArrayQuiz
						].idCourse;
					this.nextQuiz();
				},
				(err) => {}
			);
	}

	private getScreenConfig() {
		this.buttonText = this.translateService.instant("SOCKETQUIZPREVIEW.NEXT");
		this.quizzesStackService.responseListQuizzesStackChallenges = [];
	}

	nextQuiz(calledFormButton?: boolean) {
		this.currentPosition++;
		this.answered = false;
		this.arrayQuizzesLength = this.listQuizzes.length;

		if (calledFormButton) {
			this.currentIdArrayQuiz = this.currentIdArrayQuiz + 1;
		}

		const idQuiz =
			this.listQuizzes[
				this.currentIdArrayQuiz == this.listQuizzes.length
					? this.currentIdArrayQuiz - 1
					: this.currentIdArrayQuiz
			].idQuiz;
		const idTarget =
			this.listQuizzes[
				this.currentIdArrayQuiz == this.listQuizzes.length
					? this.currentIdArrayQuiz - 1
					: this.currentIdArrayQuiz
			].idTarget;
		const idCourse =
			this.listQuizzes[
				this.currentIdArrayQuiz == this.listQuizzes.length
					? this.currentIdArrayQuiz - 1
					: this.currentIdArrayQuiz
			].idCourse;
		const multi =
			this.listQuizzes[
				this.currentIdArrayQuiz == this.listQuizzes.length
					? this.currentIdArrayQuiz - 1
					: this.currentIdArrayQuiz
			].multi;
		let arrayQuizStack: QuizStack[] = [];
		const quizResponses =
			this.quizzesStackService.responseListQuizzesStackChallenges;

		quizResponses.forEach((q, i) => {
			const quiz: any = q.quiz;
			const quizStack: QuizStack = {
				idQuiz: quiz.idQuiz,
				idQuizOriginal:
					quiz.idMultiplexQuiz === 0 ? quiz.idQuiz : quiz.idQuizFather,
				idUser: this.loginService.getUser().idUser,
				asked: q.openTime,
				answered: q.answered,
				result: q.result ? 1 : 0,
				timeCreation: Date.now(),
				idTarget: q.idTarget,
				idCourse: q.idCourse,
				answersImage: null,
				answersDocs: null,
				answersAudio: null,
				answersVideo: null,
				certifiedQuiz: quiz.certifiedQuiz,
				role: "o",
				answersText: q.text,
				selectedOptions: q.reponses.join(","),
			};
			arrayQuizStack.push(quizStack);
		});
		this.socketService
			.getSyncChallenge()
			.pipe(take(1))
			.subscribe((res) => {
				if (!(res && quizResponses[0].quiz.idMultiplexQuiz === 0)) {
					console.log(this.competitionStarted, "Que valor tiene aqui?");
					if (this.competitionStarted == true) {
						this.quizzesStackService
							.answerQuizzesSession(
								this.idGroup,
								arrayQuizStack.slice(arrayQuizStack.length - 1)[0],
								false,
								this.roomCode
							)
							.pipe(take(1))
							.subscribe((res) => {}); // Envío la respuesta del quiz
					} else {
						this.quizzesStackService
							.answerQuizzesSession(
								this.idGroup,
								arrayQuizStack.slice(arrayQuizStack.length - 1)[0],
								true,
								null
							)
							.pipe(take(1))
							.subscribe((res) => {}); // Envío la respuesta del quiz
					}
				}
			});
		if (
			this.buttonText !=
			this.translateService.instant("SOCKETQUIZPREVIEW.FINALIZEQUIZZ")
		) {
			this.getDataQuiz(idQuiz, idTarget, idCourse, [], multi);
		}
		if (
			this.buttonText ==
			this.translateService.instant("SOCKETQUIZPREVIEW.FINALIZEQUIZZ")
		) {
			this.buttonText = this.translateService.instant("SOCKETQUIZPREVIEW.NEXT");
			this.currentPosition = 0;
			this.closeQuiz();
			this.close();
		}
		if (this.currentIdArrayQuiz === this.arrayQuizzesLength - 1) {
			this.buttonText = this.translateService.instant(
				"SOCKETQUIZPREVIEW.FINALIZEQUIZZ"
			);
		}
	}

	closeQuiz() {
		this.quiz = null;
		this.elements = [];
		this.listQuizzes = [];
		this.currentIdArrayQuiz = 0;
		this.isLoading = true;
		this.socketService.setSyncChallenge(false);
	}

	close(method?: string) {
		console.log("Cerramos el modal");
		this.socketService.setSyncChallenge(false);
		this.activeModal.close(method ? method : "close");
		localStorage.removeItem("challengeOn");
		localStorage.removeItem("challengeType");
		this.groupService.setRunInterval(true);
	}

	onAnswered() {
		this.answered = true;
	}

	saveResponse() {
		const responsesList =
			this.quizzesStackService.responseListQuizzesStackChallenges;
		this.lastQuizStack = [];
		this.lastQuizStack =
			this.quizzesStackService.responseListQuizzesStackChallenges;
		//Si estamos en el modo lista, tenemos que marcar ese quiz como realizado para que el usuario no pueda volver a repetir la pregunta
		if (
			(this.selectedQuiz &&
				this.selectedType === SOCKETMESSAGES.ORDERMODLIST) ||
			this.selectedType === SOCKETMESSAGES.ORDERMODAUTO
		) {
			this.listQuizzes.forEach((q) => {
				responsesList.forEach((r) => {
					if (r.idQuiz === q.idQuiz) {
						q.responses = r.reponses;
						r.reponses.length
							? (q.hasResponse = true)
							: (q.hasResponse = false);
						r.reponses.length
							? (q.hasResponse = true)
							: (q.hasResponse = false);
					}
				});
			});
		}
	}

	timeEnd(data) {
		if (data) {
			this.currentIdArrayQuiz++;
			this.nextQuiz();
			this.answered = false;
		}
	}

	//Función que recibe los valores del elemento seleccionado en el listado de los cursos
	clickElement(element: CourseListModel): void {
		this.answered = false;
		const isAuthor: boolean = this.loginService.esAutor();
		//De forma provisional, sólo las acciones se contemplan desde el rol de estudiante para poder visualizar los nodos y actividades
		if (!isAuthor) {
			switch (element.type) {
				case ELEMENTTYPE.COURSE:
					break;
				case ELEMENTTYPE.GRAPH:
					break;
				case ELEMENTTYPE.NODE:
					break;
				case ELEMENTTYPE.QUIZ:
					this.quiz = null;
					this.elements = [];
					this.isLoading = false;
					this.selectedQuiz = element.idQuiz;
					this.idCourse = element.idCourse;
					this.idTarget = element.idTarget;
					this.getDataQuiz(
						element.idQuiz,
						element.idTarget,
						element.idCourse,
						element.responses,
						element.multi
					);
					break;
			}
		}
	}

	//Función que finaliza el reto y envia las respuestas al servidor
	endChallenge(showModal = true) {
		if (!showModal) {
			this.finalizeChallenge();
			return;
		}

		//Primero, mostramos un mensaje para que acepte o cancele el envío de las respuestas
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.mensaje = this.translateService.instant(
			"QUIZSOCKETPREVIEW.ENDCHALLENGEMODAL"
		);
		modalRef.result.then((result) => {
			if (result) {
				this.finalizeChallenge();
			}
		});
	}

	private finalizeChallenge() {
		let arrayQuizStack: QuizStack[] = [];
		const quizResponses =
			this.quizzesStackService.responseListQuizzesStackChallenges;
		quizResponses.forEach((q, i) => {
			const quiz: any = q.quiz;
			const quizStack: QuizStack = {
				idQuiz: quiz.idQuiz,
				idQuizOriginal:
					quiz.idMultiplexQuiz === 0 ? quiz.idQuiz : quiz.idQuizFather,
				idUser: this.loginService.getUser().idUser,
				asked: q.openTime,
				answered: q.answered,
				result: q.result ? 1 : 0,
				timeCreation: Date.now(),
				idTarget: q.idTarget,
				idCourse: q.idCourse,
				answersImage: null,
				answersDocs: null,
				answersAudio: null,
				answersVideo: null,
				certifiedQuiz: quiz.certifiedQuiz,
				role: "o",
				answersText: q.text,
				selectedOptions: q.reponses.join(","),
			};
			arrayQuizStack.push(quizStack);
		});

		this.quizzesStackService
			.answerListQuizzesSession(this.idGroup, arrayQuizStack)
			.pipe(take(1))
			.subscribe(
				(result) => {
					this.toaster.success(
						this.translateService.instant("QUIZSOCKETPREVIEW.RESPONSEOK")
					);
					this.close();
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("QUIZSOCKETPREVIEW.RESPONSEOK")
					);
				}
			);
	}

	//Mostramos un reloj con los minutos/segundos para completar el examen
	private showClock(): void {
		if (this.clockIsShown == false) {
			if (this.timerId) {
				clearInterval(this.timerId);
			}
			//this.iniBlock = false;
			let date = new Date();
			date.setSeconds(date.getMinutes() + this.challengeTime);

			this.configCountDown = {
				leftTime: this.challengeTime * 60,
			};
			this.clockIsShown = true;
		}

		// this.timerId = countdown(
		// 	date,
		// 	(timeStamp) => {
		// 		this.time = timeStamp;

		// 		if (!this.iniBlock) {
		// 			this.iniBlock = true;
		// 			this.countSeconds = this.time.seconds + this.time.minutes * 60;
		// 		}

		// 		if (this.time.minutes === 0 && this.time.seconds === 0) {
		// 			clearInterval(this.timerId);
		// 			//Tenemos que mandar las respuestas cuando se acabe el tiempo
		// 			this.endChallenge(false);
		// 		}
		// 	},
		// 	countdown.HOURS | countdown.MINUTES | countdown.SECONDS
		// );
	}

	handleCountDownEvent(e: CountdownEvent) {
		if (e.action === "done") {
			this.endChallenge(false);
		}
	}
}
