export class GroupModel {
    idGroup: number;
    idProfessor: number;
    title: string;
    description: string;
    imagen:  string;
    idLanguage: number;
    idCenter: number;
    share: string;
    creationDate: null | Date;
    editDate: null | Date;
		identification: string;
		assignedProjects: number;
		assignedStudents: number;
		challengesRealized: number;
		created_from_role: string;
		nivel: string;
		letras: string;
		numberOfInvited?: any;
		invitacionesList?: any;
		idUser?: any;
}

export enum GruposModalClose{
    CERRARLISTADOCURSOS = 65749879744,
    CERRARLISTADOGRUPOS
}

export interface ConfigChallengeModel{
	challengeTime:number,
	option: string,
	synchronizedStudents:boolean,
	randomOrder:boolean
}

export interface ChallengeListModel {
	idGroup:        number;
	idQuizzesstack: number;
	idSession:      number;
	idSessionStack: number;
	nameSession:    string;
}

