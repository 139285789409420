<nb-card class="no-edge-card modal-max-height-100-65 mb-0">

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			 (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-12">


						<nb-card class="background_themecolor">
							<div class="row text-center">
								<div class="col-md-4">
									<div class="btn-group">
										<div
											class="btn btn-primary"
											mwlCalendarPreviousView
											[view]="view"
											[(viewDate)]="viewDate"
											(viewDateChange)="closeOpenMonthViewDay()"
										>
											Anterior
										</div>
										<div
											class="btn btn-outline-secondary"
											mwlCalendarToday
											[(viewDate)]="viewDate"
										>
											Hoy
										</div>
										<div
											class="btn btn-primary"
											mwlCalendarNextView
											[view]="view"
											[(viewDate)]="viewDate"
											(viewDateChange)="closeOpenMonthViewDay()"
										>
											Siguiente
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h3>
								</div>
								<div class="col-md-4">
									<div class="btn-group">
										<div
											class="btn btn-primary"
											(click)="setView(CalendarView.Month)"
											[class.active]="view === CalendarView.Month"
										>
											Mes
										</div>
										<div
											class="btn btn-primary"
											(click)="setView(CalendarView.Week)"
											[class.active]="view === CalendarView.Week"
										>
											Semana
										</div>
										<div
											class="btn btn-primary"
											(click)="setView(CalendarView.Day)"
											[class.active]="view === CalendarView.Day"
										>
											Dia
										</div>
									</div>
								</div>
							</div>
							<br />
							<div [ngSwitch]="view">
								<mwl-calendar-month-view
									*ngSwitchCase="CalendarView.Month"
									[viewDate]="viewDate"
									[events]="events"
    							[locale]="locale"
									[refresh]="refresh"
									[activeDayIsOpen]="activeDayIsOpen"
									(dayClicked)="dayClicked($event.day)"
									(eventClicked)="handleEvent('Clicked', $event.event)"
									(eventTimesChanged)="eventTimesChanged($event)"
								>
								</mwl-calendar-month-view>
								<mwl-calendar-week-view
									*ngSwitchCase="CalendarView.Week"
									[viewDate]="viewDate"
									[events]="events"
    								[locale]="locale"
									[refresh]="refresh"
									(eventClicked)="handleEvent('Clicked', $event.event)"
									(eventTimesChanged)="eventTimesChanged($event)"
								>
								</mwl-calendar-week-view>
								<mwl-calendar-day-view
									*ngSwitchCase="CalendarView.Day"
									[viewDate]="viewDate"
									[events]="events"
    								[locale]="locale"
									[refresh]="refresh"
									(eventClicked)="handleEvent('Clicked', $event.event)"
									(eventTimesChanged)="eventTimesChanged($event)"
								>
								</mwl-calendar-day-view>
							</div>

							<!-- Everything you see below is just for the demo, you don't need to include it in your app -->

							<br /><br /><br />

							<!-- <h3>
								Editar Eventos
								<button class="btn btn-primary float-end" (click)="addEvent()">
									Agregar Nuevo Evento
								</button>
								<div class="clearfix"></div>
							</h3>

							<div class="table-responsive">
								<table class="table table-bordered">
									<thead>
										<tr>
											<th>Titulo</th>
											<th>Fecha de inicio</th>
											<th>Fecha final</th>
											<th>Eliminar</th>
										</tr>
									</thead>

									<tbody>
										<tr *ngFor="let event of events">
											<td>
												<input
													type="text"
													class="form-control"
													[(ngModel)]="event.title"
													(keyup)="refresh.next()"
												/>
											</td>

											<td>
												<input
													class="form-control"
													type="text"
													mwlFlatpickr
													[(ngModel)]="event.start"
													(ngModelChange)="refresh.next()"
													[altInput]="true"
													[convertModelValue]="true"
													[enableTime]="true"
													dateFormat="Y-m-dTH:i"
													altFormat="F j, Y H:i"
													placeholder="Not set"
												/>
											</td>
											<td>
												<input
													class="form-control"
													type="text"
													mwlFlatpickr
													[(ngModel)]="event.end"
													(ngModelChange)="refresh.next()"
													[altInput]="true"
													[convertModelValue]="true"
													[locale]="locale"
													[enableTime]="true"
													dateFormat="Y-m-dTH:i"
													altFormat="F j, Y H:i"
													placeholder="Not set"
												/>
											</td>
											<td>
												<button class="btn btn-danger" (click)="deleteEvent(event)">
													Delete
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div> -->

							<ng-template #modalContent let-close="close">
								<div class="modal-header">
									<h5 class="modal-title">Editar Evento</h5>
									<button type="button" class="close" (click)="close()">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<div>
										<input
											type="text"
											class="form-control"
											[(ngModel)]="editingEvent.Name"
											(keyup)="refresh.next()"
										/>
									</div>

									<div>
										<nb-select #levelSelector placeholder="Nivel" [(ngModel)]="selectedLevel"
											(selectedChange)="setLevel($event)">
											<nb-option *ngFor="let level of levelsOfCountry" [value]="level.idCountryLevel">{{ level.countryLevel
												}}</nb-option>
										</nb-select>


									</div>

									<div>
										<button nbButton size="small" class="btn-themecolor" (click)="openGroupsSelector()">
											ELige el grupo
										</button>

										<div *ngIf="selectedGroupInfo">
											{{ selectedGroupInfo.title }}
										</div>
									</div>

									<div>
										<button nbButton size="small" class="btn-themecolor" (click)="selectContent()">
											ELige el ideografo.
										</button>

										<div *ngIf="asignatura">
											{{ asignatura }}
										</div>
									</div>

									<div>
										<input
											class="form-control"
											type="text"
											mwlFlatpickr
											[(ngModel)]="editingEvent.Fecha_Inicio"
											(ngModelChange)="refresh.next()"
											[altInput]="true"
											[convertModelValue]="true"
											[enableTime]="true"
											dateFormat="Y-m-dTH:i"
											altFormat="F j, Y H:i"
											placeholder="Not set"
										/>
									</div>

									<div>
										<input
											class="form-control"
											type="text"
											mwlFlatpickr
											[(ngModel)]="editingEvent.Fecha_Fin"
											(ngModelChange)="refresh.next()"
											[altInput]="true"
											[convertModelValue]="true"
											[locale]="locale"
											[enableTime]="true"
											dateFormat="Y-m-dTH:i"
											altFormat="F j, Y H:i"
											placeholder="Not set"
										/>
									</div>


								</div>
								<div class="modal-footer">
									<button type="button" class="btn btn-outline-secondary" (click)="saveNewEvent(editingEvent);close()">
										Guardar
									</button>
								</div>
							</ng-template>

						</nb-card>

				</div>
			</div>

	</nb-card-body>
</nb-card>
