import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ListaComponent implements OnInit {
	@Input() consultaID: number | null = null;

	labels: any[] = [];
	labels_nivel: any[] = [
		{
			label: "1º ESO",
			data: [
				{	label: 'Pedro Martinez', data: [86]},
				{	label: 'Ana Diaz-Granados',	data: [63]},
				{	label: 'Patricia Contreras', data: [88]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
		},
		{
			label: "2º ESO",
			data: [
				{	label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [32]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [34]},
				{	label: 'Maria García', data: [57]},
				{	label: 'Montse Abreu', data: [83]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
		},
		{
			label: "3º ESO",
			data: [
				{	label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [26]},
				{	label: 'Maria García', data: [96]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [70]},
				{	label: 'Alicia Roa', data: [38]},
				{	label: 'Carlos Perez', data: [0]},
			]
		},
		{
			label: "4º ESO",
			data: [
				{	label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [51]},
				{	label: 'Maria García', data: [10]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [12]},
				{	label: 'Alicia Roa', data: [43]},
				{	label: 'Carlos Perez', data: [0]},
			]
		},
		{
			label: "1º BACH",
			data: [
				{	label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [60,]},
				{	label: 'Maria García', data: [51]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [79]},
				{	label: 'Alicia Roa', data: [72]},
				{	label: 'Carlos Perez', data: [44]},
			]
		},
		{
			label: "2º BACH",
			data: [
				{	label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [89]},
				{	label: 'Maria García', data: [38]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [84]},
				{	label: 'Alicia Roa', data: [84]},
				{	label: 'Carlos Perez', data: [46]},
			]
		},
	];




	labels_asignatura: any[] = [
		{
      label: "Matemáticas",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [30]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [84]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
    },
		{
      label: "Lenguaje",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [94]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [43]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
    },
		{
      label: "Geografía",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [67]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [80]},
			]
    },
		{
      label: "Historia",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [80]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [80]},
			]
    },
		{
      label: "Geometría",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [87]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
    },
		{
      label: "Filosofía",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [55]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [92]},
			]
    },
		{
      label: "Música",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [84]},
				{	label: 'Carlos Perez', data: [0]},
			]
    },
		{
      label: "Inglés",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [75]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
    },
		{
      label: "Biología",
      data: [
				{ label: 'Pedro Martinez', data: [0]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [74]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
    },
		{
      label: "Física",
      data: [
				{ label: 'Pedro Martinez', data: [60]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [46]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [0]},
				{	label: 'Montse Abreu', data: [0]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
    },
		{
      label: "Química",
      data: [
				{ label: 'Pedro Martinez', data: [44]},
				{	label: 'Ana Diaz-Granados',	data: [0]},
				{	label: 'Patricia Contreras', data: [0]},
				{	label: 'Luisa Smith',	data: [0]},
				{	label: 'Maria García', data: [71]},
				{	label: 'Montse Abreu', data: [88]},
				{	label: 'Felipe Aristizabal', data: [0]},
				{	label: 'Alicia Roa', data: [0]},
				{	label: 'Carlos Perez', data: [0]},
			]
    },
	];

	constructor(){}

	ngOnInit(){
		if (this.consultaID !== null) {
			switch (this.consultaID){
				case 1:
					this.labels = this.labels_nivel;
					break;
				case 2:
					this.labels = this.labels_asignatura;
					break;
			}
		}
	}

	colorKnowledge(valor: number): string {
    let color = "grey";
    if (valor == 0) color =  "#9A9B9C";
    else if (valor > 0 && valor < 11) color = "#FB0D1C";
    else if (valor < 21) color = "#FC5C20";
    else if (valor < 31) color = "#FC9A27";
    else if (valor < 41) color = "#FEE533";
    else if (valor < 51) color = "#FFFD38";
    else if (valor < 61) color = "#D4FD35";
    else if (valor < 71) color = "#9CFD32";
    else if (valor < 81) color = "#29F82E";
    else if (valor < 91) color = "#2AD52D";
    else if (valor < 101) color = "#1AAF54";
    return color;
  }

}
