import { CourseListModel, ResponseChallengeModel } from './../../../shared/components/cursos/modal-cursos-listado/interface/modal-cursos-listado';
import { CourseDetailResponseModel } from './../../models/courses/course-detail-response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


// Models
import { CourseModel, FilterCourseModel } from 'src/app/core/models/courses';
import { LoginService } from '../login';


const GETALLCOURSES = 'course/coursByFilter';
const GETCOURSEDETAILV2 = 'course/getCourseByIdCourse-v2';
const GETGRAPHSBYIDGROUPIDCOURSE = 'course/getGraphsByIdGroupByIdCourse'
const GETGRAPHSBYIDCOURSEFORPROFESOR = 'course/getGraphsByIdCourseForProfesor'
const CREATEUPDATECOURS = 'course/create-course-v2';
const DELETECOURSE = 'course/deleteCourse';
const FILTERCOURSENEW = 'course/coursByFilterNew';
const FILTERCOURSENEW2 = 'course/coursByFilterNew2';
const SAVEINFOCOURSE = 'course/editCourseDetails';
const EDITORSLIST = 'course/courseEditorsList';
const NOEDITORSLIST = 'course/UsersListNotEditors';
const ADDEDITOR = 'course/addEditorToCourse';
const DELETEEDITOR = 'course/deleteEditorsCourse';
const FILTERCOURSETREEV2 = 'course/coursTreeByFilterV2';
const FILTERCOURSETREEV2FORFLASH = 'course/coursTreeByFilterV2ForFlash';
const ELEMENTSTREE = 'course/getelementlist';
const ELEMENTSTREEV2 = 'course/getelementlist2';
const FILTERBYCHALLENGE = 'course/courstreebyfilterchallenge';
const CREATEPROJECTAUTO = 'course/createProjectAuto';
const MAKEVISIBLECOURSE = 'course/makeVisibleCourse';
const CHANGECOURSETYPE = 'course/changeCourseType';
const GETCOUNTRYLEVELSBYCOUNTRY = "commons/getCountryLevelsByCountry";
const GETCOUNTRYLEVELSBYID = "commons/getCountryLevelsById";
const GETCOURSESLISTRESOLVED = "course/getCoursesListResolved";
const GETCOURSEBYIDCOURSEIDGROUP = "course/getCourseByIdCourseIdGroup";
const GETCOURSESWITHHELPASKED = "course/courstreehelp";

const ELEMENTSTREEHELP = "course/getelementhelplist";

export const CHALLENGEFILTERTYPE = {
	NONE: 0,
	NOEXCLUSIVES: 1,
	EXCLUSIVES: 2,
	BOTH: 3
}

@Injectable({
	providedIn: "root",
})
export class CoursesService {
	public coursesSusbcribe = new Subject<{ data }>();

	constructor(private http: HttpClient, private loginService: LoginService) {}

	/**
	 * Function that returns all courses filtered
	 * @param params Object with filter params
	 * @returns An Observable of CourseModel[]
	 */
	getAllCourses(params: FilterCourseModel): Observable<CourseModel[]> {
		return this.http.post<CourseModel[]>(GETALLCOURSES, params);
	}

	/**
	 * Function that returns the information of a given course
	 * @param idCourse Course identificator
	 * @returns An Observable of CourseDetailResponseModel
	 */
	getCourseById(idCourse: number): Observable<CourseDetailResponseModel> {
		let httpParams = new HttpParams().set("idCourse", idCourse.toString());
		return this.http.get<CourseDetailResponseModel>(GETCOURSEDETAILV2, {
			params: httpParams,
		});
	}

	getCourseByIdCourseIdGroup(idCourse: number, idGroup: number) {
		return this.http.get<any>(
			`${GETCOURSEBYIDCOURSEIDGROUP}/${idCourse}/${idGroup}`
		);
	}

	getGraphsByIdGroupByIdCourse(idCourse: number, idGroup: number) {
		return this.http.get<any>(
			`${GETGRAPHSBYIDGROUPIDCOURSE}/${idGroup}/${idCourse}`
		);
	}

	getGraphsByIdCourseForProfesor(idCourse: number) {
		return this.http.get<any>(`${GETGRAPHSBYIDCOURSEFORPROFESOR}/${idCourse}`);
	}

	/**
	 * Function that creates or updates the course
	 * @param course Object course
	 * @param file Image file
	 * @returns An Observable of CourseResponseModel
	 */
	setCourse(course: CourseModel, file?: File | string): Observable<any> {
		let form: FormData = new FormData();

		form.append("cours", JSON.stringify(course));
		form.append("files", file);

		return this.http.post<any>(CREATEUPDATECOURS, form, {
			reportProgress: true,
			observe: "events",
		});
	}

	/**
	 * Function that removes a given course
	 * @param idCourse Course identifier
	 * @returns An Observable. Get HTTP CODE 200 to delete OK
	 */
	deleteCourse(idCourse: number): Observable<any> {
		let params = new HttpParams().set("idCourse", idCourse.toString());
		return this.http.delete<any>(DELETECOURSE, { params: params });
	}

	// FILTRADO DE CURSOS, BUSCADOR DE LA NUEVA PANTALLA
	// NUEVO ENDPOINT
	filtradoCursosNew(filtrado: String, myCourses: boolean, rol: string) {
		const body = {
			filter: filtrado,
			myCourses: myCourses,
		};
		return this.http.post<any>(`${FILTERCOURSENEW}/${rol}`, body);
	}
	filtradoCursosNew2(filtrado: String, myCourses: boolean, rol: string, idGroup: number) {
		const body = {
			filter: filtrado,
			myCourses: myCourses,
		};
		return this.http.post<any>(`${FILTERCOURSENEW2}/${rol}/${idGroup}`, body);
	}

	filtradoCursosGrupo(idGroup: number, filter: any) {
		let url = `group/coursesAsigned`;
		const q = {
			idGroup: idGroup,
			filter: filter.filtrado,
		};
		return this.http.post<any>(url, q);
	}

	recordarQuizesListado(idCurso) {
		return this.http.get<any>(`quizze/course/${idCurso}/user`);
	}

	saveInfoCourse(data: any, idCurso: number) {
		const body = {
			idCourse: idCurso,
			courseTittle: data.title,
			idSubject: data.subject,
			idCountry: data.country,
			deckSize: data.deckSize,
		};

		return this.http.post<any>(`${SAVEINFOCOURSE}`, body);
	}

	///////////////   EDITORES    ///////////////
	/////////////////////////////////////////////

	editorList(idCourse: number) {
		return this.http.get<any>(`${EDITORSLIST}/${idCourse}`);
	}

	noEditorList(idCourse: number, filter: any) {
		const body = {
			idCourse: idCourse,
			filter: filter,
		};
		return this.http.post<any>(`${NOEDITORSLIST}`, body);
	}

	addEditor(idCourse: number, idUser: number) {
		return this.http.get<any>(`${ADDEDITOR}/${idCourse}/${idUser}`);
	}

	deleteEditor(idCourse: number, idUser: number) {
		return this.http.delete<any>(`${DELETEEDITOR}/${idCourse}/${idUser}`);
	}

	/** LLAMADAS PARA MODO LISTA DE CURSOS */

	//Listado con los cursos en modo arbol y con opción de filtro filtrados
	coursesListMode(
		filter: string,
		rol: string,
		isMyCourses: boolean,
		idGroup?: number
	): Observable<CourseListModel[]> {
		const body = {
			filter: filter,
			myCourses: isMyCourses,
			idGroup: idGroup || null,
		};
		return this.http.post<CourseListModel[]>(
			`${FILTERCOURSETREEV2}/${rol}`,
			body
		);
	}

	coursesListModeForFlash(
		filter: string,
		isMyCourses: boolean,
		idGroup?: number
	): Observable<CourseListModel[]> {
		const body = {
			filter: filter,
			myCourses: isMyCourses,
			idGroup: idGroup || null,
		};
		return this.http.post<CourseListModel[]>(
			`${FILTERCOURSETREEV2FORFLASH}`,
			body
		);
	}

	coursesWithHelpAsked(): Observable<CourseListModel[]> {
		return this.http.get<CourseListModel[]>(GETCOURSESWITHHELPASKED);
	}

	coursesTreeListByChallenge(
		idGroup: number,
		option: number
	): Observable<ResponseChallengeModel> {
		const body = {
			idGroup: idGroup,
			excOption: option, //0 Vacío / 1 No exclusivos / 2 Exclusivos / 3 Ambos
		};
		return this.http.post<ResponseChallengeModel>(`${FILTERBYCHALLENGE}`, body);
	}

	/**
	 * Función para mostrar los elementos dentro del componente app-tree (NO DESAFÍOS)
	 * @param idTarget ID del grafo
	 * @param idNode ID del nodo
	 * @param rol ID del rol
	 * @returns
	 */
	childrensListMode(
		idCourse: number,
		idTarget: number,
		idNode: number,
		rol: string
	): Observable<CourseListModel[]> {
		return this.http.get<CourseListModel[]>(
			`${ELEMENTSTREE}/${idCourse}/${idTarget}/${idNode}/${rol}`
		);
	}

	/**
	 * Función para mostrar los elementos dentro del componente app-tree (NO DESAFÍOS)
	 * @param idTarget ID del grafo
	 * @param idNode ID del nodo
	 * @param rol ID del rol
	 * @returns
	 */
	childrensListModeHelp(
		idCourse: number,
		idTarget: number,
	): Observable<CourseListModel[]> {
		return this.http.get<CourseListModel[]>(
			`${ELEMENTSTREEHELP}/${idCourse}/${idTarget}`
		);
	}

	/**
	 * Función para mostrar los elementos dentro del componente app-tree para los desafíos
	 * @param excOption Número que identifica la selección de grafos exclusivos o no
	 * @param idGroup ID del grupo
	 * @param idCourse ID del curso
	 * @param idTarget ID del grafo
	 * @param idNode ID del nodo
	 * @returns
	 */
	newChildrensListMode(
		excOption: number,
		idGroup: number,
		idCourse: number,
		idTarget: number,
		idNode: number
	): Observable<CourseListModel[]> {
		return this.http.get<CourseListModel[]>(
			`${ELEMENTSTREEV2}/${excOption}/${idGroup}/${idCourse}/${idTarget}/${idNode}`
		);
	}
	createProjectAutomatically() {
		return this.http.get<any>(`${CREATEPROJECTAUTO}`);
	}

	makeVisibleCourse(idCourse: number, isVisible: number) {
		return this.http.get<any>(`${MAKEVISIBLECOURSE}/${isVisible}/${idCourse}`);
	}

	changeCourseType(idCourse: number, courseType: number) {
		return this.http.get<any>(`${CHANGECOURSETYPE}/${courseType}/${idCourse}`);
	}

	getCountryLevelsByCountry(country: string) {
		return this.http.get<any>(`${GETCOUNTRYLEVELSBYCOUNTRY}/${country}`);
	}

	getCountryLevelsById(id: number) {
		return this.http.get<any>(`${GETCOUNTRYLEVELSBYID}/${id}`);
	}

	/**
	 * METODO PARA OBTENER LISTADO DE CURSOS QUE EL ESTUDIANTE HA REALIZADO SIQUIERA UNO DE SUS QUIZZES
	 * @author jdela
	 */
	getCoursesListResolved() {
		return this.http.get<any>(`${GETCOURSESLISTRESOLVED}`);
	}
}
