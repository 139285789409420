import { UsersService } from './../../../core/services/users/users.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GroupModel } from 'src/app/core/models/groups/groups-model';
import { User } from 'src/app/core/models/users/user.models';
import { CoursesService } from 'src/app/core/services/courses';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { DynamicDatabase } from 'src/app/core/utils/treeView.utils';
import { environment } from 'src/environments/environment';
import { GruposOpcionesComponent } from '../grupos/grupos-opciones/grupos-opciones.component';
import { ModalEditarGrupoComponent } from '../grupos/modal-editar-grupo/modal-editar-grupo.component';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { SearchGroupComponent } from './search-groups/search-group.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, interval, Subject, Subscription  } from 'rxjs';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { ModalAceptarCancelarComponent } from '../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component';
import { MastersService } from 'src/app/core/services/masters';
import { CenterService } from 'src/app/core/services/center/center.service';

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

@Component({
	selector: "app-modal-grupos-editar",
	templateUrl: "./modal-grupos-editar.component.html",
	styleUrls: ["./modal-grupos-editar.component.scss"],
})
export class ModalGruposEditarComponent implements OnInit {
	public gruposList: GroupModel[] = [];
	public gruposListAux: GroupModel[] = [];
	public gruposListBuscar: GroupModel[] = [];
	user: User;
	profile: string;
	studentProfile: Profiles = Profiles.Student;
	group: GroupModel;
	public formulario: UntypedFormGroup;
	numberOfInvited: number = 0;
	invitacionesList: any;
	numberOfInvitedTeacher: number = 0;
	invitacionesListTeacher: any;
	public mediaViewUrl = `${environment.groupImagen}`;
	cargando: boolean;
	isProfesor: boolean = false;
	private invitacionesGruposInterval: Subscription;
	private destroy$ = new Subject();
	idGrupoSelect: number = 0;

	networkSpeed = 1;
	storedGroupInfo: any;
	nivelesColors: { [key: string]: string } = {};
	center: any;

	constructor(
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public loginService: LoginService,
		public mastersService: GetDataService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public database: DynamicDatabase,
		public router: Router,
		private groupService: GruposService,
		public userService: UsersService,
		private formBuild: UntypedFormBuilder,
		private toaster: ToasterService,
		private masterService: MastersService,
		private centerService: CenterService,
	) {
		this.formulario = this.formBuild.group({
			filtrado: [""],
		});
	}

	ngOnInit() {
		this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
		this.storedGroupInfo = JSON.parse(localStorage.getItem('selectedGroupInfo'));
    this.idGrupoSelect = this.storedGroupInfo ? this.storedGroupInfo.idGroup : 0;
		this.cargando = true;
		this.profile = this.loginService.getProfile();
		this.user = this.loginService.getUser();
		//*Si es estudiante, se obtiene el listado de grupos que pertenece
		//Si no, se obtiene el listado de grupos creados
		if (this.profile === this.studentProfile) {
			this.getGruposEstudiante();
			this.getInvitacionesGrupos();
			this.startInvitacionesGruposInterval();
		} else {
			this.getGrupos();
			this.isProfesor = true;
			this.getAssignmentCenters();
		}
	}

	private startInvitacionesGruposInterval() {

		this.invitacionesGruposInterval = interval(5000).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.getInvitacionesGrupos();
			this.getGruposEstudianteBucle();
		});
	}

	ngOnDestroy() {
		if (this.invitacionesGruposInterval) {
			this.invitacionesGruposInterval.unsubscribe();
		}
		this.destroy$.next(true);
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
  }

	getGruposEstudiante() {
		this.gruposList = [];
		this.groupService
			.getGruposEstudiante()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				this.addGrupos(res.data);
				this.cargando = false;
			});
	}

	getGruposEstudianteBucle() {
		this.gruposListAux = [];
		this.groupService
			.getGruposEstudiante()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				this.addGruposBucle(res.data);
			});
	}

	addGruposBucle(data: any) {
		for (let index = 0; index < data.length; index++) {
			const element = data[index] as GroupModel;
			this.gruposListAux.push(element);
		}
		if (this.gruposList.length != this.gruposListAux.length) {
			this.getGruposEstudiante();
		}
	}

	getGrupos() {
		this.gruposList = [];
		this.groupService
			.getGrupos()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				this.addGrupos(res.data);
				this.cargando = false;
			});
	}

	addGrupos(data: any) {
		for (let index = 0; index < data.length; index++) {
			const element = data[index] as GroupModel;
			this.groupService
				.getPeticionesProfesor(element.idGroup)
				.pipe(finalize(() => (this.cargando = false)))
				.subscribe(
					(res: any) => {
						element.numberOfInvited = res.data.length;
						element.invitacionesList = res.data;
						this.cargando = false;
					},
					(err) => console.log(err)
				);

			this.gruposList.push(element);
		}
	}

	verDetalleGrupo(grupo: GroupModel) {
		this.destroy$.next(true);
		const selectedGroupInfo = {
			idGroup: grupo.idGroup,
			title: grupo.title,
			description: grupo.description,
			group: grupo,
			idUser: this.user.idUser,
			assignedProjects: grupo.assignedProjects,
			assignedStudents: grupo.assignedStudents,
		};
		localStorage.removeItem("selectedGroupInfo");
		localStorage.setItem(
			"selectedGroupInfo",
			JSON.stringify(selectedGroupInfo)
		);
		this.groupService.setRunInterval(true);
		this.closeModal(selectedGroupInfo); // AHORA SIEMPRE LO CERRAMOS, NO IMPORTA EL ROL 10-ENE-2024
		//SIEMPRE VA A GUARDAR LA INFORMACION DEL GRUPO, SI ES PROFESOR SOLO CIERRA LA MODAL, SI ES ESTUDIANTE ABRE LA QUE SIGUE

		// if (this.profile === Profiles.Teacher) {
		// 	this.closeModal("");
		// } else {
		// 	const modalRef = this.modalService.open(GruposOpcionesComponent, {
		// 		scrollable: true,
		// 		windowClass: `${MODAL_DIALOG_TYPES.W95}`,
		// 	});
		// 	modalRef.componentInstance.id = grupo.idGroup;
		// 	modalRef.componentInstance.group = grupo;
		// 	modalRef.componentInstance.isStudent =
		// 		this.profile === Profiles.Student ? true : false;

		// 	modalRef.result.then(
		// 		(result) => {
		// 			this.startInvitacionesGruposInterval();
		// 			if (result !== "") {
		// 				if (result === "delete") {
		// 					this.getGrupos();
		// 				} else {
		// 					for (let index = 0; index < this.gruposList.length; index++) {
		// 						const grupo = this.gruposList[index];
		// 						if (grupo.idGroup === result.idGroup) {
		// 							grupo.title = result.title;
		// 							grupo.description = result.description;
		// 							grupo.imagen = result.imagen;
		// 							grupo.idLanguage = result.idLanguage;
		// 							break;
		// 						}
		// 					}
		// 				}
		// 			}
		// 		},
		// 		(err) => {
		// 			console.error("ERROR verDetalleGrupo(): ", err);
		// 		}
		// 	);
		// }

		//Creamos el log
		const bodyLog1 = {
			action: "grupos",
			actionData: "Ha seleccionado el grupo con id " + grupo.idGroup + ".",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: grupo.idGroup,
		};
		this.masterService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	modificarGrupo(grupo: GroupModel) {
		const modalRef = this.modalService.open(ModalEditarGrupoComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		this.destroy$.next(true);
		modalRef.componentInstance.id = grupo.idGroup;
		modalRef.componentInstance.group = grupo;

		modalRef.result.then(
			(result) => {
				if (result !== "") {
					if (result === "delete") {
						this.getGrupos();
						//this.closeModal('delete');
						localStorage.removeItem("selectedGroupInfo");
					} else {
						this.group = result as GroupModel;
						setTimeout(() => {
							this.getGrupos();
						}, 1500);
					}
				}
			},
			(err) => {}
		);
	}

	salirGrupo(grupo: GroupModel){
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
			{
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30
			});
		//modalRef.componentInstance.optionalTitle = this.translateService.instant('ALERTCHALLENGENODE.SELECTGROUPTITLE')
		modalRef.componentInstance.mensaje = this.translateService.instant('MODALGROUPSEDIT.MSJSALIRGRUPO')
		modalRef.componentInstance.disableCancel = false;

		modalRef.result.then((result: boolean) => {
			if(result){
				this.groupService.eliminarEstudiante(grupo.idGroup, this.user.idUser).subscribe(res => {
					this.toaster.success(this.translateService.instant('GRUPOS.ELIMINADO'));
					this.getGruposEstudiante();
					this.getInvitacionesGrupos();
					this.startInvitacionesGruposInterval();
					},
					err => console.log(err));
			}
		});
	}

	closeModal(sendData?: any) {
		this.destroy$.next(true);
		this.activeModal.close(sendData);
	}

	nuevoGrupo() {
		if(this.center){
			const modalRef = this.modalService.open(ModalEditarGrupoComponent, {
				scrollable: false,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			modalRef.componentInstance.id = "nuevo";

			modalRef.result.then(
				(result) => {
					if (result !== "") {
						this.gruposList.push(result as GroupModel);
					}
				},
				(err) => {}
			);
		} else{
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
				{
					scrollable: true,
					windowClass: MODAL_DIALOG_TYPES.W30
				});
			modalRef.componentInstance.mensaje = this.translateService.instant('GRUPOS.ASSIGNCENTER')
			modalRef.componentInstance.disableCancel = false;

			modalRef.result.then((result: boolean) => {
				if(result){
					this.closeModal('');
					this.masterService.openSettings(Profiles.Teacher);
					//Creamos el log
					const bodyLog = {
						action: "ajustes",
						actionData: "Ha entrado en el menú de Ajustes.",
						startDate: new Date(),
						endingDate: null,
						role: this.loginService.getProfile(),
						idGroup: 0,
					};
					this.masterService.createActivityHistory(bodyLog.action, bodyLog.actionData, bodyLog.startDate, bodyLog.endingDate, bodyLog.role, bodyLog.idGroup).subscribe();
				}
			});
		}

	}

	generateHexCode() {
		const letters = "89ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 8)];
		}
		return color;
	}

	// Método para obtener el color de un nivel
	public getNivelesColor(nivel: string): string {
		// Si el nivel es "Sin nivel específico" se pone gris
		if(nivel == "Sin nivel específico"){
			return "#EDF1F7"
		} else{
					// Si el color para el nivel ya fue generado, se devuelve
		if (this.nivelesColors[nivel]) {
			return this.nivelesColors[nivel];
		}
		// Si no, se genera un nuevo color, se almacena y se devuelve
		const newColor = this.generateHexCode();
		this.nivelesColors[nivel] = newColor;
		return newColor;
		}
	}

	aceptar(invitacion: any) {
		this.groupService
			.aceptarInvitacionEstudiante(invitacion.idGroup, invitacion.idStudent)
			.subscribe(
				(res: any) => {
					this.toaster.success(
						this.translateService.instant("MODALGROUPSEDIT.INVITATIONOK")
					);
					this.getGruposEstudiante();
					this.getInvitacionesGrupos();
				},
				(err) => console.log(err)
			);
	}

	aceptarTeacher(idGroup, invitacion: any) {
		this.groupService.aceptarInvitacion(idGroup, invitacion.idUser).subscribe(
			(res: any) => {
				this.toaster.success(
					this.translateService.instant("MODALGROUPSEDIT.INVITATIONOK")
				);
				this.getGrupos();
			},
			(err) => console.log(err)
		);
	}

	rechazar(invitacion: any) {
		this.groupService
			.rechazarInvitacionEstudiante(invitacion.idGroup, invitacion.idStudent)
			.subscribe(
				(res: any) => {
					this.toaster.success(
						this.translateService.instant("MODALGROUPSEDIT.INVITATIONREJECTED")
					);
					this.getInvitacionesGrupos();
				},
				(err) => console.log(err)
			);
	}

	rechazarTeacher(idGroup, invitacion: any) {
		this.groupService.rechazarInvitacion(idGroup, invitacion.idUser).subscribe(
			(res: any) => {
				this.toaster.success(
					this.translateService.instant("MODALGROUPSEDIT.INVITATIONREJECTED")
				);
				this.getGrupos();
			},
			(err) => console.log(err)
		);
	}

	getInvitacionesGrupos() {
		this.groupService
			.getGruposInvitaciones()
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					// console.log(res)
					this.numberOfInvited = res.data.length;
					this.invitacionesList = res.data;
				},
				(err) => console.log(err)
			);
	}

	filtrarEstudiantes() {
		this.cargando = true;
		const filtradoValue = this.formulario.value;
		this.groupService.getGruposFiltradoEstudiante(filtradoValue).subscribe(
			(res) => {
				this.gruposListBuscar = res.data;
				this.cargando = false;
			},
			(err) => console.log(err)
		);
	}

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	solicitarUnirGrupo(grupoUniver: any) {
		console.log(grupoUniver);
		this.groupService.solicitarUnirGrupo(grupoUniver.idGroup).subscribe(
			(res: any) => {
				console.log(res);
				this.toaster.success(
					this.translateService.instant("MODALGROUPSEDIT.REQUESTSENT")
				);
				this.filtrarEstudiantes();
				this.cargando = false;
			},
			(err) => console.log(err)
		);
	}

	openSearchGroups() {
		const modalRef = this.modalService.open(SearchGroupComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.result.then(
			(result) => {
				if (result !== "") {
					this.gruposListBuscar = result;
				}
			},
			(err) => {}
		);
	}

	noGroup() {
		localStorage.removeItem("selectedGroupInfo");
		this.storedGroupInfo = JSON.parse(localStorage.getItem('selectedGroupInfo'));
		this.idGrupoSelect = this.storedGroupInfo ? this.storedGroupInfo.idGroup : 0;
		if (this.profile === this.studentProfile) {
			this.getGruposEstudiante();
			this.getInvitacionesGrupos();
			this.startInvitacionesGruposInterval();
		} else {
			this.getGrupos();
			this.isProfesor = true;
		}
	}

	private getAssignmentCenters() {
		this.centerService
			.getListCenterByIdUser()
			.pipe()
			.subscribe((result) => {
				this.center = result[0];
			});
	}

}
